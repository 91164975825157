import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import Loading from '../../components/site/Loading';
import AuthService from '../../services/auth';
import { Link } from '../../entities/notification';

const logo = process.env.PUBLIC_URL + '/galilaio/galilaio_logo.svg';

interface ShortLinkPageState {
  loading: boolean;
}

interface ShortLinkPageProps {
  match?: any;
  history: any;
}

class ShortLinkPage extends Component<ShortLinkPageProps, ShortLinkPageState> {
  
  constructor(props: ShortLinkPageProps) {
    super(props);
    this.state = {
      loading: false
    }
  }

  componentDidMount = () => {
    // get id from url
    // query db and update campaign stats
    // redirect to the correct page

    const linkId = this.props.match.params.id;

    this.fetchShortLink(linkId);

  }

  fetchShortLink = async (linkId: string) => {

    this.setState({loading: true});
    // fetch
    const shortLinkRes = await axios.get(`${AuthService.getApiUrls().notifications}/shortlink/${linkId}`);

    if (shortLinkRes) {

      const shortLink: Link = shortLinkRes.data;

      if (shortLink.type === 'campaign') {
        // update campaign stat
        const statData = {
          type: 'CampaignStat',
          data: {
            compaignId: shortLink.typeId,
            name: 'totalClicks',
            value: 1
          }
        };
        await axios.post(`${AuthService.getApiUrls().site}/campaign/${shortLink.typeId}/stat`, statData);
        // update campaign contact status
        await axios.put(`${AuthService.getApiUrls().site}/campaign/contact/${shortLink.contactId}`, { status: 'clickThrough' });
      }

      if (shortLink.redirect) {
        if (shortLinkRes.data.redirect.indexOf('?') !== -1) {
          window.location.replace(`${shortLinkRes.data.redirect}&ref=${linkId}`);  
        } else {
          window.location.replace(`${shortLinkRes.data.redirect}?ref=${linkId}`);
        }
      } else {
        window.location.replace(`https://homewize.io/?ref=${linkId}`);
      }

    } else {
      window.location.replace("https://homewize.io/");
    }
    this.setState({
      loading: false
    });
  }

  render() {
    return ( 
      <div style={styles.container}>
        <div style={styles.title}>Please wait. You will be forwarded...</div>
        <Loading open={this.state.loading} />
        <div style={styles.footerContainer}>
          < div style={styles.footer}>Powered by</div>
            <img src={logo} style={styles.logo} />
        </div>
      </div>
    )
  }

}

export default withRouter<any, any>(ShortLinkPage);

const styles: any = {
  container: {
    padding: 60
  },
  logoContainer: {
    margin: '0px auto'
  },
  title: {
    fontSize: 18,
    textAlign: 'center'
  },
  footerContainer: {
    display: 'flex',
    position: 'fixed',
    left: 0,
    bottom: 20,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  footer: {
    fontSize: 10
  },
  logo: {
    width: 50
  }
}