import React, { useState } from "react";
// material
import { Typography, Box, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// components
import TextInput from "../inputs/TextInput";
// entities
import { Campaign } from "../../entities/site";
import TextField from "@material-ui/core/TextField";

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

interface TabPanelProps {
  campaign?: Campaign;
  messagesPerDay: number;
  weekDays: number[];
  startDate: string;
  endDate: string;
  onChange(type: string, valueStr: string): void;
  onWeekDayChange(day: number): void;
}

const AddCampaignTimeline: React.FC<TabPanelProps> = (props) => {
  const classes = useStyles({});
  // const [rate, setRate] = useState<string>('');
  // const [startDate, setStartDate] = useState<string>('');
  // const [endDate, setEndDate] = useState<string>('');

  const isSelected = (day: number) => {
    if (props.weekDays.indexOf(day) > -1) {
      return true;
    }
    return false;
  };

  return (
    <div className={classes.timeLineSection}>
      <div className={classes.header}>Timeline</div>
      <div className={classes.daysSectionContainer}>
        <div>
          <div>
            <div className={classes.fieldHeader}>
              Number of texts sent (minimum 100 per day)
            </div>
            <div className={classes.perDay}>
              <input
                className={classes.perDayField}
                value={props.messagesPerDay}
                onChange={(event) =>
                  props.onChange("messagesPerDay", event.target.value)
                }
              />
              {/* <TextField
            className={classes.date}
            variant={"outlined"}
            type="date"
            value={props.startDate}
            onChange={(event: any) => props.onChange('startDate', 0, event.target.value)}
          />
          <TextField
            inputProps={{
              className: classes.input
              maxLength: 10
            }}
            variant={"outlined"}
            value={props.zipCode}
            onChange={(event) => props.onChange("messagesPerDay", parseInt(event.target.value, 10), event.target.value)}
          /> */}
              <span className={classes.perDayLabel}>Per Day</span>
            </div>
          </div>
        </div>
        <div>
          <div className={classes.fieldHeader}>
            Select the days of the week you want to send
          </div>
          <Grid container className={classes.days}>
            {days.map((day, index) => (
              <div
                key={`weekday-${index}`}
                className={
                  isSelected(index + 1)
                    ? classes.weekDaySelected
                    : classes.weekDay
                }
                onClick={() => props.onWeekDayChange(index + 1)}
              >
                {day}
              </div>
            ))}
          </Grid>
        </div>
      </div>
      <div>
        <Grid container spacing={1}>
          <Grid item>
            <div className={classes.fieldHeader}>Start Date</div>
            <TextField
              className={classes.date}
              variant={"outlined"}
              type="date"
              value={props.startDate}
              onChange={(event: any) =>
                props.onChange("startDate", event.target.value)
              }
            />
          </Grid>
          <Grid item>
            <div className={classes.fieldHeader}>End Date</div>
            <TextField
              className={classes.date}
              variant={"outlined"}
              type="date"
              value={props.endDate}
              onChange={(event: any) =>
                props.onChange("endDate", event.target.value)
              }
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default AddCampaignTimeline;

/*<Grid container spacing={2}>
  <Grid item xs={12}>
    <TextInput
        title="Number of texts sent (minimum 100 per day)"
        type="number"
        placeholder=""
        result={props.messagesPerDay}
        onChange={(event: any) => props.onChange('messagesPerDay', event.target.value, '')}
    />
  </Grid>

  <Grid item xs={12}>
    Select the days of the week you want to send
  </Grid>
  <Grid item xs={2}>
    <div className={(isSelected(1) ? classes.weekDaySelected : classes.weekDay)} onClick={() => props.onWeekDayChange(1)}>Mon</div>
  </Grid>
  <Grid item xs={2}>
    <div className={(isSelected(2) ? classes.weekDaySelected : classes.weekDay)} onClick={() => props.onWeekDayChange(2)}>Tue</div>
  </Grid>
  <Grid item xs={2}>
    <div className={(isSelected(3) ? classes.weekDaySelected : classes.weekDay)} onClick={() => props.onWeekDayChange(3)}>Wed</div>
  </Grid>
  <Grid item xs={2}>
    <div className={(isSelected(4) ? classes.weekDaySelected : classes.weekDay)} onClick={() => props.onWeekDayChange(4)}>Thu</div>
  </Grid>
  <Grid item xs={2}>
    <div className={(isSelected(5) ? classes.weekDaySelected : classes.weekDay)} onClick={() => props.onWeekDayChange(5)}>Fri</div>
  </Grid>
  <Grid item xs={2}>
    <div className={(isSelected(6) ? classes.weekDaySelected : classes.weekDay)} onClick={() => props.onWeekDayChange(6)}>Sat</div>
  </Grid>

  <Grid item xs={4}>
    <TextInput
        title="Start Date"
        type="date"
        placeholder=""
        result={props.startDate}
        onChange={(event: any) => props.onChange('startDate', 0, event.target.value)}
    />
  </Grid>
  <Grid item xs={4}>
    <TextInput
        title="End Date"
        type="date"
        placeholder=""
        result={props.endDate}
        onChange={(event: any) => props.onChange('endDate', 0, event.target.value)}
    />
  </Grid>
</Grid>*/

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      width: "auto",
      // height: 24,
      color: "#333333",
      fontFamily: "Asap",
      fontSize: 18,
      // marginTop: 30,
    },
    daysSectionContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "80%",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        display: "flex",
        flexDirection: "column",
      },
      [theme.breakpoints.only("md")]: {
        display: "flex",
        flexDirection: "space-between",
        width: "90%",
      },
    },
    fieldHeader: {
      // width: 282,
      color: "#666666",
      fontFamily: "Asap",
      fontSize: 16,
      marginTop: 15,
    },
    perDayField: {
      boxSizing: "border-box",
      height: 40,
      width: 120,
      border: "1px solid #00ABCA",
      borderRadius: 2,
      backgroundColor: "#FFFFFF",
      fontSize: 18,
      padding: "10px",
    },
    date: {
      marginTop: 10,
      [theme.breakpoints.only("xs")]: {
        minWidth: "150px",
      },
    },
    perDay: {
      display: "flex",
      alignItems: "center",
      marginTop: 10,
      marginBottom: 20,
    },
    perDayLabel: {
      height: 19,
      width: 55,
      color: "#999999",
      fontFamily: "Asap",
      fontSize: 16,
      marginLeft: 10,
    },
    days: {
      marginTop: 10,
    },
    container: {
      padding: 10,
    },
    weekDay: {
      padding: "10px 15px 10px 15px",
      border: "1px solid #CCC",
      fontSize: 16,
      cursor: "pointer",
      width: 80,
      height: 42,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "10px 10px 10px 0px",
    },
    weekDaySelected: {
      padding: "10px 15px 10px 15px",
      border: "4px solid #7FD4E3",
      fontSize: 16,
      width: 80,
      height: 42,
      cursor: "pointer",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "10px 10px 10px 0px",
    },
    timeLineSection: {
      padding: "0px",
      [theme.breakpoints.down("md")]: {
        padding: "0px 15px",
      },
    },
  })
);
