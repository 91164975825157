
import React from 'react';
// import { ResponsivePie } from '@nivo/pie'

interface PieChartProps {
    stat: number;
    total: number;
}

const StatChart: React.FC<PieChartProps> = (props) => {

    const data = [
        {
          "id": "sent",
          "label": "sent",
          "value": props.stat,
          "color": "hsl(82, 70%, 50%)"
        },
        {
          "id": "total",
          "label": "total",
          "value": props.total,
          "color": "hsl(65, 70%, 50%)"
        }
      ];
      return (null);

    // return (
    //     <ResponsivePie
    //         data={data}
    //         margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
    //         innerRadius={0.8}
    //         padAngle={0}
    //         cornerRadius={0}
    //         colors={['#29E2BF', '#E0E0E0']}
    //         borderWidth={1}
    //         borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
    //         radialLabelsSkipAngle={10}
    //         radialLabelsTextXOffset={6}
    //         radialLabelsTextColor="#333333"
    //         radialLabelsLinkOffset={0}
    //         radialLabelsLinkDiagonalLength={16}
    //         radialLabelsLinkHorizontalLength={24}
    //         radialLabelsLinkStrokeWidth={1}
    //         radialLabelsLinkColor={{ from: 'color' }}
    //         slicesLabelsSkipAngle={10}
    //         slicesLabelsTextColor="#333333"
    //         motionStiffness={90}
    //         motionDamping={15}
    //         enableRadialLabels={false}
    //         enableSlicesLabels={false}
    //     />
    // );
};

export default StatChart;