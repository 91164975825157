import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Auth from '@aws-amplify/auth';
import axios from 'axios';

// app
import { Auth as AuthEntity, Session } from '../../../entities/auth';
import { Message } from '../../../entities/site';
import { addAuth, deleteAuth, addSession, setMessage, clearMessage } from '../../../store/action-creators';
import { colors } from '../../../config/styles';
import UtilsService from '../../../services/utils';
// containers
import AuthContainer from '../containers/Auth';
import RowContainer from '../containers/Row';
// components
import TextInput from '../../../components/inputs/TextInput'; 
import ActionButton from '../../../components/buttons/ActionButton';
import { MessageTypes } from '../../../components/message/Message';
// models
import { APIUrl } from '../../../entities/site';
// service 
import AuthService from '../../../services/auth';


const styles: any = {
  link: {
    color: colors.blue,
    cursor: 'pointer'
  },
  footer: {
    textAlign: 'center',
    fontSize: 12
  },
  forgot: {
    textAlign: 'center',
    fontSize: 12,
    color: colors.blue,
    cursor: 'pointer'
  }
}

interface LoginProps {
  open: any;
  auth: AuthEntity;
  session: Session;
  message: Message;
  apiUrls: APIUrl;
  onClose(route?: string): void;
  addAuth(auth: AuthEntity): void;
  deleteAuth(): void;
  addSession(session: Session): void;
  setMessage(message: Message): void;
  clearMessage(): void;
}

interface LoginState {
  loading: boolean;
  email: string;
  password: string;
  disabled: boolean;
}

class Login extends Component<LoginProps, LoginState> {
  
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      loading: false,
      email: '',
      password: '',
      disabled: false
    }
  }

  componentDidMount = () => {
    // mounted
    this.props.clearMessage();
  }

  handleLogin = async () => {
    // login
    try {
      let redirectUrl = '/';
      this.props.clearMessage();
      this.setState({loading: true, disabled: true});
      let currentSession = this.props.session;
      const user = await Auth.signIn(this.state.email.trim(), this.state.password.trim());
      const authSession: any = await Auth.currentSession();

      // get db user
      const userRes = await axios.get(`${AuthService.getApiUrls().user}/user/${user.username}`);

      if (userRes.data.status === 'delete') {
        await Auth.signOut();
        this.props.deleteAuth();
        this.props.setMessage({message: 'Error logging in', messageType: MessageTypes.Error});
        this.setState({loading: false, disabled: false});
        return;
      }
      
      if (userRes.data.status === 'reset') {
        redirectUrl = '/auth/reset';
      }

      const auth: AuthEntity = {
        id: user.username,
        user: userRes.data,
        token: authSession.accessToken.jwtToken,
        payload: authSession.accessToken.payload
      }

      currentSession = {
        page: redirectUrl,
        selectedMortgage: undefined,
        address: undefined,
        payment: 0,
        homesViewed: 0,
        option: 0
      }

      this.setState({loading: false, disabled: false});
      this.props.addAuth(auth);
      this.props.addSession(currentSession);
      this.props.onClose(currentSession.page);

    } catch (error) {
      console.log('error', error);
      this.setState({loading: false, disabled: false});
      this.props.setMessage({message: 'Error logging in', messageType: MessageTypes.Error});
      await UtilsService.logError(AuthService.getApiUrls().core, 'Login', 'handleLogin', error);
    }
  }

  handleSignup = () => {
    // login
    this.props.onClose('signup');
  }

  handleForgot = () => {
    // login
    this.props.onClose('forgot');
  }

  onEmailChange = (event: any) => {
    this.setState({email: event.target.value});
  }

  onPasswordChange = (event: any) => {
    this.setState({password: event.target.value});
  }

  render() {
    return ( 
      <AuthContainer 
        open={this.props.open} 
        title="Login" 
        onClose={this.props.onClose}
        message={this.props.message}
      >
        <RowContainer>
          <TextInput 
            type="string"
            placeholder="Email"
            title="Email"
            result={this.state.email}
            onChange={this.onEmailChange} 
          />
        </RowContainer>
        <RowContainer>
          <TextInput 
            type="password"
            placeholder="Password"
            title="Password"
            result={this.state.password}
            onChange={this.onPasswordChange} 
          />
        </RowContainer>
        <RowContainer>
          <ActionButton name="Login" onClick={this.handleLogin} loading={this.state.loading} size="sm" fullWidth disabled={this.state.disabled} />
        </RowContainer>
        <RowContainer>
          <div style={styles.footer}>Not a member? <span style={styles.link} onClick={this.handleSignup}>Sign Up</span></div>
        </RowContainer>
        <RowContainer>
          <div style={styles.forgot} onClick={this.handleForgot}>Forgot passord?</div>
        </RowContainer>
      </AuthContainer>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session,
    message: state.site.message,
    apiUrls: state.site.apiUrls
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ addAuth, deleteAuth, addSession, setMessage, clearMessage }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(Login));
