import React from 'react';
import { Modal, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
// import { StringifyOptions } from 'query-string';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

interface NavBarProps {
  route: string;
  icon: string;
  onClick(): void;
}
  
const NavBar: React.FC<NavBarProps> = (props: NavBarProps) => {

  const classes = useStyles({});

  return (
    <div className={classes.container}>
      <div className={classes.dash} onClick={props.onClick}>Dashboard</div>
      <div className={classes.divider}><ArrowForwardIosIcon fontSize="inherit" /></div>
      <div className={classes.icon}><img src={props.icon} className={classes.iconImage} /></div>
      <div className={classes.route}>{props.route}</div>
    </div>
  )  
}
export default NavBar;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      paddingTop: 20,
      paddingBottom: 20
    },
    dash: {
      fontSize: 16,
      color: '#00ABCA',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    },
    divider: {
      fontSize: 12,
      paddingLeft: 10,
      paddingRight: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    iconImage: {
      width: 40,
      height: 40
    },
    route: {
      paddingLeft: 10,
      fontSize: 24,
      color: '#596675',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }),
);