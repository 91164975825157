import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios';

import { Grid, FormControlLabel, Checkbox, Button } from '@material-ui/core';
// app
import { Auth as AuthEntity } from '../../../entities/auth';
import { Message, MessageTypes, APIUrl } from '../../../entities/site';
import { openSnackbar, closeSnackbar } from '../../../store/action-creators';
// containers
import RowContainer from '../../../containers/Row';
// components
import TextInput from '../../../components/inputs/TextInput';
import ActionButton from '../../../components/buttons/ActionButton';
import Loading from '../../../components/site/Loading';
// services
import Modal from '../../../containers/Modal';
import { Team, emptyTeam, TeamUser } from '../../../entities/site';
// service 
import AuthService from '../../../services/auth';

interface AddAssistProps {
  match?: any;
  teamUser: TeamUser;
  open: any;
  auth: AuthEntity;
  apiUrls: APIUrl;
  dataType: string;
  onClose(reload: boolean): void;
  openSnackbar(message: string): void;
  closeSnackbar(): void;
}
interface AddAssistState {
  loading: boolean;
  team: Team;
  name: string;
  email: string;
  phoneNumber: string;
  canText: boolean;
  externalId: string;
  message: Message;
  disabled: boolean;
  zipcode: string;
  zips:string[];
}

class AddLoanAssist extends Component<AddAssistProps, AddAssistState> {

  constructor(props: AddAssistProps) {
    super(props);
    this.state = {
      loading: false,
      team: emptyTeam,
      name: '',
      email: '',
      phoneNumber: '',
      canText: false,
      externalId: '',
      message: {
        message: '',
        messageType: MessageTypes.Notification
      },
      disabled: false,
      zipcode: '',
      zips:[],
    }
  }

  componentDidMount = () => {
    // mounted
  }

  onNameChange = (event: any) => {
    this.setState({ name: event.target.value });
  }

  onEmailChange = (event: any) => {
    this.setState({ email: event.target.value });
  }

  onPhoneNumberChange = (event: any) => {
    this.setState({ phoneNumber: event.target.value });
  }

  onCanTextChange = (event: any) => {
    this.setState({ canText: event.target.checked });
  }

  onExternalIdChange = (event: any) => {
    this.setState({ externalId: event.target.value });
  }
  onZipcodeChange = (event: any) => {
    this.setState({ zipcode: event.target.value });
  }
  handleAddZip = () => {
    this.setState({ zips: [ this.state.zipcode, ...this.state.zips] });
  }

  handleSave = async () => {
    // sign up
    try {

      if (!this.checkForm()) {
        return;
      }

      const userId = this.props.match.params.id;

      this.setState({
        disabled: true
      });

      const dataTeamUser = {
        teamId: "",
        userId: "",
        parentId: userId,
        teamName: "",
        teamType: "",
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phoneNumber,
        canText: this.state.canText,
        role: this.props.dataType,
        meta: this.state.zips.toString()
      };

      console.log('dataTeamUser', dataTeamUser);

      // save new team user
      await axios.post(`${AuthService.getApiUrls().site}/team/user`, dataTeamUser);
      this.props.openSnackbar('Successfully saved loan officer');
      
      this.handleClose(true);

    } catch (error) {
      this.setState({ disabled: false });
      this.setState({ message: { message: 'Error saving loan officer', messageType: MessageTypes.Error } });
    }
  }

  resetForm = () => {
    this.setState({
      disabled: false,
      loading: false,
      name: '',
      email: '',
      phoneNumber: '',
      canText: false,
      externalId: '',
      zipcode: '',
      zips:[],
    });
  }

  checkForm = () => {
    let error = '';
    if (!this.state.name) {
      error = 'Name required';
    }
    if (!this.state.email) {
      error = 'Email required';
    }
    if (!error) {
      this.setState({ message: { message: error, messageType: MessageTypes.Error } });
      return true;
    }
    return false;
  }

  handleClose = (reload: boolean) => {
    this.resetForm();
    this.props.onClose(reload);
  }

  render() {
    let title = "Loan Officer Assisant";
    if (this.props.dataType === 'realtor') {
      title = "Realtor Partner";
    }
    if (this.props.dataType === 'solar') {
      title = "Solar Partner";
    }

    return (
      <Modal
        open={this.props.open}
        title={title}
        onClose={() => this.handleClose(false)}
        message={this.state.message}
      >
        <div>
          <Loading open={this.state.loading} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                title="Name"
                type="string"
                placeholder=""
                result={this.state.name}
                onChange={this.onNameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                title="Email"
                type="string"
                placeholder=""
                result={this.state.email}
                onChange={this.onEmailChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                title="Cell Phone"
                type="string"
                placeholder=""
                result={this.state.phoneNumber}
                onChange={this.onPhoneNumberChange}
              />
            </Grid>
            <Grid item xs={4} style={{
              display: 'flex',
              alignItems: 'flex-end'
            }}>
              <div style={{
                backgroundColor: '#F4F7FC',
                borderRadius: '4px',
                padding: '0px 10px 0px 10px',
                marginTop: 18
              }}
              >
                <FormControlLabel
                  style={{
                    fontSize: '14px',
                    color: '#5D759D'
                  }}
                  control={<Checkbox
                    name="checkedC"
                    checked={this.state.canText}
                    onChange={this.onCanTextChange}
                  />}
                  label="Ok to text"
                />
              </div>
            </Grid>
            { (this.props.dataType === 'realtor' || this.props.dataType === 'solar') &&
              <Grid item xs={5}>
                <TextInput
                  title="License Number"
                  type="string"
                  placeholder=""
                  result={this.state.externalId}
                  onChange={this.onExternalIdChange}
                />
              </Grid>
            }
            { this.props.dataType === 'realtor' &&
              <Grid item xs={8}>
                <TextInput
                  title="Zip Code"
                  type="string"
                  placeholder=""
                  result={this.state.zipcode}
                  onChange={this.onZipcodeChange}
                />
              </Grid>
            }
            { this.props.dataType === 'realtor' &&
              <Grid item xs={3} style={{marginTop: 14}}>
                <ActionButton
                  name="Add"
                  onClick={this.handleAddZip}
                  fullWidth
                  size="sm"
                  disabled={this.state.disabled}
                />
              </Grid>
            }
            {this.state.zips.length>0 && this.state.zips.map((value, index)=>
              <Grid item key={index} className="m-1">
                <Button variant="contained">{value}</Button>
              </Grid>
            )}
          </Grid>
          
          
          <div style={{
            marginTop: 30
          }}>
            <RowContainer>
              <Grid container spacing={1} justify="flex-end">
                <Grid item xs={3} >
                  <ActionButton
                    name="Cancel"
                    onClick={() => this.handleClose(false)}
                    fullWidth size="sm"
                    disabled={this.state.disabled}
                    type="secondary"
                  />
                </Grid>
                <Grid item xs={3} >
                  <ActionButton
                    name="Save"
                    onClick={this.handleSave}
                    fullWidth
                    size="sm"
                    disabled={this.state.disabled}
                  />
                </Grid>
              </Grid>
            </RowContainer>
          </div>
        </div>
      </Modal>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    apiUrls: state.site.apiUrls
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ openSnackbar, closeSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(AddLoanAssist));
