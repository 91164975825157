import React from 'react';
// material
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// entities
import { Campaign } from '../../../entities/site';
// components
import StatChart from './StatChart';

interface MessageRowProps {
  campaign: Campaign;
}

const CampaignHeader: React.FC<MessageRowProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={8}>
          <div className={classes.title}>{props.campaign.name}</div>
        </Grid>
        <Grid item xs={4}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>Start Date: <span>{props.campaign.startDate}</span></div>
            <div>End Date: <span>{props.campaign.endDate}</span></div>
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.bottomContainer}>
        <Grid item xs={4}>
          <div className={classes.statContainer}>
            <div className={classes.statTitle}>Total Send</div>
            <div className={classes.stat}>{props.campaign.totalSent}</div>
            <div className={classes.statSub}>of {props.campaign.totalContacts}</div>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.statContainer}>
            <div className={classes.statTitle}>Active</div>
            <div className={classes.chartContainer}>
              <div style={{width: 60, height: 60}}><StatChart stat={props.campaign.totalActive} total={props.campaign.totalContacts} /></div>
              <div className={classes.statChart}>{props.campaign.totalActive}</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.statContainer}>
            <div className={classes.statTitle}>Click Throughs</div>
            <div className={classes.chartContainer}>
              <div style={{width: 60, height: 60}}><StatChart stat={props.campaign.totalClicks} total={props.campaign.totalContacts} /></div>
              <div className={classes.statChart}>{props.campaign.totalClicks}</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.statContainer}>
            <div className={classes.statTitle}>Opt Outs</div>
            <div className={classes.chartContainer}>
              <div style={{width: 60, height: 60}}><StatChart stat={props.campaign.totalOptOuts} total={props.campaign.totalContacts} /></div>
              <div className={classes.statChart}>{props.campaign.totalOptOuts}</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.statContainer}>
            <div className={classes.statTitle}>Non Responsive</div>
            <div className={classes.chartContainer}>
              <div style={{width: 60, height: 60}}><StatChart stat={props.campaign.totalNonResponsive} total={props.campaign.totalContacts} /></div>
              <div className={classes.statChart}>{props.campaign.totalNonResponsive}</div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )

}

export default CampaignHeader;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 20,
      marginBottom: 20
    },
    bottomContainer: {
      border: '2px solid #E0E0E0'
    },
    title: {
      fontSize: 18,
      fontWeight: 'bold',
      paddingTop: 10,
      paddingBottom: 10,
      textAlign: 'left'
    },
    statContainer: {
      padding: 20
    },
    statTitle: {
      color: '#627995',
      fontSize: 18,
      textAlign: 'center'
    },
    chartContainer: {
      display: 'flex',
      marginTop: 10,
      justifyContent: 'center'
    },
    stat: {
      color: '#627995',
      fontSize: 32,
      fontWeight: 'bold',
      textAlign: 'center'
    },
    statSub: {
      textAlign: 'center'
    },
    statChart: {
      color: '#627995',
      fontSize: 32,
      fontWeight: 'bold',
      marginLeft: 20
    }
  })
);