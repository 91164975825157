import React, { useState } from 'react';
import { Modal, CircularProgress, Hidden } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
// entities
import { APIUrl } from '../../entities/site';
import { AddressShort } from '../../entities/home';
import { Session } from '../../entities/auth';
// components
import Search from '../../components/inputs/Search';
// images
const homeBackground = process.env.PUBLIC_URL + '/galilaio/galilaio_home_hero.jpg';

interface AddressSearchProps {
  session: Session;
  background?: boolean;
  onClick(address: AddressShort): void;
}

const AddressSearch: React.FC<AddressSearchProps> = (props: AddressSearchProps) => {

  const classes = useStyles({});
  const [loading, setLoading] = useState<boolean>(false);

  const showBackground = props.background !== undefined ? props.background : true;
  let backgroundStyle;
  if (showBackground) {
    backgroundStyle = {
      backgroundImage: `url(${homeBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: '100%',
      height: 600,
      display: 'flex',
      justifyContent: 'center'
    };
  }

  return (
    <div className={classes.container}>
      <div style={backgroundStyle}>
        <Hidden smDown>
          <div style={{paddingTop: showBackground ? 60 : 0, maxWidth: 600}}>
            <Search
              onShowLoading={() => setLoading(true)}
              onClick={props.onClick}
              session={props.session}
            />
          </div>
        </Hidden>
        <Hidden mdUp>
          <div style={{paddingTop: showBackground ? 10 : 0}}>
            <Search
              onShowLoading={() => setLoading(true)}
              onClick={props.onClick}
              session={props.session}
            />
          </div>
        </Hidden>
      </div>

    </div>
  )
}
export default AddressSearch;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: 20,
      backgroundColor: '#F4F7FC',
      borderRadius: 4
    }
  }),
);