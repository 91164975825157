import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import { sortBy } from 'lodash';
import moment from 'moment';
// material
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Container } from '@material-ui/core';
// app
import { updateSession, setApiUrls, addAuth, openSnackbar, closeSnackbar } from '../../store/action-creators';
// containers
import AppContainer from '../../containers/App';
import ProfileContainer from '../../containers/Profile';
// models
import { Auth, Session } from '../../entities/auth';
import { APIUrl, Address, Log } from '../../entities/site';
import { User } from '../../entities/user';
// components
import Loading from '../../components/site/Loading';
// service 
import AuthService from '../../services/auth';

const useProd = true;

const styles: any = {
  tableWrapper: {
    maxHeight: 440,
    overflow: 'auto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'left'
  }
}

interface AdminUsersPageProps {
  history: any;
  auth: Auth;
  session: Session;
  apiUrls: APIUrl;
  updateSession(session: Session): void;
  setApiUrls(urls: APIUrl): void;
  openSnackbar(message: string): void;
  closeSnackbar(): void;
}

interface AdminPageState {
  loading: boolean;
  users: User[];
}

class AdminUsersPage extends Component<AdminUsersPageProps, AdminPageState> {

  constructor(props: AdminUsersPageProps) {
    super(props);
    this.state = {
      loading: false,
      users: []
    }
  }

  componentDidMount = async () => {
    // if (!this.isAdmin()) {
    //   this.props.history.push('/');
    // }
    this.fetchUsers();
  }

  fetchUsers = async () => {
    const userRes = await axios.get(`${AuthService.getApiUrls().user}/users`);
    this.setState({
      users: sortBy(userRes.data.results, ['createdDate']).reverse()
    });
  }

  handleShowLoading = () => {
    this.setState({ loading: true });
  }

  render() {
    

    return (
      <AppContainer loading={this.state.loading} private maxWidth="lg">
          <ProfileContainer>
            <Loading open={this.state.loading} />
            <Container maxWidth="lg">
              <div style={styles.title}>Users</div>
              <Paper>
                <div style={styles.tableWrapper}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Created</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.users.map((user: User, index) => (
                        <TableRow key={`user-${index}`}>
                          <TableCell component="th" scope="row">{user.id}</TableCell>
                          <TableCell>{user.name}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{user.phone}</TableCell>
                          <TableCell>{moment(user.createdDate).format('MM-DD-YYYY HH:MM')}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Paper>
            </Container>
          </ProfileContainer>
        </AppContainer>
    )
  }


}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session,
    apiUrls: state.site.apiUrls
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ updateSession, setApiUrls, addAuth, openSnackbar, closeSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(AdminUsersPage));
