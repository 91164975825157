import React, { useState, useEffect } from 'react';
import numeral from 'numeral';
// components
import TextInput from '../inputs/TextInput';
// material
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
// style
import { colors } from '../../config/styles';

interface HomeValueProps {
  estimate: number;
  loanBalance: number;
  valuePercent: number;
  equity: number;
  onSave(loanBalance: number): void;
}

const HomeValue: React.FC<HomeValueProps> = (props) => {

  const classes = useStyles({});
  const [updateBalance, setUpdateBalance] = useState<boolean>(false);
  const [loadBalance, setLoanBalance] = useState<number>(0);

  const handleLoanBalanceSave = () => {
    setUpdateBalance(false);
    const loanBalanceNumber = numeral(loadBalance).value() || 0;
    props.onSave(loanBalanceNumber);
  }

  useEffect(() => {

    console.log('props.loanBalance', props.loanBalance)

    if (props.loanBalance === 0) {
      setUpdateBalance(true);
    } else {
      setLoanBalance(props.loanBalance);
    }
    
  }, [props.loanBalance]);

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={6} md={3}>
          <div className={classes.title}>Estimated Value</div>
          <div className={classes.homeValue}>{numeral(props.estimate).format('$0,0')}</div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className={classes.title}>Est. Loan Balance</div>
          <div className={classes.dark}>{numeral(props.loanBalance).format('$0,0')}</div>
          <div className={classes.update} onClick={() => setUpdateBalance(true)}>update</div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className={classes.title}>Est. Loan to Value</div>
          <div className={classes.dark}>{`${props.valuePercent}%`}</div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className={classes.title}>Est. Cash/Equity Available</div>
          <div className={classes.equity}>{numeral(props.equity).format('$0,0')}</div>
        </Grid>
      </Grid>
      <Dialog open={updateBalance} onClose={() => setUpdateBalance(false)}>
        <DialogContent>
          <div className={classes.modalContainer}>
            <div className={classes.modalTitle}>Set loan balance, so we can finish the calculations</div>
            <TextInput
              title="Loan Balance"
              type="number"
              placeholder=""
              result={loadBalance}
              onChange={(event: any) => setLoanBalance(event.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdateBalance(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLoanBalanceSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default HomeValue;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: 20,
      backgroundColor: '#FFF',
      borderBottom: '1px solid #C9D3E0'
    },
    modalContainer: {
      mindWidth: 300
    },
    modalTitle: {
      fontSize: 16,
      paddingBottom: 20
    },
    title: {
      fontSize: 14,
      textAlign: 'center'
    },
    homeValue: {
      color: '#00C980',
      fontSize: 30,
      fontWeight: 700,
      textAlign: 'center'
    },
    dark: {
      color: '#596675',
      fontSize: 24,
      fontWeight: 700,
      textAlign: 'center'
    },
    equity: {
      color: '#00C980',
      fontSize: 24,
      fontWeight: 700,
      textAlign: 'center'
    },
    update: {
      color: '#00ABCA',
      fontSize: 14,
      textAlign: 'center',
      cursor: 'pointer'
    }
  }),
);