import React from 'react';

const styles: any = {
  divider: {
    height: 1,
    backgroundColor: '#E1E1E1',
    marginTop: 20,
    marginBottom: 20
  },
  dividerMd: {
    height: 3,
    backgroundColor: '#E1E1E1',
    marginTop: 30,
    marginBottom: 30
  },
  dividerSm: {
    height: 1,
    backgroundColor: '#E1E1E1',
    marginTop: 10,
    marginBottom: 10
  }
}

interface DividerProps {
  size?: string;
}
  
const Divider: React.FC<DividerProps> = (props) => {
  
  const lineSize = props.size ? props.size : "sm";
  let lineStyle = styles.divider;
  if (lineSize === 'md') {
    lineStyle = styles.dividerMd;
  }
  if (lineSize === 'sm') {
    lineStyle = styles.dividerSm;
  }

  return (
    <div style={lineStyle} />
  )  
}
export default Divider;