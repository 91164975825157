import React from "react";
// material
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// components
import Divider from "../../../divider/Divider";
// entities
import { MessageTemplate } from "../../../../entities/notification";

interface BottomBlockProps {
  template: MessageTemplate;
  weekDays: string;
  messagesPerDay: number;
}

const renderDay = (day: number) => {
  switch (day) {
    case 1:
      return "M";
    case 2:
      return "T";
    case 3:
      return "W";
    case 4:
      return "Th";
    case 5:
      return "F";
    case 6:
      return "S";
  }
  return null;
};

const BottomBlock: React.FC<BottomBlockProps> = (props) => {
  const classes = useStyles({});

  // const renderDays = () =>
  //   props.weekDays
  //     .sort((a, b) => a - b)
  //     .reduce((accumulator, day, index) => {
  //       if (index === props.weekDays.length - 1) {
  //         return accumulator + renderDay(day);
  //       }
  //       return accumulator + renderDay(day) + ", ";
  //     }, "");

  return (
    <div className={classes.container}>
      <div className={classes.text}>Template</div>
      <div className={classes.template}>
        <div className={classes.templateName}>{props.template.name}</div>
        <div className={`${classes.text} ${classes.descriptionText}`}>
          {props.template.description}
        </div>
      </div>
      <div>
        <div className={classes.text}>Schedule</div>
        <Divider />
        <div className={classes.schedule}>
          {props.messagesPerDay} Leads per day
        </div>
        <div>{props.weekDays}</div>
      </div>
    </div>
  );
};

export default BottomBlock;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "21px 20px 25px 20px",
    },
    text: {
      color: "#333333",
      fontFamily: "Asap",
      fontSize: 14,
    },
    template: {
      boxSizing: "border-box",
      height: "auto",
      width: "100%",
      border: "1px solid #B0E7F2",
      borderRadius: 2,
      backgroundColor: "#FFFFFF",
      padding: 15,
      marginTop: 6,
      marginBottom: 20,
    },
    templateName: {
      color: "#00ABCA",
      fontFamily: "Asap",
      fontSize: 16,
      fontWeight: "bold",
      marginBottom: 20,
    },
    schedule: {
      marginTop: 10,
      color: "#333333",
      fontFamily: "Asap",
      fontSize: 16,
    },
    descriptionText: {
      wordWrap: "break-word",
    },
  })
);
