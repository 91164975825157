import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import numeral from 'numeral';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { find, isEmpty } from 'lodash';
import { v1 as uuid } from 'uuid';
import ReactInterval from 'react-interval';

// app
import { updateSession, setSignup, openSnackbar } from '../../store/action-creators';
import UtilsService from '../../services/utils';
// containers
import AppContainer from '../../containers/App';
// components
import ActionButton from '../../components/buttons/ActionButton';
import NavBar from '../../components/site/NavBar';
import Loading from '../../components/site/Loading';
import AddressSearch from '../../components/site/AddressSearch';
import AddressBar from '../../components/home/AddressBar';
import HomeValue from '../../components/home/HomeValue';
import PieChart from '../../components/charts/PieChart';
import NewPayment from './components/NewPayment';
import AvailableCash from './components/AvailableCash';
import AvailableRates from './components/AvailableRates';
import MessageBar from '../../components/message/MessageBar';
import Lead from '../../components/site/Lead';
// services
import HomeService from '../../services/home';
// models
import { Session, Auth, Signup } from '../../entities/auth';
import { APIUrl, MessageTypes, Message, emptyMessage, Lead as LeadEntity, LeadTypes } from '../../entities/site';
import { Address, emptyAddress } from '../../entities/home';
import { emptyUserAddress, UserAddress } from '../../entities/user';
// style
import { colors } from '../../config/styles';
// material
import { Grid, Hidden } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
// service 
import AuthService from '../../services/auth';
// images
const iconPdc = process.env.PUBLIC_URL + '/images/icons/icon_pdc.svg';

const styles: any = {
  container: {
    paddingTop: 30
  },
  disclaimer: {
    marginTop: 40,
    color: '#778092',
    fontSize: 12,
    textAlign: 'left'
  },
  message: {
    fontSize: 18,
    padding: 40,
    textAlign: 'center',
    color: '#596675'
  },
  leftContainer: {
    borderBottom: '1px solid #E0E0E0'
  }
}

interface DesignCenterProps {
  match?: any;
  history: any;
  signup: Signup,
  auth: Auth;
  session: Session;
  apiUrls: APIUrl;
  setSignup(username: string, email: string, name: string, phone: string, isReset: boolean): void;
  updateSession(session: Session): void;
  openSnackbar(message: string): void;
}

interface DesignCenterState {
  loading: boolean;
  loadingInterval: boolean;
  loadingText: string;
  loadingCounter: number;
  cash: number;
  maxCash: number;
  percent: number;
  principal: number;
  interest: number;
  taxes: number;
  insurance: number;
  newBalance: number;
  selectedTerm: number;
  selectedRate: number;
  monthlyPayment: number;
  term: number;
  rate: number;
  address: Address;
  userAddress: UserAddress;
  message: Message;
  openLead: boolean;
}

class DesignCenterPage extends Component<DesignCenterProps, DesignCenterState> {

  constructor(props: DesignCenterProps) {
    super(props);
    this.state = {
      loading: false,
      loadingInterval: false,
      loadingText: 'loading...',
      loadingCounter: 0,
      cash: 0,
      maxCash: 0,
      percent: 0,
      principal: 0,
      interest: 0.00,
      taxes: 85,
      insurance: 85,
      newBalance: 0,
      selectedTerm: 30,
      selectedRate: 3.25,
      monthlyPayment: 0,
      term: 30,
      rate: 3.25,
      address: emptyAddress,
      userAddress: emptyUserAddress,
      message: emptyMessage,
      openLead: false
    }
  }

  componentDidMount = async () => {
    const addressId = this.props.match.params.id;

    if (addressId) {
      await this.fetchAll(addressId);
    }

  }

  fetchAll = async (addressId: string) => {

    try {

      // fetch
      this.setState({ loading: true, loadingInterval: true, message: emptyMessage });

      const addressRes = await axios.get(`${AuthService.getApiUrls().home}/address/${addressId}`);

      if (addressRes.data) {
        // TODO: fetch new address every month
        const address = addressRes.data;

        // get user address 
        const userAddressRes: any = await HomeService.getUserAddress(address);

        const calRes = HomeService.calculateCash(address, userAddressRes.loanBalance);
        const paymentRes = HomeService.calculateMonthlyPayment(this.state.selectedRate, 30, address.loanBalance, calRes.cash, this.state.taxes, this.state.insurance);
        this.setState({
          userAddress: userAddressRes,
          address: addressRes.data,
          cash: calRes.cash,
          maxCash: calRes.cash,
          percent: calRes.percent,
          monthlyPayment: paymentRes.monthlyPayment,
          principal: paymentRes.principal,
          interest: paymentRes.interest,
          loading: false
        });
      } else {

        const addressParts = HomeService.getAddressFromId(addressId);

        const propertyStatsRes = await axios.get(`${AuthService.getApiUrls().integrations}/property/calculate/?address=${addressParts.address}&city=${addressParts.city}&state=${addressParts.state}`);

        if (!propertyStatsRes.data.errorMessage) {
          const addressNew = propertyStatsRes.data;
          addressNew.id = addressId;

          const calRes = HomeService.calculateCash(addressNew, 0);
          const paymentRes = HomeService.calculateMonthlyPayment(this.state.selectedRate, 30, addressNew.loanBalance, calRes.cash, this.state.taxes, this.state.insurance);

          this.setState({
            address: addressNew,
            cash: calRes.cash,
            maxCash: calRes.cash,
            percent: calRes.percent,
            monthlyPayment: paymentRes.monthlyPayment,
            principal: paymentRes.principal,
            interest: paymentRes.interest
          });
          await axios.post(`${AuthService.getApiUrls().home}/address`, addressNew);
        }

        this.setState({ 
          loading: false,
          loadingInterval: false,
          loadingText: 'loading...',
          loadingCounter: 0
        });

      }

    } catch (error) {
      const newSession = this.props.session;
      newSession.address = undefined;
      this.props.updateSession(newSession);
      this.setState({ loading: false, message: { message: 'Error fetching address information. Please try a new address.', messageType: MessageTypes.Error } });
    }

  }

  handleNavigate = (route: string) => {
    this.props.history.push(route);
  }

  handleAddressSearch = async (address: Address) => {

    const newSession = this.props.session;
    newSession.address = address;
    this.props.updateSession(newSession);

    this.props.history.push(`/payment-design-center/${address.id}`);

  }

  handleUpdateLoanBalance = async (loanBalance: number) => {

    try {

      this.setState({ loading: true });

      const user = AuthService.getUser();
      const currentAddress = { ...this.state.address };
      const userAddressData = { ...this.state.userAddress };

      userAddressData.loanBalance = loanBalance;
      userAddressData.userId = user ? user.userId : '';
      userAddressData.addressId = this.state.address.id;
      currentAddress.loanBalance = loanBalance;

      if (user) {
        const saveRes = await HomeService.saveUserAddress(currentAddress, userAddressData);
      }

      // add loan balance to session
      const newSession = this.props.session;
      newSession.loanBalance = loanBalance;
      this.props.updateSession(newSession);

      const calRes = HomeService.calculateCash(currentAddress, loanBalance);
      this.setState({ 
        loading: false,
        userAddress: userAddressData,
        address: currentAddress,
        cash: calRes.cash,
        percent: calRes.percent
      });

    } catch (error) {
      console.log('error', error);
      this.setState({ loading: false });
    }

  }

  handleCashChange = (event: any, value: any) => {
    const cash = parseInt(value, 10);
    const paymentRes = HomeService.calculateMonthlyPayment(this.state.selectedRate, this.state.selectedTerm, this.state.address.loanBalance, cash, this.state.taxes, this.state.insurance);
    this.setState({
      cash,
      newBalance: paymentRes.newBalance,
      monthlyPayment: paymentRes.monthlyPayment,
      principal: paymentRes.principal,
      interest: paymentRes.interest
    });
  }

  handleTermChange = (term: number) => {
    const paymentRes = HomeService.calculateMonthlyPayment(this.state.selectedRate, term, this.state.address.loanBalance, this.state.cash, this.state.taxes, this.state.insurance);
    console.log('paymentRes', paymentRes);
    // term
    this.setState({
      term,
      monthlyPayment: paymentRes.monthlyPayment,
      principal: paymentRes.principal,
      interest: paymentRes.interest
    });
  }

  handleRateChange = (event: any, value: any) => {
    const paymentRes = HomeService.calculateMonthlyPayment(value, this.state.selectedTerm, this.state.address.loanBalance, this.state.cash, this.state.taxes, this.state.insurance);
    console.log('paymentRes', paymentRes);
    // rate
    this.setState({
      rate: value,
      monthlyPayment: paymentRes.monthlyPayment,
      principal: paymentRes.principal,
      interest: paymentRes.interest
    });
  }

  handleSave = () => {

    const user = AuthService.getUser();

    if (!user) {
      this.setState({ openLead: true });
    } else {
      this.handleLeadClick(user.name, user.email, user.phone);
    }

    // const messageBody = {
    //   toPhoneNumber: '+19168656917',
    //   // toPhoneNumber: '+17609852619',
    //   fromPhoneNumber: '+19259623762',
    //   message: 'Looking to refinance? How can I help?',
    //   user: {id: 'testing'}
    // };
    // axios.post(`https://f03hx98ote.execute-api.us-west-2.amazonaws.com/dev/message/send`, messageBody).then(() => {
    // // axios.get('https://hooks.zapier.com/hooks/catch/3371176/erxhxm/').then(() => {

    //   this.props.openSnackbar('Saved! We will contact you very soon.');

    //   this.setState({loading: false});

    // });

  }

  handleResetAddress = () => {
    const newSession = this.props.session;
    newSession.address = undefined;
    this.props.updateSession(newSession);
    this.handleNavigate('/')
  }

  handleLeadClose = () => {

    this.setState({ openLead: false });

  }

  handleLeadClick = async (name: string, email: string, phone: string) => {

    try {

      const newLead: LeadEntity = {
        id: "",
        type: LeadTypes.PaymentDesign,
        name,
        email,
        phone,
        message: "",
        addressId: this.state.address.id,
        address: this.state.address.address,
        estimatedValue: this.state.address.estimate,
        loanBalance: this.state.address.loanBalance,
        dreamRate: 0,
        createdDate: 0
      }

      await axios.post(`${AuthService.getApiUrls().site}/lead`, newLead);
      this.setState({ openLead: false });

      this.props.openSnackbar('Request sent! We will contact you very soon.');

    } catch (error) {
      console.log('error', error);
      this.setState({ openLead: false });
    }

  }

  handleLoadingInterval = () => {
    if (this.state.loadingCounter === 0) {
      this.setState({loadingText: 'Getting property details...'});
    } else if (this.state.loadingCounter === 1) {
      this.setState({loadingText: 'Calculating property estimates...'});
    } else {
      this.setState({loadingText: 'Finishing up property calculations...'});
    }
    this.setState({loadingCounter: this.state.loadingCounter + 1});
  }

  render() {

    const taxesMonthly = (this.state.address ? this.state.address.taxAmount / 12 : 85);

    const pieData = [
      {
        "id": "principal",
        "label": "Principal",
        "value": this.state.principal,
        "color": "hsl(197, 97%, 46%)"
      },
      {
        "id": "interest",
        "label": "Interest",
        "value": this.state.interest,
        "color": "hsl(158, 100%, 40%)"
      },
      {
        "id": "taxes",
        "label": "Taxes",
        "value": this.state.taxes,
        "color": "hsl(240, 75%, 76%)"
      },
      {
        "id": "insurance",
        "label": "Insurance",
        "value": this.state.insurance,
        "color": "hsl(34, 100%, 48%)"
      }
    ];

    return (
      <AppContainer showOption={false} private={false} maxWidth="lg" openSignup={false} color="secondary">
        <Loading open={this.state.loading} text={this.state.loadingText} />
        <ReactInterval timeout={5000} enabled={this.state.loadingInterval} callback={this.handleLoadingInterval} />
        <NavBar route="Payment Design Center" icon={iconPdc} onClick={() => this.handleNavigate('/')} />
        <MessageBar message={this.state.message} />
        <Lead
          open={this.state.openLead}
          onClick={this.handleLeadClick}
          onClose={this.handleLeadClose}
        />

        {!this.state.address.id &&
          <AddressSearch
            session={this.props.session}
            onClick={this.handleAddressSearch}
          />
        }

        {this.state.address.id &&

          <div>
            <AddressBar address={this.state.address} onClick={this.handleResetAddress} />
            <HomeValue
              estimate={this.state.address.estimate}
              loanBalance={this.state.userAddress.loanBalance}
              valuePercent={this.state.percent}
              equity={this.state.cash}
              onSave={this.handleUpdateLoanBalance}
            />
            <div style={{ marginTop: 20 }}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <NewPayment
                    chartData={pieData}
                    monthlyPayment={this.state.monthlyPayment}
                    payment={this.state.monthlyPayment}
                    principal={this.state.principal}
                    interest={this.state.interest}
                    taxes={taxesMonthly}
                    insurance={85}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <div style={styles.leftContainer}>
                    <div style={styles.message}>Congratulations!  You are under the loan to value threshold.  Design your payment below.</div>
                  </div>
                  <div style={styles.leftContainer}>
                    <AvailableCash
                      cash={this.state.cash}
                      maxCash={this.state.maxCash}
                      onChange={this.handleCashChange}
                    />
                  </div>
                  <div style={styles.leftContainer}>
                    <AvailableRates
                      term={this.state.term}
                      rate={this.state.rate}
                      onTermChange={this.handleTermChange}
                      onRateChange={this.handleRateChange}
                    />
                  </div>
                  <ActionButton name="Save This Scenrio" onClick={this.handleSave} size="lg" fullWidth />
                </Grid>
              </Grid>
              <div style={styles.disclaimer}>{`Rates are updated daily by our Parent company American Pacific Mortgage Company.  Terms, restrictions, and conditions apply, not all applicants will qualify. American Pacific Mortgage Company is not affiliated with any U.S. Government Agency Including HUD/FHA. Payment example is based on (${this.state.rate}%) rate, ${this.state.term}-year amortization fixed rate mortgage. Principle, interest, Taxes & Insurance. Corporate Address:  3000 Lava Ridge Court, Suite 200, Roseville CA 95661 NMLS ID#1850 NMLS Consumer Access www.nmlsconsumeraccess.org`}</div>
            </div>

          </div>

        }



      </AppContainer>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    signup: state.auth.signup,
    auth: state.auth.auth,
    session: state.session.session,
    apiUrls: state.site.apiUrls
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ updateSession, setSignup, openSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(DesignCenterPage));
