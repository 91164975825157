import React from 'react';
import MoneyText from '../../../components/inputs/MoneyText';
import { Option } from '../../../entities/option';
import Grid from '@material-ui/core/Grid';

const ehoLogo = process.env.PUBLIC_URL + '/equal_housing.svg';

const styles: any = {
  singleContainer: {
    backgroundColor: '#F1F1F1',
    padding: 10,
    marginBottom: 10,
    borderRadius: 4,
    border: '1px solid #E0E0E0'
  },
  groupContainer: {
    backgroundColor: '#F1F1F1',
    padding: 10,
    borderRadius: '4px 4px 0px 0px',
    border: '1px solid #E0E0E0'
  },
  lastContainer: {
    backgroundColor: '#F1F1F1',
    borderRadius: '0px 0px 4px 4px',
    padding: 10,
    borderLeft: '1px solid #E0E0E0',
    borderRight: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0'
  },
  singleContainerLight: {
    backgroundColor: '#FFFFFF',
    padding: 10,
    marginBottom: 10,
    borderRadius: 4,
    border: '1px solid #E0E0E0'
  },
  groupContainerLight: {
    backgroundColor: '#FFFFFF',
    padding: 10,
    borderRadius: '4px 4px 0px 0px',
    border: '1px solid #E0E0E0'
  },
  lastContainerLight: {
    backgroundColor: '#FFFFFF',
    borderRadius: '0px 0px 4px 4px',
    padding: 10,
    borderLeft: '1px solid #E0E0E0',
    borderRight: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#9D9D9D',
  },
  rowGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#9D9D9D',
    paddingBottom: 10
  },
  button: {
    color: "#fff"
  },
  actionButton: {
    marginTop: 10
  },
  textDark: {
    color: '#333333',
    fontWeight: 800
  },
  money: {
   color: '#333333',
   fontWeight: 800
  },
  disclaimer: {
    color: '#333333',
    fontSize: 11
  },
  disclaimerContainer: {
    padding: 5
  },
  imageContainer: {
    backgroundColor: '#F1F1F1',
    border: '1px solid #E0E0E0',
    padding: '5px 5px 0px 6px',
    width: 35,
    margin: '10px auto',
    borderRadius: 4
  }
}


interface PaymentDetailsProps {
  option: Option;
  type: string;
}

const PaymentDetails = (props: PaymentDetailsProps) => (
  <div>
    <div style={(props.type === "dark" ? styles.singleContainer : styles.singleContainerLight)}>
      <div style={styles.row}>
        <div style={styles.textDark}>{`Total Down ${props.option.percentDown.toFixed(2)}% ${(props.option.loanType === 2 ? 'FHA' : 'Cov')}`}</div>
        <div style={styles.money}><MoneyText value={props.option.downPayment} /></div>
      </div>
    </div>
    <div style={(props.type === "dark" ? styles.singleContainer : styles.singleContainerLight)}>
      <div style={styles.row}>
        <div style={styles.textDark}>Loan Amount</div>
        <div style={styles.money}><MoneyText value={props.option.loanAmount} /></div>
      </div>
    </div>
    <div style={(props.type === "dark" ? styles.groupContainer : styles.groupContainerLight)}>
      <div style={styles.rowGroup}>
        <div>Taxes (monthly)</div>
        <div style={styles.money}><MoneyText value={props.option.taxes} /></div>
      </div>
      <div style={styles.rowGroup}>
        <div>Mortgage Insurance</div>
        <div style={styles.money}><MoneyText value={props.option.mortgageInsurance} /></div>
      </div>
      <div style={styles.rowGroup}>
        <div>Hazard Insurance</div>
        <div style={styles.money}><MoneyText value={props.option.hazardInsurance} /></div>
      </div>
      <div style={styles.rowGroup}>
        <div>Principal + Interest</div>
        <div style={styles.money}><MoneyText value={props.option.principalInterest} /></div>
      </div>
    </div>
    <div style={(props.type === "dark" ? styles.lastContainer : styles.lastContainerLight)}>
      <div style={styles.row}>
        <div style={styles.textDark}>Total</div>
        <div style={styles.money}><MoneyText value={(props.option.taxes+props.option.mortgageInsurance+props.option.hazardInsurance+props.option.principalInterest)} /></div>
      </div>
    </div>
    <div style={styles.disclaimerContainer}>
      <Grid container spacing={8}>
        <Grid item xs={10}>
          <div style={styles.disclaimer}>
            Rates are updated daily by our lending partner American Pacific Mortgage Company. Terms, restrictions, and conditions apply, not all applicants will qualify. American Pacific Mortgage Company is not affiliated with any U.S. Government Agency including HUD/FHA. Payment example is based {(props.option.interestRate * 100).toFixed(2)}% rate with a {props.option.aprPercent.toFixed(3)}% APR, 30-year amortization fixed rate mortgage. Principle, Interest, Taxes & Insurance. Corporate Address: 3000 Lava Ridge Court, Suite 200, Roseville CA 95661. NMLS ID#1850 NMLS Consumer Access www.nmlsconsumeraccess.org.   
          </div>
        </Grid>
        <Grid item xs={2}>
          <div style={styles.imageContainer}>
            <img src={ehoLogo} />
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default PaymentDetails;