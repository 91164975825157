import React from 'react';
// material
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message';

export interface MessageRowEntity {
  title: string;
  date: string;
  message: string;
}

interface MessageRowProps {
  title: string;
  date: string;
  message: string;
}

const MessageRow: React.FC<MessageRowProps> = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        <Grid item xs={2}>
          <div className={classes.iconContainer}>
            <MessageIcon fontSize="large" style={{ color: '#FFF' }} />
          </div>
        </Grid>
        <Grid item xs={10}>
          <div className={classes.header}>
            <div className={classes.title}>{props.title}</div>
            <div className={classes.date}>{props.date}</div>
          </div>
          <div className={classes.message}>{props.message}</div>
        </Grid>
      </Grid>
    </div>
  )

}
export default MessageRow;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      borderBottom: '1px solid #d7dee2'
    },
    title: {
      fontWeight: 'bold',
      textAlign: 'left'
    },
    date: {
      fontSize: 10,
      color: '#9db0be',
      textAlign: 'left'
    },
    message: {
      padding: 5,
      textAlign: 'left'
    },
    iconContainer: {
      width: 60,
      height: 60,
      borderRadius: 30,
      backgroundColor: '#ccc',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
);