import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import { sortBy } from 'lodash';
import moment from 'moment';
// material
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Container } from '@material-ui/core';
// app
import { updateSession, setApiUrls, addAuth, openSnackbar, closeSnackbar } from '../../store/action-creators';
// containers
import AdminContainer from '../../containers/Admin';
// models
import { Auth, Session } from '../../entities/auth';
import { APIUrl } from '../../entities/site';
// components
import Loading from '../../components/site/Loading';

const useProd = true;

const styles: any = {
  tableWrapper: {
    maxHeight: 440,
    overflow: 'auto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'left'
  }
}

interface AdminPageProps {
  history: any;
  auth: Auth;
  session: Session;
  apiUrls: APIUrl;
  updateSession(session: Session): void;
  setApiUrls(urls: APIUrl): void;
  openSnackbar(message: string): void;
  closeSnackbar(): void;
}

interface AdminPageState {
  loading: boolean;
}

class AdminPage extends Component<AdminPageProps, AdminPageState> {

  constructor(props: AdminPageProps) {
    super(props);
    this.state = {
      loading: false
    }
  }

  componentDidMount = async () => {
    // fetch stuff
  }


  // isAdmin = () => {
  //   const auth = this.props.auth;
  //   const emails = [
  //     'jonathon.d.gray@gmail.com',
  //     'Jay@SistarMortgage.com',
  //     'jay@micazza.com',
  //     'j.moulton@yahoo.com'
  //   ]
  //   if (auth && auth.user && auth.user.email) {
  //     if (emails.indexOf(auth.user.email) !== -1) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  handleShowLoading = () => {
    this.setState({ loading: true });
  }

  render() {

    return (
      <AdminContainer showOption={false} private={false} maxWidth={false}>
        <Loading open={this.state.loading} />
        <Container maxWidth="lg">
          <div style={styles.title}>Admin Home</div>
          
         
        </Container>
      </AdminContainer>
    )
  }


}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session,
    apiUrls: state.site.apiUrls
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ updateSession, setApiUrls, addAuth, openSnackbar, closeSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(AdminPage));
