import React, { useState } from 'react';

// entities
import { Address } from '../../entities/home';
// material
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
// style
import { colors } from '../../config/styles';

interface AddressBarProps {
  address: Address;
  onClick(): void;
}

const AddressBar: React.FC<AddressBarProps> = (props) => {

  const classes = useStyles({});

  return (
    <Grid container className={classes.addressContainer}>
      <Grid item xs={6}>
        <div className={classes.address}>{props.address.address}</div>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.addressLink} onClick={props.onClick}>Try a new address?</div>
      </Grid>
    </Grid>
  )
}
export default AddressBar;

const useStyles = makeStyles((theme) =>
  createStyles({
    addressContainer: {
      padding: 15,
      backgroundColor: '#F4F7FC',
      borderRadius: 4
    },
    address: {
      fontSize: 16,
      textAlign: 'left',
      color: '#596675',
      paddingTop: 5
    },
    addressLink: {
      padding: 5,
      fontSize: 16,
      textAlign: 'right',
      color: '#00ABCA',
      cursor: 'pointer'
    }
  }),
);