import React from 'react';
import { withRouter } from "react-router-dom";
// material
import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// compontent
import AddressSearch from '../../../components/site/AddressSearch';
import Search from '../../../components/inputs/Search';
import EmailInput from '../../../components/inputs/EmailInput';

// 
import { Session } from '../../../entities/auth';
import { Address } from '../../../entities/home';

interface HomeWelcomePackageProps {
    history: any;
    id: string;
    orientation: string;
    image: string;
    icon: string;
    title: string;
    description: string;
    session: Session;
    onClick(email: string): void;
}


const HomeWelcomePackage: React.FC<HomeWelcomePackageProps> = (props) => {

    const classes = useStyles({});

    const containerClass = (props.orientation === 'right' ? classes.containerRight : classes.containerLeft)

    return (
        <div id={props.id} className={containerClass}>
            <Container maxWidth="lg" >
                <Grid container spacing={8}>
                    {props.orientation === 'right' &&
                        <React.Fragment>
                            <Grid item xs={12} md={6}>
                                <div className={classes.imageContainer}><img src={props.image} className={classes.image} /></div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={classes.titleContainer}>
                                    <div><img src={props.icon} className={classes.icon} /></div>
                                    <div className={classes.title}>{props.title}</div>
                                </div>
                                <div className={classes.description}>{props.description}</div>
                                <div>
                                    <EmailInput
                                        title="Enter Your Email"
                                        onClick={props.onClick}
                                    />
                                </div>
                            </Grid>
                        </React.Fragment>
                    }
                    {props.orientation === 'left' &&
                        <React.Fragment>
                            <Grid item xs={12} md={6}>
                                <div className={classes.titleContainer}>
                                    <div><img src={props.icon} className={classes.icon} /></div>
                                    <div className={classes.title}>{props.title}</div>
                                </div>
                                <div className={classes.description}>{props.description}</div>
                                <div>
                                    <EmailInput
                                        title="Enter Your Email"
                                        onClick={props.onClick}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={classes.imageContainer}><img src={props.image} className={classes.image} /></div>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    containerRight: {
        backgroundColor: '#002436',
        paddingTop: 85,
        paddingBottom: 85
    },
    containerLeft: {
        backgroundColor: '#002436',
        paddingTop: 85,
        paddingBottom: 85
    },
    titleContainer: {
        display: 'flex'
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    image: {
        width: 500,
        borderRadius: 20
    },
    icon: {
        width: 70,
        height: 70
    },
    title: {
        color: '#FFF',
        fontSize: 30,
        paddingLeft: 20,
        display: 'flex',
        alignItems: 'center'
    },
    description: {
        color: '#8794A3',
        fontSize: 20,
        paddingTop: 30,
        paddingBottom: 60
    }

}));

export default withRouter<any, any>(HomeWelcomePackage);