import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';

const CustomTabs = withStyles({
    root: {
      borderBottom: 'none',
    },
    indicator: {
      backgroundColor: '#00ABC7',
    },
  })(Tabs);

  export default CustomTabs;