import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, createStyles, Theme } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const breadcrumbNameMap: { [key: string]: string } = {
  "/campaign/new/details": "Details",
  "/campaign/new/template": "Template",
  "/campaign/new/timeline": "Timeline",
  "/campaign/new/billing": "Billing",
};

interface BreadCrumbMenuProps {
  current: string;
}

const BreadCrumbMenu: React.FC<BreadCrumbMenuProps> = ({ current }) => {
  const classes = useStyles();

  const breadcrumbKeys = Object.keys(breadcrumbNameMap);
  const curIndex = breadcrumbKeys.findIndex(
    (breadcrumb) => current === breadcrumb
  );

  return (
    <div className={classes.breadcrumbs}>
      {breadcrumbKeys.map((link, index) => {
        if (index < curIndex) {
          return (
            <div className={classes.breadcrumb} key={`breadCrumb-${index}`}>
              <div className={classes.iconmenu}>
                <CheckCircleOutlineIcon
                  className={classes.circle}
                  color={"disabled"}
                />
                {breadcrumbNameMap[link]}{" "}
              </div>
              <ArrowForwardIcon
                className={classes.arrow}
                color={"disabled"}
                fontSize={"small"}
              />
            </div>
          );
        } else {
          return (
            <div className={classes.breadcrumb} key={`breadCrumb-${index}`}>
              <div className={classes.iconmenu}>
                <Avatar
                  className={`${classes.avatar} ${
                    curIndex === index
                      ? classes.activeAvatar
                      : classes.nextAvatar
                  }`}
                >
                  {index + 1}
                </Avatar>
                <span style={{ color: curIndex === index ? "#00ABC7" : "" }}>
                  {breadcrumbNameMap[link]}
                </span>
              </div>
              <div>
                {index !== breadcrumbKeys.length - 1 && (
                  <ArrowForwardIcon
                    className={classes.arrow}
                    color={"disabled"}
                    fontSize={"small"}
                  />
                )}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default BreadCrumbMenu;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
    },
    breadcrumb: {
      color: "#B1BCCA",
      fontFamily: "Asap",
      fontSize: 14,
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.between("xs", "sm")]: {
        // maxWidth: "100%"
        fontSize: "15px",
      },
    },
    block: {},
    avatar: {
      width: 20,
      height: 20,
      color: "#FFF",
      fontSize: 14,
      marginRight: 6,
      [theme.breakpoints.only("xs")]: {
        margin: "0px auto 10px",
        width: "40px",
        height: "40px",
      },
    },
    arrow: {
      margin: "0 15px",
      [theme.breakpoints.only("xs")]: {
        margin: "0 4px",
      },
    },
    circle: {
      marginRight: 6,
      [theme.breakpoints.only("xs")]: {
        fontSize: "45px",
        marginBottom: "8px",
      },
    },
    nextAvatar: {},
    activeAvatar: {
      backgroundColor: "#00ABCA",
    },
    iconmenu: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.only("xs")]: {
        flexDirection: "column",
      },
    },
  })
);
