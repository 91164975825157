import React from 'react';
import numeral from 'numeral';
// components
import LineChart from '../../../components/charts/LineChart';
import ActionButton from '../../../components/buttons/ActionButton';
import TextInput from '../../../components/inputs/TextInput';
// material
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
// style
import { colors } from '../../../config/styles';

const cloudImage = process.env.PUBLIC_URL + '/images/icons/img_cloud.svg';

interface RateProps {
  rate: number;
  onChange(rate: number): void;
  onClick(): void;
}

const SelectRate: React.FC<RateProps> = (props) => {

  const classes = useStyles({});

  return (
    <div className={classes.container}>
      <div className={classes.text}>Enter your Dream Rate below and we’ll notify you when that rate becomes available.</div>
      <div className={classes.rateContainer}>
        <TextInput 
          title=""
          type="number"
          placeholder=""
          result={props.rate}
          onChange={(event: any) => props.onChange(event.target.value)} 
        />
        <div className={classes.unit}>$</div>
      </div>
      <div className={classes.buttonContainer}>
        <ActionButton name="Save This Rate" onClick={props.onClick} size="lg" fullWidth />
      </div>
    </div>
  )
}
export default SelectRate;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: 20,
      backgroundColor: '#FFF'
    },
    buttonContainer: {
      padding: 40
    },
    text: {
      fontSize: 18,
      textAlign: 'center',
      padding: 40,
      color: '#596675'
    },
    rateContainer: {
      width: 100,
      margin: '0px auto',
      display: 'flex'
    },
    rate: {
      fontSize: 20,
      color: '#596675'
    },
    unit: {
      fontSize: 25,
      color: '#ACB2BA',
      paddingTop: 5,
      paddingLeft: 5
    },
  }),
);