import React, { Component } from 'react';
// app
import { colors } from '../../config/styles';
import { Message, MessageTypes } from '../../entities/site';

interface MessageBarProps {
  message: Message;
}

class MessageBar extends Component<MessageBarProps, {}> {
    
    render() {
      const { message } = this.props;

      const styles: any = {
        container: {
          padding: 8,
          background: colors.green,
          margin: '0px auto',
          width: '100%'
        },
        message: {
          textAlign: 'center',
          color: colors.greenDark,
          fontSize: 12
        }
      }

      if (message.messageType === MessageTypes.Error) {
        styles.container.background = colors.red;
        styles.message.color = colors.redDark;
      } else if (message.messageType === MessageTypes.Warning) {
        styles.container.background = colors.yellow;
        styles.message.color = colors.yellowDark;
      }

      return this.props.message.message ? ( 
        <div style={styles.container}>
          <div style={styles.message}>{message.message}</div>
        </div>
      ) : null;
    }
  };

export default MessageBar;
