import React, { useState } from "react";
// material
import { Typography, Box, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// components
import TextInput from "../inputs/TextInput";
import SelectInput, { SelectItem } from "../inputs/SelectInput";
// entities
import { Campaign } from "../../entities/site";

interface AddCampaignProps {
  campaign?: Campaign;
  name: string;
  type: string;
  client: string;
  showClients: boolean;
  onChange(name: string, value: string): void;
}

const campaignTypes: SelectItem[] = [
  {
    name: "Restaurant",
    value: "Restaurant",
  },
  {
    name: "Real estate",
    value: "Real estate",
  },
  {
    name: "Hair Salon",
    value: "Hair Salon",
  },
  {
    name: "Coffee shop",
    value: "Coffee shop",
  },
  {
    name: "Debt",
    value: "Debt",
  },
  {
    name: "Refi",
    value: "Refi",
  },
];

const clients: SelectItem[] = [
  {
    name: "Galilaio",
    value: "galilaio",
  },
  {
    name: "Homewize",
    value: "homewize",
  },
];

const AddCampaign: React.FC<AddCampaignProps> = (props) => {
  const classes = useStyles({});

  return (
    <div>
      <Grid container>
        <Grid item xs={6} className={classes.inputField}>
          <TextInput
            title="Campaign Name"
            type="string"
            placeholder=""
            result={props.name}
            onChange={(event: any) =>
              props.onChange("name", event.target.value)
            }
          />
          <SelectInput
            title="Campaign Type"
            value={props.type}
            values={campaignTypes}
            onChange={(event: any) =>
              props.onChange("type", event.target.value)
            }
            fullWidth
          />
          {props.showClients && (
            <SelectInput
              title="Client"
              value={props.client}
              values={clients}
              onChange={(event: any) =>
                props.onChange("client", event.target.value)
              }
              fullWidth
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default AddCampaign;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 10,
    },
    inputField: {
      [theme.breakpoints.only("xs")]: {
        minWidth: "100%",
      },
    },
  })
);
