import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
// app
import { Auth as AuthEntity } from '../../entities/auth';
import { Message, MessageTypes } from '../../entities/site';
import { openSnackbar, closeSnackbar } from '../../store/action-creators';
import { colors } from '../../config/styles';
// entities
import { CampaignContact } from '../../entities/site';
// containter
import Modal from '../../containers/Modal';

const styles: any = {
    link: {
        color: colors.blue,
        cursor: 'pointer'
    },
    footer: {
        textAlign: 'center',
        fontSize: 12
    }
}

interface CampaignContactsListProps {
    open: any;
    title: string;
    campaignContacts: CampaignContact[];
    auth: AuthEntity;
    onClose(route?: string): void;
    openSnackbar(message: string): void;
    closeSnackbar(): void;
}

interface CampaignContactsListState {
    loading: boolean;
    message: Message;
    disabled: boolean;
}

class CampaignContactsList extends Component<CampaignContactsListProps, CampaignContactsListState> {

    constructor(props: CampaignContactsListProps) {
        super(props);
        this.state = {
            loading: false,
            message: {
                message: '',
                messageType: MessageTypes.Notification
            },
            disabled: false
        }
    }

    render() {
        return (
            <Modal
                open={this.props.open}
                title={this.props.title}
                onClose={this.props.onClose}
                message={this.state.message}
            >
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>First</TableCell>
                                    <TableCell>Last</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.campaignContacts.map((contact: CampaignContact, index) => (
                                    <TableRow key={`campaign-${index}`}>
                                        <TableCell>{contact.firstName}</TableCell>
                                        <TableCell>{contact.lastName}</TableCell>
                                        { !contact.phoneNumber && 
                                            <TableCell style={{color: red[500]}}>missing</TableCell>
                                        }
                                        { contact.phoneNumber && 
                                            <TableCell>{contact.phoneNumber}</TableCell>
                                        }
                                        <TableCell>{(contact.email ? contact.email : 'n/a')}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Modal>
        )
    }

}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth.auth
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ openSnackbar, closeSnackbar }, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter<any, any>(CampaignContactsList));
