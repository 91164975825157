import { combineReducers } from "redux";
import actions from "./actions";

export const payment = (state = { payment: 0 }, action: any) => {
  switch (action.type) {
    case actions.ADD_PAYMENT:
      state = Object.assign({}, state, {
        payment: action.payload
      });
      break;
  }
  return state;
};

export const auth = (state = { auth: {} }, action: any) => {
  switch (action.type) {
    case actions.SET_SIGNUP:
      state = Object.assign({}, state, {
        signup: action.payload
      });
      break;
    case actions.CLEAR_SIGNUP:
      state = Object.assign({}, state, {
        signup: action.payload
      });
      break;
    case actions.ADD_AUTH:
      state = Object.assign({}, state, {
        auth: action.payload
      });
      break;
    case actions.DELETE_AUTH:
      state = Object.assign({}, state, {
        auth: action.payload
      });
      break;
  }
  return state;
};

export const session = (state = { session: {} }, action: any) => {
  switch (action.type) {
    case actions.ADD_SESSION:
      state = Object.assign({}, state, {
        session: action.payload
      });
      break;
    case actions.UPDATE_SESSION:
      state = Object.assign({}, state, {
        session: action.payload
      });
      break;
  }
  return state;
};

export const site = (state = { site: {} }, action: any) => {
  switch (action.type) {
    case actions.OPEN_SNACKBAR:
      state = Object.assign({}, state, {
        site: {
          snackbar: true,
          snackbarMsg: action.payload
        }
      });
      break;
    case actions.CLOSE_SNACKBAR:
      state = Object.assign({}, state, {
        site: {
          snackbar: false,
          snackbarMsg: ''
        }
      });
      break;
    case actions.SET_MESSAGE:
      state = Object.assign({}, state, {
        message: action.payload
      });
      break;
    case actions.CLEAR_MESSAGE:
      state = Object.assign({}, state, {
        message: action.payload
      });
      break;
    case actions.SET_API_URLS:
      state = Object.assign({}, state, {
        apiUrls: action.payload
      });
      break;
    case actions.CLEAR_API_URLS:
      state = Object.assign({}, state, {
        apiUrls: action.payload
      });
      break;
  }
  return state;
};

export default combineReducers({ payment, auth, session, site });