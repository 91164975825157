import React from 'react';
import numeral from 'numeral';
// material
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import ActionButton from '../../../components/buttons/ActionButton';
// components
import BarChart from '../../../components/charts/BarChart';

interface PmiPaymentProps {
  payment: number;
  onClick(): void;
}

const PmiPayment: React.FC<PmiPaymentProps> = (props) => {
    
    const classes = useStyles({});

    return (
      <div className={classes.container}>
        <div className={classes.title}>Tired of paying mortgage insurance?  Based on our figures, you’re likely currently paying around…</div>
        <div className={classes.divider} />
        <div className={classes.payment}>{props.payment} per month</div>
        <div className={classes.description}>in Private Mortgage Insurance (PMI)</div>
        <div className={classes.button}><ActionButton name="Let's Get This Removed" onClick={props.onClick} size="lg" fullWidth /></div>
      </div>
    );
}
export default PmiPayment;


const useStyles = makeStyles(theme => ({
  container: {
    padding: 20,
    backgroundColor: '#FFF'
  },
  title: {
    color: '#596675',
    fontSize: 18,
    textAlign: 'center'
  },
  divider: {
    height: 2,
    backgroundColor: '#E0E0E0',
    marginTop: 30,
    marginBottom: 30
  },
  payment: {
    color: '#596675',
    fontSize: 50,
    textAlign: 'center',
    paddingTop: 10,
    paddingBottom: 10
  },
  description: {
    textAlign: 'center',
    color: '#8794A3'
  },
  button: {
    marginTop: 20
  }
}));