import axios from 'axios';
import convert from 'xml-js';
import { Home } from '../entities/home';
import { Auth, Token } from '../entities/auth';
import UtilsService from './utils';
// import { TokenDuration } from 'aws-sdk/clients/cognitoidentity';

/*
  View Now: https://hooks.zapier.com/hooks/catch/3371176/erx4qo/

  Schedule Viewing: https://hooks.zapier.com/hooks/catch/3371176/erxhxm/

  Down Payment Assistance: https://hooks.zapier.com/hooks/catch/3371176/erxdhh/

  Talk To A Loan Officer: https://hooks.zapier.com/hooks/catch/3371176/erxdpo/

  All Of Above: https://hooks.zapier.com/hooks/catch/3371176/erxm5x/
*/

class ZapService {
  static buildParams = (home: Home, auth: Auth) => {
    const user = UtilsService.decodeToken(auth.token);
    
    const address = `${home.streetNumber} ${home.streetName}`;
    let params = `?listingAgentFirstName=${home.listingAgentFirstName}`;
    params += `&listingAgentLastName=${home.listingAgentLastName}`;
    params += `&listingAgentMLSID=${home.listingAgentMLSID}`;
    params += `&listingAgentNumber=${home.listingAgentNumber}`;
    params += `&address=${address}`;
    params += `&zipCode=${home.zipCode}`;

    // attach user data
    const userParams = ZapService.buildUserParams(auth);
    params += `&${userParams}`

    return params;
  }
  static buildUserParams = (auth: Auth) => {
    // attach user data
    const token = UtilsService.decodeToken(auth.token) as Token;
    let phone = token.phone_number;
    phone = phone.replace('+', '');
    let params = `name=${token.name}`;
    params += `&phone=${phone}`;
    params += `&email=${token.email}`;
    return params;
  }
  static sendViewNow = (home: Home, auth: Auth) => {
    return new Promise((resolve, reject) => {
      try {
        const params = ZapService.buildParams(home, auth);
        // const url = `https://hooks.zapier.com/hooks/catch/3371176/erx4qo${params}`;
        // axios.get(url)
        // .then((res) => {
        //   resolve(res.data);
        // })
        // .catch((error) => {
        //   reject(error);
        // });
      } catch(error) {
        reject(error);
      }
    });
  }
  static sendScheduleViewing = (home: Home, auth: Auth) => {
    return new Promise((resolve, reject) => {
      try {
        const params = ZapService.buildParams(home, auth);
        const url = `https://hooks.zapier.com/hooks/catch/3371176/erxhxm${params}`;
        axios.get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
      } catch(error) {
        reject(error);
      }
    });
  }   
  static sendDownpaymentAssistent = (auth: Auth) => {
    return new Promise((resolve, reject) => {
      try {
        const params = ZapService.buildUserParams(auth);
        const url = `https://hooks.zapier.com/hooks/catch/3371176/erxdhh?${params}`;
        axios.get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
      } catch(error) {
        reject(error);
      }
    });
  } 
  static sendTalkToLoanOfficer = (home: Home, auth: Auth) => {
    return new Promise((resolve, reject) => {
      try {
        const params = ZapService.buildParams(home, auth);
        const url = `https://hooks.zapier.com/hooks/catch/3371176/erxdpo${params}`;
        axios.get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
      } catch(error) {
        reject(error);
      }
    });
  }
  static sendAll = (home: Home, auth: Auth) => {
    return new Promise((resolve, reject) => {
      try {
        const params = ZapService.buildParams(home, auth);
        const url = `https://hooks.zapier.com/hooks/catch/3371176/erxm5x${params}`;
        axios.get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
      } catch(error) {
        reject(error);
      }
    });
  }   
}

export default ZapService;