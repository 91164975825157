import React from 'react';
import numeral from 'numeral';
// components
import LineChart from '../../../components/charts/LineChart';
// material
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
// style
import { colors } from '../../../config/styles';

const cloudImage = process.env.PUBLIC_URL + '/images/icons/img_cloud.svg';

interface RateProps {
  rate: number;
}
  
const Rate: React.FC<RateProps> = (props) => {
  
  const classes = useStyles({});

  return (
    <div className={classes.container}>
        <div className={classes.title}>Your Dream Rate</div>
        <div className={classes.imageContainer}> 
          <div className={classes.rateValue}>{numeral(props.rate).format('0.0')}<span className={classes.percent}>%</span></div>
          <img src={cloudImage} />
        </div>
    </div>
  )  
}
export default Rate;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: 20,
      backgroundColor: '#FFF'
    },
    imageContainer: {
      margin: '0px auto',
      padding: 60,
      position: 'relative'
    },
    rateValue: {
      position: 'absolute',
      left: 130,
      top: 120,
      fontSize: 54,
      color: '#7575FC'
    },
    percent: {
      fontSize: 36,
      color: '#7575FC'
    },
    title: {
        fontSize: 20,
        textAlign: 'left',
        color: '#596675'
    }
  }),
);