import React from 'react';
// material
import { makeStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import BarChart from '@material-ui/icons/BarChart';
import MultilineChart from '@material-ui/icons/MultilineChart';
import DevicesOutlined from '@material-ui/icons/DevicesOutlined';
import PhonelinkRing from '@material-ui/icons/PhonelinkRing';
// components
import Loader from '../../containers/Loader';

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: 10,
        width: '100%',
        minHeight: 150,
        maxHeight: 400,
        background: '#fff',
        zIndex: 99,
        position: 'absolute',
        boxShadow: '2px 1px 5px 0px #eee',
        overflowY: 'scroll',
        border: '1px solid #EEEEEE',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    resultContainer: {
        display: 'flex', 
        padding: 20,
        color: '#1B1B25',
        '&:hover': {
            backgroundColor: '#F8F8FC'
        },
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            padding: 15,
        },
    },
    resultContainerEven: {
        backgroundColor: '#FBFBFD',
        display: 'flex', 
        padding: 20,
        color: '#1B1B25',
        '&:hover': {
            backgroundColor: '#F8F8FC'
        },
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            padding: 15,
        },
    },
    row: {
        display: 'flex',
        marginLeft: 10
    },
    rowBottom: {
        display: 'flex',
        '&:last-child': {
            marginLeft: 10
        }
    },
    name: {
        fontSize: 14,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
    },
    link: {
        color: '#0F69AC',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        }
    },
    icon: {
        marginLeft: 5,
        fontSize: 16
    },
    deviceTitle: {
        fontWeight: 'bold',
        paddingRight: 10,
        color: '#666666'
    },
    noResults: {
        padding: 20,
        textAlign: 'center',
        color: '#1B1B25'
    }
}));

interface SearchProps {
    open: boolean;
    loading: boolean;
    addresses: string[];
    onClick(address: string): void;
}


const SearchResults: React.FC<SearchProps> = (props) => {
    
    const classes = useStyles({});
    const resultsElement = (
        <div className={classes.container}>
            <Loader open={props.loading}>
                <div>
                    { props.addresses.map((address: string, index: number) => 
                        <div 
                            key={`patient-${index}`} 
                            className={( index % 2 ? classes.resultContainerEven : classes.resultContainer )}
                            onClick={() => props.onClick(address)}
                        >
                            <div>{address}</div>
                        </div>
                    )}
                    { (!props.addresses.length && !props.loading) && 
                        <div className={classes.noResults}>No addresses found</div>
                    }
                </div>
            </Loader>
        </div>
    );
    return props.open ? resultsElement : null;
}
export default SearchResults;