import React, { useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

// material
import { createStyles, makeStyles } from "@material-ui/styles";
import { Grid, Theme } from "@material-ui/core";
// components
import FooterToolbar from "./components/FooterToolbar";
import BreadCrumbMenu from "../../components/breadcrumb/BreadCrumbMenu";
import LeftPanel from "../../components/campaign/billing/leftpanel/LeftPanel";
import TopBlock from "../../components/campaign/billing/rightpanel/TopBlock";
import BottomBlock from "../../components/campaign/billing/rightpanel/BottomBlock";
import MidBlock from "../../components/campaign/billing/rightpanel/MidBlock";
import Loading from "../../components/site/Loading";
// containers
import AppContainer from "../../containers/App";
// services
import AuthService from "../../services/auth";
// entities
import { Auth, Session } from "../../entities/auth";
import {
  Collaborator,
  Campaign,
  emptyCampaign,
  CampaignContact,
} from "../../entities/site";
import {
  Billing,
  emptyBilling,
  CardDetails,
  emptyCardDetails
} from "../../entities/payment";
import {
  MessageTemplate,
  emptyMessageTemplate,
} from "../../entities/notification";
// store
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  updateSession,
  openSnackbar,
  closeSnackbar,
} from "../../store/action-creators";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      padding: "20px",
    },
    container: {},
    leftPanel: {
      width: "70%",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "90%",
      },
    },
    rightPanel: {
      border: "1px solid #CCCCCC",
      borderRadius: "0 0 4px 4px",
      backgroundColor: "#F8F8F8",
      width: "100%",
      [theme.breakpoints.only("xs")]: {
        marginTop: "20px",
      },
    },
    title: {
      fontFamily: "Asap",
      fontSize: 24,
      color: "#1C242D",
      textAlign: "left",
      [theme.breakpoints.only("xs")]: {
        marginBottom: 10,
      },
    },
    headerContainer: {
      marginBottom: "20px",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.only("xs")]: {
        display: "flex",
        justifyContent: "flex-start",
      },
    },
  })
);

interface NewCampaignTimelineProps {
  history: any;
  classes: any;
  auth: Auth;
  session: Session;
  updateSession(session: Session): void;
  openSnackbar(message: string): void;
  closeSnackbar(): void;
}

const NewCampaignBilling: React.FC<NewCampaignTimelineProps> = (props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [campaign, setCampaign] = useState<Campaign>(emptyCampaign);
  const [totalContacts, setTotalContacts] = useState<number>(0);
  const [template, setTemplate] = useState<MessageTemplate>(
    emptyMessageTemplate
  );
  const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
  const [contactChunks, setContactChunks] = useState<CampaignContact[][]>([]);

  const [totalCost, setTotalCost] = useState<number>(0);
  const [billing, setBilling] = useState<Billing>(emptyBilling);
  const [cardDetails, setCardDetails] = useState<CardDetails>(emptyCardDetails);
  const [split, setSplit] = useState<boolean>(false);

  const [campaignId, setCampaignId] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  const setCampaignData = () => {
    // set campaign from session
    const campaignSession = props.session.campaign;
    console.log("session - billing", campaignSession);

    if (campaignSession) {
      if (campaignSession.campaign) {
        setCampaign(campaignSession.campaign);
        fetchTemplate(campaignSession.campaign.templateId);
      }

      if (campaignSession.collaborators) {
        setCollaborators(campaignSession.collaborators);
      }

      if (campaignSession.contacts) {
        setContactChunks(campaignSession.contacts);
      }

      if (campaignSession.contacts.length > 0) {
        const totalItems = campaignSession.contacts.reduce(
          (count, row) => count + row.length,
          0
        );
        console.log("totalItems", totalItems);
        setTotalCost(totalItems * 0.05);
        setTotalContacts(totalItems);
      }

      // if (campaignSession.contactErrors && campaignSession.contactErrors.length) {
      //   setContactErrors(campaignSession.contactErrors);
      // }
    }
  };

  const fetchTemplate = async (templateId: string) => {
    setLoading(true);
    const templateRes = await axios.get(
      `${AuthService.getApiUrls().notifications}/message/template/${templateId}`
    );
    setTemplate(templateRes.data);
    setLoading(false);
  };

  const handleOnNext = async () => {
    try {
      console.log("auth", props.auth);

      setLoading(true);

      // save campaign
      const campaignData = {
        name: campaign.name,
        type: campaign.type,
        client: "galilaio",
        status: "unpaid",
        templateId: campaign.templateId,
        startDate: campaign.startDate,
        endDate: campaign.endDate,
        daysOfWeek: campaign.daysOfWeek,
        messagesPerDay: campaign.messagesPerDay,
        totalContacts,
        redirectUrl: campaign.redirectUrl,
        contactName: campaign.contactName,
      };
      console.log("campaignData", campaignData);
      const campaignCreateRes = await axios.post(
        `${AuthService.getApiUrls().site}/campaign`,
        campaignData
      );
      console.log("campaignCreateRes", campaignCreateRes);
      if (campaignCreateRes.data.errorMessage) {
        throw new Error("Error creating campaign");
      }
      const newCampaignId = campaignCreateRes.data.id;
      setCampaignId(newCampaignId);

      // get new copy of campaign
      const campaignRes = await axios.get(
        `${AuthService.getApiUrls().site}/campaign/${newCampaignId}`
      );
      const newCampaign = campaignRes.data;

      // charge card
      const amount = totalCost * 100;
      const cardNumber = cardDetails.cardNumber.replace(/-/g, "");
      const chargeData = {
        amount,
        expMonth: cardDetails.month,
        expYear: cardDetails.year,
        number: cardNumber,
        cvc: cardDetails.csv,
        name: billing.name,
        description: campaign.name,
        address: billing.address,
        city: billing.city,
        state: billing.state,
        zip: billing.zipCode,
        refId: newCampaignId,
        refType: "campaign",
        email: props.auth.user?.email,
      };
      console.log("chargeData", chargeData);
      const chargeRes = await axios.post(
        `${AuthService.getApiUrls().payment}/payment/charge`,
        chargeData
      );
      if (chargeRes.data.errorMessage) {
        throw new Error("Error charging card");
      }
      console.log("chargeRes", chargeRes);
      const chargeId = chargeRes.data.id;

      // post payment
      const paymentData = {
        amount,
        description: campaign.name,
        type: "campaign",
        typeId: newCampaignId,
        chargeId,
        userId: props.auth.id,
      };
      console.log("paymentData", paymentData);
      const paymentRes = await axios.post(
        `${AuthService.getApiUrls().payment}/payment`,
        paymentData
      );
      if (paymentRes.data.errorMessage) {
        throw new Error("Error creating payment");
      }
      console.log("paymentRes", paymentRes);

      // update campaign
      newCampaign.status = "new";
      newCampaign.paymentId = paymentRes.data.id;
      console.log("newCampaign", newCampaign);
      const campaignUpdateRes = await axios.put(
        `${AuthService.getApiUrls().site}/campaign/${newCampaignId}`,
        newCampaign
      );
      console.log("campaignUpdateRes", campaignUpdateRes);
      if (campaignUpdateRes.data.errorMessage) {
        throw new Error("Error update campaign");
      }

      // save contacts
      const contactData = {
        campaignId: newCampaignId,
        campaignName: campaign.name,
        chucks: contactChunks,
      };
      console.log("contactData", contactData);
      const contactRes = await axios.post(
        `${AuthService.getApiUrls().site}/campaign/contacts/import`,
        contactData
      );
      console.log("contactRes", contactRes);
      if (contactRes.data.errorMessage) {
        throw new Error("Error creating campaign contacts");
      }

      // save campaign collaborators
      const collaboratorData = {
        campaignId: newCampaignId,
        collaborators,
      };
      console.log("collaboratorData", collaboratorData);
      const collaboratorRes = await axios.post(
        `${AuthService.getApiUrls().site}/campaign/collaborators/import`,
        collaboratorData
      );
      console.log("collaboratorRes", collaboratorRes);
      if (collaboratorRes.data.errorMessage) {
        throw new Error("Error creating campaign collaborators");
      }

      setLoading(false);
      setSubmitted(true);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
      // show error
      props.openSnackbar("Error creating campaign");
    }
  };

  const handleBillingOnChange = (name: string, value: string) => {
    setBilling({ ...billing, [name]: value });
  };

  const handleCardDetailChange = (name: string, value: string) => {
    setCardDetails({ ...cardDetails, [name]: value });
  };

  const handleCancel = () => {
    const newSession = props.session;
    newSession.campaign = undefined;
    props.updateSession(newSession);
    props.history.push("/");
  };

  const handleFinish = () => {
    const newSession = props.session;
    newSession.campaign = undefined;
    props.updateSession(newSession);
    props.history.push(`/campaign/${campaignId}`);
  };

  useEffect(() => {
    setCampaignData();
  }, []);

  return (
    <AppContainer showOption={false} private={false} maxWidth="lg">
      <Loading open={loading} text="saving..." />
      <div className={classes.mainContainer}>
        <Grid container className={classes.container}>
          <Grid
            container
            justify={"space-between"}
            className={classes.headerContainer}
          >
            <Grid item>
              <div className={classes.title}>Billing Information</div>
            </Grid>
            <Grid
              item
              // justify={"flex-end"}
            >
              <BreadCrumbMenu current="/campaign/new/billing" />
            </Grid>
          </Grid>
          <Grid container justify={"space-between"}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className={classes.leftPanel}>
                <LeftPanel
                  billing={billing}
                  carDetails={cardDetails}
                  submitted={submitted}
                  onBillingChange={handleBillingOnChange}
                  onCareDetailsChange={handleCardDetailChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className={classes.rightPanel}>
                <TopBlock
                  records={totalContacts}
                  totalCost={totalCost}
                  split={split}
                  onChange={setSplit}
                />
                <MidBlock
                  split={split}
                  collaborators={collaborators}
                  total={500}
                />
                <BottomBlock
                  template={template}
                  weekDays={campaign.daysOfWeek}
                  messagesPerDay={campaign.messagesPerDay}
                />
              </div>
            </Grid>
          </Grid>
          <FooterToolbar
            pre="/campaign/new/timeline"
            onNext={handleOnNext}
            submit
            submitted={submitted}
            onCancel={handleCancel}
            onFinish={handleFinish}
          />
        </Grid>
      </div>
    </AppContainer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      updateSession,
      openSnackbar,
      closeSnackbar,
    },
    dispatch
  );
};

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(NewCampaignBilling)
);
