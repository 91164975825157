import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    color: '#FFF',
    textTransform: 'capitalize',
    padding: '10px 0',
    backgroundColor: '#009FDB',
    boxShadow: 'none',
    border: '1px solid #009FDB',
    borderRadius: 20,
    '&:hover': {
      backgroundColor: '#008fc5'
    }
  },
  secondary: {
    color: '#666',
    textTransform: 'capitalize',
    padding: '10px 0',
    backgroundColor: '#F8F8F8',
    boxShadow: 'none',
    border: '1px solid #666',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#d9d9d9'
    }
  },
  link: {
    color: '#627995',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});


const useStylesSmall = makeStyles({
  root: {
    color: '#FFF',
    textTransform: 'capitalize',
    backgroundColor: '#009FDB',
    boxShadow: 'none',
    border: '1px solid #009FDB',
    borderRadius: 20,
    '&:hover': {
      backgroundColor: '#008fc5'
    }
  },
  secondary: {
    color: '#627995',
    textTransform: 'capitalize',
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    borderRadius: 20,
    backgroundColor: 'inherit',
    '&:hover': {
      border: '1px solid #999999',
      backgroundColor: 'inherit'
    }
  }
});


const styles: any = {
  containerSmall: {
    padding: 10,
    backgroundColor: '#29ABE2',
    borderRadius: 2,
    cursor: 'pointer'
  },
  container: {
    padding: 15,
    backgroundColor: '#29ABE2',
    borderRadius: 2,
    cursor: 'pointer',
  },
  button: {
    color: '#fff',
    textAlign: 'center'
  }
}

interface ActionButtonProps {
  name: string;
  loading?: boolean;
  size?: string;
  type?: string;
  fullWidth?: boolean;
  textOnly?: boolean;
  disabled?: boolean;
  onClick(): void;
}

// const ActionButton = (props: ActionButtonProps) => (
//   <div style={(props.size && props.size === "small" ? styles.containerSmall : styles.container)} onClick={props.onClick}>
//     { (props.loading !== undefined && props.loading) &&
//       <CircularProgress size={15} style={{color: '#FFF', marginLeft: '47%'}} />
//     }
//     { !props.loading &&
//       <div style={styles.button}>{props.name}</div>
//     }
//   </div>
// );

const ActionButton = (props: ActionButtonProps) => {
  const classes = useStyles({});
  const classesSmall = useStylesSmall({});

  let className: any = classes.root;
  if (props.type && props.type === 'secondary') {
    className = classes.secondary;
  }

  if (props.size && props.size === 'sm') {
    className = classesSmall.root;
  }

  if (props.type && props.type === 'secondary') {
    className = classesSmall.secondary;
  }

  return (
    <div>
      {props.textOnly &&
        <div onClick={props.onClick} className={classes.link}>{props.name}</div>
      }
      {!props.textOnly &&
        <Button
          variant="contained"
          color="primary"
          className={className}
          fullWidth={(props.fullWidth !== undefined ? props.fullWidth : false)}
          onClick={props.onClick}
          disabled={(props.disabled !== undefined ? props.disabled : false)}
        >
          <span style={{
            fontSize: '16px'
          }}>
            {props.name}
          </span>
        </Button>
      }
    </div>
  )
};

export default ActionButton;
