import React, { useState } from "react";
import { find } from "lodash";
// material
import {
  FormControlLabel,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  DialogActions,
  Button,
  Dialog,
  Box,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import { Delete as DeleteIcon, Add as AddIcon } from "@material-ui/icons";
// components
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TabPanel from "../../tabs/TabPanel";
// entities
import { TeamUser, TeamList, Collaborator } from "../../../entities/site";

interface AddCollaboratorsProps {
  open: boolean;
  teamsUsers: TeamUser[];
  branches: TeamList[];
  collaborators: Collaborator[];
  type?: string;
  onClose(): void;
  onOpen(): void;
  onChange(value: boolean, collaborator: Collaborator): void;
  onDelete(id: string): void;
}

const AddCollaborator: React.FC<AddCollaboratorsProps> = (props) => {
  const classes = useStyles();

  const [tab, setTab] = useState<number>(0);

  const isChecked = (checkId: string) => {
    const isFound = find(props.collaborators, { id: checkId });
    if (isFound) {
      return true;
    }
    return false;
  };

  const renderCollaborators = () => {
    return (
      <div>
        <List>
          {props.collaborators.map((collaborator, index) => (
            <ListItem divider key={`team-${index}`}>
              <ListItemText primary={`${collaborator.name} - ${collaborator.type}`} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => props.onDelete(collaborator.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Button
          classes={{ root: classes.collaboratorText }}
          startIcon={<AddIcon />}
          className={classes.button}
          onClick={props.onOpen}
        >
          Add
        </Button>
      </div>
    );
  };

  const renderType = props.type !== undefined ? props.type : 'inline';
  return (
    <React.Fragment>
      { renderType === 'inline' ? renderCollaborators() : null }
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth
        maxWidth={"sm"}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle classes={{ root: classes.dialogTitle }} disableTypography>
          Campaign Collaborators
        </DialogTitle>
        <DialogContent>
          { renderType === 'modal' ? renderCollaborators() : null }
          <Tabs
            value={tab}
            onChange={(event: any, value: number) => setTab(value)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab classes={{ root: classes.tab }} label={"Users"} />
            <Tab classes={{ root: classes.tab }} label={"Branches"} />
          </Tabs>
          <TabPanel index={0} value={tab}>
            <List>
              {props.teamsUsers.map((teamUser, index) => (
                <ListItem divider key={`user-${index}`}>
                  <FormControlLabel
                    className={classes.labelRoot}
                    control={
                      <Checkbox
                        checked={isChecked(teamUser.id)}
                        color={"primary"}
                        onChange={(event, newValue) =>
                          props.onChange(newValue, {
                            id: teamUser.userId,
                            name: teamUser.name,
                            type: "individual",
                          })
                        }
                        name={`${teamUser.id}`}
                      />
                    }
                    label={`${teamUser.name} - ${teamUser.email}`}
                  />
                </ListItem>
              ))}
            </List>
          </TabPanel>
          <TabPanel index={1} value={tab}>
            <List>
              {props.branches.map((branch, index) => (
                <ListItem divider key={`team-${index}`}>
                  <FormControlLabel
                    className={classes.labelRoot}
                    control={
                      <Checkbox
                        checked={isChecked(branch.teamId)}
                        color={"primary"}
                        onChange={(event, newValue) =>
                          props.onChange(newValue, {
                            id: branch.teamId,
                            name: branch.teamName,
                            type: "branch",
                          })
                        }
                        name={`${branch.teamId}`}
                      />
                    }
                    label={`${branch.teamName}`}
                  />
                </ListItem>
              ))}
            </List>
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <button className={classes.cancelButton} onClick={props.onClose}>
            Cancel
          </button>
          <button className={classes.saveButton} onClick={props.onClose}>
            Save
          </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AddCollaborator;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelRoot: {
      width: "100%",
      color: "#00ABCA",
    },
    modal: {
      width: 500,
      height: 388,
    },
    collaboratorText: {
      justifyContent: "normal",
    },
    tab: {
      fontSize: 14,
      fontFamily: "Asap",
      fontWeight: "normal",
      textTransform: "none",
    },
    dialogPaper: {
      height: 500,
    },
    dialogTitle: {
      fontSize: 18,
      color: "#333333",
      fontFamily: "Asap",
    },
    team: {
      width: 420,
      height: 40,
      backgroundColor: "#F4F7FC",
      borderRadius: 2,
      color: "#333333",
      fontFamily: "Asap",
      fontSize: 16,
      marginTop: 10,
      padding: "11px 0 10px 15px",
      [theme.breakpoints.between("xs", "sm")]: {
        maxWidth: "100%",
      },
    },
    button: {
      backgroundColor: "#F4F7FC",
      width: "100%",
      height: 40,
      borderRadius: 2,
      display: "flex",
      fontSize: 14,
      color: "#00ABCA",
      paddingLeft: 16,
      fontWeight: "bold",
      cursor: "pointer",
      [theme.breakpoints.between("xs", "sm")]: {
        maxWidth: "100%",
      },
    },

    saveButton: {
      height: 40,
      width: 140,
      color: "#FFFFFF",
      fontFamily: "Asap",
      fontSize: 16,
      textAlign: "center",
      borderRadius: 4,
      backgroundColor: "#00ABCA",
      border: "none",
      cursor: "pointer",
    },

    cancelButton: {
      height: 40,
      border: "1px solid #CCCCCC",
      width: 140,
      color: "#666666",
      fontFamily: "Asap",
      fontSize: 16,
      textAlign: "center",
      borderRadius: 4,
      backgroundColor: "#F8F8F8",
      cursor: "pointer",
    },
  })
);
