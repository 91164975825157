import React from 'react';
import numeral from 'numeral';

interface MoneyTextProps {
  value: number;
}

const MoneyText = (props: MoneyTextProps) => (
  <span>{numeral(props.value).format('$0,0')}</span>
);

export default MoneyText;
