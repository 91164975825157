
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Switch } from 'react-router-dom';
import moment from 'moment';

// entities
import { Campaign } from '../../entities/site';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    borderTop: "1px solid #CCCCCC"
  },

  tablecelldata: {
    display: "flex",
  },
  startsent: {
    marginLeft: "20px"
  },
  itemname: {
    color: "#00ABC7",
    fontSize: "18px",
    cursor: 'pointer'
  },
  number: {
    fontSize: "16px",
    color: "#5D759D",
    fontWeight: 500,
    textAlign: 'center'
  },
  numbertxt: {
    fontSize: "14px",
    color: "#5C749E",
    textAlign: 'center'
  },
  threedot: {
    color: "#C8D0DA",
    width: "40px", height: "40px",

  },
  statustxtactive: {
    borderRadius: "26px",
    width: "max-content !important",
    fontSize: "12px",
    textAlign: "center",
    padding: "3px 11px;",
    fontWeight: 700,
    fontFamily: "Roboto Bold",
    textTransform: "uppercase",
  },
});

// fetch status color
const getStatusTheme = (status: string) => {
  switch (status) {
    case 'active':
      return { backgroundColor: "#C9F8EF", color: "#00B878" }
    case 'complete':
      return { backgroundColor: "#f9b5b5", color: "white" }
    case 'done':
      return { backgroundColor: "#eef0f3", color: "#627995" }
    default:
      return { backgroundColor: "#c7c7f7", color: "white" }
  }
}
const startDate = (date: any) => {
  const sDate = moment(date).format('MMM DD, YYYY');
  return sDate
}

interface CampaignsTableProps {
  campaigns: Campaign[];
  onClick(campaign: Campaign): void;
}

export const CampaignsTable = (props: CampaignsTableProps) => {
  
  const classes = useStyles();

  const rendertable = (item: Campaign) => {
    return (
      <TableRow >
        <TableCell component="th" scope="row">
          <div className={classes.tablecelldata}>
            <div>
              <DateRangeIcon style={{ color: "#00ABC7" }} />
            </div>
            <div className={classes.startsent}>
              <div className={classes.itemname} onClick={() => props.onClick(item)}>
                {item.name}
              </div>
              <div>
                <div style={{ fontSize: "14px !important", color: "#5B74A0" }}>Contact: {item.contactName}</div>
                <div style={{ fontSize: "14px !important", color: "#5B74A0" }}>Sent: {item.totalSent} of {item.totalContacts}</div>
                <div style={{ fontSize: "14px !important", color: "#5B74A0" }}>Start: {startDate(item.startDate)}</div>
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div style={getStatusTheme(item.status)} className={classes.statustxtactive}>
            {item.status}
          </div>
        </TableCell>
        <TableCell >
          <p className={classes.number}>{item.totalActive}</p>
          <p className={classes.numbertxt}>Active</p>
        </TableCell>
        <TableCell >
          <p className={classes.number}>{item.totalClicks}</p>
          <p className={classes.numbertxt}>Clicks</p>
        </TableCell>
        <TableCell >
          <p className={classes.number}>{item.totalNonResponsive}</p>
          <p className={classes.numbertxt}>No Reply</p>
        </TableCell>
        <TableCell>
          <p className={classes.number}>{item.totalOptOuts}</p>
          <p className={classes.numbertxt}>Opt-Outs</p>
        </TableCell>
        {/* <TableCell style={{ textAlignLast: "right" }}>
          <div>
            <MoreHorizIcon className={classes.threedot} />
          </div>
        </TableCell> */}
      </TableRow>
    )
  }

  return (
    <div className="root">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {
              props.campaigns.map(rendertable)
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

