import React from "react";
import numeral from "numeral";
// material
import { Checkbox, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// components
import Divider from "../../../divider/Divider";

interface TopBlockProps {
  records: number;
  totalCost: number;
  split: boolean;
  onChange: (value: boolean) => void;
}

const TopBlock: React.FC<TopBlockProps> = (props) => {
  const classes = useStyles({});
  return (
    <div className={classes.headerBlock}>
      <div className={classes.topBorder} />

      <div className={classes.numbersBlock}>
        <Grid
          container
          justify={"space-between"}
          className={classes.topNumbersBlock}
        >
          <div className={classes.records}>Records</div>
          <div className={classes.numberOfRecords}>
            {numeral(props.records).format("0,0")}
          </div>
        </Grid>
        <Divider />
        <Grid container justify={"space-between"}>
          <div className={classes.totalCost}>Total Cost</div>
          <div className={classes.totalCost}>
            {numeral(props.totalCost).format("$0.00")}
          </div>
        </Grid>

        <Grid container justify={"space-between"}>
          <div className={classes.label}> at .05¢ per record</div>
          {/* <div className={classes.splitBlock}>
            <Checkbox
              color={"primary"}
              checked={split}
              onChange={(event, newValue) => onChange(newValue)}
            />
            <div className={classes.label}>split among collaborators</div>
          </div> */}
        </Grid>
      </div>
    </div>
  );
};

export default TopBlock;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topBorder: {
      height: 20,
      width: "100%",
      borderRadius: "4px 4px 0 0",
      background: "linear-gradient(135deg, #00ABCA 0%, #00C094 100%)",
    },
    headerBlock: {
      boxSizing: "border-box",
      height: 210,
      width: "100%",
      backgroundColor: "#FFFFFF",
    },
    numbersBlock: {
      padding: "20px 20px 0 20px",
    },
    topNumbersBlock: {
      marginBottom: 30,
    },
    totalCost: {
      color: "#00ABCA",
      fontFamily: "Asap",
      fontSize: 34,
      fontWeight: "bold",
      marginTop: 21,
      [theme.breakpoints.only("xs")]: {
        fontSize: "26px",
        fontWeight: 500,
      },
    },
    records: {
      height: 24,
      width: 200,
      color: "#333333",
      fontFamily: "Asap",
      fontSize: 20,
      fontWeight: "bold",
    },
    numberOfRecords: {
      height: 24,
      color: "#333333",
      fontFamily: "Asap",
      fontSize: 24,
      fontWeight: "bold",
    },
    label: {
      color: "#666666",
      fontFamily: "Asap",
      fontSize: 16,
      marginLeft: 11,
    },
    splitBlock: {
      display: "flex",
      alignItems: "center",
      marginTop: 6,
    },
  })
);
