export enum TeamType {
  Team,
  Branch,
  Company,
  Organization
}

export enum TeamUserRole {
  Manager,
  Loanofficer,
  Member
}

export interface Team {
  id: string;
  name: string;
  description: string;
  type: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  externalId: string;
  parentId: string;
  createdDate: number;
  teams: Team[];
  teamUsers: TeamUser[];
}

export const emptyTeam = {
  id: '',
  name: '',
  description: '',
  type: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  externalId: '',
  parentId: '',
  createdDate: 0,
  teams: [],
  teamUsers: []
}

export interface TeamUser {
  id: string;
  teamId: string;
  userId: string;
  teamName: string;
  teamType: string;
  name: string;
  email: string;
  createdDate: number;
  parentId?: string;
  phone?: string;
  role?: string;
  canText?: boolean;
  meta?: string;
}

export const emptyTeamUser = {
  id: '',
  teamId: '',
  userId: '',
  parentId: '',
  teamName: '',
  teamType: '',
  name: '',
  email: '',
  phone: '',
  role: '',
  externalId: '',
  canText: true,
  meta: '',
  createdDate: 0
}