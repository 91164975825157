export const colors = {
    primaryText: '#627A96', // new
    secondayText: '#586675',
    aqua: '#00ADCB', // new
    darkAqua: '#627A96', // new
    lightBlue: '#29AAE1', // new
    blue: '#00b3fe',
    darkBlue: '#1c252e',
    gray: '##ced4da',
    lightGray: '#F1F1F1',
    darkGray: '#666666',
    mediumGray: '#333333',
    green: '#91CE91',
    greenLight: '#05CA81', // new
    greenDark: '#3E8D41',
    yellow: '#EBC063',
    yellowDark: '#A57A20',
    red: '#E27B79',
    redDark: '#A42B26'
}