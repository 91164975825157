import React from 'react';

const styles: any = {
  container: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: '1px solid #CDCDCD',
    display: 'flex'
  },
  containerBorder: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  text: {
    fontSize: 12,
    color: '#586675'
  },
  textBold: {
    fontSize: 14,
    color: '#627A96',
    fontWeight: 'bold'
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    marginRight: 10,
    marginTop: 10
  }
}

interface RowItemProps {
  title: string;
  value: string;
  color?: string;
  padding?: number;
  bold?: boolean;
  border?: boolean;
}
  
const RowItem: React.FC<RowItemProps> = (props) => {
  const containerClass = props.border ? styles.containerBorder : styles.container;
  const boldClass = props.bold ? styles.textBold : styles.text;
  const containerPadding = props.padding || 5;
  const paddingStyle = {
    padding: containerPadding
  }
  let color;
  if (props.color) {
    const colorStyle = {
      backgroundColor: props.color
    }
    color = <div style={{...styles.circle, ...colorStyle}} />
  }
  return (
    <div style={styles.container}>
      {color}
      <div style={{...containerClass, ...paddingStyle}}>
        <div style={boldClass}>{props.title}</div>
        <div style={boldClass}>{props.value}</div>
      </div>
    </div>
  )  
}
export default RowItem;