import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import numeral from 'numeral';
import { withRouter } from 'react-router-dom';
import { InputBase } from '@material-ui/core';
import { Search } from '@material-ui/icons';

// app
import { updateSession, setSignup, openSnackbar } from '../../store/action-creators';
import UtilsService from '../../services/utils';
// containers
import AppContainer from '../../containers/App';
// components
import NavBar from '../../components/site/NavBar';
import Loading from '../../components/site/Loading';
import SearchInput from '../../components/inputs/SearchInput';
// services
import HomeService from '../../services/home';
// models
import { Session, Auth, Signup } from '../../entities/auth';
import { APIUrl, Address } from '../../entities/site';
import { Mortgage, User } from '../../entities/profile';
// style
import { colors } from '../../config/styles';
// material
import { Grid, Hidden } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
// images
const iconReverseMortgage = process.env.PUBLIC_URL + '/images/icons/icon_reverse_mortgage.svg';
const homeBackground = process.env.PUBLIC_URL + '/galilaio/galilaio_home_hero.jpg';

const styles: any = {
  container: {
    padding: 20,
    backgroundColor: '#F4F7FC',
    borderRadius: 4
  }
}

interface SearchPageProps {
  match?: any;
  history: any;
  signup: Signup,
  auth: Auth;
  session: Session;
  apiUrls: APIUrl;
  setSignup(username: string, email: string, name: string, phone: string, isReset: boolean): void;
  updateSession(session: Session): void;
  openSnackbar(message: string): void;
}

interface SearchPageState {
  loading: boolean;
  searchTerm: string;
}

class SearchPage extends Component<SearchPageProps, SearchPageState> {

  constructor(props: SearchPageProps) {
    super(props);
    this.state = {
      loading: false,
      searchTerm: (this.props.session.payment > 0 ? numeral(this.props.session.payment).format('$0,0') : '')
    }
  }

  componentDidMount = async () => {
    // mounted
  }

  handleNavigate = (route: string) => {
    this.props.history.push(route);
  }

  onChange = (event: any) => {
    let inputText = event.target.value;
    inputText = numeral(inputText).format('$0,0');
    this.setState({ searchTerm: inputText })
  }

  onClick = (term: string) => {
    if (term.length) {
      const payment = numeral(term).value();
      const path = `/payment/options`;
      const newSession = this.props.session;
      newSession.payment = payment || 0;
      newSession.page = path;
      this.props.updateSession(newSession);
      this.props.history.push(path);
    }
  }

  render() {

    return (
      <AppContainer showOption={false} private={false} maxWidth="lg" openSignup={false} color="secondary">
        <Loading open={this.state.loading} />

        <NavBar route="Search by Payment" icon={iconReverseMortgage} onClick={() => this.handleNavigate('/')} />

        <div style={styles.container}>
          <div style={{
            backgroundImage: `url(${homeBackground})`,
            backgroundSize: 'cover',
            // backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            width: '100%',
            height: 600
          }}>
              <Hidden smDown>
                <div style={{ paddingTop: 60 }}>
                  <SearchInput
                    title="Enter Payment"
                    onClick={this.onClick}
                  />
                </div>
              </Hidden>
              <Hidden mdUp>
                <div style={{ paddingTop: 10 }}>
                  <SearchInput
                    title="Enter Payment"
                    onClick={this.onClick}
                  />
                </div>
              </Hidden>
          </div>
        </div>

      </AppContainer>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    signup: state.auth.signup,
    auth: state.auth.auth,
    session: state.session.session,
    apiUrls: state.site.apiUrls
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ updateSession, setSignup, openSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(SearchPage));
