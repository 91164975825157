import React from 'react';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';

export interface SelectItem {
  name: string;
  value: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      paddingBottom: 23
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    titleName:{
      fontSize: 17, 
      textAlign: 'left',
      color: '#627995',
      marginBottom: 10,
    },
  }),
);

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },

    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

interface SelectInputProps {
  title?: string;
  value: string;
  values: SelectItem[];
  fullWidth?: boolean;
  placeholder?: string;
  onChange(event: any): void;
}

const SelectInput: React.FC<SelectInputProps> = (props) => {

  const classes = useStyles();

  return (
    <div>
      <FormControl className={classes.formControl} style={props.fullWidth !== undefined && props.fullWidth ? {width: '100%'} : {minWidth: 200}} >
        { props.title !== undefined &&
          <div className={classes.titleName}>{props.title}</div>
        }
        <Select
          value={props.value}
          onChange={props.onChange}
          input={<BootstrapInput name="age" id="age-customized-select" />}
          placeholder={props.placeholder !== undefined ? props.placeholder : ''}
        >
          {props.values.map((item: SelectItem, index: number) => 
            <MenuItem key={`item-${item.value}-${index}`} value={item.value}>{item.name}</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectInput;