import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import { openSnackbar } from '../../store/action-creators';
import AppContainer from '../../containers/App';
import Grid from '@material-ui/core/Grid';
import TextInput from '../../components/inputs/TextInput';
import ActionButton from '../../components/buttons/ActionButton';
import AuthService from '../../services/auth';

import { Lead, LeadTypes } from '../../entities/site';

// const background = process.env.PUBLIC_URL + '/background_home.jpg';
// const facebook = process.env.PUBLIC_URL + '/facebook.svg';
// const linkdin = process.env.PUBLIC_URL + '/linkedin.svg';

const styles: any = {
  container: {
    maxWidth: 800,
    margin: '60px auto',
    backgroundColor: '#29AAE1',
    textAlign: 'left',
    borderRadius: 2
  },
  bodyContainer: {
    margin: '0 auto',
    maxWidth: 600,
    marginTop: 40,
    borderRadius: 4,
    background: "rgba(0, 0, 0, 0.5)",
    padding: '0px 20px 20px 20px'
  },
  titleLeft: {
    color: '#29AAE1',
    fontSize: 18,
    paddingBottom: 20,
    fontWeight: 700
  },
  titleRight: {
    color: '#fff',
    fontSize: 18,
    paddingBottom: 30,
    fontWeight: 700
  },
  bodyItem: {
    color: '#fff',
    paddingBottom: 20,
    fontSize: 12
  },
  leftContainer: {
    backgroundColor: '#fff',
    padding: 40
  },
  rightContainer: {
    padding: 40,
    margin: '0px auto'
  },
  button: {
    marginTop: 20
  },
  input: {
    marginTop: 10
  },
  inputTitle: {
    fontSize: 12,
    color: '#ced4da'
  },
  social: {
    paddingRight: 10,
    cursor: 'pointer'
  }
}

interface ContactPageProps {
  openSnackbar(msg: string): void;
}

interface ContactPageState {
  name: string;
  phone: string;
  email: string;
  message: string;
}

class ContactPage extends Component<ContactPageProps, ContactPageState> {
  
  constructor(props: ContactPageProps) {
    super(props);
    this.state = {
      name: '',
      phone: '',
      email: '',
      message: ''
    }
  }

  componentDidMount = () => {
    document.body.style.backgroundColor = '#F1F1F1';
    document.body.style.backgroundImage = 'none';
  }

  onNameChange = (event: any) => {
    this.setState({name: event.target.value});
  }

  onPhoneChange = (event: any) => {
    this.setState({phone: event.target.value});
  }

  onEmailChange = (event: any) => {
    this.setState({email: event.target.value});
  }

  onMessageChange = (event: any) => {
    this.setState({message: event.target.value});
  }

  onContactSubmit = async () => {

    try {

      const newLead: Lead = {
        id: "",
        type: LeadTypes.Contact,
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message,
        addressId: "",
        address: "",
        estimatedValue: 0,
        loanBalance: 0,
        dreamRate: 0,
        createdDate: 0
      }

      await axios.post(`${AuthService.getApiUrls().site}/lead`, newLead);

      this.props.openSnackbar('Request sent! We will contact you very soon.');

    } catch (error) {
      console.log('error', error);
    }

    // axios.post(UtilsService.getAPIUrl(`core/sendemail`), {
    //   from: 'contact@micazza.com',
    //   to: this.state.email,
    //   subject: 'Micazza - Contact Request',
    //   text: `name: ${this.state.name} \n\r email: ${this.state.email} \n\r phone: ${this.state.phone}`,
    //   html: `name: ${this.state.name} <br /><br /> email: ${this.state.email}<br /><br /> phone: ${this.state.phone}`,
    // })
    // .then((res) => {
    //   this.props.openSnackbar("Contact request sent");
    //   this.setState({
    //     name: '',
    //     email: '',
    //     phone: ''
    //   });
    // })
    // .catch((error: any) => {
    //   console.log('error', error)
    // });
  }

  onSocialClick = (type: string) => {
    if (type === 'facebook') {
      window.location.href = 'https://www.facebook.com/itsmicazza/';
    }
    if (type === 'linkedin') {
      window.location.href = 'https://www.linkedin.com/company/micazza/';
    }
  }

  render() {
    return ( 
      <AppContainer private={false}>
        <div style={styles.container}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={8}>
              <div style={styles.leftContainer}>
                <div style={styles.titleLeft}>Contact Us</div>
                <div style={styles.input}>
                  <div style={styles.inputTitle}>Name</div>
                  <TextInput 
                    type="string"
                    placeholder="Name"
                    result={this.state.name}
                    onChange={this.onNameChange} 
                  />
                </div>
                <div style={styles.input}>
                  <div style={styles.inputTitle}>Phone</div>
                  <TextInput 
                    type="string"
                    placeholder="Phone"
                    result={this.state.phone}
                    onChange={this.onPhoneChange} 
                  />
                </div>
                <div style={styles.input}>
                  <div style={styles.inputTitle}>Email</div>
                  <TextInput 
                    type="string"
                    placeholder="Email"
                    result={this.state.email}
                    onChange={this.onEmailChange} 
                  />
                </div>
                <div style={styles.input}>
                  <div style={styles.inputTitle}>Email</div>
                  <TextInput 
                    type="string"
                    placeholder="Message"
                    result={this.state.message}
                    multiline
                    onChange={this.onMessageChange} 
                  />
                </div>
                <div style={styles.button}>
                  <ActionButton name="Submit" onClick={this.onContactSubmit} fullWidth />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div style={styles.rightContainer}>
                <div style={styles.titleRight}>Contact Info</div>
                <div style={styles.bodyItem}>548 Gibson Drive, Suite 180 <br/>Roseville, CA 95678</div>
                <div style={styles.bodyItem}>Phone: 888-255-5724</div>
                <div style={styles.bodyItem}>Email: contact@galilaio.com</div>
              </div>
            </Grid>
          </Grid>
        </div>
      </AppContainer>
    )
  }

}


const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ openSnackbar }, dispatch);
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter<any, any>(ContactPage));
