import React, { useState } from 'react';

const styles: any = {
  container: {
  }
}

interface BannerImageProps {
  image: string;
  height?: number;
  children: any;
}

const BannerImage: React.FC<BannerImageProps> = (props) => {

  const [hovered, setHovered] = useState(false);

  const containerHeight = props.height !== undefined ? props.height : '100%';

  return (
    <div style={{
        backgroundImage: `url(${props.image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: containerHeight,
        width: '100%'
      }}>
        {props.children}
      </div>
  )
};
export default BannerImage;