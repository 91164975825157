import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
// store
import { updateSession, openSnackbar } from '../../store/action-creators';
// models
import { Session, Auth } from '../../entities/auth';
import { Option } from '../../entities/option';
// containers
import AppContainer from '../../containers/App';
// components
import NavBar from '../../components/site/NavBar';
import OptionItem from './components/OptionItem';
import Loading from '../../components/site/Loading';
// services
import UtilsService from '../../services/utils';
import ZapService from '../../services/zap';
// images
const iconReverseMortgage = process.env.PUBLIC_URL + '/images/icons/icon_reverse_mortgage.svg';
const background = process.env.PUBLIC_URL + '/background_loans.jpg';

const styles: any = {
  container: {
    marginTop: 20,
    marginBottom: 60,
  },
}

interface OptionsProps {
  history: any;
  match: any;
  session: Session;
  auth: Auth;
  updateSession(session: Session): void;
  openSnackbar(msg: string): void;
}

interface OptionsState {
  options: Option[];
  loading: boolean;
}

class OptionsPage extends Component<OptionsProps, OptionsState> {
  constructor(props: OptionsProps) {
    super(props);

    this.state = {
      options: [],
      loading: false
    }
  }

  componentDidMount = () => {
    // document.body.style.background = 'url(' + background + ') 0% 0% / cover';
    document.body.style.background = 'url(' + background + ') no-repeat';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundAttachment = 'fixed';
    document.body.style.backgroundPosition = 'center'; 

    this.fetchOptions(this.props.session.payment);
    
  }

  handleNavigate = (route: string) => {
    this.props.history.push(route);
  }

  fetchOptions = (payment: number) => {
    this.setState({loading: true});
    const today = moment().format('YYYY-MM-DD');

    // check for cache
    axios.get(UtilsService.getAPIUrl(`cache/${today}-options-${payment}`)).then((resCache) => {

      if (resCache.data && resCache.data.body) {
        const body = JSON.parse(resCache.data.body);
        this.setState({options: body, loading: false});
      } else {
        axios.get(UtilsService.getAPIUrl(`homes/options?payment=${payment}`))
        .then((res) => {
          // return
          this.setState({options: res.data, loading: false});
          // cache data
          const cacheData = {
            id: `${today}-options-${payment}`,
            data: res.data
          }
          axios.post(UtilsService.getAPIUrl(`cache`), cacheData);
        })
        .catch(() => {
          // emtoy
          this.setState({loading: false});
        })
      }

    }).catch(() => {
      this.setState({loading: false});
    });

  }

  updatePayment = (payment: number) => {
    // update session
    const newSession = this.props.session;
    newSession.payment = payment;
    this.props.updateSession(newSession);
    // fetch new
    this.fetchOptions(payment);
  }

  onClick = (optionId: number) => {
    const newSession = this.props.session;
    const url = `/payment/results/${newSession.payment}/?option=${optionId}`;
    newSession.page = url;
    newSession.option = optionId;
    this.props.updateSession(newSession);
    this.props.history.push(url);
  }

  onHelpClick = () => {
    if (UtilsService.isLoggedIn(this.props.auth)) {
      ZapService.sendDownpaymentAssistent(this.props.auth)
      .then((res) => {
        this.props.openSnackbar('Loan assistent request sent');
      })
      .catch((error) => {
        this.props.openSnackbar('Something went wrong');
      });
    } else {
      window.location.href = UtilsService.getLoginPath();
    }
  }

  handleRefresh = () => {
    console.log('option fresh')
    this.fetchOptions(this.props.session.payment);
  }

  render() {
    return (
        <AppContainer showOption={false} private={false} maxWidth="lg" openSignup={false} color="secondary">
          
          <Loading open={this.state.loading} />
          <NavBar route="Search by Payment" icon={iconReverseMortgage} onClick={() => this.handleNavigate('/')} />

          <div style={styles.container}>
            <Grid container spacing={6} justify="center">
              <Grid item xs={12}>
                {!this.state.loading && 
                  <div className="items">
                    {this.state.options.map((option, index) => (
                      <OptionItem 
                        key={'option-' + index}
                        optionId={option.optionId} 
                        option={option}
                        onClick={this.onClick}
                        onHelpClick={this.onHelpClick}
                      />
                    ))}
                  </div>
                }
              </Grid>
            </Grid>
          </div>
      </AppContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    session: state.session.session,
    auth: state.auth.auth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ updateSession, openSnackbar }, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter<any, any>(OptionsPage));