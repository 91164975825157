import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';
import { Option } from '../../../entities/option';
import ActionButton from '../../../components/buttons/ActionButton';
import Hidden from '@material-ui/core/Hidden';
import PaymentDetails from './PaymentDetails';

const styles: any = {
  container: {
    padding: 20,
  },
  contentContainer: {
    padding: 20
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    justifyItems: 'center',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#F1F1F1',
    color: '#666666',
    fontSize: 14,
    fontWeight: 600
  },
  icon: {
    fontSize: 18,
    fontWight: 700,
    cursor: 'pointer'
  },

  rowGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#9D9D9D',
    paddingBottom: 10
  },
  actionButton: {
    marginTop: 10
  }
}

interface OptionDetailProps {
  optionId: number;
  option: Option;
  open: boolean;
  onClick(optionId: number): void;
  onClose(): void;
}

const OptionDetail = (props: OptionDetailProps) => (
  <div>
    <Hidden smDown>
      <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.open} maxWidth="md">
        <div className="option-details">
          <div style={styles.header}>
            <div>Option {props.optionId} - {props.option.homeCount} Homes Available</div>
            <div style={styles.icon}><Close onClick={props.onClose} /></div>
          </div>
          <div style={styles.contentContainer}>
            <PaymentDetails option={props.option} type="dark" />
            <div style={styles.actionButton}>
              <ActionButton name="See Homes" onClick={() => props.onClick(props.optionId)} size="sm" fullWidth />
            </div>
          </div>
        </div>
      </Dialog>
    </Hidden>
    <Hidden mdUp>
      <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.open} maxWidth="md" fullWidth>
        <div className="option-details">
          <div style={styles.header}>
            <div>Option {props.optionId} Details</div>
            <div style={styles.icon}><Close onClick={props.onClose} /></div>
          </div>
          <div style={styles.contentContainer}>
            <PaymentDetails option={props.option} type="dark" />
            <div style={styles.actionButton}>
              <ActionButton name="See Homes" onClick={() => props.onClick(props.optionId)} size="sm" fullWidth />
            </div>
          </div>
        </div>
      </Dialog>
    </Hidden>
  </div>
);

export default OptionDetail;
