import React, { Component } from 'react';
// material
import {createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
// app
import { Message, MessageTypes } from '../entities/site';
// components
import ModalMessage from '../components/message/ModalMessage';
// style
import { colors } from '../config/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '0 auto',
      minWidth: 400,
      maxWidth: 800,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
      [theme.breakpoints.down('sm')]: {
        minWidth: 275,
      },
    },
    headerContainer: {
      backgroundColor: '#F1F1F1',
      borderBottomColor: '#E1E1E1',
      borderBottomWidth: 1
    },
    left: {
      fontSize: 14,
      fontWeight: 'bold',
      padding: 10,
      textAlign: 'left',
      color: colors.darkGray
    },
    right: {
      paddingRight: 10,
      paddingTop: 10,
      textAlign: 'right'
    },
    icon: {
      fontSize: 18,
      color: colors.darkGray
    }
  })
);

interface ModalProps {
  title: string;
  open: boolean;
  message?: Message;
  width?: number;
  onClose(data?: any): void;
}

const Modal: React.FC<ModalProps> = (props) => {

  const classes = useStyles({});
  const message = props.message || { message: '', messageType: MessageTypes.Notification };

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <Grid container className={classes.headerContainer}>
        <Grid item xs={6}>
          <div className={classes.left}>{props.title}</div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.right}><Close onClick={props.onClose} className={classes.icon} /></div>
        </Grid>
      </Grid>
      {message.message !== '' && (
        <ModalMessage message={message} />
      )}
      <DialogContent style={{ minWidth: props.width !== undefined ? props.width : 400, paddingTop: 20, paddingBottom: 20 }}>
        {props.children}
      </DialogContent>
    </Dialog>
  )

}
export default Modal;