import React, { Component } from "react";
// material
import { Grid, withStyles } from "@material-ui/core";
import Divider from "../../../components/divider/Divider";
import { withRouter } from "react-router-dom";

const styles: any = (theme: any) => ({
  container: {
    height: 80,
    borderTop: 1,
    margin: "0px auto",
    backgroudnColor: "#ccc",
    padding: "15px 0px 10px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 15px",
    },
  },
  button: {
    width: 180,
    height: 40,
    borderRadius: 4,
    border: "none",
    fontSize: 16,
    fontFamily: "Roboto Medium",
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: 50,
    cursor: "pointer",
  },
  cancelButton: {
    height: 40,
    border: "1px solid #CCCCCC",
    width: 180,
    color: "#666666",
    fontFamily: "Roboto Medium",
    fontSize: 16,
    textAlign: "center",
    borderRadius: 4,
    backgroundColor: "#F8F8F8",
    cursor: "pointer",
    marginRight: 10,
    [theme.breakpoints.only("xs")]: {
      width: 80,
    },
  },
  saveButton: {
    height: 40,
    width: 180,
    color: "#FFFFFF",
    fontFamily: "Roboto Medium",
    fontSize: 16,
    textAlign: "center",
    borderRadius: 4,
    backgroundColor: "#00ABCA",
    border: "none",
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      width: 80,
    },
  },
  footerContainer: {
    marginTop: 100,
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      marginTop: 50,
    },
  },
});

interface FooterToolbarProps {
  history: any;
  pre: boolean;
  submit: boolean;
  submitted: boolean;
  onNext(): void;
  onCancel(): void;
  onFinish?(): void;
}

class FooterToolbar extends Component<FooterToolbarProps> {
  constructor(props: FooterToolbarProps) {
    super(props);
    console.log(props.history);
  }

  handleCampaignClick = () => {
    this.props.history.push(`/newcampaign/details`);
  };

  handlePrevClick = () => {
    this.props.history.push(this.props.pre);
  };

  render() {
    const { classes, pre, submit, submitted }: any = this.props;

    return (
      <div className={classes.footerContainer}>
        <Divider />
        <div className={classes.container}>
          <Grid container justify={"space-between"}>
            <Grid item>
              <button
                className={classes.cancelButton}
                onClick={this.props.onCancel}
              >
                Cancel
              </button>
            </Grid>
            <Grid item>
              {pre && (
                <button
                  className={classes.cancelButton}
                  onClick={this.handlePrevClick}
                >
                  Previous
                </button>
              )}
              {(submitted && (
                <button
                  className={classes.saveButton}
                  onClick={this.props.onFinish}
                >
                  Launch Campaign
                </button>
              )) ||
                (submit && (
                  <button
                    className={classes.saveButton}
                    onClick={this.props.onNext}
                  >
                    Submit
                  </button>
                )) || (
                  <button
                    className={classes.saveButton}
                    onClick={this.props.onNext}
                  >
                    Next
                  </button>
                )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

// export default withRouter<any, any>(FooterToolbar);
export default withRouter<any, any>(withStyles(styles)(FooterToolbar));
