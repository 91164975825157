import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Slider } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import PopperJs from 'popper.js';
import numeral from 'numeral';

interface AmountProps {
  min: number;
  max: number;
  defaultValue: number;
  onChange(event: any, value: any): void;
}

export interface Marks {
  value: number;
  label: string;
}

const PrettoSlider = withStyles({
  root: {
    color: '#00A6E6',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

const ValueLabelComponent = (props: Props) => {
  const { children, open, value } = props;
  const popperRef = React.useRef<PopperJs | null>(null);

  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  return (
    <Tooltip
      PopperProps={{
        popperRef,
      }}
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={numeral(value).format('$0,0')}
    >
      {children}
    </Tooltip>
  );
}

  
const AmountSlider: React.FC<AmountProps> = (props) => {


  // const valueLabelFormat = (value: number) => {
  //   return marks.findIndex(mark => mark.value === value) + 1;
  // }

  const valueLabelFormat = (value: number) => {
    return numeral(value).format('$0,0');
  }

  const valuetext = (value: number) => {
    return `${value}`;
  }

  return (
    <div>
      <PrettoSlider
        value={props.defaultValue}
        valueLabelFormat={valueLabelFormat}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-restrict"
        valueLabelDisplay="auto"
        min={0}
        max={props.max}
        onChange={props.onChange}
        ValueLabelComponent={ValueLabelComponent}
      />
    </div>
  )  
}
export default AmountSlider;