export interface Billing {
    name: string;
    address: string;
    city: string;
    state: string;
    zipCode: number | undefined;
  }
  
  export const emptyBilling: Billing = {
    name: '',
    address: '',
    city: '',
    state: '',
    zipCode: undefined
  }
  
  export interface CardDetails {
    cardNumber: string;
    month: number | undefined;
    year: number | undefined;
    csv: number | undefined;
  }
  
  export const emptyCardDetails: CardDetails = {
    cardNumber: '4242-4242-4242-4242',
    month: undefined,
    year: undefined,
    csv: undefined
  }
  
  export interface Payment {
    id: string;
    amount: number;
    description: string;
    type: string;
    typeId: string;
    chargeId: string;
    userId: string;
    createdDate: number;
  }