import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

// material
import { Drawer } from '@material-ui/core'

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            marginTop: 60,
            width: drawerWidth,
        },
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
        },
    }),
);

interface DrawerContainerProps {
    children: any;
}

const DrawerContainer = (props: DrawerContainerProps) => {

    const classes = useStyles();


    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left"
        >
            {props.children}
        </Drawer>
    );
}

export default DrawerContainer;
