import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { v1 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
// material
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Hidden, InputBase } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
// hooks
import Debounce from './Debounce';
// components
import SearchResults from './SearchResults';
import ActionButton from '../buttons/ActionButton';
// entites
import { Session } from '../../entities/auth';
import { APIUrl, Address } from '../../entities/site';
import { AddressShort } from '../../entities/home';
// services
import UtilsService from '../../services/utils';
import AuthService from '../../services/auth';

export enum SearchAction {
  Patient,
  PrintReport,
  RecordGrowth,
  Device
}

interface Coords {
  accuracy: number;
  altitude: number;
  altitudeAccuracy: number;
  heading: number;
  latitude: number;
  longitude: number;
  speed: number;
}

const MIN_CHAR_SEARCH = 2;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    searchContainer: {
      display: 'flex',
      justifyContent: 'center'
    },
    searchText: {
      paddingBottom: 5,
      textAlign: 'left',
      color: '#4A607C',
      fontSize: 16
    },
    searchTextMobile: {
      paddingBottom: 5,
      textAlign: 'center',
      color: '#4A607C',
      fontSize: 14
    },
    search: {
      position: 'relative',
      borderTop: '1px solid #C9D3E0',
      borderBottom: '1px solid #C9D3E0',
      borderLeft: '1px solid #C9D3E0',
      backgroundColor: '#FFF',
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      '&:hover': {
        backgroundColor: fade('#FFF', 0.9),
      },
      width: '100%',
      padding: 5
    },
    searchIcon: {
      padding: 15,
      backgroundColor: '#00ABCA',
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      border: '1px solid #C9D3E0'
    },
    inputRoot: {
      color: 'inherit',
      width: 400,
      [theme.breakpoints.down('sm')]: {
        width: 275,
      },
    },
    inputInput: {
      paddingLeft: 20,
      paddingTop: 16,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 400,
      },
      [theme.breakpoints.down('sm')]: {
        width: 275,
        paddingLeft: 30
      },
    },
    searchMobile: {
      position: 'relative',
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 20,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    inputRootMobile: {
      color: 'inherit',
      width: 200,
    },
    inputInputMobile: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 150,
      },
    },
    resultContainer: {
      margin: '0px auto',
      width: 600
    },
    resultContainerMobile: {
      margin: '0px auto',
      width: '100%'
    }
  }),
);

const token = uuid();

interface SearchProps {
  session: Session;
  onShowLoading(): void;
  onClick(address: AddressShort): void;
}

const Search: React.FC<SearchProps> = (props) => {

  const classes = useStyles({});
  const [search, setSearch] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showSearch, setShowSearch] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState<string[]>([]);
  const [selected, setSelected] = useState<number>(0);
  const debouncedSearchTerm = Debounce(searchTerm, 500);
  const node: any = useRef();

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        setIsSearching(true);
        fetchSearch(debouncedSearchTerm);
      } else {
        setIsSearching(false);
        setResults([]);
      }
      document.addEventListener('mouseup', handleClick, false);
      document.addEventListener('keydown', handleKeydown, false);
      return () => {
        document.removeEventListener('mouseup', handleClick, false);
        document.removeEventListener('keydown', handleKeydown, false);
      }
    },
    [debouncedSearchTerm, props.session]
  );

  const handleClick = (e: any) => {
    if (node && node.current.contains(e.target)) {
      return;
    }
    setShowSearch(false);
  }

  const handleKeydown = (e: any) => {
    if (e.key === 'ArrowDown') {
      const nextSelected = selected + 1;
      console.log('nextSelected', nextSelected, (results.length - 1));
      if (nextSelected > (results.length - 1)) {
        console.log('reset');
        setSelected(0);
      } else {
        console.log('increment');
        setSelected(nextSelected);
      }
    }
    if (e.key === 'ArrowUp') {
      const nextSelected = selected - 1;
      if (nextSelected < 0) {
        setSelected(results.length - 1);
      } else {
        setSelected(nextSelected);
      }
    }
    // if (e.key === 'Enter' && selected > 0) {
    //     const selectedResult = props.results[selected];
    //     setSearch(selectedResult.value);
    //     setShowSearch(false);
    //     setSearch(selectedResult.title);
    //     props.onClick(selectedResult);
    // }
  }

  const fetchSearch = (value: string): any => {
    if (value.length > 0 && value.length > MIN_CHAR_SEARCH) {
      setShowSearch(true);
      setIsSearching(true);

      let baseUrl = `${AuthService.getApiUrls().integrations}/integrations/address/autocomplete?term=${value}&token=${token}`;
      if (props.session.coords) {
        baseUrl = baseUrl + `&lat=${props.session.coords.latitude}&long=${props.session.coords.longitude}`;
      }

      axios.get(baseUrl).then((res) => {

        if (res.data.status === 'OK') {
          const addresses: string[] = [];
          res.data.predictions.map((prediction: any) => {
            const address = prediction.description.replace(', USA', '');
            addresses.push(address);
          });
          setIsSearching(false);
          setResults(addresses);
        } else {
          setIsSearching(false);
          setResults([]);
        }

      }).catch((error) => {
        console.log(error);
      });

    } else {
      setShowSearch(false);
    }
  }

  const handleSearchClick = async (address: string) => {
    try {

      props.onShowLoading();

      const addressKey = UtilsService.getKeyFromAddress(address);
      const addressRes: AddressShort = {
        id: addressKey,
        address
      };

      console.log('Search - addressRes', addressRes);

      setSearch(address);
      setShowSearch(false);
      setResults([]);
      props.onClick(addressRes);



      // const addressCheckRes = await axios.get(`${props.apiUrls.core}/address/${addressKey}`);
      // if (addressCheckRes.data) {
      //   addressDB = addressCheckRes.data;
      // } else {
      //   const addressRes = await axios.post(`${props.apiUrls.core}/address`, {
      //     id: addressKey,
      //     address,
      //     estimate: 0,
      //     loanBalance: 0
      //   });
      //   addressDB = addressRes.data;
      // }

    } catch (error) {
      // shit
    }
  }

  const handleSearchChange = (searchTermText: string) => {
    setSearch(searchTermText);
    if (searchTermText.length > MIN_CHAR_SEARCH) {
      setIsSearching(true);
    }
  }

  return (
    <div style={{ position: 'relative' }} ref={node}>
      <Hidden smDown>
        <div className={classes.container}>
          <div className={classes.searchText}>Enter Your Address</div>
          <div className={classes.searchContainer}>
            <div className={classes.search}>
              <InputBase
                value={search}
                onKeyUp={(event: any) => setSearchTerm(event.target.value)}
                onChange={(event) => handleSearchChange(event.target.value)}
                placeholder="Start typing..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            <div className={classes.searchIcon}>
              <SearchIcon style={{ color: '#FFF', fontSize: 30 }} />
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.searchTextMobile}>Enter Your Address</div>
        <div style={{ border: ' 2px solid #00ABCA' }}>
          <div className={classes.search}>
            <InputBase
              value={search}
              onKeyUp={(event: any) => setSearchTerm(event.target.value)}
              onChange={(event) => handleSearchChange(event.target.value)}
              placeholder="Start typing..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        </div>
      </Hidden>
      <SearchResults
        open={showSearch}
        loading={isSearching}
        addresses={results}
        onClick={handleSearchClick}
      />
    </div>
  );
}

export default Search;