import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import moment from 'moment';
import { Grid } from '@material-ui/core'; 

// app
import { updateSession, setSignup, openSnackbar } from '../../store/action-creators';
import UtilsService from '../../services/utils';
// containers
import AppContainer from '../../containers/App';
// components
import NavBar from '../../components/site/NavBar';
import Loading from '../../components/site/Loading';
import ResultItem from './components/ResultItem';
import SelectInput from '../../components/inputs/SelectInput';
// services
import HomeService from '../../services/home';
// models
import { Session, Auth, Signup } from '../../entities/auth';
import { APIUrl } from '../../entities/site';
import { Home } from '../../entities/home';
// service 
import AuthService from '../../services/auth';
// images
const iconPdc = process.env.PUBLIC_URL + '/images/icons/icon_pdc.svg';
const iconReverseMortgage = process.env.PUBLIC_URL + '/images/icons/icon_reverse_mortgage.svg';

const styles: any = {
  container: {
    paddingTop: 30
  },
  disclaimer: {
    marginTop: 40,
    color: '#778092',
    fontSize: 12,
    textAlign: 'left'
  },
  message: {
    fontSize: 18,
    padding: 40,
    textAlign: 'center',
    color: '#596675'
  },
  leftContainer: {
    borderBottom: '1px solid #E0E0E0'
  }
}

interface ResultsPageProps {
  match?: any;
  location: any;
  history: any;
  signup: Signup,
  auth: Auth;
  session: Session;
  apiUrls: APIUrl;
  setSignup(username: string, email: string, name: string, phone: string, isReset: boolean): void;
  updateSession(session: Session): void;
  openSnackbar(message: string): void;
}

interface ResultsPageState {
  loading: boolean;
  county: string;
  homes: Home[];
}

class ResultsPage extends Component<ResultsPageProps, ResultsPageState> {

  constructor(props: ResultsPageProps) {
    super(props);
    this.state = {
      loading: false,
      county: 'Placer',
      homes: []
    }
  }

  componentDidMount = async () => {
    // mounted
    this.fetchAll();
  }

  fetchAll = async () => {

    this.setState({
      loading: true
    })

    const today = moment().format('YYYY-MM-DD');
    const payment = parseInt(this.props.match.params.payment, 10);
    // const qs = queryString.parse(this.props.location.search);
    const qs = { option: '' };
    // 
    const homeRes = await axios.get(`${AuthService.getApiUrls().home}/homes?payment=${payment}&option=${qs.option}&county=Placer&count=0`);

    this.setState({
      loading: false,
      homes: homeRes.data.homes
    })

  }

  handleNavigate = (route: string) => {
    this.props.history.push(route);
  }

  onCountyChange = (event: any) => {
    this.setState({ county: event.target.value });
  }

  onViewHome = () => {
    // view
  }

  onSave = () => {
    // save
  }

  render() {

    return (
      <AppContainer showOption={false} private={false} maxWidth="lg" openSignup={false} color="secondary">
        <Loading open={this.state.loading} />
        <NavBar route="Search by Payment" icon={iconReverseMortgage} onClick={() => this.handleNavigate('/')} />
        <Grid container spacing={2}>
          <Grid item xs={8} />
          <Grid item xs={4}>
            <SelectInput
              title=''
              value={this.state.county}
              onChange={this.onCountyChange}
              values={[{
                name: 'Placer',
                value: 'Placer'
              }]} />
          </Grid>
        </Grid>
        <div style={styles.containerBody} className="items">
          {this.state.homes.map((home, index) => (
            <ResultItem
              key={index}
              onClick={this.onViewHome}
              onSave={this.onSave}
              cardId={index}
              item={home}
            />
          ))}
        </div>
      </AppContainer>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    signup: state.auth.signup,
    auth: state.auth.auth,
    session: state.session.session,
    apiUrls: state.site.apiUrls
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ updateSession, setSignup, openSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(ResultsPage));
