import React from 'react';
import state from '../../store/index';

interface HasAccessProps {
  roles: string[];
  notRoles?: string[];
  children: any;
}
  
const HasAccess: React.FC<HasAccessProps> = (props: HasAccessProps) => {

  let hasRole = false;
  const currentState: any = state.getState();
  if (currentState.auth.auth) {
    const roles = currentState.auth.auth.user.role;
    props.roles.forEach((role) => {
      const check = roles.indexOf(role);  
      if (check !== -1) {
        hasRole = true;
      }
    });

    if (props.notRoles !== undefined) {
      props.notRoles.forEach((role) => {
        const check = roles.indexOf(role);
        if (check !== -1) {
          hasRole = false;
        }
      });
    }

  }

  return hasRole ? (
    props.children
  ) : null;
}
export default HasAccess;