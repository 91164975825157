import React from 'react';
import numeral from 'numeral';
// material
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// components
import SquareItem from './SquareItem';
import RateSlider from './RateSlider';

interface AvailableRatesProps {
  term: number;
  rate: number;
  onTermChange(term: number): void;
  onRateChange(event: any, value: any): void;
}

const marks = [
  {
    value: 2.25,
    label: '',
  },
  {
    value: 2.5,
    label: '',
  },
  {
    value: 2.625,
    label: '',
  },
  {
    value: 2.75,
    label: '',
  },
  {
    value: 2.875,
    label: '',
  },
  {
    value: 3,
    label: '',
  },
  {
    value: 3.125,
    label: '',
  },
  {
    value: 3.25,
    label: '',
  },
  {
    value: 3.375,
    label: '',
  },
  {
    value: 3.5,
    label: '',
  },
  {
    value: 3.625,
    label: '',
  },
  {
    value: 3.75,
    label: '',
  },
  {
    value: 3.875,
    label: '',
  },
  {
    value: 4,
    label: '',
  },
  {
    value: 4.125,
    label: '',
  },
  {
    value: 4.25,
    label: '',
  },
  {
    value: 4.375,
    label: '',
  },
  {
    value: 4.5,
    label: '',
  },
  {
    value: 4.625,
    label: '',
  },
  {
    value: 4.75,
    label: '',
  },
  {
    value: 4.875,
    label: '',
  },
  {
    value: 5,
    label: '',
  },
  {
    value: 5.125,
    label: '',
  },
  {
    value: 5.25,
    label: '',
  },
  {
    value: 5.375,
    label: '',
  },
  {
    value: 5.5,
    label: '',
  },
  {
    value: 5.625,
    label: '',
  },
  {
    value: 5.75,
    label: '',
  },
  {
    value: 5.875,
    label: '',
  },
  {
    value: 6,
    label: '',
  },
  {
    value: 6.125,
    label: '',
  },
  {
    value: 6.25,
    label: '',
  },
  {
    value: 6.375,
    label: '',
  },
  {
    value: 6.5,
    label: '',
  },
  {
    value: 6.625,
    label: '',
  },
  {
    value: 6.75,
    label: '',
  },
  {
    value: 6.875,
    label: '',
  },
  {
    value: 7,
    label: '',
  }
];

const AvailableRates: React.FC<AvailableRatesProps> = (props) => {

  const classes = useStyles({});

  return (
    <div className={classes.container}>
      <div className={classes.title}>Available Rates</div>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <SquareItem
            title="30 Year"
            type="Fixed"
            value={30}
            selected={props.term === 30 ? true : false}
            onClick={props.onTermChange}
          />
        </Grid>
        <Grid item xs={4}>
          <SquareItem
            title="15 Year"
            type="Fixed"
            value={15}
            selected={props.term === 15 ? true : false}
            onClick={props.onTermChange}
          />
        </Grid>
        <Grid item xs={4}>
          <SquareItem
            title="5 Year"
            type="Arm"
            value={5}
            selected={props.term === 5 ? true : false}
            onClick={props.onTermChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={4} md={3}>
          <div className={classes.rateContainer}>
            <div className={classes.rate}>{props.rate}</div>
            <div className={classes.unit}>%</div>
          </div>
        </Grid>
        <Grid item xs={8} md={9}>
          <RateSlider
            defaultValue={props.rate}
            marks={marks} 
            onChange={props.onRateChange}
          />
        </Grid>
      </Grid>

    </div>
  );
}
export default AvailableRates;


const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 20,
    paddingBottom: 20
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    color: '#596675',
    paddingBottom: 20
  },
  unit: {
    fontSize: 20,
    color: '#ACB2BA',
    paddingRight: 10
  },
  rateContainer: {
    border: '1px solid #ABB4C0',
    padding: '10px 15px 10px 15px',
    borderRadius: 4,
    display: 'flex'
  },
  rate: {
    fontSize: 20,
    color: '#596675'
  }
}));