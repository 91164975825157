import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import { sortBy, find, findIndex } from 'lodash';
import moment from 'moment';
// material
import { Container } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// app
import { updateSession, setApiUrls, addAuth, openSnackbar, closeSnackbar } from '../../store/action-creators';
// containers
import AppContainer from '../../containers/App';
import Loader from '../../containers/Loader';
// models
import { Auth, Session } from '../../entities/auth';
import { APIUrl, Campaign, emptyCampaign, CampaignContact } from '../../entities/site';
import { MessageGroup } from '../../entities/notification';
import { Address } from '../../entities/home';
// components
import Loading from '../../components/site/Loading';
import { MessageRowEntity } from './components/MessageRow';
import CampaignService from '../../services/campaign';
import CampaignHeader from './components/CampaignHeader';
import CampaignContactHeader from './components/CampaignContactHeader';
import CampaignContactPanel from './components/CampaignContactPanel';
import CustomTab from '../../components/tabs/CustomTab';
import CustomTabs from '../../components/tabs/CustomTabs';
import ActionButton from '../../components/buttons/ActionButton';
// services
import AuthService from '../../services/auth';
import UtilsService from '../../services/utils';

const useProd = true;

const styles: any = {
  tableWrapper: {
    maxHeight: 440,
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    paddingBottom: 10
  },
  columnHeader: {
    width: '30%',
    textAlign: 'left',
    color: '#627995',
  },
  columnHeaderLast: {
    width: '10%',
    textAlign: 'left',
    color: '#627995',
  },
  column: {
    width: '30%',
    textAlign: 'left'
  },
  columnLast: {
    width: '10%',
    textAlign: 'center'
  },
  columnName: {
    width: '30%',
    color: '#29C7E2',
    fontWeight: 'bold',
    textAlign: 'left'
  },
  backContainer: {
    paddingTop: 10,
    display: 'flex',
    cursor: 'pointer'
  },
  backIcon: {
    color: '#00adcc'
  },
  backText: {
    paddingLeft: 10,
    color: '#00adcc'
  }
}

export interface ContactData {
  id: string;
  contact: CampaignContact;
  loading: boolean;
  messageGroup: MessageGroup | null;
  address: Address | null;
  data: MessageRowEntity[];
}

interface CampaignPageProps {
  match?: any;
  history: any;
  auth: Auth;
  session: Session;
  apiUrls: APIUrl;
  updateSession(session: Session): void;
  setApiUrls(urls: APIUrl): void;
  openSnackbar(message: string): void;
  closeSnackbar(): void;
}

interface CampaignPageState {
  loading: boolean;
  campaign: Campaign;
  campaignContacts: CampaignContact[];
  campaignContactsOptOuts: CampaignContact[];
  campaignContactsClickThroughs: CampaignContact[];
  contactData: ContactData[];
  status: string;
  lastKey: any;
}

class CampaignPage extends Component<CampaignPageProps, CampaignPageState> {

  constructor(props: CampaignPageProps) {
    super(props);
    this.state = {
      loading: false,
      campaign: emptyCampaign,
      campaignContacts: [],
      campaignContactsOptOuts: [],
      campaignContactsClickThroughs: [],
      contactData: [],
      status: 'all',
      lastKey: null
    }
  }

  componentDidMount = async () => {

    const campaignId = this.props.match.params.id;

    this.fetchCampaign(campaignId);
    this.fetchCampaignContacts(campaignId, this.state.status, null);
  }

  fetchCampaign = async (campaignId: string) => {
    // fetch
    const campaignRes = await axios.get(`${AuthService.getApiUrls().site}/campaign/${campaignId}`);
    this.setState({
      campaign: campaignRes.data,
    });
  }

  fetchCampaignContacts = async (campaignId: string, status: string, lastKey: any) => {

    try {

      this.setState({ loading: true });

      let url = `${AuthService.getApiUrls().site}/campaign/${campaignId}/contacts?limit=50`;
      if (status !== 'all') {
        url += `&status=${status}`;
      }
      if (lastKey) {
        url += `&id=${lastKey.id}&campaignId=${lastKey.campaignId}`
      }
      const campaignContactsRes = await axios.get(url);

      let contacts = this.state.campaignContacts;
      contacts = contacts.concat(campaignContactsRes.data.results);
      contacts = sortBy(contacts, 'firstName');

      this.setState({
        loading: false,
        campaignContacts: contacts,
        lastKey: campaignContactsRes.data.lastKey,
      });

    } catch (error) {
      this.setState({ loading: false });
    }

  }

  handleLoadMore = async () => {
    // load more
    await this.fetchCampaignContacts(this.state.campaign.id, this.state.status, this.state.lastKey);
  }

  handleRoute = (campaign: Campaign) => {
    this.props.history.push(`/admin/campaign/${campaign.id}`);
  }

  handleChange = async (contact: CampaignContact) => {
    this.fetchMessageGroup(contact, false);
  }

  fetchMessageGroup = async (contact: CampaignContact, refresh: boolean) => {

    try {

      //
      // check cache
      //

      const data = find(this.state.contactData, { id: contact.id });
      if (!data || refresh) {

        const newData: ContactData = {
          id: contact.id,
          loading: true,
          contact,
          messageGroup: null,
          address: null,
          data: []
        };

        if (!refresh) {
          // not loaded, query message group from contact
          this.setState({ contactData: [...this.state.contactData, newData] });
        }

        // get message group by contact id
        const messageGroupRes = await axios.get(`${AuthService.getApiUrls().notifications}/message/group/contact/${contact.id}`);
        if (!messageGroupRes.data) {
          console.log(messageGroupRes.data)
        }
        if (messageGroupRes) {
          newData.messageGroup = messageGroupRes.data;
          // get messages
          const messages = await axios.get(`${AuthService.getApiUrls().notifications}/messages/group/${messageGroupRes.data.id}`);
          const sortedMessages = sortBy(messages.data, 'createdDate');
          for (const message of sortedMessages) {
            newData.data.push({
              title: message.toPhoneNumber,
              date: moment(message).format('MMM Do YYYY'),
              message: message.message
            });
          }

          //
          // TODO: get notes
          //

          newData.loading = false;
          // update cache
          const updateDate = [...this.state.contactData];
          const updateIndex = findIndex(this.state.contactData, { id: contact.id });
          updateDate[updateIndex] = newData;
          this.setState({ contactData: updateDate });

        }

      }

    } catch (error) {
      const updateData = [...this.state.contactData];
      const updateIndex = findIndex(this.state.contactData, { id: contact.id });
      const newData = { ...updateData[updateIndex], loading: false };
      updateData[updateIndex] = newData;
      this.setState({ contactData: updateData });
    }

  }

  showLoader = (contact: CampaignContact) => {
    const data = find(this.state.contactData, { id: contact.id });
    if (data && data.loading) {
      return true;
    }
    return false;
  }

  handleSendMessage = async (contact: CampaignContact, message: string) => {

    // send message
    const data = find(this.state.contactData, { id: contact.id });
    if (data && data.messageGroup && message) {

      const group = data.messageGroup;
      const messageRes = await CampaignService.sendMessage(
        AuthService.getApiUrls().notifications,
        this.props.auth.user,
        group.id,
        group.toPhoneNumber,
        group.fromPhoneNumber,
        message
      );

      if (messageRes) {
        this.fetchMessageGroup(contact, true);
      }

    }

  }

  handleRefresh = (contact: CampaignContact) => {
    this.handleSetContactLoading(contact);
    this.fetchMessageGroup(contact, true);
  }

  handlePropertyLoad = async (contact: CampaignContact) => {

    try {

      this.handleSetContactLoading(contact);

      const addressStr = `${contact.address}, ${contact.city}, ${contact.state}`;
      const addressId = UtilsService.getKeyFromAddress(addressStr);
      const updateIndex = findIndex(this.state.contactData, { id: contact.id });
      const updateData = [...this.state.contactData];

      // look in cache
      const addressRes = await axios.get(`${AuthService.getApiUrls().home}/address/${addressId}`);
      if (addressRes.data) {
        updateData[updateIndex] = { ...updateData[updateIndex], address: addressRes.data, loading: false };
        this.setState({ contactData: updateData });
      } else {
        const propertyStatsRes = await axios.get(`${AuthService.getApiUrls().integrations}/property/calculate/?address=${contact.address}&city=${contact.city}&state=${contact.state}`);
        if (propertyStatsRes) {
          updateData[updateIndex] = { ...updateData[updateIndex], address: addressRes.data, loading: false };
          const addressNew = propertyStatsRes.data;
          addressNew.id = addressId;
          this.setState({ contactData: updateData });
          await axios.post(`${AuthService.getApiUrls().home}/address`, addressNew);
        }
      }

    } catch (error) {
      const updateIndex = findIndex(this.state.contactData, { id: contact.id });
      const updateData = [...this.state.contactData];
      updateData[updateIndex] = { ...updateData[updateIndex], address: null, loading: false };
      this.setState({ contactData: updateData });
    }

  }

  handleSetContactLoading = (contact: CampaignContact) => {
    const updateIndex = findIndex(this.state.contactData, { id: contact.id });
    const updateData = [...this.state.contactData];
    updateData[updateIndex] = { ...updateData[updateIndex], loading: true };
    this.setState({ contactData: updateData });
  }

  handleTabChange = (newValue: string) => {
    this.setState({ 
      status: newValue,
      lastKey: null,
      campaignContacts: []
    });
    this.fetchCampaignContacts(this.state.campaign.id, newValue, null);
  }

  a11yProps = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  /*
  e2e1644c
  git reset --hard e2e1644c
  git push --force origin e2e1644c:master
  */

  render() {

    return (
      <AppContainer showOption={false} private={false} maxWidth={false}>
        <Loading open={this.state.loading} />
        <Container maxWidth="lg">
          <div style={styles.backContainer} onClick={() => this.props.history.push(`/campaigns`)}>
            <ArrowBackIcon style={styles.backIcon} />
            <div style={styles.backText}>Back to campaigns</div>
          </div>
          <CampaignHeader campaign={this.state.campaign} />
          <div style={{ marginBottom: 10 }}>
            <CustomTabs value={this.state.status} onChange={(event: any, value: string) => this.handleTabChange(value)} aria-label="contact statuses">
              <CustomTab value="all" label="All" {...this.a11yProps('all')} />
              <CustomTab value="active" label="Active" {...this.a11yProps('active')} />
              <CustomTab value="clickThrough" label="Click Throughs " {...this.a11yProps('clickThrough')} />
              <CustomTab value="sent" label="Sent " {...this.a11yProps('sent')} />
              <CustomTab value="optOut" label="Opt Outs" {...this.a11yProps('optOut')} />
            </CustomTabs>
          </div>
          <div style={{ marginBottom: 80 }}>
            <CampaignContactHeader />
            {this.state.campaignContacts.map((contact, index) => (
              <CampaignContactPanel
                key={`all-${index}`}
                contact={contact}
                contactData={this.state.contactData}
                showLoader={this.showLoader(contact)}
                onPanelOpen={this.handleChange}
                onSendMessage={this.handleSendMessage}
                onRefresh={this.handleRefresh}
                onPropertyLoad={this.handlePropertyLoad}
              />
            ))}
            {
              this.state.lastKey !== null &&
              <ActionButton name="LOAD MORE" onClick={this.handleLoadMore} fullWidth />
            }
          </div>
        </Container>
      </AppContainer>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session,
    apiUrls: state.site.apiUrls
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ updateSession, setApiUrls, addAuth, openSnackbar, closeSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(CampaignPage));
