import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { closeSnackbar, openSnackbar } from '../store/action-creators';
import { withRouter } from 'react-router-dom';
// material
import { Container, Snackbar, Drawer, AppBar, Toolbar, Typography } from '@material-ui/core';
// components
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import AdminNav from '../pages/admin/components/AdminNav';
// entities
import { Auth, Session } from '../entities/auth';
import { Site } from '../entities/site';
// services
import UtilsService from '../services/utils';
// container
import DrawerContainer from './Drawer';

const drawerWidth = 240;

const styles = {
  container: {
    // display: 'flex'
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: 20
  }
}


interface AdminContainerProps {
  children: any;
  auth: Auth;
  session: Session;
  site: Site;
  private: boolean;
  openSignup?: boolean;
  loading?: boolean;
  showOption?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  history: any;
  onRefresh?: void;
  openSnackbar(msg: string): void;
  closeSnackbar(event: any): void;
}

class AdminContainer extends Component<AdminContainerProps, {}> {

  componentDidMount = () => {
    this.props.openSnackbar("");
    if (this.props.auth && this.props.session) {
      if (!this.props.auth.token && this.props.session.homesViewed > 0) {
        // force the user to login
        // moved code to zaps and saves
        // this.onLogin();
      }
    }
  }

  onLogin = () => {
    window.location.href = UtilsService.getLoginPath();
  }

  onNavigate = (route: string) => {
    this.props.history.push(route);
  } 

  render() {
    const maxWidth = this.props.maxWidth || false;
    const padding = !maxWidth ? 0 : 32;
    return (
      <div style={styles.container}>
        <Header 
          onLogin={this.onLogin} 
          showOption={this.props.showOption} 
          onRefresh={this.props.onRefresh} 
          openSignup={this.props.openSignup} 
        />
        <div style={{display: 'flex'}}>
            <DrawerContainer>
                <AdminNav auth={this.props.auth} onClick={this.onNavigate} />
            </DrawerContainer>
            <div style={styles.content}>
                {this.props.children}
                <Footer />
            </div>
        </div>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={(this.props.site.snackbar && this.props.site.snackbarMsg ? true : false)}
          onClose={this.props.closeSnackbar}
          autoHideDuration={2000}
          classes={{
            root: 'message',
          }}
          ContentProps={{
            className: 'message-content'
          }}
          message={<span id="message-id" >{this.props.site.snackbarMsg}</span>}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session,
    site: state.site.site
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ closeSnackbar, openSnackbar }, dispatch);
};

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(AdminContainer);

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter<any, any>(AdminContainer));