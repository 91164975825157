import { Option } from './option';

export interface Home {
  id: string;
  importId: string;
  apxSqFtPrimaryRes: number;
  acres: number; 
  bedrooms: number; 
  fullBathrooms: number; 
  halfBathrooms: number; 
  city: string;
  county: string;
  lastModifiedDateTime: string;
  listingAgentFirstName: string;
  listingAgentLastName: string;
  listingAgentMLSID: string;
  listingAgentNumber: number;
  listingOfficeMLSID: string;
  listingOfficeName: string;
  listingOfficeNumber: number;
  listingPrice: number;
  listingRid: number;
  lotSquareFootage: number;
  mLNumber: number;
  pictureCount: number;
  propertyDescription: string;
  propertySubtype1: string;
  sellingAgentMLSID: string;
  sellingAgentNumber: number;
  sellingCoAgentMLSID: string;
  sellingCoAgentNumber: number;
  sellingCoOfficeMLSID: string;
  sellingCoOfficeName: string;
  sellingCoOfficeNumber: number;
  sellingOfficeMLSID: string;
  sellingOfficeName: string;
  sellingOfficeNumber: number;
  sellingPrice: number;
  state: string;
  statusDateIDX: string;
  streetName: string;
  streetNumber: number;
  streetNumberModifier: string;
  streetSuffix: string;
  subdivision: string;
  unit: string;
  valueRangePrice: number;
  virtualTourURL: string;
  yearBuilt: number;
  yearBuiltException: string;
  zipCode: number;
  zipPlus4: number;
  statusIDX: string;
  saved: boolean;
  images?: Image[];
  coverImage?: Image;
  userHomeId?: string;
  option?: Option;
  payment?: number;
}

export interface Image {
  id: string;
  createdDate: number;
  key: string;
  listingId: number;
  url: string;
}

export interface Address {
  id: string;
  propertyId: string;
  address: string;
  stateCode: string;
  xCoord: string;
  yCoord: string;
  beds: number;
  baths: number;
  sqft: number;
  saleDate: string;
  salePrice: number;
  taxAmount: number;
  taxValue: number;
  loanAmount: number;
  loanType: string;
  loanDate: string;
  estimate: number;
  loanBalance: number;
  rentEstimate: string;
  createdDate: number;
}

export const emptyAddress: Address = {
  id: "",
  propertyId: "",
  address: "",
  stateCode: "",
  xCoord: "",
  yCoord: "",
  beds: 0,
  baths: 0,
  sqft: 0,
  saleDate: "",
  salePrice: 0,
  taxAmount: 0,
  taxValue: 0,
  loanAmount: 0,
  loanType: "",
  loanDate: "",
  estimate: 0,
  loanBalance: 0,
  rentEstimate: '',
  createdDate: 0,
};

export interface AddressShort {
  id: string;
  address: string;
}

export const emptyAddressShort: AddressShort = {
  id: "",
  address: ""
};

export const emptyAddressSetting: AddressSetting = {
  id: '',
  userId: '',
  addressId: '',
  removeMortgageInsurance: true,
  propertyIncraese: 0,
  propertyCash: 0,
  propertyEquity: 0,
  createdDate: 0,
  updatedDate: 0
};

export interface AddressSetting {
  id?: string;
  userId: string;
  addressId: string;
  removeMortgageInsurance: boolean;
  propertyIncraese: number;
  propertyCash: number;
  propertyEquity: number;
  createdDate: number;
  updatedDate?: number;
}