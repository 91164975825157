import React, { Component } from 'react';
import { Home } from '../../../entities/home';
import MoneyText from '../../../components/inputs/MoneyText';
import UtilsService from '../../../services/utils';
import numeral from 'numeral';
// import ResultHeart from './ResultHeart';

const home = process.env.PUBLIC_URL + '/home.png';
const bathroomIcon = process.env.PUBLIC_URL + '/galilaio/batg.png';
const roomIcon = process.env.PUBLIC_URL + '/galilaio//bed.png';

const styles: any = {
  container: {
    backgroundColor: '#fff',
    borderRadius: 4,
    position: 'relative'
  },
  header: {
    backgroundSize: 'cover',
    minHeight: '200px',
    borderRadius: '4px 4px 0px 0px'
  },
  address: {
    backgroundColor: '#F1F1F1',
    padding: 5,
    color: '#3B3B3B',
    fontSize: 13
  },
  details: {
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-evenly',
    color: '#626262',
  },
  detailsItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  img: {
    width: 200
  },
  icon: {
    height: 15,
    paddingRight: 10
  },
  dark: {
    color: '#333333',
    fontSize: 18,
    fontWeight: 700,
    padding: 10
  },
  item: {
    padding: 10
  },
  spacer: {
    width: 1,
    backgroundColor: '#D8D8D8'
  },
  itemSq: {
    padding: '12px 10px 10px 10px',
    fontSize: 12
  },
  heart: {
    position: 'absolute',
    top: 5,
    right: 5
  }
}

interface ResultItemProps {
  cardId: number;
  item: Home;
  onClick(homeId: string): void;
  onSave(homeId: string): void;
}

class ResultItem extends Component<ResultItemProps, {}> {
  constructor(props: ResultItemProps) {
    super(props);
  }

  render() {
    const { item, onSave, onClick } = this.props;
    const backgroundImage = (item.coverImage ? item.coverImage : home);
    return (
      <div style={styles.container} className="item-home">
        {/* <div style={styles.heart} >
          <ResultHeart selected={item.saved} onClick={() => onSave(item.id)} />
        </div> */}
        <div onClick={() => onClick(item.id)}>
          <div style={{
            backgroundSize: 'cover',
            minHeight: 200, 
            backgroundImage: `url('${backgroundImage}')`,
            backgroundPosition: 'center'
          }} />
          <div style={styles.address}>{`${item.streetNumber} ${item.streetName}, ${item.city} ${item.zipCode}`}</div>
          { UtilsService.isLocal() &&
            <div>{item.listingRid}</div>
          }
          <div style={styles.details}>
            <div style={styles.item}><img src={roomIcon} style={styles.icon} />{item.bedrooms}</div>
            <div style={styles.spacer} />
            <div style={styles.item}><img src={bathroomIcon} style={styles.icon} />{item.fullBathrooms}</div>
            <div style={styles.spacer} />
            <div style={styles.itemSq}>{numeral(item.apxSqFtPrimaryRes).format('0,0')} sq ft</div>
            <div style={styles.spacer} />
            <div style={styles.dark}><MoneyText value={item.listingPrice} /></div>
          </div>
        </div>
      </div>
    );
  }
}
export default ResultItem;