import React, { useState } from "react";
import { filter } from 'lodash';
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Popover, Grid } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { TeamUser, emptyTeamUser } from '../../../entities/team';

interface AssistProps {
  users: TeamUser[];
  type: string;
  onEdit: (teamUser: TeamUser) => void;
}

const AssistTable: React.FC<AssistProps> = (props) => {

  const classes = useStyles({});
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [ancher, setAnchor] = useState<any>(null);
  const [selectedTeamUser, setSelectedTeamUser] = useState<TeamUser>(emptyTeamUser);

  const filtered = filter(props.users, { role: props.type }) as TeamUser[];

  console.log('type', props.type);
  console.log('users', props.users);

  const handleEditClick = () => {
    props.onEdit(selectedTeamUser);
    setShowPopover(false)
  }

  const handleRowClick = (teamUser: TeamUser) => {
    setSelectedTeamUser(teamUser);
  }

  return (
    <div>
      <Popover
        open={showPopover}
        anchorEl={ancher}
        onClose={() => setShowPopover(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Typography
          onClick={handleEditClick}
          className={classes.typography}>
          Edit
          </Typography>
      </Popover>
      <Grid className={classes.gridContainer} container>
        <Grid item className={classes.gridHeaderContainer} xs={12} container alignItems="center">
          <Grid xs={4} item>
            <Typography className={classes.gridHeader}>Name</Typography>
          </Grid>
          <Grid xs={4} item>
            <Typography className={classes.gridHeader}>Email</Typography>
          </Grid>
          <Grid xs={4} item>
            <Typography className={classes.gridHeader}>Phone</Typography>
          </Grid>
        </Grid>
        {filtered && filtered.map((value, index) =>
          <Grid alignItems="center" key={index} item className={classes.gridRow} xs={12} container>
            <Grid xs={4} item>
              <Typography className={classes.nameCol}>{value.name}</Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography className={classes.mailCol}>{value.email}</Typography>
            </Grid>
            <Grid xs={3} item>
              <Typography className={classes.mailCol}>{value.phone}</Typography>
            </Grid>
            <Grid className={classes.dotsContainer} xs={1} item>
              <MoreVertIcon className={classes.dots} onClick={() => handleRowClick(value)} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  )
};

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
    color: '#005B7C',
    fontSize: '14px',
    cursor: 'pointer'
  },
  gridHeaderContainer: {
    padding: '6px 0 6px 15px'
  },
  nameCol: {
    color: '#00597E',
    fontSize: 14,
    fontFamily: 'Roboto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  gridRow: {
    padding: '0 0 0 15px'

  },
  mailCol: {
    color: '#333333',
    fontSize: 14,
    fontFamily: 'Roboto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  dots: {
    fontSize: 35,
    marginLeft: 'auto',
    color: '#c9d3e0',
    marginRight: 6,
    transform: 'rotate(90deg)',
  },
  dotsContainer: {
    textAlign: 'right',
  },
  gridHeader: {
    color: '#6C7C8E',
    fontSize: 14,
    fontFamily: 'Roboto',
  },
  gridContainer: {
    '& > .MuiGrid-grid-xs-12:nth-child(even)': {
      backgroundColor: '#f4f6f9'
    },
    borderBottom: '1px solid #C9D3E0',
  }
}));

export default AssistTable;
