import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import { filter } from 'lodash';
import moment from 'moment';
// material
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Container, Grid } from '@material-ui/core';
// app
import { updateSession, openSnackbar, closeSnackbar } from '../../store/action-creators';
// containers
import AppContainer from '../../containers/App';
import ProfileContainer from '../../containers/Profile';
// models
import { Auth, Session } from '../../entities/auth';
import { APIUrl, TeamUser } from '../../entities/site';
// components
import Loading from '../../components/site/Loading';
import ActionButton from '../../components/buttons/ActionButton';
import Empty from './components/Empty';
import AddLoanOfficer from './components/AddTeamUser';
// service 
import AuthService from '../../services/auth';

const useProd = true;

const styles: any = {
  tableWrapper: {
    maxHeight: 800,
    overflow: 'auto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'left'
  }
}

interface BranchPageProps {
  match?: any;
  history: any;
  auth: Auth;
  session: Session;
  apiUrls: APIUrl;
  updateSession(session: Session): void;
  openSnackbar(message: string): void;
  closeSnackbar(): void;
}

interface BranchPageState {
  loading: boolean;
  teamUsers: TeamUser[];
  openAddTeam: boolean;
}

class BranchPage extends Component<BranchPageProps, BranchPageState> {

  constructor(props: BranchPageProps) {
    super(props);
    this.state = {
      teamUsers: [],
      loading: false,
      openAddTeam: false
    }
  }

  componentDidMount =  () => {
    this.fetchLoanOfficers();
  }

  fetchLoanOfficers = async () => {

    const branchId = this.props.match.params.branchid;

    this.setState({
      loading: true
    });
    const teamUsersRes = await axios.get(`${AuthService.getApiUrls().site}/team/${branchId}/users`);
    const teamUsersFiltered = filter(teamUsersRes.data, { role: 'loanofficer' }) as TeamUser[];
    this.setState({
      loading: false,
      teamUsers: teamUsersFiltered
    });

  }

  handleShowLoading = () => {
    this.setState({ loading: true });
  }

  handleOpenAddTeam = () => {
    this.setState({ openAddTeam: true });
  }

  handleCloseTeam = () => {
    this.setState({ openAddTeam: false });
    this.fetchLoanOfficers();
  }

  render() {

    return (
      <AppContainer loading={this.state.loading} private maxWidth="lg">
        <ProfileContainer>
          <Loading open={this.state.loading} />
          <Container maxWidth="lg">
            <Grid container>
              <Grid xs={10}>
                <div style={styles.title}>Loan Officers</div>
              </Grid>
              <Grid xs={2}>
                <ActionButton name="New Loan Officer" size="sm" onClick={this.handleOpenAddTeam} fullWidth />
              </Grid>
            </Grid>
            {this.state.teamUsers.length === 0 &&
              <Empty message="No Loan Officers to Display" />
            }
            {this.state.teamUsers.length > 0 &&
              <Paper>
                <div style={styles.tableWrapper}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>email</TableCell>
                        <TableCell>Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.teamUsers.map((teamUser: TeamUser, index) => (
                        <TableRow key={`address-${index}`}>
                          <TableCell component="th" scope="row">{teamUser.name}</TableCell>
                          <TableCell>{teamUser.email}</TableCell>
                          <TableCell>{moment(teamUser.createdDate).format('MM-DD-YYYY HH:MM')}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Paper>
            }
          </Container>
          <AddLoanOfficer open={this.state.openAddTeam} onClose={this.handleCloseTeam} />
        </ProfileContainer>
      </AppContainer>
    )
  }


}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session,
    apiUrls: state.site.apiUrls
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ updateSession, openSnackbar, closeSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(BranchPage));
