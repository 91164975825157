export const emptyMessageTemplate = {
  id: '',
  name: '',
  description: '',
  rate: 0,
  createdDate: 0
};

export interface MessageTemplate {
  id: string;
  name: string;
  description: string;
  rate: number;
  createdDate: number;
}

export interface MessageGroup {
  id: string;
  campaignId: string;
  contactId: string;
  toPhoneNumber: string;
  fromPhoneNumber: string;
  groupStatus: string;
  count: number;
  createdDate: number;
}

export const emptyMessage = {
  id: '',
  groupId: '',
  messageId: '',
  fromPhoneNumber: '',
  toPhoneNumber: '',
  message: '',
  createdDate: 0
}

export interface Message {
  id: string;
  groupId: string;
  messageId: string;
  fromPhoneNumber: string;
  toPhoneNumber: string;
  message: string;
  createdDate: number;
}

export interface NotifyState {
  mortage: boolean;
  property: boolean;
  cash: boolean;
  cashFlows: boolean;
  dreamRate: boolean;
}

export const emptyNotifyState = {
  mortage: true,
  property: true,
  cash: true,
  cashFlows: true,
  dreamRate: true
}

export const emptyMessageReceipt = {
  ApiVersion: '',
  Body: '',
  DateSent: '',
  From: '',
  MessagePrice: '',
  MessageSid: '',
  Status: '',
  To: '',
  UseType: ''
}

export interface MessageReceipt {
  ApiVersion: string;
  Body: string;
  DateSent: string;
  From: string;
  MessagePrice: string;
  MessageSid: string;
  Status: string;
  To: string;
  UseType: string;
}

export enum LinkType {
  Campaign,
  Marketing
}

export interface Link {
  id: string;
  domain: string;
  redirect: string;
  title: string;
  typeId: string;
  type: string;
  contactId: string;
  shortLink: string;
  createdDate: number;
}