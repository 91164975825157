import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { filter, findIndex } from "lodash";
import moment from "moment";
// material
import { Grid, Typography } from "@material-ui/core";
// app
import {
  updateSession,
  setApiUrls,
  openSnackbar,
  closeSnackbar
} from "../../store/action-creators";
// containers
import AppContainer from "../../containers/App";
import ProfileContainer from "../../containers/Profile";
// models
import { Auth, Session } from "../../entities/auth";
import { Team, TeamUser, emptyTeam } from "../../entities/site";
// components
import Loading from "../../components/site/Loading";
import ActionButton from "../../components/buttons/ActionButton";
import AddOrganization from "./components/AddOrganization";
import Empty from "./components/Empty";
import TeamTable from "./components/TeamTable";
import AddTeam from "./components/AddTeam";
import HasAccess from '../../components/auth/HasAccess';
import AlertDialog from '../../components/site/AlertDialog';
// services 
import AuthService, { Roles } from '../../services/auth';

const useProd = true;

const styles: any = {
  tableWrapper: {
    maxHeight: 800,
    overflow: "auto"
  },
  empty: {
    padding: 40,
    backgroundColor: 'rgba(201,211,224,0.2)',
    color: '#00597E',
    textAlign: 'center',
    fontSize: 14
  },
  emptySmall: {
    padding: 10,
    backgroundColor: 'rgba(201,211,224,0.2)',
    color: '#00597E',
    textAlign: 'center',
    fontSize: 14
  }
};

interface OrganizationPageProps {
  history: any;
  auth: Auth;
  session: Session;
  updateSession(session: Session): void;
  openSnackbar(message: string): void;
  closeSnackbar(): void;

}

interface OrganizationPageState {
  loading: boolean;
  teams: Team[];
  openAddTeam: boolean;
  openAddOrganization: boolean;
  selectedTeam: Team;
  showDeleteDialog: boolean;
}

class OrganizationsPage extends Component<
  OrganizationPageProps,
  OrganizationPageState
  > {
  constructor(props: OrganizationPageProps) {
    super(props);
    this.state = {
      teams: [],
      loading: false,
      openAddTeam: false,
      openAddOrganization: false,
      selectedTeam: emptyTeam,
      showDeleteDialog: false
    };
  }

  componentDidMount = async () => {
    this.fetchOrganization();
  };

  fetchOrganization = async () => {
    this.setState({
      loading: true
    });

    const teamsRes = await axios.get(`${AuthService.getApiUrls().site}/teams/user/${this.props.auth.id}`);
    const organizations = filter(teamsRes.data, { type: "organization" }) as Team[];
    const branches = filter(teamsRes.data, { type: "branch" }) as Team[];

    branches.forEach((branch: Team) => {
      const orgIndex = findIndex(organizations, { id: branch.parentId });
      if (orgIndex !== -1) {
        if (!organizations[orgIndex].teams) {
          organizations[orgIndex].teams = [];
        }
        organizations[orgIndex].teams.push(branch);
      }
    });

    this.setState({
      loading: false,
      teams: organizations
    });
  };

  handleShowLoading = () => {
    this.setState({ loading: true });
  };

  handleOpenAddTeam = () => {
    this.setState({ openAddTeam: true });
  };

  handleCloseTeam = () => {
    this.setState({ openAddTeam: false });
    this.fetchOrganization();
  };

  handleTeamClick = async (isOpen: boolean, parentId: string, teamId: string) => {

    const organizations = this.state.teams;
    const organizationIndex = findIndex(organizations, { id: parentId });
    if (organizationIndex !== -1) {

      const branchIndex = findIndex(organizations[organizationIndex].teams, { id: teamId });

      if (branchIndex !== -1 && !this.state.teams[organizationIndex].teams[branchIndex].teamUsers) {

        this.setState({
          loading: true
        });

        const teamUsersRes = await axios.get(`${AuthService.getApiUrls().site}/team/${teamId}/users`);
        const teamUsersFiltered = filter(teamUsersRes.data, { role: 'teamuser' }) as TeamUser[];

        organizations[organizationIndex].teams[branchIndex].teamUsers = [...teamUsersFiltered];

        this.setState({
          loading: false,
          teams: organizations
        });

      }
    }

  }

  handleEditBranch = (team: Team) => {
    // load modal
    this.setState({ selectedTeam: team, openAddTeam: true });
  }

  handleShowDeleteTeam = (team: Team) => {
    this.setState({ selectedTeam: team, showDeleteDialog: true });
  }

  handleDeleteTeam = async (value: boolean) => {
    try {
      this.setState({ showDeleteDialog: false });

      if (value) {
          this.setState({ loading: true });
          // delete team user
          await axios.delete(`${AuthService.getApiUrls().site}/team/${this.state.selectedTeam.id}`);
          this.setState({ loading: true });
      }
  } catch (error) {
      console.log('error', error);
  }
  }

  handleLoanOfficerClick = (teamUser: TeamUser) => {
    this.props.history.push(`/profile/loanofficer/${teamUser.id}`);
  }

  handleAddOrganization = () => {
    this.setState({ openAddOrganization: true });
  }

  handleCloseOrganization = (reload: boolean) => {
    this.setState({ openAddOrganization: false });
    if (reload) {
      this.fetchOrganization();
    }
  }

  render() {
    const { teams } = this.state;
    return (
      <AppContainer loading={this.state.loading} private maxWidth="lg">
        <ProfileContainer>
          <Loading open={this.state.loading} />
          <Grid container>
            <Grid item xs={7}>
              <div className="title">Organizations</div>
            </Grid>
            <Grid item xs={3}>
              <div style={{ marginRight: 5 }}>
                <HasAccess roles={[Roles.Admin, Roles.Superadmin, Roles.Orgadmin]}>
                  <ActionButton
                    name="Add Organization"
                    size="sm"
                    onClick={this.handleAddOrganization}
                    fullWidth
                  />
                </HasAccess>
              </div>
            </Grid>
            <Grid item xs={2}>
              <HasAccess roles={[Roles.Admin, Roles.Superadmin, Roles.Orgadmin]}>
                <ActionButton
                  name="Add Team"
                  size="sm"
                  onClick={this.handleOpenAddTeam}
                  fullWidth
                />
              </HasAccess>
            </Grid>
          </Grid>
          {teams.length > 0 && teams.map((organization: Team, index: number) => (
            <div key={`org-${index}`}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={'organization-title'}>{organization.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={'organization-title'} style={{ fontSize: 12, paddingTop: 6, textAlign: 'right' }}>{`${organization.address} ${organization.city}, ${organization.state}, ${organization.zipCode}`}</Typography>
                </Grid>
              </Grid>
              { organization.teams && organization.teams.length > 0 && organization.teams.map((branch: Team, indexBranch: number) => (
                <React.Fragment key={`branch-${indexBranch}`}>
                  <TeamTable
                    team={branch}
                    editTeam={this.handleEditBranch}
                    deleteTeam={this.handleShowDeleteTeam}
                    onClick={this.handleTeamClick}
                    onTeamUserClick={this.handleLoanOfficerClick}
                  />
                </React.Fragment>
              ))
              }
              { !organization.teams &&
                <div style={styles.emptySmall}>No branches to display</div>
              }
            </div>
          ))
          }
          {!teams.length &&
            <div style={styles.empty}>No organizations to display</div>
          }
          <AddTeam
            open={this.state.openAddTeam}
            teams={this.state.teams}
            team={this.state.selectedTeam}
            onClose={this.handleCloseTeam}
          />
          <AddOrganization
            open={this.state.openAddOrganization}
            onClose={this.handleCloseOrganization}
          />
          <AlertDialog
            title="Delete Team"
            desc="Are you sure you want to the delete the team?"
            open={this.state.showDeleteDialog}
            onClose={this.handleDeleteTeam}
          />
        </ProfileContainer>
      </AppContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    { updateSession, openSnackbar, closeSnackbar },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(OrganizationsPage));
