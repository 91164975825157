import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { findIndex } from "lodash";
// material
import { Grid, makeStyles } from "@material-ui/core";
// components
import AddCampaignTimeline from "../../components/campaign/AddCampaignTimeline";
import BreadCrumbMenu from "../../components/breadcrumb/BreadCrumbMenu";
import FooterToolbar from "./components/FooterToolbar";
// containers
import AppContainer from "../../containers/App";
// entities
import { Auth, Session } from "../../entities/auth";
// store
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  updateSession,
  openSnackbar,
  closeSnackbar,
} from "../../store/action-creators";

const daysMap = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];

interface NewCampaignTimelineProps {
  history: any;
  auth: Auth;
  session: Session;
  updateSession(session: Session): void;
  openSnackbar(message: string): void;
  closeSnackbar(): void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0px auto",
    padding: "0px 25px",
    [theme.breakpoints.only("xs")]: {
      padding: "0px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0px 30px !important",
    },
  },
  title: {
    fontFamily: "Asap",
    fontSize: 24,
    color: "#1C242D",
    textAlign: "left",
    marginBottom: 20,
  },
  timelineHeadingContainer: {
    padding: "20px 0px 0px",
    [theme.breakpoints.down("md")]: {
      padding: "20px 15px",
    },
  },
}));

const NewCampaignTimeline: React.FC<NewCampaignTimelineProps> = (props) => {
  const [messagesPerDay, setMessagesPerDay] = useState<number>(0);
  const [daysOfWeek, setDaysOfWeek] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const setCampaign = () => {
    // set campaign from session
    const campaignSession = props.session.campaign;
    const campaign = campaignSession?.campaign;

    console.log("session - timeline", campaignSession);

    if (campaign) {
      setMessagesPerDay(campaign.messagesPerDay);
      setStartDate(campaign.startDate);
      setEndDate(campaign.startDate);

      if (campaign.daysOfWeek) {
        const daysOfWeekNew: number[] = [];
        const days = campaign.daysOfWeek.split(",");
        console.log("days", days);
        days.forEach((day) => {
          daysOfWeekNew.push(daysMap.indexOf(day));
        });
        setDaysOfWeek(daysOfWeekNew);
      }
    }
  };

  const handleTimelineChange = (type: string, valueStr: string) => {
    // this is shit code
    if (type === "messagesPerDay") {
      const messages = valueStr ? parseInt(valueStr, 10) : 0;
      setMessagesPerDay(messages);
    }
    if (type === "startDate") {
      setStartDate(valueStr);
    }
    if (type === "endDate") {
      setEndDate(valueStr);
    }
  };

  const handleWeekDayChange = (value: number) => {
    const daysOfWeekNew = [...daysOfWeek];
    const index = daysOfWeekNew.indexOf(value);
    if (index > -1) {
      daysOfWeekNew.splice(index, 1);
    } else {
      daysOfWeekNew.push(value);
    }
    setDaysOfWeek(daysOfWeekNew);
  };

  const handleOnNext = () => {
    if (!startDate) {
      props.openSnackbar("Campaign start date required");
      return;
    }

    if (!endDate) {
      props.openSnackbar("Campaign start date required");
      return;
    }

    if (!daysOfWeek.length) {
      props.openSnackbar("Campaign week days required");
      return;
    }

    if (!messagesPerDay) {
      props.openSnackbar("Campaign messages per day required");
      return;
    }

    // build daysOfWeek string
    let daysOfWeekStr = "";
    daysOfWeek.forEach((day) => {
      daysOfWeekStr += `${daysMap[day]},`;
    });
    daysOfWeekStr = daysOfWeekStr.substring(0, daysOfWeekStr.length - 1);

    const newSession = props.session;
    const newCampaign = newSession.campaign?.campaign;
    if (!newCampaign) {
      props.openSnackbar("Campaign details missing");
      return;
    }
    newCampaign.startDate = startDate;
    newCampaign.endDate = endDate;
    newCampaign.daysOfWeek = daysOfWeekStr;
    newCampaign.messagesPerDay = messagesPerDay;

    props.updateSession(newSession);
    props.history.push("/campaign/new/billing");
  };

  const handleCancel = () => {
    const newSession = props.session;
    newSession.campaign = undefined;
    props.updateSession(newSession);
    props.history.push("/");
  };

  useEffect(() => {
    setCampaign();
  }, []);

  const classes = useStyles();

  return (
    <AppContainer showOption={false} private={false} maxWidth="lg">
      <div className={classes.container}>
        <Grid
          container
          justify={"space-between"}
          className={classes.timelineHeadingContainer}
        >
          <Grid item>
            <div className={classes.title}>Select Timeline</div>
          </Grid>
          <Grid item>
            <BreadCrumbMenu current="/campaign/new/timeline" />
          </Grid>
        </Grid>
        <AddCampaignTimeline
          messagesPerDay={messagesPerDay}
          weekDays={daysOfWeek}
          startDate={startDate}
          endDate={endDate}
          onChange={handleTimelineChange}
          onWeekDayChange={handleWeekDayChange}
        />
      </div>
      <FooterToolbar
        history={props.history}
        pre="/campaign/new/template"
        onNext={handleOnNext}
        submit={false}
        onCancel={handleCancel}
      />
    </AppContainer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      updateSession,
      openSnackbar,
      closeSnackbar,
    },
    dispatch
  );
};

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(NewCampaignTimeline)
);
