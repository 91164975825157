import React, { useState } from "react";
import axios from 'axios';
import { Typography, Link, Grid, Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// entities
import { Team, emptyTeam, TeamUser, emptyTeamUser } from '../../../entities/site';
import { User } from '../../../entities/user';
// components
import AddTeamUser from "./AddTeamUser";
import AlertDialog from '../../../components/site/AlertDialog';
import Loading from '../../../components/site/Loading';
// service
import AuthService from '../../../services/auth';


interface TeamTableProps {
    team: Team;
    editTeam: (team: Team) => void;
    deleteTeam: (team: Team) => void;
    onClick: (open: boolean, parentId: string, teamId: string) => void;
    onTeamUserClick: (teamUser: TeamUser) => void;
};

const TeamTable: React.FC<TeamTableProps> = (props) => {
    const classes = useStyles({});
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [teamOptionAncher, setTeamOptionAnchor] = useState<any>(null)
    const [showTeamOptionsPopover, setShowTeamOptionsPopover] = useState<boolean>(false);
    const [showAddOfficerModal, setShowAddOfficerModal] = useState<boolean>(false);

    const [selectedLoanOfficer, setSelectedLoanOfficer] = useState<TeamUser>(emptyTeamUser);

    const [loanOfficerAncher, setLoanOfficerAncher] = useState<any>(null)
    const [showLoanOfficerPopover, setShowLoanOfficerPopover] = useState<boolean>(false);

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    const toggle = (parentId: string, teamId: string) => {
        const toggleOpen = !isOpen;
        setIsOpen(toggleOpen)
        props.onClick(toggleOpen, parentId, teamId);
    };

    const handleTeamOptionsClick = (e: any) => {
        setShowTeamOptionsPopover(true);
        setTeamOptionAnchor(e.currentTarget);
    }

    const handleAddOfficerClick = () => {
        setShowAddOfficerModal(true);
        setShowTeamOptionsPopover(false);
    }

    const handleEditOfficerClick = () => {
        setShowAddOfficerModal(true);
    }

    const handleLoanOfficerOptionsClick = (e: any, teamUser: TeamUser) => {
        console.log('teamUser', teamUser);
        setSelectedLoanOfficer(teamUser);
        setShowLoanOfficerPopover(true);
        setLoanOfficerAncher(e.currentTarget);
    }

    const handleViewLoanOfficer = () => {
        props.onTeamUserClick(selectedLoanOfficer);
    }

    const handleDeleteLoanOfficer = () => {
        // delete
        setShowDeleteDialog(true);
    }

    const handleAddTeamUserClose = (msg?: string) => {
        setShowLoanOfficerPopover(false);
        setShowAddOfficerModal(false);
        setSelectedLoanOfficer(emptyTeamUser);
    }

    const handleDeleteTeamUser = async (value: boolean) => {
        try {
            setShowDeleteDialog(false);

            if (value) {
                setLoading(true);
                // update user status
                const userRes = await axios.get(`${AuthService.getApiUrls().user}/user/${selectedLoanOfficer.userId}`);
                const user: User = userRes.data;
                user.status = 'delete';
                await axios.put(`${AuthService.getApiUrls().user}/user/${user.id}`, user);
                // delete team user
                await axios.delete(`${AuthService.getApiUrls().site}/team/user/${selectedLoanOfficer.id}`);
                setLoading(false);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    return (
        <Grid xs={12} className={classes.container}>
            <Loading open={loading} />
            <div className={classes.titleContainer}>
                <div className={classes.flexContainer} onClick={() => toggle(props.team.parentId, props.team.id)}>
                    <ArrowDropDownIcon className={`${classes.icon} ${!isOpen && classes.iconReverse}`} />
                    <Typography className={classes.title} >{props.team.name}</Typography>
                </div>
                <MoreVertIcon
                    onClick={handleTeamOptionsClick}
                    className={classes.dots}
                    aria-describedby={props.team.name}
                />
                <Popover
                    open={showTeamOptionsPopover}
                    anchorEl={teamOptionAncher}
                    onClose={() => setShowTeamOptionsPopover(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Typography
                        onClick={handleAddOfficerClick}
                        className={classes.typography}>
                        Add Loan Officer
                        </Typography>
                    <Typography
                        onClick={() => props.editTeam(props.team)}
                        className={classes.typography}>
                        Edit Banch
                        </Typography>
                    <Typography
                        onClick={() => props.deleteTeam(props.team)}
                        className={classes.typography}>
                        Delete Banch
                        </Typography>
                </Popover>
                <Popover
                    open={showLoanOfficerPopover}
                    anchorEl={loanOfficerAncher}
                    onClose={() => setShowLoanOfficerPopover(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <div style={{ minWidth: 100 }}>
                        <Typography
                            onClick={handleViewLoanOfficer}
                            className={classes.typographySmall}>
                            View
                        </Typography>
                        <Typography
                            onClick={handleEditOfficerClick}
                            className={classes.typographySmall}>
                            Edit
                        </Typography>
                        <Typography
                            onClick={handleDeleteLoanOfficer}
                            className={classes.typographySmall}>
                            Delete
                        </Typography>
                    </div>
                </Popover>
            </div>
            <AlertDialog
                title="Delete Team Member"
                desc="Are you sure you want to the delete the team user?"
                open={showDeleteDialog}
                onClose={handleDeleteTeamUser}
            />
            <AddTeamUser
                open={showAddOfficerModal}
                teamUser={selectedLoanOfficer}
                team={props.team}
                onClose={handleAddTeamUserClose}
            />
            {isOpen ?
                <Grid className={classes.gridContainer} container>
                    <Grid className={classes.gridHeaderContainer} xs={12} container alignItems="center">
                        <Grid xs={4} item>
                            <Typography className={classes.gridHeader}>Name</Typography>
                        </Grid>
                        <Grid xs={4} item>
                            <Typography className={classes.gridHeader}>Email</Typography>
                        </Grid>
                        <Grid xs={4} item>
                            <Typography className={classes.gridHeader}>Phone</Typography>
                        </Grid>
                    </Grid>
                    {
                        props.team.teamUsers &&
                        props.team.teamUsers.map((teamUser: TeamUser, index: number) =>
                            <Grid alignItems="center"
                                className={classes.gridRow} key={index} xs={12} spacing={2} container>
                                <Grid xs={4} item>
                                    <Typography className={classes.nameCol} onClick={() => props.onTeamUserClick(teamUser)}>{teamUser.name}</Typography>
                                </Grid>
                                <Grid xs={4} item>
                                    <Typography className={classes.mailCol}>{teamUser.email}</Typography>
                                </Grid>
                                <Grid xs={3} item>
                                    <Typography className={classes.mailCol}>{teamUser.phone}</Typography>
                                </Grid>
                                <Grid className={classes.dotsContainer} xs={1} item>
                                    <MoreVertIcon
                                        onClick={(event: any) => handleLoanOfficerOptionsClick(event, teamUser)}
                                        className={classes.dots}
                                        aria-describedby={teamUser.name}
                                    />
                                </Grid>
                            </Grid>
                        )}
                </Grid>
                : null
            }
        </Grid>
    )
};

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
        color: '#005B7C',
        fontSize: '14px',
        cursor: 'pointer'
    },
    typographySmall: {
        padding: theme.spacing(1),
        color: '#005B7C',
        fontSize: '14px',
        cursor: 'pointer'
    },
    container: {
        paddingTop: 20,
        paddingBottom: 40,
        marginLeft: 20,
    },
    flexContainer: {
        display: 'flex',
    },
    gridHeaderContainer: {
        padding: '6px 0 6px 15px'
    },
    nameCol: {
        color: '#00597E',
        fontSize: 14,
        fontFamily: 'Roboto',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        cursor: 'pointer'
    },
    gridRow: {
        padding: '0 0 0 15px'

    },
    mailCol: {
        color: '#333333',
        fontSize: 14,
        fontFamily: 'Roboto',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        paddingBottom: 15,
        borderBottom: '1px solid #C9D3E0',
    },
    title: {
        color: '#607897',
        fontSize: 18,
        marginLeft: 12,
    },
    icon: {
        color: '#666666',
    },
    iconReverse: {
        transform: 'rotate(-90deg)',
    },
    dots: {
        fontSize: 35,
        marginLeft: 'auto',
        color: '#c9d3e0',
        marginRight: 6,
        transform: 'rotate(90deg)',
    },
    dotsContainer: {
        textAlign: 'right',
    },
    gridHeader: {
        color: '#6C7C8E',
        fontSize: 14,
        fontFamily: 'Roboto',
    },

    gridContainer: {
        '& > .MuiGrid-grid-xs-12:nth-child(even)': {
            backgroundColor: '#f4f6f9',
            padding: '0px 15px'
        },
        '& > .MuiGrid-grid-xs-12:nth-child(odd)': {
            padding: '15px 15px'
        }
    }
}));

export default TeamTable;
