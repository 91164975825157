import axios from 'axios';
import { identity } from 'lodash';
import numeral from 'numeral';
// entites
import { Address } from '../entities/site';
import { UserAddress, emptyUserAddress } from '../entities/user';
// components
import { SelectItem } from '../components/inputs/SelectInput';
// services
import AuthService from './auth';

export interface Loan {
  loanBalance: number;
  loanAmount: number;
  loanDate: string;
  loanType: string;
}

class HomeService {

  static saveUserAddress = async (address: Address, userAddress: UserAddress) => {

    try {

      const user = AuthService.getUser();

      if (userAddress.id) {
        await axios.put(`${AuthService.getApiUrls().user}/user/address/${userAddress.id}`, userAddress); 
      } else {
        await axios.post(`${AuthService.getApiUrls().user}/user/address`, userAddress); 
      }

      return true;

    } catch (error) {
      return error;
    }

  }

  static getUserAddressList = (userAddresses: UserAddress[]): SelectItem[] => {
    const list: SelectItem[] = [];
    userAddresses.forEach((ud: UserAddress) => {
      list.push({
        value: ud.id,
        name: ud.addressId
      })
    });
    return list;
  } 

  static getUserAddress = (address: Address) => {

    return new Promise((resolve, reject) => {

      try {

        const user = AuthService.getUser();
        if (user) {

          // get user addresses
          axios.get(`${AuthService.getApiUrls().user}/user/${user.userId}/address`).then((userAddressRes) => {

            const userAddresses = userAddressRes.data;
            const userAddress: UserAddress = userAddresses.find(ud => ud.addressId === address.id);

            if (userAddress) {
              resolve(userAddress);
            } else {
              resolve(emptyUserAddress);
            }

          });

        } else {
          resolve(emptyUserAddress);
        }


      } catch (error) {
        return reject(error);
      }

    });

  }

  static calculateCash(address: Address, loanBalance: number) {
    loanBalance = loanBalance ? numeral(loanBalance).value() : numeral(address.loanBalance).value();
    const maxValue = (address.estimate * .8);
    const percent = Math.round((loanBalance / address.estimate) * 100);
    let cash = Math.floor(maxValue - loanBalance);
    cash = cash < 0 ? 0 : cash;

    return {
      cash,
      percent
    };

  }

  static calculateMonthlyPayment = (rate: number, term: number, balance: number, amountNeeded: number, taxes: number, insurance: number) => {

    /*
    For example, say you borrowed $265,000 on a 15-year mortgage at 4.32 percent. 
    Start by dividing 0.0432 by 12 to find that the monthly rate equals 0.0036. 
    Next, add 1 to 0.0036 to get 1.0036. 
    Third, multiply 15 years by 12 payments per year to find that your loan consists of 180 monthly payments. 
    Fourth, raise 1.0036 to the negative 180th power to get 0.5237. 
    Fifth, subtract 0.5237 from 1 to get 0.4763. 
    Sixth, divide 0.0036 by 0.4763 to get 0.00755826. 
    Finally, multiply 0.00755826 by $265,000 to find your monthly payment will be $2,002.93.
    */

    rate = (rate / 100);
    const monthlyRate = (rate / 12);
    const newMonthlyRate = monthlyRate + 1;
    const payments = term * 12;
    let calcRate = Math.pow(newMonthlyRate, -(payments));
    calcRate = Math.abs(calcRate - 1);
    calcRate = monthlyRate / calcRate;
    const newBalance = balance + amountNeeded;
    const monthly = calcRate * newBalance;

    const totalMonthlyPayment = (monthly + taxes + insurance);
    const interest = (balance * rate) / 12;
    const principal = (monthly - interest);

    return {
      newBalance,
      monthlyPayment: totalMonthlyPayment,
      principal,
      interest
    }
  }

  static getAddressFromId(addressId: string) {
    const parts = addressId.split('-');
    let city = "";
    let state = "";
    let address = "";

    parts.forEach((part, index) => {

      if (index !== (parts.length - 1) && index !== (parts.length - 2)) {
        if (!address) {
          address = part;
        } else {
          address = address + " " + part;
        }
      } else if (index === (parts.length - 2)) {
        city = part;
      } else if (index === (parts.length - 1)) {
        state = part;
      }

    })

    return {
      address,
      city,
      state
    }

  }

}

export default HomeService;