import React, { Component } from "react";
// material
import { withRouter } from "react-router-dom";
// containers
import AppContainer from '../../containers/App';

const robotHead = process.env.PUBLIC_URL + "/galilaio/robot_head.svg";
const logoName = process.env.PUBLIC_URL + "/galilaio/logo_name.png";

const styles: any = {
  logoName: {
    width: 200,
    height: 30,
    marginTop: 20,
    marginBottom: 45,
    textAlign: "center",
  },
  placeholderText: {
    size: 16,
    lineHeight: 1.2,
    color: "#627995",
    textAlign: "center",
  },
  container: {
    width: "100%",
    marginTop: 50,
  },
  textContainer: {
    textAlign: "center",
  },
  iconContainer: {
    marginTop: 50,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    fontSize: 20,
    lineHeight: 1.5,
    color: "#627995",
    fontFamily: "Roboto Regular",
  },
  link: {},
  bannerContainer: {},
  button: {
    width: 240,
    height: 40,
    borderRadius: 4,
    border: "none",
    backgroundColor: "#00ABCA",
    fontSize: 16,
    fontFamily: "Roboto Medium",
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: 50,
    cursor: "pointer",
  },
};

interface NewCampaignProps {
  history: any;
}

interface NewCampaignState {
  email: string;
  address: string;
  loading: boolean;
}

class NewCampaignPage extends Component<NewCampaignProps, NewCampaignState> {
  constructor(props: NewCampaignProps) {
    super(props);
    this.state = {
      email: "",
      address: "",
      loading: false,
    };
  }

  handleCampaignClick = () => {
    this.props.history.push(`/campaign/new/details`);
  };

  render() {
    return (
      <AppContainer showOption={false} private={false} maxWidth={false}>
        <div style={styles.container}>
          <div style={styles.textContainer}>
            <div>
              <div style={styles.text}>Welcome to</div>
              <div style={styles.iconContainer}>
                <img src={robotHead} />
                <img style={styles.logoName} src={logoName} />
              </div>
              <div style={styles.placeholderText}>
                You haven’t created any campaigns yet. Let’s get this party
                started!
              </div>
              <button style={styles.button} onClick={this.handleCampaignClick}>
                Create Campaign
              </button>
            </div>
          </div>
        </div>
      </AppContainer>
    );
  }
}

export default withRouter<any, any>(NewCampaignPage);
