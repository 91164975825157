import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Container } from '@material-ui/core';

const ehoLogo = process.env.PUBLIC_URL + '/images/equal_housing.svg';

const styles: any = {
  logo: {
    width: 150,
    marginRight: 20,
    verticalAlign: 'bottom'
  },
  text: {
    fontSize: 13,
    color: '#939EB4',
    textAlign: 'left'
  },
  linkContainer: {
    paddingTop: 10,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  link: {
    fontSize: 13,
    color: '#009FDB',
    textAlign: 'left',
    borderRight: '1px solid #939EB4',
    paddingRight: 10,
    cursor: 'pointer'
  },
  linkSecond: {
    fontSize: 13,
    color: '#009FDB',
    textAlign: 'left',
    borderRight: '1px solid #939EB4',
    paddingRight: 10,
    paddingLeft: 10,
    cursor: 'pointer'
  },
  copyright: {
    fontSize: 13,
    color: '#939EB4',
    textAlign: 'left',
    paddingLeft: 10
  },
  copyText: {
    paddingLeft: 10
  }
}

interface FooterProps {
  history: any
}

const Footer: React.FC<FooterProps> = (props) => {

  const handleClick = (route: string) => {
    props.history.push(route);
  }

  return (
    <div className="footer">
      <Container maxWidth="lg" >
        <Grid container spacing={4}>
          <Grid item sm={6} style={{display: 'flex', alignItems: 'center'}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img src={ehoLogo} style={styles.logo} />
              <div style={styles.text}>v1.2 - CA DRE#01215943  •  AZ Corp BK #0937942  •  NV MB 2941/164  •  NMLS #1850 Licensed by the Dept. of Business Oversight under the CRMLA.  Licensed under the Oregon Consumer Finance Act.  NMLS consumer access.  Accessibility Statement.</div>
            </div>
          </Grid>
          <Grid item sm={6}>
            <div style={styles.linkContainer}>
              {/* <div style={styles.link} onClick={() => handleClick('/privacy-policy')}>Privacy Policy</div>
              <div style={styles.linkSecond} onClick={() => handleClick('/terms-conditions')}>Terms & Conditions</div> */}
              <div style={styles.copyright}>
                <span style={styles.link} onClick={() => handleClick('/privacy-policy')}>Privacy Policy</span>
                <span style={styles.linkSecond} onClick={() => handleClick('/terms-conditions')}>Terms & Conditions</span>
                <span style={styles.copyText}>© 2021 HomeWize is a dba of ERS Nationwide Inc. For informational purposes only. No guarantee of accuracy is expressed or implied. Programs shown may not include all options or pricing structures. Rates, terms, programs and underwriting policies subject to change without notice. This is not an offer to extend credit or a commitment to lend. All loans subject to underwriting approval. Some products may not be available in all states and restrictions may apply. Equal Housing Opportunity.</span>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

export default withRouter<any, any>(Footer);
