import { Session, Auth } from '../entities/auth';
import jwtDecode from 'jwt-decode';
import { Home } from '../entities/home';
import axios from 'axios';
import { v1 as uuid } from 'uuid';

const useProd = true;

export interface CustomMenuItem {
  name: string;
  metaName: string;
  count: number;
  homes: Home[];
  sessionPayment: number;
}

class UtilsService {

  static getCounties() {
    return [
      { name: 'Sacramento', meta: 'sacramento' },
      { name: 'Placer', meta: 'placer' },
      { name: 'El Dorado', meta: 'el-dorado' },
      { name: 'Yolo', meta: 'yolo' },
      { name: 'San Joaquin', meta: 'san-joaquin' },
      { name: 'Fresno', meta: 'fresno' },
      { name: 'Solano', meta: 'solano' },
      { name: 'Marin', meta: 'marin' },
      { name: 'Mendocino', meta: 'mendocino' },
      { name: 'Napa', meta: 'napa' },
      { name: 'Contra Costa', meta: 'contra-costa' },
      { name: 'Alameda', meta: 'alameda' },
      { name: 'Santa Clara', meta: 'santa-clara' }
    ];
  }

  static getMenu() {
    const menu: CustomMenuItem[] = [];
    const counties = UtilsService.getCounties();
    counties.forEach((county) => {
      menu.push({
        name: county.name,
        metaName: county.meta,
        count: 0,
        homes: [],
        sessionPayment: 0
      });
    });
    return menu;
  }

  static isLocal() {
    if (window.location.host.indexOf('localhost') > -1 && !useProd) {
      return true;
    }
    return false;
  }

  static isLoggedIn = (auth: Auth) => {
    if (auth && auth.token) {
      return true;
    }
    return false;
  }

  static error = (error: any) => {
    return error;
  }

  static getRedirectUrl = () => {
    return 'testing';
  }

  static setPath = (session: Session, path: string) => {
    session.page = path;
    return session;
  }

  static getClient = () => {
    const client = {
      clientId: '44vtu6s8dlt97tspem0mnsg2av',
      redirectUrl: 'https://micazza.com/auth/login'
    }
    if (window.location.host.indexOf('dev') > -1) {
      client.clientId = '65at58q28hsik3h3p46391m1bp';
      client.redirectUrl = 'https://dev.micazza.com/auth/login';
    }
    return client;
  }

  static getLoginPath = () => {
    if (UtilsService.isLocal()) {
      const localClientId = '7bk8f29jjv1uus3atdl390kaef';
      return `https://auth.micazza.com/login?response_type=token&client_id=${localClientId}&redirect_uri=http://localhost:3000/auth/login`;
    } else {
      const client = UtilsService.getClient();
      let params = '?response_type=token';
      params += `&client_id=${client.clientId}`;
      params += `&redirect_uri=${client.redirectUrl}`;
      return `https://auth.micazza.com/login${params}`;
    }
  }

  static getAPIUrl = (endpoint: string) => {
    if (UtilsService.isLocal()) {
      return `http://localhost:4000/${endpoint}`
    } else {
      return `https://jzuaqzvjsh.execute-api.us-west-2.amazonaws.com/dev/${endpoint}`
    }
  }

  static decodeToken = (token: string) => {
    return jwtDecode(token);
  }

  static getKeyFromAddress = (address: string) => {
    address = address.replace(new RegExp("\\bCt\\b"), "Court");
    address = address.replace(new RegExp("\\bDr\\b"), "Drive");
    address = address.replace(new RegExp("\\bSt\\b"), "Street");
    address = address.replace(new RegExp("\\bAve\\b"), "Avenue");
    address = address.replace(new RegExp("\\bW\\b"), "West");
    address = address.replace(new RegExp("\\bN\\b"), "North");
    address = address.replace(new RegExp("\\bE\\b"), "East");
    address = address.replace(new RegExp("\\bS\\b"), "South");
    address = address.replace(new RegExp("\\bBlvd\\b"), "Boulevard");
    address = address.replace(/[, ]+/g, '-');
    address = address.replace(/\s/g, '-');
    // const addressParts = address.split(',');
    // key = addressParts.join('-');
    return address;
  }

  static getAddressFromKey = (addressKey: string) => {
    return addressKey.replace('-', ' ');
  }

  static getKeyFromMortgage = (mortgage: any) => {
    const address = `${mortgage.address}, ${mortgage.city}, ${mortgage.state}, USA`;
    return UtilsService.getKeyFromAddress(address);
  }

  static logError = async (coreUrl: string, page: string, method: string, error: any) => {
    return axios.post(`${coreUrl}/core/log`, {
      id: `${page}-${method}-${uuid()}`,
      data: error
    });
  }

}

export default UtilsService;