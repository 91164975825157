import React from 'react';

const styles: any = {
  container: {
    padding: '10px 2px',
    backgroundColor: '#F0F0F0',
    border: '1px solid #E4E4E4',
    borderRadius: 4,
    cursor: 'pointer'
  },
  containerSelected: {
    padding: '10px 5px',
    backgroundColor: '#F0F0F0',
    border: '2px solid #7676FC',
    borderRadius: 4,
    cursor: 'pointer',
    color: '#7676FC'
  },
  title: {
    fontSize: 18,
    textAlign: 'center'
  },
  value: {
    fontSize: 12,
    textAlign: 'center'
  }
}

interface SquareItemProps {
  title: string;
  type: string;
  value: number;
  selected?: boolean;
  onClick(systemValue: number): void;
}
  
const SquareItem: React.FC<SquareItemProps> = (props) => {
  
  const [hover, setHover] = React.useState<boolean>(false);

  const squareStyle = props.selected || hover ? styles.containerSelected : styles.container;
  return (
    <div 
      style={squareStyle} 
      onMouseEnter={() => setHover(true)} 
      onMouseLeave={() => setHover(false)}
      onClick={() => props.onClick(props.value)}
    >
      <div style={styles.title}>{props.title}</div>
      <div style={styles.value}>{props.type}</div>
    </div>
  )  
}
export default SquareItem;