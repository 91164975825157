import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';


interface RowContainerProps {
  children: any;
}

const RowContainer: React.FC<RowContainerProps> = (props) => {
  return (
    <div style={{marginTop: 10}}>
      {props.children}
    </div>
  )
}

export default RowContainer;