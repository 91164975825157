import React from 'react';
import numeral from 'numeral';
// material
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
// components
import BarChart from '../../../components/charts/BarChart';

interface LoanValueProps {
  percent: number;
}

const LoanValue: React.FC<LoanValueProps> = (props) => {
    
    const classes = useStyles({});
    let msg = "Congratulations!";

    if (props.percent > 80) {
      msg = "Almost there!"
    }

    return (
      <div className={classes.container}>
        <div className={classes.msg}>{msg}</div>
        <div className={classes.percentContainer}>
          <div style={{marginTop: 12, paddingRight: 20}}><CheckCircle className={classes.checkIcon} /></div>
          <div className={classes.percent}>{props.percent}%</div> 
        </div>
        <div className={classes.barContainer}>
          <BarChart percent={(props.percent / 100)} height={25} />
        </div>
        <div className={classes.description} >You can remove your mortgage insurance once you are at or below 80% LTV</div>
      </div>
    );
}
export default LoanValue;


const useStyles = makeStyles(theme => ({
  container: {
    padding: 20,
    backgroundColor: '#FFF',
    borderRight: '1px solid #CAD4E1',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
    },
  },
  checkIcon: {
    fontSize: 60,
    color: '#05CA81'
  },
  percentContainer: {
    display: 'flex', 
    justifyContent: 'center', 
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
  barContainer: {
    marginLeft: 50, 
    marginRight: 50,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0, 
      marginRight: 0
    },
  },
  msg: {
    fontSize: 20,
    color: '#596675'
  },
  percent: {
    fontSize: 60, 
    fontWeight: 'bold', 
    color: '#00B5D7'
  },
  description: {
    paddingTop: 77,
    paddingBottom: 20,
    color: '#8794A3',
    fontSize: 14,
    fontStyle: 'italic',
    textAlign: 'center'
  }
}));