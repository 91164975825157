import React, { useState, useEffect } from "react";
import { sortBy, find } from "lodash";
import axios from "axios";
// material
import { withRouter } from "react-router-dom";
import { Grid, makeStyles } from "@material-ui/core";
// components
import BreadCrumbMenu from "../../components/breadcrumb/BreadCrumbMenu";
import FooterToolbar from "./components/FooterToolbar";
import Loading from "../../components/site/Loading";
// containers
import AppContainer from "../../containers/App";
// entites
import {
  MessageTemplate,
  emptyMessageTemplate,
} from "../../entities/notification";
import { Auth, Session } from "../../entities/auth";
// services
import AuthService from "../../services/auth";
// store
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  updateSession,
  openSnackbar,
  closeSnackbar,
} from "../../store/action-creators";

const robotHead = process.env.PUBLIC_URL + "/galilaio/robot_head.svg";
const logoName = process.env.PUBLIC_URL + "/galilaio/logo_name.png";

interface NewCampaignTemplatePageProps {
  history: any;
  auth: Auth;
  session: Session;
  updateSession(session: Session): void;
  openSnackbar(message: string): void;
  closeSnackbar(): void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0px auto",
    padding: "0px 25px",
    [theme.breakpoints.only("xs")]: {
      padding: "0px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0px 30px !important",
    },
  },
  title: {
    fontFamily: "Asap",
    fontSize: 24,
    color: "#1C242D",
    textAlign: "left",
    marginBottom: 40,

    [theme.breakpoints.down("md")]: {
      marginBottom: "20px",
    },
  },
  templates: {
    width: 1140,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "20px",
    },
  },
  template: {
    margin: "10px 0px",
  },
  package: {
    boxSizing: "border-box",
    height: 300,
    width: 270,
    borderRadius: 4,
    border: "1px solid #CCCCCC",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      margin: "auto",
    },
  },
  chosenPackage: {
    height: 320,
    width: "280px",
    borderRadius: 4,
    border: "10px solid #9BD8E4",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      margin: "auto",
      width: "90%",
    },
  },
  packageTitle: {
    height: 24,
    width: 240,
    color: "#00ABCA",
    fontFamily: "Asap",
    fontSize: 16,
    fontWeight: "bold",
  },
  packageInfo: {
    padding: "15px 24.56px 0 14.48px",
  },
  packageText: {
    marginTop: 15,
    fontSize: 14,
    fontFamily: "Asap",
    color: "#333333",
    height: 200,
    width: "auto",
    letterSpacing: 0,
    lineHeight: 1.5,
    wordBreak: "break-all",
  },
  packageRate: {
    backgroundColor: "#ECFCFF",
    color: "#00ABCA",
    height: 40,
    width: "100%",
    marginTop: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0 0 4px 4px",
    border: "1px solid #B0E7F2",
    boxSizing: "border-box",
  },
  headingSection: {
    marginTop: "20px",
    padding: "10px 0px",
    [theme.breakpoints.only("md")]: {
      padding: "10px 20px",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "10px 15px",
    },
  },
}));

const NewCampaignTemplatePage: React.FC<NewCampaignTemplatePageProps> = (
  props
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [templates, setTemplates] = useState<MessageTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<MessageTemplate>(
    emptyMessageTemplate
  );

  const fetchTemplates = async () => {
    setLoading(true);
    const templateRes = await axios.get(
      `${AuthService.getApiUrls().notifications}/message/templates`
    );
    let templatesAll = templateRes.data;
    templatesAll = sortBy(templatesAll, "createdDate")
      .filter((t) => t.client === "galilaio")
      .reverse();

    const campaignSession = props.session.campaign;
    const templateId = campaignSession?.campaign.templateId;

    console.log("session - template", campaignSession);

    if (templateId) {
      const foundTemplate = find(templatesAll, { id: templateId });
      if (foundTemplate) {
        setSelectedTemplate(foundTemplate);
      }
    }

    setTemplates(templatesAll);
    setLoading(false);
  };

  const handleOnNext = () => {
    if (!selectedTemplate) {
      props.openSnackbar("Campaign template required");
      return;
    }
    const newSession = props.session;
    const newCampaign = newSession.campaign?.campaign;
    if (!newCampaign) {
      props.openSnackbar("Campaign details missing");
      return;
    }
    newCampaign.templateId = selectedTemplate.id;

    props.updateSession(newSession);
    props.history.push("/campaign/new/timeline");
  };

  const handleCancel = () => {
    const newSession = props.session;
    newSession.campaign = undefined;
    props.updateSession(newSession);
    props.history.push("/");
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const classes = useStyles();

  return (
    <AppContainer showOption={false} private={false} maxWidth="lg">
      <Loading open={loading} />
      <div className={classes.container}>
        <Grid
          container
          justify={"space-between"}
          className={classes.headingSection}
        >
          <Grid item>
            <div className={classes.title}>Select Template</div>
          </Grid>
          <Grid item>
            <BreadCrumbMenu current="/campaign/new/template" />
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.templates}
          // spacing={2}
        >
          {templates.map((template, index) => (
            <Grid
              className={classes.template}
              key={`temp-${index}`}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <div
                className={
                  selectedTemplate.id === template.id
                    ? classes.chosenPackage
                    : classes.package
                }
                onClick={() => setSelectedTemplate(template)}
              >
                <div className={classes.packageInfo}>
                  <div className={classes.packageTitle}>{template.name}</div>
                  <div className={classes.packageText}>
                    {template.description}
                  </div>
                </div>
                <div className={classes.packageRate}>{template.rate}</div>
              </div>
            </Grid>
          ))}
        </Grid>
        <FooterToolbar
          pre="/campaign/new/details"
          submit={false}
          onNext={handleOnNext}
          onCancel={handleCancel}
        />
      </div>
    </AppContainer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      updateSession,
      openSnackbar,
      closeSnackbar,
    },
    dispatch
  );
};

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(NewCampaignTemplatePage)
);
