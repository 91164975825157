import React from 'react';
// material
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        minHeight: 150,
        width: '100%'
    },
    progressContainer: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(248, 248, 252, 0.4)'
    },
    progress: {
        margin: 20
    }
}));

interface LoaderProps {
    open: boolean;
    children: React.ReactNode;
}

const Loader: React.FC<LoaderProps> = (props) => {
    const classes = useStyles();
    if (props.open) {
        return (
            <div className={classes.container}>
                <div className={classes.progressContainer}>
                    <CircularProgress className={classes.progress} />
                </div>
                {props.children}
            </div>
        )
    }
    return ( <div style={{width: '100%'}}>{props.children}</div> );
}
export default Loader;