import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import numeral from 'numeral';
import axios from 'axios'
import { find } from 'lodash';

// app
import { updateSession, openSnackbar, closeSnackbar } from '../../store/action-creators';
import UtilsService from '../../services/utils';
// containers
import AppContainer from '../../containers/App';
// material
import { Grid, Hidden } from '@material-ui/core';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
// components
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import ActionButton from '../../components/buttons/ActionButton';
import Divider from '../../components/divider/Divider';
// entities
import { Auth, Session } from '../../entities/auth';
import { APIUrl } from '../../entities/site';
import { Mortgage } from '../../entities/profile';
// service 
import AuthService from '../../services/auth';

const states = ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'];

const styles: any = {
  container: {
    flex: 1,
    height: "100%",
    minHeight: "100%",
    marginBottom: 200,
    marginTop: 40
  },
  pageTitle: {
    textAlign: 'left',
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 40
  },
  form: {
    borderLeft: '10px solid #F1F1F1',
    paddingLeft: 20,
    marginRight: 40,
    marginBottom: 10
  },
  formGrid: {
    backgroundColor: '#fafafa',
    borderLeft: '10px solid #F1F1F1',
    paddingLeft: 20,
    marginRight: 40,
    marginBottom: 10
  },
  input: {
    marginTop: 10
  },
  sectionTitle: {
    textAlign: 'left',
    fontSize: 14
  },
  mortgageContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10
  },
  mortgageItemFirst: {
    textAlign: 'left',
    paddingRight: 20,
    fontSize: 16
  },
  mortgageItem: {
    textAlign: 'left',
    paddingRight: 20,
    fontSize: 16,
    paddingTop: 10
  }
}

interface ProfileMortgagePageProps {
  auth: Auth;
  history: any;
  session: Session;
  apiUrls: APIUrl;
  openSnackbar(msg: string): void;
  closeSnackbar(): void;
  updateSession(session: Session): void;
}

interface ProfileMortgagePageState {
  loading: boolean;
  reportTitle: string;
  address: string;
  city: string;
  state: string;
  zipCode: number;
  originalLoanBalance: string;
  loanBalance: string;
  interestRate: number;
  mortgageInsurance: string;
  escrow: string;
  mortgages: Mortgage[];
}

class ProfileMortgagePage extends Component<ProfileMortgagePageProps, ProfileMortgagePageState> {
  
  constructor(props: ProfileMortgagePageProps) {
    super(props);
    this.state = {
      loading: false,
      reportTitle: '',
      address: '',
      city: '',
      state: '',
      zipCode: 0,
      originalLoanBalance: '$0.00',
      loanBalance: '$0.00',
      interestRate: 0,
      mortgageInsurance: '$0.00',
      escrow: '$0.00',
      mortgages: []
    }
  }

  componentDidMount = () => {
    // mounted
    this.fetchUserMortgages();
  }

  handleCancel = () => {
    this.props.history.push(`/estimator`);
  }

  handleSaveProfile = () => {

    this.props.openSnackbar("Saving...");

    axios.post(`${AuthService.getApiUrls().user}/user/mortgage`, {
      userId: this.props.auth.id,
      reportTitle: this.state.reportTitle,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zipCode: this.state.zipCode,
      originalLoanBalance: numeral(this.state.originalLoanBalance).value(),
      loanBalance: numeral(this.state.loanBalance).value(),
      interestRate: numeral(this.state.interestRate).value(),
      mortgageInsurance: numeral(this.state.mortgageInsurance).value(),
      escrow: numeral(this.state.escrow).value()
    }).then((res) => {
      
      this.props.closeSnackbar();

      this.fetchUserMortgages();

    }).catch((error) => {
      // something went wrong
      UtilsService.logError(AuthService.getApiUrls().core, 'ProfileMortgage', 'handleSaveProfile', error);
    });

  }

  fetchUserMortgages = () => {
    axios.get(`${AuthService.getApiUrls().user}/user/mortgage/${this.props.auth.id}`).then((res) => {

      this.setState({mortgages: res.data});

    }).catch((error) => {
      UtilsService.logError(AuthService.getApiUrls().core, 'ProfileMortgage', 'fetchUserMortgages', error);
    });
  }

  handleReportTitleChange = (event: any) => {
    this.setState({reportTitle: event.target.value});
  }

  handleAdressChange = (event: any) => {
    this.setState({address: event.target.value});
  }

  handleCityChange = (event: any) => {
    this.setState({city: event.target.value});
  }

  handleStateChange = (event: any) => {
    this.setState({state: event.target.value});
  }

  handleZipCodeChange = (event: any) => {
    this.setState({zipCode: event.target.value});
  }

  handleOriginalLoanBalanceChange = (event: any) => {
    this.setState({originalLoanBalance: numeral(event.target.value).format('$0,0')});
  }

  handleLoanBalanceChange = (event: any) => {
    this.setState({loanBalance: numeral(event.target.value).format('$0,0')});
  }

  handleInterestRateChange = (event: any) => {
    this.setState({interestRate: event.target.value});
  }

  handleMortgageInsuranceChange = (event: any) => {
    this.setState({mortgageInsurance: numeral(event.target.value).format('$0,0')});
  }

  handleEscrowChange = (event: any) => {
    this.setState({escrow: numeral(event.target.value).format('$0,0')});
  }

  handleMortgageClick = async (mortgageId: string) => {
    // set selected mortgage
    let addressObj;
    const mortgage: Mortgage | undefined = find(this.state.mortgages, {id: mortgageId});
    const address = `${mortgage?.address}, ${mortgage?.city}, ${mortgage?.state}`;
    const addressKey = UtilsService.getKeyFromAddress(address);

    const addressRes = await axios.get(`${AuthService.getApiUrls().core}/address/${addressKey}`);

    if (addressRes.data) {
      addressObj = addressRes.data;
    } else {
      // cleanup old records
      // get estimate
      const resIntegration = await axios.get(`${AuthService.getApiUrls().integrations}/integrations/property/?address=${address}`);
      // store address
      const addressNewRes = await axios.post(`${AuthService.getApiUrls().core}/address`, {
        id: addressKey,
        address,
        estimate: resIntegration.data.estimate,
        loanBalance: (mortgage?.loanBalance ? mortgage?.loanBalance : 0)
      });
      addressObj = addressNewRes.data;
    }

    const newSession = this.props.session;
    newSession.address = addressObj;
    newSession.selectedMortgage = mortgage;
    this.props.updateSession(newSession);
    this.props.history.push(`/estimator/reficenter/${addressObj.id}`);
  }

  render() {
    return ( 
      <AppContainer loading={this.state.loading} private maxWidth="md">
        <div style={styles.container}>

          { this.state.mortgages.length > 0 &&
            <Grid container style={{marginBottom: 20}}>
              <Grid item xs={12}>
                <div style={styles.pageTitle}>
                  Mortgages
                </div>
                {this.state.mortgages.map((mortgage, index) => 
                  <div style={styles.formGrid} key={`mortgage-${index}`}>
                    <div style={styles.mortgageContainer}>
                      <Hidden smDown>
                        <div style={styles.mortgageItemFirst}>
                          <div style={{fontWeight: 'bold'}}>{mortgage.reportTitle}</div>
                          <div>{mortgage.address}</div>
                        </div>
                        <div style={styles.mortgageItem}>{mortgage.city}</div>
                        <div style={styles.mortgageItem}>{mortgage.zipCode}</div>
                        <div style={styles.mortgageItem}>{numeral(mortgage.loanBalance).format('$0,0')}</div>
                        <div style={styles.mortgageItem}>{`${mortgage.interestRate}%`}</div>
                        <div>
                          <ActionButton name="Select" onClick={() => this.handleMortgageClick(mortgage.id)} fullWidth size="sm" />
                        </div>
                      </Hidden>
                      <Hidden mdUp>
                        <div>{mortgage.address}</div>
                        <div style={styles.mortgageItem}>{mortgage.city}</div>
                        <div>
                          <ActionButton name="Select" onClick={() => this.handleMortgageClick(mortgage.id)} fullWidth size="sm" />
                        </div>
                      </Hidden>
                      
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          }

          <Grid container>
            <Grid item xs={12} md={6}>
              <div style={styles.pageTitle}>
                Add Mortgage
              </div>

              <div style={styles.form}>

                <div>
                  <div style={styles.input}>
                    <TextInput 
                      title="Report Title"
                      type="string"
                      placeholder=""
                      result={this.state.reportTitle}
                      onChange={this.handleReportTitleChange} 
                    />
                  </div>
                  <div style={styles.input}>
                    <TextInput 
                      title="Adress"
                      type="string"
                      placeholder=""
                      result={this.state.address}
                      onChange={this.handleAdressChange} 
                    />
                  </div>
                  <div style={styles.input}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div style={{paddingRight: 10}}>
                          <TextInput 
                            title="City"
                            type="string"
                            placeholder=""
                            result={this.state.city}
                            onChange={this.handleCityChange} 
                          />
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div style={{paddingRight: 10}}>
                          <TextInput 
                            title="State"
                            type="string"
                            placeholder=""
                            result={this.state.state}
                            onChange={this.handleStateChange} 
                          />
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <TextInput 
                          title="Zip Code"
                          type="number"
                          placeholder=""
                          result={this.state.zipCode}
                          onChange={this.handleZipCodeChange} 
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div style={styles.input}>
                    <TextInput 
                      title="Original Loan Balance"
                      type="string"
                      placeholder=""
                      result={this.state.originalLoanBalance}
                      onChange={this.handleOriginalLoanBalanceChange} 
                      width={200}
                    />
                  </div>
                  <div style={styles.input}>
                    <TextInput 
                      title="Loan Balance"
                      type="string"
                      placeholder=""
                      result={this.state.loanBalance}
                      onChange={this.handleLoanBalanceChange} 
                      width={200}
                    />
                  </div>
                  <div style={styles.input}>
                    <TextInput 
                      title="Interest Rate"
                      type="string"
                      placeholder=""
                      result={this.state.interestRate}
                      onChange={this.handleInterestRateChange} 
                      width={200}
                    />
                  </div>
                  <div style={styles.input}>
                    <TextInput 
                      title="Mortgage Insurance (PMI)"
                      type="string"
                      placeholder=""
                      result={this.state.mortgageInsurance}
                      onChange={this.handleMortgageInsuranceChange} 
                      width={200}
                    />
                  </div>
                  <div style={styles.input}>
                    <TextInput 
                      title="Escrow"
                      type="string"
                      placeholder=""
                      result={this.state.escrow}
                      onChange={this.handleEscrowChange} 
                      width={200}
                    />
                  </div>
                </div>

              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Hidden smDown>
                <div style={{padding: 60, backgroundColor: '#e0e0e0', margin: 60, borderRadius: 2}}>
                  <NoteAddOutlinedIcon style={{fontSize: 98, color: '#eee'}} />
                  <div style={{padding: 10, color: '#424242'}}>
                    Mortgage Document Upload Coming Soon...
                  </div>
                </div>
              </Hidden>
            </Grid>
          </Grid>
          <Divider />
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <ActionButton name="Cancel" onClick={this.handleCancel} size="sm" type="secondary" fullWidth={false} />
            <ActionButton name="Save Profile" onClick={this.handleSaveProfile} size="sm" fullWidth={false} />
          </div>
        </div>
      </AppContainer>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session,
    apiUrls: state.site.apiUrls
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ updateSession, openSnackbar, closeSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(ProfileMortgagePage));