import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
// material
import { Paper } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { green, red } from "@material-ui/core/colors";
// components

interface DropContactsProps {
  campaign?: any;
  total: number;
  errors: number;
  onClick(type: string): void;
  onDrop(data: any): void;
}

const DropContacts: React.FC<DropContactsProps> = (props) => {
  const classes = useStyles({});
  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [fileName, setFileName] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files

    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      const fileData = reader.result;
      const data: any = {
        name: file.name,
        type: file.type,
        file: fileData,
      };
      props.onDrop(data);
      setFileName(data.name);
      setIsUploaded(true);
    };
    reader.readAsDataURL(file);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className={classes.container}>
      <div className={classes.title}>Upload Leads</div>

      <div {...getRootProps()} className={classes.dropZone}>
        <input {...getInputProps()} />
        {isUploaded || props.total > 0 ? (
          <div className={classes.resultDropzone}>
            <div>
              {fileName} <br /> successfully uploaded
            </div>
            <div className={classes.chooseFile}>Choose file</div>
          </div>
        ) : isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag .csv file here, or Browse</p>
        )}
      </div>
      {(isUploaded || props.total > 0) && (
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              textAlign: "left",
              color: "#1C242D",
              fontSize: 40,
              cursor: "pointer",
            }}
            onClick={() => props.onClick("contacts")}
          >
            <CheckCircleIcon style={{ color: green[500], marginRight: 20 }} />
            {props.total} Records
          </div>
          <div
            style={{
              textAlign: "left",
              color: "#f44336",
              fontSize: 40,
              cursor: "pointer",
            }}
            onClick={() => props.onClick("errors")}
          >
            <HighlightOffIcon style={{ color: red[500], marginRight: 20 }} />
            {props.errors} Errors
          </div>
        </div>
      )}
    </div>
  );
};
export default DropContacts;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 20,
      backgroundColor: "#F4F7FC",
    },
    title: {
      fontSize: 18,
      textAlign: "left",
      paddingBottom: 10,
    },
    dropZone: {
      padding: 20,
      border: "1px dashed #CCCCCC",
      backgroundColor: "#FFF",
      fontSize: 16,
      textAlign: "center",
    },
    resultDropzone: {
      fontSize: 14,
      display: "flex",
      justifyContent: "space-between",
    },
    chooseFile: {
      border: "1px solid #00ABCA",
      borderRadius: "4px",
      width: "100px",
      height: "34px",
      color: "#00ABCA",
      fontFamily: "Asap",
      fontSize: 14,
      fontWeight: 500,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);
