import React from 'react'
import { Redirect, Route } from 'react-router-dom';
// service 
import AuthService from '../../services/auth';

interface RProtectedRouteProps {
  component: any;
  key: string;
  path: string;
  exact: boolean;
  roles: string[];
}

const LoginRedirect: React.FC<{}> = () => {
  return <Redirect to={{ pathname: "/auth/login" }} />
}
const ProtectedRoute: React.FC<RProtectedRouteProps> = (props: RProtectedRouteProps) => {
  return <Route
    key={props.path}
    path={props.path}
    exact={props.exact}
    component={AuthService.isAuthorized(props.roles) ? props.component : LoginRedirect}
  />
}
export default ProtectedRoute;