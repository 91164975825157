import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Auth from '@aws-amplify/auth';
// app
import { addAuth, deleteAuth, addSession, updateSession, setApiUrls } from '../../store/action-creators';
import { colors } from '../../config/styles';
// containers
import AppContainer from '../../containers/App';
// models
import { Session, Auth as AuthEntity } from '../../entities/auth';
import { APIUrl } from '../../entities/site';
// components
import ActionButton from '../../components/buttons/ActionButton';
import RowContainer from '../../containers/Row';
import TextInput from '../../components/inputs/TextInput';
// services
import UtilsService from '../../services/utils';
import AuthService from '../../services/auth';

const styles: any = {
  container: {
    height: "100%",
    minHeight: "100%",
    maxWidth: 600,
    margin: '0px auto',
    padding: 40
  },
  link: {
    color: colors.blue,
    cursor: 'pointer'
  },
  footer: {
    textAlign: 'center',
    fontSize: 12
  }
}

interface LoginPageProps {
  history: any;
  session: Session;
  addAuth(auth: AuthEntity): void;
  deleteAuth(): void;
  addSession(session: Session): void;
  updateSession(session: Session): void;
  setApiUrls(urls: APIUrl): void;
}

interface LoginPageState {
  loading: boolean;
  disabled: boolean;
  email: string;
  password: string;
  openSignup: boolean;
}

class LoginPage extends Component<LoginPageProps, LoginPageState> {

  constructor(props: LoginPageProps) {
    super(props);
    this.state = {
      loading: false,
      disabled: false,
      email: '',
      password: '',
      openSignup: false
    }
  }

  componentDidMount = () => {
    // home
  }

  handleLogin = async () => {
    // login
    try {
      let redirectUrl = '/';
      // this.props.clearMessage();
      this.setState({loading: true, disabled: true});
      let currentSession = this.props.session;
      const user = await Auth.signIn(this.state.email.trim(), this.state.password.trim());
      const authSession: any = await Auth.currentSession();

      // get db user
      const userRes = await axios.get(`${AuthService.getApiUrls().user}/user/${user.username}`);

      if (userRes.data.status === 'delete') {
        await Auth.signOut();
        this.props.deleteAuth();
        // this.props.setMessage({message: 'Error logging in', messageType: MessageTypes.Error});
        this.setState({loading: false, disabled: false});
        return;
      }
      
      if (userRes.data.status === 'reset') {
        redirectUrl = '/auth/reset';
      }

      const auth: AuthEntity = {
        id: user.username,
        user: userRes.data,
        token: authSession.accessToken.jwtToken,
        payload: authSession.accessToken.payload
      }

      currentSession = {
        page: redirectUrl,
        selectedMortgage: undefined,
        address: undefined,
        payment: 0,
        homesViewed: 0,
        option: 0
      }

      this.setState({loading: false, disabled: false});
      this.props.addAuth(auth);
      this.props.addSession(currentSession);
      this.handleRoute(redirectUrl);

    } catch (error) {
      console.log('error', error);
      this.setState({loading: false, disabled: false});
      // this.props.setMessage({message: 'Error logging in', messageType: MessageTypes.Error});
      await UtilsService.logError(AuthService.getApiUrls().core, 'Login', 'handleLogin', error);
    }
  }

  handleRoute = (route: string) => {
    this.props.history.push(route);
  }

  onEmailChange = (event: any) => {
    this.setState({email: event.target.value});
  }

  onPasswordChange = (event: any) => {
    this.setState({password: event.target.value});
  }

  handleSignup = () => {
    this.setState({openSignup: true});
  }

  render() {
    return (
      <AppContainer showOption={false} private={false} openSignup={this.state.openSignup}>
        <div style={styles.container}>
          <RowContainer>
            <TextInput
              type="string"
              placeholder="Email"
              title="Email"
              result={this.state.email}
              onChange={this.onEmailChange}
            />
          </RowContainer>
          <RowContainer>
            <TextInput
              type="password"
              placeholder="Password"
              title="Password"
              result={this.state.password}
              onChange={this.onPasswordChange}
            />
          </RowContainer>
          <RowContainer>
            <ActionButton name="Login" onClick={this.handleLogin} loading={this.state.loading} size="sm" fullWidth disabled={this.state.disabled} />
          </RowContainer>
          <RowContainer>
            <div style={styles.footer}>Not a member? <span style={styles.link} onClick={this.handleSignup}>Sign Up</span></div>
          </RowContainer>
        </div>
      </AppContainer>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    session: state.session.session
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ addAuth, deleteAuth, addSession, updateSession, setApiUrls }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(LoginPage));
