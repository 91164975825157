import React, { useState } from 'react';
import { find } from 'lodash';
import numeral from 'numeral';

// material
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloudIcon from '@material-ui/icons/CloudDownload';
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button
} from '@material-ui/core';
// entities
import { Campaign, CampaignContact } from '../../../entities/site';
import { ContactData } from '../Campaign';
// components
import MessageRow, { MessageRowEntity } from './MessageRow';
// containers
import Loader from '../../../containers/Loader';

interface CampaignContactPanelProps {
  contact: CampaignContact;
  showLoader: boolean;
  contactData: ContactData[];
  onPanelOpen(contact: CampaignContact): void;
  onSendMessage(contact: CampaignContact, message: string): void;
  onRefresh(contact: CampaignContact): void;
  onPropertyLoad(contact: CampaignContact): void;
}

const CampaignContactPanel: React.FC<CampaignContactPanelProps> = (props) => {
  const classes = useStyles();

  const [message, setMessage] = useState<string>('');

  const displayMessages = (contact: CampaignContact) => {
    const data = find(props.contactData, { id: contact.id });
    if (data && data.data.length) {
      return (
        <div style={{ padding: 10 }}>
          <div style={{ marginBottom: 10 }}>
            <div style={{ textAlign: 'left' }}>From: <span style={{ fontWeight: 'bold' }}>{data.messageGroup ? data.messageGroup.fromPhoneNumber : ''}</span></div>
            <div style={{ textAlign: 'left' }}>Status: <span style={{ fontWeight: 'bold' }}>{data.messageGroup ? data.messageGroup.groupStatus : ''}</span></div>
          </div>
          {data.data.map((messageRow: MessageRowEntity, index: number) => (
            <div key={`message-${index}`} style={{ marginBottom: 10 }}>
              <MessageRow title={messageRow.title} date={messageRow.date} message={messageRow.message} />
            </div>
          ))}
        </div>
      )

    } else {
      return <div>Message thread not started</div>;
    }
  }

  const displayAddress = (contact: CampaignContact) => {
    const data = find(props.contactData, { id: contact.id });
    if (data && data.address) {
      // numeral(props.rate).format('0.0')
      return (
        <div>
          <div className={classes.addressRow}>
            <div className={classes.addressProp}>Beds:</div>
            <div className={classes.addressValue}>{data.address.beds}</div>
          </div>
          <div className={classes.addressRow}>
            <div className={classes.addressProp}>Baths:</div>
            <div className={classes.addressValue}>{data.address.baths}</div>
          </div>
          <div className={classes.addressRow}>
            <div className={classes.addressProp}>Sqft:</div>
            <div className={classes.addressValue}>{data.address.sqft}</div>
          </div>
          <div className={classes.addressRow}>
            <div className={classes.addressProp}>Estimated Value:</div>
            <div className={classes.addressValue}>{numeral(data.address.estimate).format('$0.0')}</div>
          </div>
          <div className={classes.addressRow}>
            <div className={classes.addressProp}>Estimated Loan Balance:</div>
            <div className={classes.addressValue}>{numeral(data.address.loanBalance).format('$0.0')}</div>
          </div>
          <div className={classes.addressRow}>
            <div className={classes.addressProp}>Loan Amount:</div>
            <div className={classes.addressValue}>{numeral(data.address.loanAmount).format('$0.0')}</div>
          </div>
          <div className={classes.addressRow}>
            <div className={classes.addressProp}>Loan Date:</div>
            <div className={classes.addressValue}>{data.address.loanDate}</div>
          </div>
          <div className={classes.addressRow}>
            <div className={classes.addressProp}>Loan Type:</div>
            <div className={classes.addressValue}>{data.address.loanType}</div>
          </div>
          <div className={classes.addressRow}>
            <div className={classes.addressProp}>Sale Date:</div>
            <div className={classes.addressValue}>{data.address.saleDate}</div>
          </div>
          <div className={classes.addressRow}>
            <div className={classes.addressProp}>Sale Price:</div>
            <div className={classes.addressValue}>{numeral(data.address.salePrice).format('$0.0')}</div>
          </div>
          <div className={classes.addressRow}>
            <div className={classes.addressProp}>Tax Amount:</div>
            <div className={classes.addressValue}>{numeral(data.address.taxAmount).format('$0.0')}</div>
          </div>
          <div className={classes.addressRow}>
            <div className={classes.addressProp}>Tax Value:</div>
            <div className={classes.addressValue}>{numeral(data.address.taxValue).format('$0.0')}</div>
          </div>
        </div>
      )
    } else {
      return null;
    }
  }

  return (
    <ExpansionPanel onChange={() => props.onPanelOpen(props.contact)}>
      <ExpansionPanelSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={classes.columnName}>{props.contact.firstName} {props.contact.lastName}</div>
        <div className={classes.column}>{props.contact.phoneNumber}</div>
        <div className={classes.column}>{(props.contact.email ? props.contact.email : 'N/A')}</div>
        <div className={classes.column}>{(props.contact.city ? props.contact.city : 'N/A')}</div>
        <div className={classes.columnLast}>{props.contact.status}</div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Loader open={props.showLoader}>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <Button
                color="primary"
                startIcon={<RefreshIcon />}
                onClick={() => props.onRefresh(props.contact)}
              >
                Refresh
              </Button>
            </Grid>
            <Grid item xs={8}>
              <div style={{ marginBottom: 20 }}>
                <textarea style={{ width: '100%', height: 125 }} value={message} onChange={(event: any) => setMessage(event.target.value)}>
                  Reply to message
                </textarea>
                <div style={{ float: 'right' }}>
                  <Button variant="contained" color="secondary" fullWidth onClick={() => props.onSendMessage(props.contact, message)}>Send</Button>
                </div>
              </div>
              <div>
                { displayMessages(props.contact) }
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.propertyContainer}>
                <div className={classes.addressTitle}>Address</div>
                <div className={classes.address}>{props.contact.address}, {props.contact.city} {props.contact.state} {props.contact.zip}</div>
                <div>
                  <Button
                    color="primary"
                    startIcon={<CloudIcon />}
                    onClick={() => props.onPropertyLoad(props.contact)}
                  >
                    Get Property Info
                  </Button>
                </div>
                <div className={classes.addressContainer}>
                  { displayAddress(props.contact) }
                </div>
              </div>
            </Grid>
          </Grid>
        </Loader>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );

}

export default CampaignContactPanel;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderTop: '1px solid #d7dee2',
      paddingTop: 20
    },
    propertyContainer: {
      paddingLeft: 30
    },
    addressContainer: {
      padding: 10
    },
    addressTitle: {
      fontWeight: 'bold'
    },
    address: {

    },
    link: {
      color: '#29C7E2'
    },
    columnHeader: {
      width: '30%',
      textAlign: 'left',
      color: '#627995',
    },
    columnHeaderLast: {
      width: '10%',
      textAlign: 'left',
      color: '#627995',
    },
    column: {
      width: '30%',
      textAlign: 'left'
    },
    columnLast: {
      width: '10%',
      textAlign: 'center'
    },
    columnName: {
      width: '30%',
      color: '#29C7E2',
      fontSize: '18px',
      textAlign: 'left'
    },
    addressRow: {
      display: 'flex',
      paddingBottom: 2
    },
    addressProp: {
      paddingRight: 10,
      width: '60%',
      textAlign: 'left',
      color: '#9db0be'
    },
    addressValue: {
      fontWeight: 'bold',
      width: '40%',
      textAlign: 'right'
    }
  })
);