import React from "react";
// import MaskInput from 'react-maskinput';
// import MaskedInput from "react-text-mask";
// material
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
// components
interface PaymentDetailsProps {
  cardNumber: string;
  year: number | undefined;
  month: number | undefined;
  csv: number | undefined;
  onChange: (name: string, value: any) => void;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = (props) => {
  const classes = useStyles({});
  return (
    <React.Fragment>
      <div className={classes.detailsHeader}>Payment Details</div>
      <div className={classes.fieldLabel}>Card Number</div>
      {/* <MaskedInput
        mask={[
          /[1-9]/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        className={classes.maskedInput}
        value={props.cardNumber}
        onChange={(event) => props.onChange("cardNumber", event.target.value)}
      /> */}
      <Grid
        container
        direction={"row"}
        justify={"space-between"}
        className={classes.bottomBlock}
      >
        <div>
          <div className={classes.fieldLabel}>Expiration Date</div>
          <TextField
            inputProps={{
              className: classes.input,
              maxLength: 2,
            }}
            variant={"outlined"}
            className={classes.dateField}
            value={props.month}
            placeholder="MM"
            onChange={(event) => props.onChange("month", event.target.value)}
          />
          <span className={classes.cardDivider}>/</span>
          <TextField
            inputProps={{
              className: classes.input,
              maxLength: 4,
            }}
            variant={"outlined"}
            className={classes.yearField}
            value={props.year}
            placeholder="YYYY"
            onChange={(event) => props.onChange("year", event.target.value)}
          />
        </div>
        <div className={classes.csvField}>
          <div className={classes.fieldLabel}>CSV</div>
          <TextField
            inputProps={{ className: classes.input }}
            variant={"outlined"}
            value={props.csv}
            onChange={(event) => props.onChange("csv", event.target.value)}
          />
        </div>
      </Grid>
    </React.Fragment>
  );
};
export default PaymentDetails;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailsHeader: {
      height: 24,
      color: "#333333",
      fontFamily: "Asap",
      fontSize: 18,
      marginBottom: 15,
    },
    bottomBlock: {
      marginTop: 19,
    },
    fieldLabel: {
      height: 16,
      width: "auto",
      color: "#666666",
      fontFamily: "Asap",
      fontSize: 14,
      marginBottom: 4,
    },
    cardDivider: {
      fontSize: 28,
      paddingRight: 10,
      color: "#ccc",
    },
    dateField: {
      width: 80,
      marginRight: 10,
      [theme.breakpoints.only("xs")]: {
        marginBottom: "10px",
      },
    },
    yearField: {
      width: 100,
    },
    csvField: {
      width: 80,
    },
    input: {
      height: 3,
    },
    maskedInput: {
      width: "100%",
      borderRadius: 4,
      height: 40,
      borderColor: "rgba(0, 0, 0, 0.2)",
      borderWidth: 1,
      fontSize: 16,
      paddingLeft: 15,
    },
  })
);
