import React from 'react';
import { withRouter } from "react-router-dom";
// material
import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// compontent
import AddressSearch from '../../../components/site/AddressSearch';
import Search from '../../../components/inputs/Search';

// 
import { Session } from '../../../entities/auth';
import { Address } from '../../../entities/home';

interface HomeSectionProps {
    history: any;
    id: string;
    orientation: string;
    image: string;
    icon: string;
    title: string;
    description: string;
    session: Session;
}


const HomeSection: React.FC<HomeSectionProps> = (props) => {

    const classes = useStyles({});

    const containerClass = (props.orientation === 'right' ? classes.containerRight : classes.containerLeft)

    const handleAddressSearch = async (address: Address) => {
        if (props.id === 'pmi-removal') {
            props.history.push(`/pmi-removal/${address.id}`);
        }
        if (props.id === 'payment-design-center') {
            props.history.push(`/payment-design-center/${address.id}`);
        }
        if (props.id === 'dream-rate') {
            props.history.push(`/dream-rate/${address.id}`);
        }
    }

    const handleLoading = async () => {
        // niot used
    }

    return (
        <div id={props.id} className={containerClass}>
            <Container maxWidth="lg" >
                <Grid container spacing={8}>
                    {props.orientation === 'right' &&
                        <React.Fragment>
                            <Grid item xs={12} md={6}>
                                <div className={classes.imageContainer}><img src={props.image} className={classes.image} /></div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={classes.titleContainer}>
                                    <div><img src={props.icon} className={classes.icon} /></div>
                                    <div className={classes.title}>{props.title}</div>
                                </div>
                                <div className={classes.description}>{props.description}</div>
                                <div>
                                    <Search
                                        onShowLoading={handleLoading}
                                        onClick={handleAddressSearch}
                                        session={props.session}
                                    />
                                </div>
                            </Grid>
                        </React.Fragment>
                    }
                    {props.orientation === 'left' &&
                        <React.Fragment>
                            <Grid item xs={12} md={6}>
                                <div className={classes.titleContainer}>
                                    <div><img src={props.icon} className={classes.icon} /></div>
                                    <div className={classes.title}>{props.title}</div>
                                </div>
                                <div className={classes.description}>{props.description}</div>
                                <div>
                                    <Search
                                        onShowLoading={handleLoading}
                                        onClick={handleAddressSearch}
                                        session={props.session}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={classes.imageContainer}><img src={props.image} className={classes.image} /></div>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    containerRight: {
        backgroundColor: '#F4F7FC',
        paddingTop: 85,
        paddingBottom: 85
    },
    containerLeft: {
        backgroundColor: '#FFF',
        paddingTop: 85,
        paddingBottom: 85
    },
    titleContainer: {
        display: 'flex'
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    image: {
        width: 500,
        borderRadius: 20
    },
    icon: {
        width: 70,
        height: 70
    },
    title: {
        color: '#596675',
        fontSize: 30,
        paddingLeft: 20,
        display: 'flex',
        alignItems: 'center'
    },
    inputTitle: {
        color: '#4A607C',
        fontSize: 16
    },
    description: {
        color: '#8794A3',
        fontSize: 20,
        paddingTop: 30,
        paddingBottom: 60
    }

}));

export default withRouter<any, any>(HomeSection);