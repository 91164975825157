import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import { sortBy } from 'lodash';
// material
import { Container } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { withStyles, Theme } from '@material-ui/core/styles';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CallMadeIcon from '@material-ui/icons/CallMade';
import ShuffleIcon from '@material-ui/icons/Shuffle';
// app
import { updateSession, addAuth, openSnackbar, closeSnackbar } from '../../store/action-creators';
// containers
import AppContainer from '../../containers/App';
// models
import { Auth, Session } from '../../entities/auth';
import { APIUrl, Campaign, campaignStatusList, TabType } from '../../entities/site';
// components
import CampaignCards, { Card } from '../../components/campaign/CampaignCards';
import Loading from '../../components/site/Loading';
import ActionButton from '../../components/buttons/ActionButton';
import { SelectItem } from '../../components/inputs/SelectInput';
import AuthService from '../../services/auth';
import CustomTab from '../../components/tabs/CustomTab';
import CustomTabs from '../../components/tabs/CustomTabs';
import { CampaignsTable } from '../../components/campaign/CampaignsTable';
// pages
import CampaignsTableMobile from '../../components/campaign/CampaignsTableMobile';
import CampaignsCardMobile from '../../components/campaign/CampaignCardMobile';

const useProd = true;


export const TabList: TabType[] = [
  {
    name: "New",
    value: 'new'
  },
  {
    name: "Current",
    value: 'active'
  },
  {
    name: "Archived",
    value: 'archived'
  }
]

const styles: any = (theme: Theme) => ({
  title: {
    fontSize: "24px",
    fontFamily: "Roboto Medium",
    [theme.breakpoints.down('xs')]: {
      fontSize: "20px"
    }
  },
  campaignContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    padding: "20px 5px"
  },
  campaigndiv: {
    padding: "0px 20px",
    marginBottom: "100px",
    [theme.breakpoints.down('sm')]: {
      padding: "0px !important",
    }
  },
  tabdiv: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down('xs')]: {
      display: "block",
    },
    marginTop: 20,
    marginBottom: 10
  },
  newcampaign: {
    color: "#627995",
    fontSize: "17px",
    marginTop: "30px",
    [theme.breakpoints.down('xs')]: {
      marginTop: "0px",
      marginBottom: "15px",
      fontSize: "16px",
    }
  }
})



interface AdminPageProps {
  history: any;
  auth: Auth;
  session: Session;
  apiUrls: APIUrl;
  updateSession(session: Session): void;
  setApiUrls(urls: APIUrl): void;
  openSnackbar(message: string): void;
  closeSnackbar(): void;
}

interface AdminPageState {
  loading: boolean;
  campaigns: Campaign[];
  status: string;
  statuses: SelectItem[];
  stats: Card[];
}

class CampaignsPage extends Component<AdminPageProps, AdminPageState> {

  constructor(props: AdminPageProps) {
    super(props);
    this.state = {
      loading: false,
      campaigns: [],
      status: 'active',
      statuses: campaignStatusList,
      stats: []
    }
  }

  componentDidMount = async () => {
    this.fetchCampaignStats();
    this.fetchCampaigns(this.state.status);
  }

  onNavigate = (route: string) => {
    this.props.history.push(route);
  }

  fetchCampaignStats = async () => {

    let sent = 0;
    let clicks = 0;
    const favorites = 0;
    let templates = 0;

    const campaignsSentRes = await axios.get(`${AuthService.getApiUrls().site}/campaigns`);
    campaignsSentRes.data.results.map((campaign: Campaign) => {
      sent = sent + campaign.totalSent;
      clicks = clicks + campaign.totalClicks;
    });

    const templateRes = await axios.get(`${AuthService.getApiUrls().notifications}/message/templates`);
    templates = templateRes.data.length;

    const Carddata: Card[] = [
      {
        imgsrc: "https://placeimg.com/80/80/arch",
        name: "Total Sent",
        value: sent,
        path: "",
        avatarIcon: ShuffleIcon,
        avatarIconColor: "#00ABC7"
      },
      {
        imgsrc: "https://placeimg.com/80/80/arch",
        name: "Clicks",
        value: clicks,
        cardicon: "",
        path: "campaign/clicks",
        avatarIcon: CallMadeIcon,
        avatarIconColor: "#00B878"
      },
      {
        imgsrc: "https://placeimg.com/80/80/arch",
        name: "Favorites",
        value: favorites,
        path: "campaign/favorites",
        avatarIcon: FavoriteBorderIcon,
        avatarIconColor: "#00ABCA"
      },
      {
        imgsrc: "https://placeimg.com/80/80/arch",
        name: "Templates",
        value: templates,
        path: "",
        avatarIcon: FileCopyIcon,
        avatarIconColor: "#627995"
      }
    ];

    this.setState({stats: Carddata})

  }

  fetchCampaigns = async (status: string) => {

    try {

      this.setState({ loading: true });
      const campaignsRes = await axios.get(`${AuthService.getApiUrls().site}/campaigns?status=${status}&limit=${25}`);
      console.log('campaignsRes', campaignsRes);
      const filtered = sortBy(campaignsRes.data.results, 'createdDate').reverse();
      this.setState({
        campaigns: filtered,
        loading: false
      });

    } catch (error) {
      console.log('error', error);
      this.setState({ loading: false });
    }

  }

  handleShowLoading = () => {
    this.setState({ loading: true });
  }

  handleRoute = (campaign: Campaign) => {
    this.props.history.push(`/campaign/${campaign.id}`);
  }

  handleTabChange = (value: string) => {
    this.setState({ status: value });
    this.fetchCampaigns(value);
  }

  handleCampaignClick = (campaign: Campaign) => {
    this.props.history.push(`/campaign/${campaign.id}`);
  }

  a11yProps = (status: string) => {
    return {
      id: `simple-tab-${status}`,
      'aria-controls': `simple-tabpanel-${status}`,
    };
  }

  render() {
    const { classes }: any = this.props;
    return (
      <AppContainer showOption={false} private={false} maxWidth={false}>
        <Loading open={this.state.loading} />
        <Container maxWidth="lg">
          <div className={classes.campaigndiv}>
            <div className={classes.campaignContainer}>
              <div className={classes.title}>Campaigns</div>
              <div style={styles.button}>
                <ActionButton size="sm" name="Create Campaign" onClick={() => this.onNavigate('/campaign/new/details')} />
              </div>
            </div>
            {/* card */}
            <Hidden only={['xs']}>
              <CampaignCards cards={this.state.stats} />
            </Hidden>
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
              <CampaignsCardMobile cards={this.state.stats} />
            </Hidden>

            {/* tabdiv  */}
            <div className={classes.tabdiv}>
              <div>
                <CustomTabs value={this.state.status} onChange={(event: any, value: string) => this.handleTabChange(value)} aria-label="campaign contact">
                  <CustomTab value="active" label="Active" {...this.a11yProps('active')} />
                  <CustomTab value="new" label="New" {...this.a11yProps('new')} />
                  <CustomTab value="archived" label="Archived" {...this.a11yProps('clickThroughs')} />
                </CustomTabs>
              </div>
              <div className={classes.newcampaign} >
                {this.state.campaigns.length ? this.state.campaigns.length + ' Campaigns ' : 'No Campaigns'}
              </div>
            </div>

            {/*  campaigntable */}
            <Hidden only={['xs']}>
              <CampaignsTable 
                campaigns={this.state.campaigns}
                onClick={this.handleCampaignClick} 
              />
            </Hidden>
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
              <CampaignsTableMobile 
                campaigns={this.state.campaigns} 
                onClick={this.handleCampaignClick} 
              />
            </Hidden>
          </div>
        </Container>
      </AppContainer>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session,
    apiUrls: state.site.apiUrls
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ updateSession, addAuth, openSnackbar, closeSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(withStyles(styles)(CampaignsPage)));
