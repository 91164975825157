import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import { sortBy } from 'lodash';
import moment from 'moment';
// material
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Container, Grid, Button } from '@material-ui/core';
// app
import { updateSession, setApiUrls, openSnackbar, closeSnackbar } from '../../store/action-creators';
// containers
import AppContainer from '../../containers/App';
import ProfileContainer from '../../containers/Profile';
// models
import { Auth, Session } from '../../entities/auth';
import { APIUrl, Team } from '../../entities/site';
// components
import Loading from '../../components/site/Loading';
import ActionButton from '../../components/buttons/ActionButton';
import AddTeam from './components/AddTeam';
import Empty from './components/Empty';
// service 
import AuthService from '../../services/auth';

const useProd = true;

const styles: any = {
    tableWrapper: {
        maxHeight: 800,
        overflow: 'auto',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: 'left'
    }
}

interface TeamPageProps {
    match?: any;
    history: any;
    auth: Auth;
    session: Session;
    apiUrls: APIUrl;
    updateSession(session: Session): void;
    setApiUrls(urls: APIUrl): void;
    openSnackbar(message: string): void;
    closeSnackbar(): void;
}

interface TeamPageState {
    loading: boolean;
    teams: Team[];
    openAddTeam: boolean;
}

class TeamPage extends Component<TeamPageProps, TeamPageState> {

    constructor(props: TeamPageProps) {
        super(props);
        this.state = {
            teams: [],
            loading: false,
            openAddTeam: false
        }
    }

    componentDidMount = async () => {
        console.log('user', this.props.auth);
        this.fetchBranches();
    }

    fetchBranches = async () => {
        const teamId = this.props.match.params.id;
        this.setState({
            loading: true
        });
        const teamsRes = await axios.get(`${AuthService.getApiUrls().site}/team/${teamId}/teams`);
        this.setState({
            loading: false,
            teams: teamsRes.data
        });
    }

    handleShowLoading = () => {
        this.setState({ loading: true });
    }

    handleOpenAddTeam = () => {
        this.setState({ openAddTeam: true });
    }

    handleCloseTeam = () => {
        this.setState({ openAddTeam: false });
        this.fetchBranches();
    }

    render() {
        const parentId = this.props.match.params.id;
        return (
            <AppContainer loading={this.state.loading} private maxWidth="lg">
                <ProfileContainer>
                    <Loading open={this.state.loading} />
                    <Container maxWidth="lg">
                        <Grid container>
                            <Grid xs={10}>
                                <div style={styles.title}>Branches</div>
                            </Grid>
                            <Grid xs={2}>
                                <ActionButton name="New Branch" size="sm" onClick={this.handleOpenAddTeam} fullWidth />
                            </Grid>
                        </Grid>
                        { this.state.teams.length === 0 &&
                            <Empty message="No Branches to Display" />
                        }
                        { this.state.teams.length > 0 &&
                            <Paper>
                                <div style={styles.tableWrapper}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell>Address</TableCell>
                                                <TableCell>City</TableCell>
                                                <TableCell>State</TableCell>
                                                <TableCell>Zip</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.teams.map((team: Team, index) => (
                                                <TableRow key={`address-${index}`}>
                                                    <TableCell component="th" scope="row">
                                                        <Button href={`/organization/${parentId}/branch/${team.id}`} color="primary">
                                                            {team.name}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell>{team.address}</TableCell>
                                                    <TableCell>{team.city}</TableCell>
                                                    <TableCell>{team.state}</TableCell>
                                                    <TableCell>{team.zipCode}</TableCell>
                                                    <TableCell>{moment(team.createdDate).format('MM-DD-YYYY HH:MM')}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                         }
                    </Container>
                    <AddTeam open={this.state.openAddTeam} onClose={this.handleCloseTeam}  />
                </ProfileContainer>
            </AppContainer>
        )
    }


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth.auth,
        session: state.session.session,
        apiUrls: state.site.apiUrls
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ updateSession, setApiUrls, openSnackbar, closeSnackbar }, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter<any, any>(TeamPage));
