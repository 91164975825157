import React, { Component } from 'react';
import { Theme } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Badge } from "@material-ui/core"
import './index.css';

const styles: any = (theme: Theme) => ({
  paper: {
    padding: "25px",
    boxShadow: "-1px 3px 6px 2px rgba(0,0,0,0.2);"

  },
  image: {
    width: 60,
    height: 60,
    borderRadius: "50px",
    backgroundColor: "#BFEAF1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto"
  },
  img: {
    margin: 'auto',
    display: 'block',
    marginTop: "20px",
    fontSize: "30px"
  },
  numbervalue: {
    color: "#22494e",
    fontSize: "16px",
    textAlign: 'center',
    margin: "8px 0px 0px 0px",
  },
  root: {
    flexGrow: 1,
  },
  cardtxt: {
    margin: "5px auto 0px"
  },
  itemname: {
    color: "#627995",
    fontSize: "18px"
  },
  cardicon: {
    border: "2px solid #00B878",
    borderRadius: "25px",
    padding: "5px 10px",
    fontWeight: 600,
    backgroundColor: "#00B878",
    color: "white",
    position: 'absolute',
    right: 0,
    top: 0,
  },
  cardtxtdiv: {
    padding: "7px",
    marginLeft: "15px",
    [theme.breakpoints.between('sm', 'lg')]: {
      marginLeft: "30px",
    }
  },
  cardcontainer: {
    width: "100%",
    boxShadow: "0px 2px 4px 2px grey"
  },
})

export interface Card {
  imgsrc?: string;
  name?: string;
  value?: string;
  cardicon?: string;
  path?: string;
  avatarIcon?: any;
  avatarIconColor?: string;
}

interface CampaignCard {
  campaigncard: Card[];
}
interface CardProps {
  carddata: Card[];
  history: any;
}

const Table = ({ item, classes, history }: any) => {
  const handleCampaignCardClick = () => {
    history.push(item.path)
  }
  return <div
    onClick={handleCampaignCardClick} style={{ width: "25%" }}>
    {item.cardicon ?
      (<Badge anchorOrigin={{ vertical: 'top', horizontal: 'right' }} className="badge" color="error" badgeContent={item.cardicon}>
        <div>
          <div className={classes.image}>
            {<item.avatarIcon style={{ color: item.avatarIconColor }} />}
          </div>
          <p className={classes.numbervalue}>{item.value}</p>
        </div>
      </Badge>) : (
        <div>
          <div className={classes.image}>
            {<item.avatarIcon style={{ color: item.avatarIconColor }} />}
          </div>
          <p className={classes.numbervalue}>{item.value}</p>
        </div>)}
  </div>
}

class CampaignsCardMobile extends Component<CardProps, CampaignCard> {
  constructor(props: any) {
    super(props);
    this.state = {
      campaigncard: this.props.carddata
    }
  }

  handleCampaignCardClick = (path: any) => {
    this.props.history.push(path);
  }

  renderCard = (item: Card, i: number) => {
    return (
      <Table {...this.props} item={item} />
    )
  }

  render() {
    const { classes }: any = this.props;
    return (
      <div style={{ display: "flex" }}>
        {this.state.campaigncard.map(this.renderCard)}
      </div>
    )
  }
}

export default withRouter<any, any>(withStyles(styles)(CampaignsCardMobile));