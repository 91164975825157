import React from 'react';
import numeral from 'numeral';
// material
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// components
import AmountSlider from './AmountSlider';
import TextInput from '../../../components/inputs/TextInput';

interface AvailableCashProps {
  cash: number;
  maxCash: number;
  onChange(event: any, value: any): void;
}

const AvailableCash: React.FC<AvailableCashProps> = (props) => {

  const classes = useStyles({});

  let inputCash = props.cash;
  if (props.cash > props.maxCash) {
    inputCash = props.maxCash;
    props.onChange(null, props.maxCash);
  }

  return (
    <div className={classes.container}>
        <div className={classes.title}>Available Cash</div>
        <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <div className={classes.cashContainer}>
                <div className={classes.unit}>$</div>
                <TextInput 
                  title=""
                  type="number"
                  placeholder=""
                  result={inputCash}
                  onChange={(event: any) => props.onChange(event, event.target.value)} 
                />
              </div>
            </Grid>
            <Grid item xs={12} md={9}>
                <AmountSlider
                    min={0}
                    max={props.maxCash}
                    defaultValue={props.cash}
                    onChange={props.onChange}
                />
            </Grid>
        </Grid>
    </div>
  );
}
export default AvailableCash;


const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 20,
    paddingBottom: 20
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    color: '#596675',
    paddingBottom: 20
  },
  unit: {
    fontSize: 20,
    color: '#ACB2BA',
    paddingRight: 10,
    paddingTop: 13
  },
  cashContainer: {
    display: 'flex'
  },
  cash: {
    fontSize: 20,
    color: '#596675'
  }
}));