import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { findIndex, find } from "lodash";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
// material
import { Grid, Hidden } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// components
import FooterToolbar from "./components/FooterToolbar";
import AddCampaign from "../../components/campaign/AddCampaign";
import DropContacts from "../../components/campaign/DropContacts";
import AddCollaborator from "../../components/campaign/details/AddCollaborator";
import BreadCrumbMenu from "../../components/breadcrumb/BreadCrumbMenu";
import { connect } from "react-redux";
import { updateSession, openSnackbar, closeSnackbar } from '../../store/action-creators';
import CampaignContactsList from '../../components/campaign/CampaignContactsList';
import CampaignContactHeaders, { MappedHeader } from '../../components/campaign/CampaignContactHeaders';
// containers
import AppContainer from "../../containers/App";
// services
import AuthService from '../../services/auth';
import CampaignService from '../../services/campaign';
// entities
import { TeamUser, TeamList, CampaignContact, emptyCampaign, Collaborator } from '../../entities/site';
import { Auth, Session } from '../../entities/auth';
import { SelectItem } from "../../components/inputs/SelectInput";

const robotHead = process.env.PUBLIC_URL + "/galilaio/robot_head.svg";
const logoName = process.env.PUBLIC_URL + "/galilaio/logo_name.png";

interface NewCampaignDetailsProps {
  history: any;
  auth: Auth;
  session: Session;
  updateSession(session: Session): void;
  openSnackbar(message: string): void;
  closeSnackbar(): void;
}

const NewCampaignPageDetails: React.FC<NewCampaignDetailsProps> = (props) => {
  const classes = useStyles();

  const [teamsUsers, setTeamsUsers] = useState<TeamUser[]>([]);
  const [branches, setBranches] = useState<TeamList[]>([]);
  // const [organizations, setOganizations] = useState<TeamList[]>([]);

  const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
  const [open, setOpen] = useState(false);
  const [campaignName, setCampaignName] = useState<string>("");
  const [campaignType, setCampaignType] = useState<string>("");

  // uploads
  const [fileData, setFileData] = useState<any>(null);
  const [uploadHeaders, setUploadHeaders] = useState<SelectItem[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [mappedHeaders, setMappedHeaders] = useState<MappedHeader[]>([]);
  const [showMapping, setShowMapping] = useState<boolean>(false);

  const [totalContacts, setTotalContacts] = useState<number>(0);
  const [contactChunks, setContactChunks] = useState<CampaignContact[][]>([]);
  const [contactErrors, setContactErrors] = useState<CampaignContact[]>([]);

  const [contactListTitle, setContactListTitle] = useState<string>("");
  const [contactListType, setContactListType] = useState<string>("");
  const [showContactList, setShowContactList] = useState<boolean>(false);
  const [campaignContacts, setCampaignContacts] = useState<CampaignContact[]>(
    []
  );

  const setCampaign = () => {
    // set campaign from session
    const campaignSession = props.session.campaign;

    if (campaignSession) {
      if (campaignSession.campaign) {
        setCampaignName(campaignSession.campaign.name);
        setCampaignType(campaignSession.campaign.type);
      }

      if (campaignSession.collaborators) {
        setCollaborators(campaignSession.collaborators);
      }

      if (campaignSession.contacts.length > 0) {
        const totalItems = campaignSession.contacts.reduce(
          (count, row) => count + row.length,
          0
        );
        setContactChunks(campaignSession.contacts);
        setTotalContacts(totalItems);
      }

      if (
        campaignSession.contactErrors &&
        campaignSession.contactErrors.length
      ) {
        setContactErrors(campaignSession.contactErrors);
      }
    }
  };

  const fetchTeamUsers = async () => {
    const teamsUserList: TeamUser[] = [];
    const branchesList: TeamList[] = [];
    // const organizationsList: TeamList[] = [];

    const teamUsersRes = await axios.get(
      `${AuthService.getApiUrls().site}/team/users/${props.auth.id}`
    );
    if (teamUsersRes) {

      teamsUserList.push({
        id: props.auth.id,
        userId: props.auth.id,
        email: (props.auth.user ? props.auth.user.email : ''),
        name: (props.auth.user ? props.auth.user.name : ''),
        teamId: 'null',
        teamName: 'null',
        teamType: 'null',
        createdDate: 0
      });

      teamUsersRes.data.forEach((teamUser: TeamUser) => {
        const teamUserIndex = findIndex(teamsUserList, {
          userId: teamUser.userId,
        });
        if (teamUserIndex === -1) {
          teamsUserList.push(teamUser);
        }
        if (teamUser.teamType === "branch") {
          const branchIndex = findIndex(branches, { teamId: teamUser.teamId });
          if (branchIndex === -1) {
            branchesList.push({
              teamId: teamUser.teamId,
              teamName: teamUser.teamName,
              teamType: teamUser.teamType,
            });
          }
        }
        // if (teamUser.teamType === 'organization') {
        //   const organizationndex = findIndex(organizations, {teamId: teamUser.teamId});
        //   if (organizationndex === -1) {
        //     organizationsList.push({teamId: teamUser.teamId, teamName: teamUser.teamName, teamType: teamUser.teamType});
        //   }
        // }
        
        // setOganizations(organizationsList);
      });
      
      console.log('teamsUserList', teamsUserList);
      console.log('branchesList', branchesList);

      setTeamsUsers(teamsUserList);
      setBranches(branchesList);

    }
  };

  const handleFileDrop = async (data: any) => {

    try {

      // get headers
      const parseHeaderRes = await axios.post(`${AuthService.getApiUrls().site}/campaign/contacts/parse/headers`, { file: data });

      console.log('parseHeaderRes', parseHeaderRes);

      if (parseHeaderRes.data.errorMessage) {
        throw parseHeaderRes.data.errorMessage;
      }
      const headersArray = parseHeaderRes.data;
      const headerItems: SelectItem[] = [
        {
          name: 'Empty Column',
          value: 'empty'
        }
      ];
      headersArray.forEach((header: string) => {
        headerItems.push({
          name: header,
          value: header
        })
      })

      setFileData(data);
      setHeaders(headersArray);
      setUploadHeaders(headerItems);
      setShowMapping(true);


    } catch (error) {
      console.log('error', error);
    }

  }

  const handleContactsClick = (type: string) => {
    let contactList: CampaignContact[] = [];
    let contactListModalTitle = "Valid Contacts";
    if (type === "contacts") {
      // loop through the chunks
      contactChunks.forEach((chunk: CampaignContact[]) => {
        chunk.forEach((contact: CampaignContact) => {
          contactList.push(contact);
        });
      });
    } else {
      contactListModalTitle = "Contacts Missing Required Data";
      contactList = contactErrors;
    }

    setContactListTitle(contactListModalTitle);
    setContactListType(type);
    setShowContactList(true);
    setCampaignContacts(contactList);
  };

  const handleCollaboratorChange = (
    checked: boolean,
    collaborator: Collaborator
  ) => {
    console.log('collaborator', collaborator);
    setCollaborators([...collaborators, collaborator]);
  };

  const handleDeleteCollaborator = (id: string) => {
    const newCollaborators = [...collaborators];
    setCollaborators(newCollaborators.filter((c) => c.id !== id));
  };

  const handleChange = (fieldName: string, value: string) => {
    if (fieldName === "name") {
      setCampaignName(value);
    }

    if (fieldName === "type") {
      setCampaignType(value);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCampaignContactListClose = () => {
    setShowContactList(false);
  };

  const handleOnNext = () => {
    if (!campaignName) {
      props.openSnackbar("Campaign name required");
      return;
    }

    if (!campaignType) {
      props.openSnackbar("Campaign type required");
      return;
    }

    const newSession = props.session;
    const newCampaign = emptyCampaign;
    newCampaign.name = campaignName;
    newCampaign.type = campaignType;

    newSession.campaign = {
      campaign: newCampaign,
      contacts: contactChunks,
      contactErrors,
      collaborators,
    };

    props.updateSession(newSession);
    props.history.push("/campaign/new/template");
  };

  const handleCancel = () => {
    const newSession = props.session;
    newSession.campaign = undefined;
    props.updateSession(newSession);
    props.history.push("/");
  };

  const handleHeaderClose = async (mapped: MappedHeader[]) => {

    setShowMapping(false);

    if (!mapped.length) {
      // show error
      return;
    }

    const mappedNew: MappedHeader[] = [];
    CampaignService.getSupportedColumns().forEach((supportedColumn: string) => {

      const search = find(mapped, { supportedColumn })
      if (search) {
        search.index = headers.indexOf(search.column);
        mappedNew.push(search);
      } else {
        mappedNew.push({
          supportedColumn,
          column: '',
          index: -1
        });
      }
    });

    setMappedHeaders(mappedNew);

    // parse again with mapped columns
    const fileDataBody = {
      file: fileData,
      cols: mappedNew
    };
    const parseRes = await axios.post(`${AuthService.getApiUrls().site}/campaign/contacts/parse`, fileDataBody)
    if (parseRes.data) {
      setTotalContacts(parseRes.data.rows);
      setContactChunks(parseRes.data.chunks);
      setContactErrors(parseRes.data.errors);
    }


  }

  useEffect(() => {
    setCampaign();
    fetchTeamUsers();
  }, []);

  return (
    <AppContainer showOption={false} private={false} maxWidth="lg">
      <Grid container>
        <Grid container spacing={4} className={classes.container}>
          <Grid item xs={12} md={6}>
            <div className={classes.title}>Campaign Details</div>
            <Hidden only={["md", "lg"]}>
              <div className={classes.progress}>
                <BreadCrumbMenu current="/campaign/new/details" />
              </div>
            </Hidden>
            <div className={classes.information}>
              <AddCampaign
                name={campaignName}
                type={campaignType}
                client="galilaio"
                showClients={false}
                onChange={handleChange}
              />
            </div>
            <div className={classes.collaboratorHeader}>
              <div className={classes.collaboratorTitle}>
                Campaign Collaborators
              </div>
            </div>
            <AddCollaborator
              teamsUsers={teamsUsers}
              branches={branches}
              open={open}
              collaborators={collaborators}
              onOpen={handleOpen}
              onClose={handleClose}
              onChange={handleCollaboratorChange}
              onDelete={handleDeleteCollaborator}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.rightSection}>
            <Hidden only={["xs", "sm"]}>
              <div className={classes.progress}>
                <BreadCrumbMenu current="/campaign/new/details" />
              </div>
            </Hidden>
            <div className={classes.drapdrop}>
              <DropContacts
                total={totalContacts}
                errors={contactErrors.length}
                onDrop={handleFileDrop}
                onClick={handleContactsClick}
              />
              <CampaignContactsList
                open={showContactList}
                title={contactListTitle}
                campaignContacts={campaignContacts}
                onClose={handleCampaignContactListClose}
              />
            </div>
          </Grid>
        </Grid>
        <FooterToolbar
          history={props.history}
          pre={false}
          submit={false}
          onNext={handleOnNext}
          onCancel={handleCancel}
        />
        <CampaignContactHeaders open={showMapping} headers={uploadHeaders} onClose={handleHeaderClose} />
      </Grid>
    </AppContainer>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: 0,
    },
    drapdrop: {
      // width: 600,
      height: 190,
      backGroundColor: "grey",
      cursor: "pointer",
      [theme.breakpoints.between("xs", "sm")]: {
        // maxWidth: "100%"
      },
    },

    information: {
      // width: 600,
      height: 190,
      marginBottom: 40,
      [theme.breakpoints.between("xs", "sm")]: {
        // maxWidth: "100%"
      },
    },

    title: {
      fontFamily: "Asap",
      fontSize: 24,
      color: "#1C242D",
      textAlign: "left",
      marginBottom: 30,
      marginTop: 25,
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: 18,
      },
    },

    progress: {
      // width: 425,
      // height: 20,
      marginBottom: 40,
      textAlign: "right",
      marginTop: 30,
    },

    collaboratorHeader: {
      // width: 420,
      display: "flex",
      justifyContent: "space-between",
    },

    collaboratorTitle: {
      fontFamily: "Asap",
      fontSize: 18,
      textAlign: "left",
      color: "#333333",
      marginBottom: 15,
    },

    editButton: {
      height: 16,
      width: 24,
      color: "#00ABCA",
      fontFamily: "Asap",
      fontSize: 14,
      fontWeight: 500,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    modal: {},
    rightSection: {
      [theme.breakpoints.only("xs")]: {
        paddingTop: "0px !important",
      },
    },
  })
);

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      updateSession,
      openSnackbar,
      closeSnackbar,
    },
    dispatch
  );
};

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(NewCampaignPageDetails)
);
