import React, { useState } from 'react';
import { v1 as uuid } from 'uuid';
import numeral from 'numeral';
// material
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { InputBase, Hidden } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

import ActionButton from '../buttons/ActionButton';

interface Coords {
  accuracy: number;
  altitude: number;
  altitudeAccuracy: number;
  heading: number;
  latitude: number;
  longitude: number;
  speed: number;
}

const MIN_CHAR_SEARCH = 2;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    searchContainer: {
      display: 'flex',
      justifyContent: 'center'
    },
    searchText: {
      paddingBottom: 5,
      textAlign: 'left',
      color: '#4A607C',
      fontSize: 16
    },
    searchTextMobile: {
      paddingBottom: 5,
      textAlign: 'center',
      color: '#4A607C',
      fontSize: 14
    },
    search: {
      position: 'relative',
      borderTop: '1px solid #C9D3E0',
      borderBottom: '1px solid #C9D3E0',
      borderLeft: '1px solid #C9D3E0',
      backgroundColor: '#FFF',
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      '&:hover': {
        backgroundColor: fade('#FFF', 0.9),
      },
      width: '100%',
      padding: 5
    },
    searchIcon: {
      padding: 15,
      backgroundColor: '#00ABCA',
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      border: '1px solid #C9D3E0'
    },
    inputRoot: {
      color: 'inherit',
      width: 400,
      [theme.breakpoints.down('sm')]: {
        width: 275,
      },
    },
    inputInput: {
      paddingLeft: 20,
      paddingTop: 16,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 400,
      },
      [theme.breakpoints.down('sm')]: {
        width: 275,
        paddingLeft: 30
      },
    },
    searchMobile: {
      position: 'relative',
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 20,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    inputRootMobile: {
      color: 'inherit',
      width: 200,
    },
    inputInputMobile: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 150,
      },
    }
  }),
);

const token = uuid();

interface EmailInputProps {
  title: string;
  onClick(email: string): void;
}

const EmailInput: React.FC<EmailInputProps> = (props) => {

  const classes = useStyles({});
  const [search, setSearch] = useState<string>('');

  return (
    <div style={{ position: 'relative' }}>
      <Hidden smDown>
        <div className={classes.container}>
          <div className={classes.searchText}>{props.title}</div>
          <div className={classes.searchContainer}>
            <div className={classes.search}>
              <InputBase
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                placeholder=""
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            <div className={classes.searchIcon}>
              <SearchIcon style={{ color: '#FFF', fontSize: 30 }} onClick={() => props.onClick(search)} />
            </div>
          </div>
        </div>
      </Hidden>
      {/* <Hidden mdUp>
        <div className={classes.searchTextMobile}>{props.title}</div>
        <div style={{ border: ' 2px solid #00ABCA' }}>
          <div className={classes.search}>
            <InputBase
              value={search}
              onChange={(event) => onChange(event)}
              placeholder="Start typing..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
          <div>
            <ActionButton
              name="Search"
              size="sm"
              onClick={handleClick}
              fullWidth
            />
          </div>
        </div>
      </Hidden> */}
    </div>
  );
}

export default EmailInput;