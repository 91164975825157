import React from "react";
// material
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// components

const states = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

interface AddressDetailsProps {
  address: string;
  city: string;
  state: string;
  zipCode: number | undefined;
  onChange: (name: string, value: any) => void;
}

const AddressDetails: React.FC<AddressDetailsProps> = (props) => {
  const classes = useStyles({});
  return (
    <div className={classes.addressBlock}>
      <div className={classes.billingField}>
        <div className={classes.fieldLabel}>Billing address</div>
        <TextField
          inputProps={{
            className: classes.input,
            maxLength: 255,
          }}
          fullWidth
          variant={"outlined"}
          value={props.address}
          onChange={(event) => props.onChange("address", event.target.value)}
        />
      </div>
      <Grid container direction={"row"} justify={"space-between"}>
        <div className={classes.cityField}>
          <div className={classes.fieldLabel}>City</div>
          <TextField
            inputProps={{
              className: classes.input,
              maxLength: 255,
            }}
            variant={"outlined"}
            value={props.city}
            onChange={(event) => props.onChange("city", event.target.value)}
          />
        </div>
        <div className={classes.stateField}>
          <div className={classes.fieldLabel}>State</div>
          <Select
            style={{ height: 40 }}
            variant={"outlined"}
            value={props.state}
            onChange={(event) => props.onChange("state", event.target.value)}
          >
            {states.map((stateName, index) => (
              <MenuItem key={`state-${index}`} value={stateName}>
                {stateName}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.zipCodeField}>
          <div className={classes.fieldLabel}>ZIP Code</div>
          <TextField
            inputProps={{
              className: classes.input,
              maxLength: 10,
            }}
            variant={"outlined"}
            value={props.zipCode}
            onChange={(event) => props.onChange("zipCode", event.target.value)}
          />
        </div>
      </Grid>
    </div>
  );
};
export default AddressDetails;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addressBlock: {
      marginBottom: 40,
    },
    billingField: {
      marginBottom: 8,
    },
    cityField: {
      width: 190,
      [theme.breakpoints.only("xs")]: {
        marginBottom: "10px",
      },
    },
    stateField: {
      width: "auto",
    },
    zipCodeField: {
      width: 100,
    },
    fieldLabel: {
      height: 16,
      width: "auto",
      color: "#666666",
      fontFamily: "Asap",
      fontSize: 14,
      marginBottom: 4,
    },
    input: {
      height: 3,
    },
  })
);
