import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Auth from '@aws-amplify/auth';
// app
import { addAuth, openSnackbar, closeSnackbar } from '../../store/action-creators';
import { colors } from '../../config/styles';
// containers
import AppContainer from '../../containers/App';
// models
import { Session, Auth as AuthEntity } from '../../entities/auth';
import { APIUrl } from '../../entities/site';
// components
import ActionButton from '../../components/buttons/ActionButton';
import RowContainer from '../../containers/Row';
import TextInput from '../../components/inputs/TextInput';
// services
import UtilsService from '../../services/utils';
import AuthService from '../../services/auth';

const styles: any = {
  container: {
    height: "100%",
    minHeight: "100%",
    maxWidth: 600,
    margin: '0px auto',
    padding: 40,
    backgroundColor: '#FFF'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  password: {
    fontSize: 10,
    textAlign: 'center'
  },
  link: {
    color: colors.blue,
    cursor: 'pointer'
  },
  footer: {
    textAlign: 'center',
    fontSize: 12
  }
}

interface ResetPageProps {
  history: any;
  session: Session;
  addAuth(auth: AuthEntity): void;
  openSnackbar(message: string, type?: string): void;
  closeSnackbar(): void;
}

interface ResetPageState {
  loading: boolean;
  disabled: boolean;
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

class ResetPage extends Component<ResetPageProps, ResetPageState> {

  constructor(props: ResetPageProps) {
    super(props);
    this.state = {
      loading: false,
      disabled: false,
      oldPassword: '',
      password: '',
      confirmPassword: ''
    }
  }

  componentDidMount = () => {
    // home
  }

  handleResetPassword = async () => {
    // login
    try {
      // this.props.clearMessage();
      this.setState({ loading: true, disabled: true });

      const currentUser = await Auth.currentAuthenticatedUser();

      if (!currentUser) {
        return;
      }

      const validationError = this.validaForm();
      if (validationError) {
        throw new Error(validationError);
      }

      await Auth.changePassword(currentUser, this.state.oldPassword, this.state.password);

      // updates user status
      const userRes = await axios.get(`${AuthService.getApiUrls().user}/user/${currentUser.username}`);
      const updatedUser = userRes.data;
      updatedUser.status = 'active';
      await axios.put(`${AuthService.getApiUrls().user}/user/${currentUser.username}`, updatedUser);

      // get real token
      const signIn = await Auth.signIn(currentUser.attributes.email, this.state.password);

      // get current session
      const authSession: any = await Auth.currentSession();

      // store new creds
      const auth: AuthEntity = {
        id: signIn.username,
        token: authSession.accessToken.jwtToken,
        payload: authSession.accessToken.payload,
        user: updatedUser
      }
      this.props.addAuth(auth);

      this.props.openSnackbar('Password has been reset');

      setTimeout(() => {
        this.handleRoute('/profile');
      }, 2000);

    } catch (error) {
      this.props.openSnackbar('Error resetting password', 'error');
      this.setState({ loading: false, disabled: false });
      // this.props.setMessage({message: 'Error logging in', messageType: MessageTypes.Error});
      await UtilsService.logError(AuthService.getApiUrls().core, 'Login', 'handleLogin', error);
    }
  }

  validaForm = () => {
    let error = '';
    if (!this.state.oldPassword) {
      error = 'Old password missing'
    }
    if (!this.state.password) {
      error = 'New password required'
    }
    if (!this.state.confirmPassword) {
      error = 'Confirm password required'
    }
    if (this.state.password !== this.state.confirmPassword) {
      error = 'Confirm password required'
    }
    return error;
  }

  handleRoute = (route: string) => {
    this.props.history.push(route);
  }

  onOldPasswordChange = (event: any) => {
    this.setState({ oldPassword: event.target.value });
  }

  onPasswordChange = (event: any) => {
    this.setState({ password: event.target.value });
  }

  onConfirmPasswordChange = (event: any) => {
    this.setState({ confirmPassword: event.target.value });
  }

  render() {
    return (
      <AppContainer showOption={false} maxWidth="lg">
        <div style={styles.container}>
          <div style={styles.title}>Reset Password</div>
          <RowContainer>
            <TextInput
              title="Old Password"
              type="password"
              placeholder=""
              result={this.state.oldPassword}
              onChange={this.onOldPasswordChange}
            />
          </RowContainer>
          <RowContainer>
            <TextInput
              title="New Password"
              type="password"
              placeholder=""
              result={this.state.password}
              onChange={this.onPasswordChange}
            />
            <div style={styles.password}>Must have 6 chars, a number, uppercase and lower case letters</div>
          </RowContainer>
          <RowContainer>
            <TextInput
              title="Confirm Password"
              type="password"
              placeholder=""
              result={this.state.confirmPassword}
              onChange={this.onConfirmPasswordChange}
            />
          </RowContainer>
          <RowContainer>
            <ActionButton name="Reset Password" onClick={this.handleResetPassword} loading={this.state.loading} size="sm" fullWidth disabled={this.state.disabled} />
          </RowContainer>
        </div>
      </AppContainer>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    session: state.session.session
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ addAuth, openSnackbar, closeSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(ResetPage));
