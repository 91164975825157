import React, { useState } from 'react';
import { colors } from '../../../config/styles';
// material
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface HomeBoxProps {
  title: string;
  description: string;
  icon: string;
  keyName: string;
  enabled: boolean;
  onClick(key: string): void;
}

const HomeBox: React.FC<HomeBoxProps> = (props) => {

  const classes = useStyles({});
  const [hovered, setHovered] = useState<string[]>([]);

  const handleMouseEnter = (key: string) => {
    const current = [...hovered];
    if (hovered.indexOf(key) === -1) {
      current.push(key);
    }
    setHovered(current);
  };

  const handleMouseLeave = (key: string) => {
    const current = [...hovered];
    const index = hovered.indexOf(key);
    if (index !== -1) {
      current.splice(index, 1);
    }
    setHovered(current);
  };

  return (
    <div className={(hovered.indexOf(props.keyName) !== -1 ? classes.containerHover : classes.container)} 
      onMouseEnter={() => handleMouseEnter(props.keyName) }
      onMouseLeave={() => handleMouseLeave(props.keyName) } 
      onClick={() => props.onClick(props.keyName)}
    >
      { !props.enabled && 
        <div className={classes.soon}>coming soon</div>
      }
      <div className={classes.icon}><img src={props.icon} /></div>
      <div className={classes.title}>{props.title}</div>
      <div className={classes.description}>{props.description}</div>
    </div>
  )
};
export default HomeBox;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 20,
      backgroundColor: '#fff',
      border: '1px solid #CBD5E1',
      cursor: 'pointer',
      position: 'relative',
      width: '100%',
      height: '100%',
      borderRadius: 8
    },
    containerHover: {
      padding: 20,
      backgroundColor: '#fff',
      border: '1px solid #00597C',
      cursor: 'pointer',
      position: 'relative',
      width: '100%',
      height: '100%',
      borderRadius: 8
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      height: 88
    },
    title: {
      fontSize: 18,
      color: '#596675',
      textAlign: 'center',
      paddingTop: 20,
      paddingBottom: 20
    },
    description: {
      fontSize: 14,
      color: '#8794A3',
      textAlign: 'center'
    },
    soon: {
      padding: 3,
      color: '#8794A3',
      backgroundColor: '#F4F7FC',
      border: '1px solid CBD5E1',
      position: 'absolute',
      left: 0,
      top: 0
    }
  })
);