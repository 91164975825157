enum actions {
  ADD_PAYMENT = "ADD_PAYMENT",
  ADD_AUTH = "ADD_AUTH",
  CLEAR_AUTH = "CLEAR_AUTH",
  DELETE_AUTH = "DELETE_AUTH",
  ADD_SESSION = "ADD_SESSION",
  UPDATE_SESSION = "UPDATE_SESSION",
  OPEN_SNACKBAR = "OPEN_SNACKBAR",
  CLOSE_SNACKBAR = "CLOSE_SNACKBAR",
  SET_SIGNUP = "SET_SIGNUP",
  CLEAR_SIGNUP = "CLEAR_SIGNUP",
  SET_MESSAGE = "SET_MESSAGE",
  CLEAR_MESSAGE = "CLEAR_MESSAGE",
  SET_API_URLS = "SET_API_URLS",
  CLEAR_API_URLS = "CLEAR_API_URLS",
  SET_TEMPLATE = "SET_TEMPLATE",
  SET_DAYS = "SET_DAYS",
  SET_DETAILS = "SET_DETAILS",
  SET_COLLABORATORS = "SET_COLLABORATORS",
  SET_SPLIT = "SET_SPLIT",
  SET_NAME_ON_CARD = "SET_NAME_ON_CARD",
  SET_SUBMIT = "SET_SUBMIT",
}
export default actions;
  