import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
// material
import {
    Grid,
} from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
// app
import {
    updateSession,
    setApiUrls,
    openSnackbar,
    closeSnackbar
} from "../../store/action-creators";
// containers
import AppContainer from "../../containers/App";
import ProfileContainer from "../../containers/Profile";
// models
import { Auth, Session } from "../../entities/auth";
import { UserAddress, emptyUserAddress } from "../../entities/user";
// components
import ActionButton from "../../components/buttons/ActionButton";
import SelectInput, { SelectItem } from '../../components/inputs/SelectInput';
// services
import AuthService from '../../services/auth';
import HomeService from '../../services/home';


const styles: any = {
    gridHeaderContainer: {
        padding: '6px 0 6px 15px',
    },
    nameCol: {
        color: '#6C7C8E',
        fontSize: 16,
        fontFamily: 'Roboto',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginRight: 10,
        padding: '3px 0',
    },
    gridRow: {
        padding: '14px 0px',
        borderBottom: '1px #C9D3E0 solid',
        lineHeight: '40px',
    },
    mailCol: {
        fontSize: 16,
        color: '#6C7C8E',
        fontFamily: 'Roboto',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    dots: {
        fontSize: 35,
        marginLeft: 'auto',
        color: '#c9d3e0',
        marginRight: 6,
        transform: 'rotate(90deg)',
    },
    dotsContainer: {
        textAlign: 'right',
    },
    gridHeader: {
        color: '#6C7C8E',
        fontSize: 14,
        fontFamily: 'Roboto',
    },
    gridContainer: {
        '& > .MuiGrid-grid-xs-12:nth-child(even)': {
            backgroundColor: '#f4f6f9'
        },
        borderBottom: '1px solid #C9D3E0',
    },
    inputArea: {
        width: 130,
        padding: 0,
        fontSize: 20,
    },
    switchLine: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    }
};

const AntSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 44,
            height: 30,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 5,
            color: theme.palette.common.white,
            height: '100%',
            '&$checked': {
                transform: 'translateX(13px)',
                color: theme.palette.common.white,
                '& + $track': {
                    // backgroundColor: theme.palette.common.white,
                    // border: `1px solid ${theme.palette.grey[500]}`,
                    backgroundColor: '#00597C',
                    borderColor: '#00597C',
                },
            },
        },
        thumb: {
            width: 20,
            height: 20,
            boxShadow: 'none',
        },
        track: {
            opacity: 1,
            boxShadow: 'none',
            borderRadius: 16 / 1,
           
            backgroundColor: theme.palette.grey[500],
            border: `1px solid ${theme.palette.grey[500]}`,
        },
        checked: {},
    }),
)(Switch);


interface NotificationsProps {
    history: any;
    auth: Auth;
    session: Session;
    updateSession(session: Session): void;
    openSnackbar(message: string): void;
    closeSnackbar(): void;

}

interface NotificationsPageState {
    loading: boolean;
    mortage: boolean;
    property: boolean;
    cash: boolean;
    cashFlows: boolean;
    dreamRate: boolean;
    propertyValue: number;
    cashValue: number;
    cashFlowsValue: number;
    dreamRateValue: number;
    userAddresses: UserAddress[];
    userAddress: UserAddress;
    userAddressesList: SelectItem[];
    userAddressId: string;
}

class NotificationsPage extends Component<
    NotificationsProps,
    NotificationsPageState
> {
    constructor(props: NotificationsProps) {
        super(props);
        this.state = {
            loading: false,
            mortage: false,
            property: false,
            propertyValue: 0,
            cash: false,
            cashValue: 0,
            cashFlows: false,
            cashFlowsValue: 0,
            dreamRate: false,
            dreamRateValue: 0,
            userAddresses: [],
            userAddress: emptyUserAddress,
            userAddressesList: [],
            userAddressId: ''
        };
    }

    componentDidMount = () => {
        this.fetchUserAddress();
        this.fetchUserSettings();
    };

    fetchUserAddress = async () => {

        try {

            this.setState({ loading: true });
            const addresses = await axios.get(`${AuthService.getApiUrls().user}/user/${this.props.auth.id}/address?type=all`);
            const addressList = HomeService.getUserAddressList(addresses.data);
            this.setState({
                loading: false,
                userAddresses: addresses.data,
                userAddressesList: addressList
            });

        } catch (error) {
            console.log('error', error);
        }

    }

    fetchUserSettings = async () => {

        try {
    
          const userSettingRes: any = await axios.get(`${AuthService.getApiUrls().user}/user/setting/${this.props.auth.id}`);
          if (userSettingRes.data) {
            this.setState({ 
                dreamRateValue: userSettingRes.data.rate,
                dreamRate: userSettingRes.data.rateNotificationActive
            });
          } else {
            await axios.post(`${AuthService.getApiUrls().user}/user/setting`, {
                userId: this.props.auth.id,
                rate: 0,
                rateNotificationActive: false
            });
          }
    
        } catch (error) {
          console.log('error', error);
        }
    
      };

      handleSaveAddressSetting = async () => {

        try {

            this.setState({ loading: false });

            // save action
            const selectedUserAddress = this.state.userAddresses.find(ud => ud.id === this.state.userAddressId);

            selectedUserAddress.removeMortgageInsurance = this.state.mortage;
            selectedUserAddress.propertyIncrease = this.state.propertyValue;
            selectedUserAddress.propertyIncreaseActive = this.state.property;
            selectedUserAddress.propertyCash = this.state.cashValue;
            selectedUserAddress.propertyCashActive = this.state.cash;
            selectedUserAddress.propertyEquity = this.state.cashFlowsValue;
            selectedUserAddress.propertyEquityActive = this.state.cashFlows;

            await axios.put(`${AuthService.getApiUrls().user}/user/address/${this.state.userAddressId}`, selectedUserAddress); 

            this.setState({ loading: false });

            this.props.openSnackbar('Successfully saved addess notification.')

        } catch (error) {
            this.props.openSnackbar('Error saving addess notification.')
        }

    };

    handleSaveSetting = async () => {

        try {

            this.setState({ loading: false });

            await axios.put(`${AuthService.getApiUrls().user}/user/setting/${this.props.auth.id}`, {
                rate: this.state.dreamRateValue,
                rateNotificationActive: this.state.dreamRate
            }); 

            this.setState({ loading: false });

            this.props.openSnackbar('Successfully saved user notification.')

        } catch (error) {
            this.props.openSnackbar('Error saving user notification.')
        }  

    };

    handleStateSetting = (event: any) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    handleNotifyValueChange = (event: any) => {
        this.setState({ ...this.state, [event.target.name]: event.target.value });
    };

    handleUserAddressChange = (event: any) => {
        const userAddressId = event.target.value;
        const selectedUserAddress = this.state.userAddresses.find(ud => ud.id === userAddressId);
        this.setState({ 
            userAddressId: event.target.value,
            mortage: selectedUserAddress.removeMortgageInsurance,
            propertyValue: selectedUserAddress.propertyIncrease,
            property: selectedUserAddress.propertyIncreaseActive,
            cashValue: selectedUserAddress.propertyCash,
            cash: selectedUserAddress.propertyCashActive,
            cashFlowsValue: selectedUserAddress.propertyEquity,
            cashFlows: selectedUserAddress.propertyEquityActive
        });
    }

    render() {
        const {
            loading,
            mortage,
            property,
            cash,
            cashFlows,
            dreamRate,
            propertyValue,
            cashValue,
            cashFlowsValue,
            dreamRateValue
        } = this.state;
        return (
            <AppContainer loading={loading} private maxWidth="lg">
                <ProfileContainer>
                    <Grid container>
                        <Grid alignItems="center" item style={styles.gridRow} container>
                            <Grid xs={6} item>
                                <div style={{ paddingRight: 10 }}>
                                    <SelectInput
                                        title="Your Addresses"
                                        placeholder="Select Address"
                                        value={this.state.userAddressId}
                                        values={this.state.userAddressesList}
                                        onChange={(event: any) => this.handleUserAddressChange(event)}
                                        fullWidth
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid alignItems="center" item style={styles.gridRow} container>
                            <Grid xs={11} item>
                                <div className="title">Address Notifications</div>
                            </Grid>
                            <Grid xs={1} item>
                                <ActionButton
                                    name="Save"
                                    size="sm"
                                    onClick={this.handleSaveAddressSetting}
                                    disabled={this.state.userAddressId ? false : true}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        {this.state.userAddressId &&
                            <React.Fragment>
                                <Grid alignItems="center" item style={styles.gridRow} xs={12} container>
                                    <Grid xs={11} item>
                                        <span style={styles.nameCol}>When I can remove my mortgage insurance</span>
                                    </Grid>
                                    <Grid xs={1} style={styles.switchLine} item>
                                        <span style={styles.nameCol}>{!mortage ? 'Off' : 'On'}</span>
                                        <AntSwitch checked={mortage} onChange={this.handleStateSetting} name="mortage" />
                                    </Grid>
                                </Grid>

                                <Grid alignItems="center" item style={styles.gridRow} xs={12} container>
                                    <Grid xs={11} item>
                                        <span style={styles.nameCol}>When my property value increases by &nbsp;
                                            <TextField
                                                style={styles.inputArea}
                                                label=""
                                                onChange={this.handleNotifyValueChange}
                                                value={propertyValue}
                                                name="propertyValue"
                                                InputProps={{
                                                    style: { height: 40, fontSize: 20 },
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                                variant="outlined"
                                            />
                                        </span>
                                    </Grid>
                                    <Grid xs={1} style={styles.switchLine} item>
                                        <span style={styles.nameCol}>{!property ? 'Off' : 'On'}</span>
                                        <AntSwitch checked={property} onChange={this.handleStateSetting} name="property" />
                                    </Grid>
                                </Grid>

                                <Grid alignItems="center" item style={styles.gridRow} xs={12} container>
                                    <Grid xs={11} item>
                                        <span style={styles.nameCol}>When I can take &nbsp;
                                            <TextField
                                                style={styles.inputArea}
                                                label=""
                                                onChange={this.handleNotifyValueChange}
                                                value={cashValue}
                                                name="cashValue"
                                                InputProps={{
                                                    style: { height: 40, fontSize: 20 },
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                                variant="outlined"
                                            />  &nbsp; out in cash
                                        </span>
                                    </Grid>
                                    <Grid xs={1} style={styles.switchLine} item>
                                        <span style={styles.nameCol}>{!cash ? 'Off' : 'On'}</span>
                                        <AntSwitch checked={cash} onChange={this.handleStateSetting} name="cash" />
                                    </Grid>
                                </Grid>

                                <Grid alignItems="center" item style={styles.gridRow} xs={12} container>
                                    <Grid xs={11} item>
                                        <span style={styles.nameCol}>When I have enough equity to buy a rental property that “cash flows” &nbsp;
                                            <TextField
                                                style={styles.inputArea}
                                                label=""
                                                onChange={this.handleNotifyValueChange}
                                                value={cashFlowsValue}
                                                name="cashFlowsValue"
                                                InputProps={{
                                                    style: { height: 40, fontSize: 20 },
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                                variant="outlined"
                                            /> &nbsp;per month
                                        </span>
                                    </Grid>
                                    <Grid xs={1} style={styles.switchLine} item>
                                        <span style={styles.nameCol}>{!cashFlows ? 'Off' : 'On'}</span>
                                        <AntSwitch checked={cashFlows} onChange={this.handleStateSetting} name="cashFlows" />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                        {!this.state.userAddressId &&
                            <div style={{textAlign: 'center', fontSize: 16, padding: 20, width: '100%'}}>Please select an address</div>
                        }

                        <Grid alignItems="center" item style={styles.gridRow} xs={12} container>
                            <Grid alignItems="center" item style={styles.gridRow} xs={12} container>
                                <Grid xs={11} item>
                                    <div className="title">User Notifications</div>
                                </Grid>
                                <Grid xs={1} item>
                                    <ActionButton
                                        name="Save"
                                        size="sm"
                                        onClick={this.handleSaveSetting}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid xs={11} item>
                                <span style={styles.nameCol}>When my dream rate of &nbsp;
                                    <TextField
                                        style={styles.inputArea}
                                        label=""
                                        onChange={this.handleNotifyValueChange}
                                        value={dreamRateValue}
                                        name="dreamRateValue"
                                        InputProps={{
                                            style: { height: 40, fontSize: 20 },
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                        variant="outlined"
                                    /> &nbsp;is available
                                </span>
                            </Grid>
                            <Grid xs={1} style={styles.switchLine} item>
                                <span style={styles.nameCol}>{!dreamRate ? 'Off' : 'On'}</span>
                                <AntSwitch checked={dreamRate} onChange={this.handleStateSetting} name="dreamRate" />
                            </Grid>
                        </Grid>

                    </Grid>
                </ProfileContainer>
            </AppContainer>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth.auth,
        session: state.session.session
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        { updateSession, setApiUrls, openSnackbar, closeSnackbar },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter<any, any>(NotificationsPage));
