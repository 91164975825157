import actions from './actions';
import { Auth, Session, Signup } from '../entities/auth';
import { Message, MessageTypes, APIUrl } from '../entities/site';

// PAYMENT - move to site section

export function addPayment(payment: number): {type: actions.ADD_PAYMENT, payload: number} {
  return {
    type: actions.ADD_PAYMENT,
    payload: payment
  };
}

// AUTH

export function setSignup(
  username: string, 
  email: string, 
  name: string,
  phone: string,
  isReset: boolean, 
  password?: string,
  user?: any
): {type: actions.SET_SIGNUP, payload: Signup} {
return {
  type: actions.SET_SIGNUP,
  payload: {
    username,
    email,
    name,
    phone,
    isReset,
    password,
    user
  }
};
}

export function clearSignup(): {type: actions.CLEAR_SIGNUP, payload: Signup} {
return {
  type: actions.CLEAR_SIGNUP,
  payload: {} as Signup
};
}

export function addAuth(auth: Auth): {type: actions.ADD_AUTH, payload: Auth} {
  return {
    type: actions.ADD_AUTH,
    payload: auth
  };
}

export function clearAuth(): {type: actions.CLEAR_AUTH, payload: null} {
  return {
    type: actions.CLEAR_AUTH,
    payload: null
  };
}

export function deleteAuth(): {type: actions.DELETE_AUTH, payload: null} {
  return {
    type: actions.DELETE_AUTH,
    payload: null
  };
}

export function addSession(session: Session): {type: actions.ADD_SESSION, payload: Session} {
  return {
    type: actions.ADD_SESSION,
    payload: session
  };
}

export function updateSession(session: Session): {type: actions.UPDATE_SESSION, payload: Session} {
  return {
    type: actions.UPDATE_SESSION,
    payload: session
  };
}

// SITE

export function openSnackbar(
  msg: string
): { type: actions.OPEN_SNACKBAR; payload: string } {
  return {
    type: actions.OPEN_SNACKBAR,
    payload: msg,
  };
}

export function closeSnackbar(): { type: actions.CLOSE_SNACKBAR; payload: boolean } {
  return {
    type: actions.CLOSE_SNACKBAR,
    payload: false,
  };
}

export function setMessage(message: Message): {type: actions.SET_MESSAGE, payload: Message} {
  return {
    type: actions.SET_MESSAGE,
    payload: message
  };
}

export function clearMessage(): {type: actions.CLEAR_MESSAGE, payload: Message} {
  return {
    type: actions.CLEAR_MESSAGE,
    payload: {
      message: '',
      messageType: MessageTypes.Notification
    }
  };
}

export function setApiUrls(urls: APIUrl): {type: actions.SET_API_URLS, payload: APIUrl} {
  return {
    type: actions.SET_API_URLS,
    payload: urls
  };
}

export function clearApiUrls(): {type: actions.CLEAR_API_URLS, payload: APIUrl} {
  return {
    type: actions.CLEAR_API_URLS,
    payload: {
      core: '',
      home: '',
      integrations: '',
      user: '',
      site: '',
      notifications: ''
    }
  };
}