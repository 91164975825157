import React from "react";
// material
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import AddressDetails from "./AddressDetails";
import PaymentDetails from "./PaymentDetails";
// components

interface NameDetailsProps {
  name: string;
  onChange: (name: string, value: string) => void;
}

const NameDetails: React.FC<NameDetailsProps> = (props) => {
  const classes = useStyles({});
  return (
    <div className={classes.nameOnCardBlock}>
      <div className={classes.fieldLabel}>Name on Card</div>
      <TextField
        inputProps={{ className: classes.input }}
        fullWidth
        variant={"outlined"}
        value={props.name}
        onChange={(event) => props.onChange("name", event.target.value)}
      />
    </div>
  );
};
export default NameDetails;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameOnCardBlock: {
      marginBottom: 15,
    },

    fieldLabel: {
      height: 16,
      width: 160,
      color: "#666666",
      fontFamily: "Asap",
      fontSize: 14,
      marginBottom: 4,
    },

    input: {
      height: 3,
    },
  })
);
