import React, { Component } from 'react';
// material
import {createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 100,
      backgroundColor: '#F4F7FC',
      textAlign: 'center',
      color: '#607897',
      fontSize: 16
    }
  })
);

interface EmptyProps {
  message: string;
}

const Empty: React.FC<EmptyProps> = (props) => {

  const classes = useStyles({});

  return (
    <div className={classes.container}>
        {props.message}
    </div>
  )

}
export default Empty;