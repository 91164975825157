import React from "react";
// material
import { Checkbox, Grid, ListItemSecondaryAction } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
// components
import Divider from "../../../divider/Divider";
// entities
import { Collaborator } from "../../../../entities/site";

interface MidBlockProps {
  split: boolean;
  collaborators: Collaborator[];
  total: number;
}

const MidBlock: React.FC<MidBlockProps> = (props) => {
  const classes = useStyles({});

  if (!props.split) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.text}>Collaborators</div>
      <Divider />
      <List>
        {props.collaborators.map((collaborator, index) => (
          <ListItem key={`collab-${index}`}>
            <ListItemAvatar>
              <Avatar>
                <AccountCircleIcon color={"secondary"} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={collaborator} className={classes.text} />
            <ListItemSecondaryAction className={classes.price}>
              $
              {Math.round((props.total * 100) / props.collaborators.length) /
                100}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default MidBlock;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "20px 20px 0",
    },
    text: {
      height: 16,
      width: 84,
      color: "#333333",
      fontFamily: "Asap",
      fontSize: 14,
    },
    price: {
      color: "#333333",
      fontFamily: "Asap",
      fontSize: 14,
      fontWeight: "bold",
    },
  })
);
