import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// material
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
// containers
import AppContainer from '../../containers/App';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 20
        },
        title: {
            fontSize: 24,
            textAlign: 'center'
        }
    })
);

interface PrivacyPolicyProps {
    history: any;
}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = (props) => {

    const classes = useStyles();
    // const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        // load
    });

    return (
        <AppContainer maxWidth="lg">
            <div className={classes.container}>
            <div className={classes.title}>Privacy Policy</div>
                <p>SuCazza, Inc., dba HomeWize is committed to protecting the privacy of your information. This Privacy Statement describes our information practices for our publicly accessible websites, including our use of cookies to collect data about your use of our websites. For information about our privacy policies governing your use of HomeWize account portals and associated platform services (“Services”), please consult our separate HomeWize Services Privacy Policy.</p>
                <h3>1. Websites Covered</h3>
                <p>In this Privacy Statement, “we,” “us,” “our,” or “HomeWize” refers to SuCazza and our affiliates, which includes any person or entity that controls HomeWize controlled by SuCazza, or is under common control with SuCazza (such as a subsidiary, parent company, or employee).</p>
                <p>This Privacy Statement covers SuCazza’s information practices for our family of websites (collectively, “SuCazza, Inc.”). In addition to HomeWize.net, SuCazza, Inc operates other websites like HomeWize.io This Privacy Statement applies equally to any rebranded or white label version of SuCazza.</p>
                
                <h3>2. Information HomeWize Collects from You</h3>
                <p>SuCazza collects information from individuals who visit any of our Sites ("Visitors"), as, well as from individuals who have registered to use our platform services (“Customers”).</p>
                <p>Some information is collected from both Visitors and Customers using commonly-used information-gathering tools. It includes standard information from your Web browser (such as browser type and browser language), your Internet Protocol ("IP") address, and the actions you take on the SuCazza Sites (such as the Web pages viewed and the links clicked). This information is collectively called “Website Navigational Information.”</p>
                <p>SuCazza, Inc., collects your Website Navigational Information using these methods:</p>

                <h4>Cookies</h4>
                <p>SuCazza uses cookies to make your interactions with our Sites easy and meaningful. When you visit one of our Sites, SuCazza’s servers send a cookie to your computer. Standing alone, cookies do not personally identify you; they merely recognize your Web browser. Unless you choose to identify yourself to SuCazza by, for example, responding to a promotional offer, opening an account, or filling out a Web form (like a "Contact Me" Web form), you remain anonymous when visiting our Sites.
                There are two types of cookies: session-based and persistent-based. Session cookies exist only during one session. They disappear from your computer when you close your browser software or turn off your computer. Persistent cookies remain on your computer after you close your browser or turn off your computer.
                If you have chosen to identify yourself to SuCazza, we can uniquely identify you as you navigate our Sites by using session cookies containing encrypted information. For example, each time you request a viewing or quote by providing your contact information or each time you login to your account portal, a session cookie containing an encrypted, unique identifier that is tied to you is placed on your browser. It enables us to process your online requests and to uniquely identify who is logged into the Service. Session cookies are required to use our Services, and we do not recognize do-not-track requests for them.
                SuCazza uses persistent cookies that only we can read and use to identify browsers that have previously visited the SuCazza Sites. When you register for our Services or otherwise provide us with your personal information, a unique identifier associated with a persistent cookie placed on your Web browser is assigned to you. We do not respond to do-not-track requests with respect to persistent cookies.
                We are especially careful about the security and confidentiality of the information stored in persistent cookies. For example, we do not store account numbers or passwords in persistent cookies.
                </p>

                <h4>Third Party Cookies</h4>
                <p>From time-to-time, SuCazza engages third parties to track and analyze non-personally identifiable usage and volume statistical information from Visitors and Customers who visit our Sites. SuCazza also uses other third-party cookies to track the performance of our advertisements.</p>
                
                <h4>Web Beacons</h4>
                <p>SuCazza uses Web beacons alone or in conjunction with cookies to compile information about Customers and Visitors' usage of our Sites and your interactions with emails that we have sent you. Web beacons are clear electronic images that can recognize certain types of information on your computer, such as cookies, when you have viewed a particular website tied to the Web beacon. For example, SuCazza sometimes places Web beacons in marketing emails that notify us when you click on the link in the email that directs you to one of our SuCazza Sites. SuCazza uses Web beacons to operate and improve our Sites and email communications.</p>
                
                <h4>IP Addresses</h4>
                <p>When you visit our Sites, we collect your Internet Protocol ("IP") address to track and aggregate your non-personally identifiable information. For example, we use IP addresses to monitor the regions from which Customers and Visitors navigate our Sites.
                The Website Navigational Information of Visitors and Customers, along with the information covered in our separate SuCazza Services Privacy Policy, are collectively referred to as “User Data.”</p>

                <p>We do not knowingly collect any personal information directly from children under the age of 13.</p>
                 
                <h3>3. How SuCazza Uses the Information It Collects</h3>
                SuCazza uses Website Navigational Information to operate and improve our Sites and Services. Moreover, we use User Data in a number of different ways to improve our Services, develop new features, and to market and promote our Services. We use User Data to perform the services requested. For example, if you fill out a "Contact Me" form on our Sites, we will use the information provided to contact you about your interest in our Services for marketing purposes. For example, we may use information you provide to contact you to further discuss your interest in our Services or to send you information about us and our partners, promotions, and events to connect our Customers with one another. For example, we may use your Customer Data to offer you the opportunity to connect with other Customers who you might know or who might share similar characteristics, such as location or industry. We may also suggest connections between Customers whose calls, messages, broadcasts, or campaigns target similar Contacts based on analyses of Customers’ Contact Data or other anonymized aggregated data.
                to allow Customers to engage, interact, and communicate with one another about their uses of our Services. For example, we may give you the opportunity to notify Customers that you have connected with about your uses of our Services, and you may receive similar notifications from other Customers. These notifications may be initiated by you or by on of the SuCazza family of companies.
                We may use anonymized aggregated data from any source for targeted advertising purposes, including in conjunction with third parties.
                The information provided to third parties does not include your personal information, but this information may be linked to your personal information after we receive it. This Privacy Statement does not cover the use of the cookies by third parties, however, as SuCazza and our family of companies does not control the cookies potentially used by third parties.
                 
                <h3>4. Security</h3>
                <p>SuCazza uses robust security measures to protect User Data from unauthorized access and maintain data accuracy. While SuCazza and our family of companies utilizes electronic and physical security to reduce the risk of improper access to or manipulation of data, we cannot guarantee the security or integrity of the data and shall not be liable for breaches of security or integrity, third-party interception in transit, or any damage caused to your computer or other property by visiting SuCazza Sites.</p>
                
                <h3>5. Public Forums</h3>
                <p>SuCazza may provide bulletin boards, blogs, or chat rooms on our Sites. Any personally identifiable information you choose to submit in such a forum may be read, collected, or used by others who visit these forums, and they may use that information to send you unsolicited messages. We are not responsible for the personally identifiable information you choose to submit in these forums.</p>
                
                <h3>6. Sharing of Information Collected</h3>
                <p>We may share User Data with third-party service providers or others who need access to the data to perform their work on our behalf. For example, we may store User Data on third-party servers. Additionally, SuCazza may share contact information that Visitors and Customers have provided to our agents so that these agents can contact you on our behalf. We do not share, sell, rent, or trade personally identifiable information with third parties for their promotional purposes.</p>
                <p>SuCazza reserves the right to disclose personally identifiable information of our Customers or Visitors if required by law, if we reasonably believe that disclosure is necessary to protect our rights, and/or to comply with a judicial proceeding, court order, or legal process. We also reserve the right to disclose this information if we believe that disclosure is reasonably necessary to protect the public from harm.</p>
                <p>If we go through a corporate sale, merger, reorganization, dissolution, or similar event, the User Data that we gather from you may be part of the assets transferred or shared in connection with the due diligence for any such transaction. Any acquirer or successor of SuCazza may continue to use your data as set forth in this Privacy Statement.</p>
                
                <h3>7. Communications Preferences</h3>
                <p>SuCazza offers Customers and Visitors who provide contact information a way to choose how we communicate with them. You may manage your receipt of marketing and nontransactional communications by clicking here or by clicking on the "unsubscribe" link located on the bottom of our marketing emails. Additionally, you may send a request specifying your communications preferences to request@homewize.net.  Customers cannot opt out of receiving transactional emails related to their account with us or our Services.</p>
                
                <h3>8. Links to Third Party Websites</h3>
                <p>SuCazza and our family of sites may contain links to other Websites. We are not responsible for the information practices or the content of such other Websites. We encourage you to review the privacy statements of other Websites to understand their information practices.</p>
                
                <h3>9. Changes to this Privacy Statement</h3>
                <p>SuCazza reserves the right to change this Privacy Statement. SuCazza will provide notification of the material changes to this Privacy Statement through our Sites at least thirty (30) days prior to the change taking effect.</p>
                
                <h3>10. California Privacy Rights and Customers’ Contact Data</h3>
                <p>The California Consumer Privacy Act (“CCPA”) (Cal. Civ. Code § 1798.100, et seq.) provides California residents with specific rights regarding the use of their personal information. SuCazza and our family of company websites operates as a service provider with respect to Customers’ Contact Data. This means that we only use Customers’ Contact Data for the purpose of providing Services to our Customers. To fulfill these purposes, SuCazza and our family of websites may access Customers’ Contact Data to provide our Services, to address technical or other service problems, or to comply with instructions of our customers who provided the data. We do not access, retain, use, or disclose Customers’ Contact Data for any other purpose.</p>
                <p>For more information, please see (Link) </p>
                
                <h3>Contacting Us</h3>
                <p>Questions regarding this Privacy Statement or the information practices of our Sites should be directed to SuCazza, Inc.,  by emailing info@homewize.net or by mailing HomeWize, 341 Lincoln Street, Roseville CA 95678</p>

            </div>
        </AppContainer>
    );
};

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth.auth
    };
};

export default withRouter<any, any>(
    connect(mapStateToProps, null)(PrivacyPolicy)
);
