import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Grid, Tabs, Tab, Box, Checkbox, Button } from "@material-ui/core";
import Popover from '@material-ui/core/Popover';
import { Typography } from "@material-ui/core";

// app
import {
  updateSession,
  openSnackbar,
  closeSnackbar
} from "../../store/action-creators";
// containers
import AppContainer from "../../containers/App";
import ProfileContainer from "../../containers/Profile";
// components
import TabPanel from "../../components/tabs/TabPanel";
import AssistTable from "./components/AssistTable";
import ActionButton from "../../components/buttons/ActionButton";
import AddLoanAssist from "./components/AddLoanAssist";
import Loading from '../../components/site/Loading';
// entities
import { Auth, Session } from "../../entities/auth";
import { APIUrl } from "../../entities/site";
import { TeamUserRole, TeamUser, emptyTeamUser } from "../../entities/site";
// service 
import AuthService from '../../services/auth';

const styles: any = {
  container: {
    flex: 1,
    height: "100%",
    minHeight: "100%",
    marginBottom: 200,
    marginTop: 40
  },
  pageTitleSub: {
    textAlign: "left",
    fontSize: 18,
    fontWeight: 700,
    marginTop: 20,
    marginBottom: 40
  },
  input: {
    marginTop: 10
  },
  sectionTitle: {
    textAlign: "left",
    fontSize: 14
  },
  mortgageContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: 20,
    border: "1px solid #F1F1F1"
  },
  mortgage: {
    textAlign: "left"
  },
  actionButton: {
    width: 85,
    height: 50,
    color: "#fff",
    backgroundColor: '#00597C',
    top: 20,
  },
  tabContainer: {
    color: '#00597E',
    fontSize: 14,
    fontFamily: 'Roboto',
    textOverflow: 'ellipsis',
    minHeight: 50,
    marginTop: 30,
    borderBottom: '1px #C9D3E0 solid',
  },
  typography: {
    color: '#005B7C',
    fontSize: '14px',
    cursor: 'pointer',
    padding: '10px 50px 5px 10px',
  },
  tabButton: { 
    minWidth: '72px',
    fontSize: '18px',
    textTransform: 'none',
    padding: 0,
    marginRight: 30,
  }

};

interface TeamUserPageProps {
  match?: any;
  auth: Auth;
  history: any;
  session: Session;
  apiUrls: APIUrl;
  openSnackbar(msg: string): void;
  closeSnackbar(): void;
  updateSession(session: Session): void;
}

interface TeamUserPageState {
  loading: boolean;
  loanOfficer: TeamUser;
  loanOfficerUsers: TeamUser[];
  name: string;
  textUpdates: boolean;
  email: string;
  phone: string;
  loantabs: number;
  showAddassistancePopover: boolean;
  assistOptionAncher: any;
  showAddLoanAssist: boolean;
  dataType: string;
  selectedAssist: TeamUser;
}


class TeamUserPage extends Component<TeamUserPageProps, TeamUserPageState> {
  constructor(props: TeamUserPageProps) {
    super(props);
    this.state = {
      loading: false,
      loanOfficer: emptyTeamUser,
      loanOfficerUsers: [],
      name: "",
      email: "",
      phone: "",
      textUpdates: false,
      loantabs: 1,
      showAddassistancePopover: false,
      assistOptionAncher: null,
      showAddLoanAssist: false,
      dataType:"realtor",
      selectedAssist: emptyTeamUser
    };
  }

  componentDidMount = () => {
    this.fetchAll();
  };

  fetchAll = async () => {

    try {

      const userId = this.props.match.params.id;

      this.setState({ loading: true });
      
      // get loan officer
      const teamUserRes = await axios.get(`${AuthService.getApiUrls().site}/team/user/${userId}`);

      // fetch loan officer users
      const teamUsersRes = await axios.get(`${AuthService.getApiUrls().site}/team/user/${userId}/users`);

      this.setState({
        loanOfficer: teamUserRes.data,
        loanOfficerUsers: teamUsersRes.data,
        name: teamUserRes.data.name,
        email: teamUserRes.data.email,
        phone: teamUserRes.data.phone,
        textUpdates: teamUserRes.data.canText,
        loading: false
      });

    } catch (e) {
      console.log(e);
    }

  };
  
  handleUpdateProfile = async () => {

    try {
      
      this.setState({ loading: true });

      const userId = this.props.match.params.id;

      const data = this.state.loanOfficer;
      data.name = this.state.name;
      data.email = this.state.email;
      data.phone = this.state.phone;
      data.canText = this.state.textUpdates;
      
      await axios.put(`${AuthService.getApiUrls().site}/team/user/${userId}`, data);

      this.setState({ loading: false });
      this.props.openSnackbar('Successfully updated loan officer')

    } catch (e) {
      console.log(e);
    }

  };

  handleNameChange = (event: any) => {
    this.setState({ name: event.target.value });
  };

  handleEmailChange = (event: any) => {
    this.setState({ email: event.target.value });
  };

  handleTextUpdatesChange = (event: any) => {
    this.setState({ textUpdates: !this.state.textUpdates });
  };

  handlePhoneChange = (event: any) => {
    this.setState({ phone: event.target.value });
  };

  handleTabChange = (event: any, newValue: number) => {
    this.setState({ loantabs: newValue });
  };

  handleAssistOptionsClick = (event: any) => {
    this.setState({ showAddassistancePopover:true});
    this.setState({ assistOptionAncher:event.currentTarget});
  };

  handleAssistModalOpen = () => {
    this.setState({ showAddLoanAssist: true})
    this.setState({ showAddassistancePopover:false})
  };

  handleAddLoanAssistClose = (reload: boolean) => {
    
    this.setState({ showAddLoanAssist:false})

    if (reload) {
      this.fetchAll();
    }
  }

  handleAssistEdit = (teamUser: TeamUser) => {
    this.setState({ selectedAssist: teamUser, showAddLoanAssist: true})
  }

  render() {
    return (
      <AppContainer loading={this.state.loading} private maxWidth="lg">
        <ProfileContainer>
          <Loading open={this.state.loading} />
          <Grid container>
            <Grid item xs={10}>
              <div className="title">Profile</div>
            </Grid>
            <Grid item xs={1}>
              <ActionButton                
                name="Update"
                onClick={this.handleUpdateProfile}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <div className="sub-title">Personal info</div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <label className="ars-label">Name</label>
                  <input
                    className="ars-input"
                    type="text"
                    value={this.state.name}
                    onChange={this.handleNameChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <label className="ars-label">Email Address</label>
                  <input
                    className="ars-input"
                    type="text"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <label className="ars-label">Phone</label>
                  <input
                    className="ars-input"
                    type="text"
                    value={this.state.phone}
                    onChange={this.handlePhoneChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box display="flex" alignItems="center" ml="-12px">
            <Checkbox
              color="primary"
              checked={this.state.textUpdates}
              onChange={this.handleTextUpdatesChange}
              name="textUpdates"
            />
            Yes, please send me text updates
          </Box>
          <Grid container>
            <Grid item xs={10}>
              <Tabs
                style={styles.tabContainer}
                centered={false}
                indicatorColor="primary"
                value={this.state.loantabs}
                onChange={this.handleTabChange}
              >
                <Tab style={styles.tabButton} label="Asistants" id="assistance" aria-controls='assistance' />
                <Tab style={styles.tabButton} label="Realtor Partners" id="realtor" aria-controls='realtor' />
                <Tab style={styles.tabButton} label="Solar Partners" id="solar" aria-controls='solar' />
              </Tabs>
            </Grid>
            <Grid item xs={2}>
              <Button
                style={styles.actionButton}
                name="Add"
                onClick={this.handleAssistOptionsClick}
              >
                Add
              </Button>
              <Popover
                open={this.state.showAddassistancePopover}
                anchorEl={this.state.assistOptionAncher}
                onClose={() =>  this.setState({ showAddassistancePopover:false})}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Typography
                  onClick={() => {
                    this.setState({dataType:'assistance'});
                    this.handleAssistModalOpen();
                  }}
                  style={styles.typography}>
                    Assistant
                </Typography>
                <Typography 
                  onClick={() => {
                    this.setState({dataType:'realtor'});
                    this.handleAssistModalOpen();
                  }} 
                  style={styles.typography}>
                    Realtor Partner
                </Typography>
                <Typography 
                  onClick={() => {
                    this.setState({dataType:'solar'});
                    this.handleAssistModalOpen();
                  }} 
                  style={styles.typography}
                >
                  Solar Partners
              </Typography>
              </Popover>
            </Grid>
          </Grid>
          <TabPanel value={this.state.loantabs} index={0}>
            <AssistTable type="assistance" users={this.state.loanOfficerUsers} onEdit={this.handleAssistEdit} />
          </TabPanel>
          <TabPanel value={this.state.loantabs} index={1}>
            <AssistTable type="realtor" users={this.state.loanOfficerUsers} onEdit={this.handleAssistEdit} />
          </TabPanel>
          <TabPanel value={this.state.loantabs} index={2}>
            <AssistTable type="solar" users={this.state.loanOfficerUsers} onEdit={this.handleAssistEdit} />
          </TabPanel>
          <AddLoanAssist
              teamUser={this.state.selectedAssist}
              open={this.state.showAddLoanAssist}
              dataType={this.state.dataType}
              onClose={this.handleAddLoanAssistClose}
          />
        </ProfileContainer>
      </AppContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session,
    apiUrls: state.site.apiUrls
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    { updateSession, openSnackbar, closeSnackbar },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(TeamUserPage));
