import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// material
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
// containers
import AppContainer from '../../containers/App';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 20
        },
        title: {
            fontSize: 24,
            textAlign: 'center'
        }
    })
);

interface TermsProps {
    history: any;
}

const Terms: React.FC<TermsProps> = (props) => {

    const classes = useStyles();
    // const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        // load
    });

    return (
        <AppContainer maxWidth="lg">
            <div className={classes.container}>
                <div className={classes.title}>Terms & Conditions</div>
                <p>Welcome to HomeWize! We are so glad that you’re interested in learning about our products. This is our terms of service, which governs all use of the services (including websites) provided by SuCazza, Inc., d/b/a HomeWize (hereinafter referred to as “HomeWize”). Please review it carefully. By accessing or using any of our website or services, you are agreeing to these terms.</p>
                
                <h3>1. Overview</h3>
                <p>
                The Terms of Service (“Terms”) applies to all access to or use of the HomeWize websites, offerings, and services (collectively, “Services”). The Services are described in more detail in Section 3. HomeWize provides you with these Services subject to your acceptance, without any modifications, of the Terms, as well as any other operating rules and policies that HomeWize publishes on its website, such as our privacy policies (collectively, the “Agreement”). By accessing one of our Services, you represent that you have reached the age of majority for where you live or that you have had a parent or legal guardian consent to be bound by these Terms.
                PLEASE REVIEW THIS AGREEMENT CAREFULLY. BY ACCESSING OR USING ONE OF HOMEWIZE’S SERVICES, YOU ACCEPT THE TERMS OF THIS AGREEMENT AND IT BECOMES A LEGALLY BINDING COMMITMENT BETWEEN YOU AND HOMEWIZE. IF YOU DO NOT AGREE WITH ANY PORTION OF THE AGREEMENT OR IF YOU DO NOT INTEND TO USE THE SERVICES IN ACCORDANCE WITH THESE TERMS, YOU MAY NOT ACCESS OR USE ANY HOMEWIZE SERVICE; YOU SHOULD LEAVE THIS WEBSITE IMMEDIATELY BY CLOSING YOUR INTERNET BROWSER WINDOW.
                We reserve the right to change these Terms from time to time, and any revised Terms will supersede prior versions. Any updates to these Terms shall be posted on this website. Unless indicated otherwise, they will be effective on the date listed at the top of the Terms. You acknowledge your responsibility to review our Terms from time to time and to be aware of any such changes. By continuing to use any of the HomeWize Services after we post any such changes, you accept the revised Terms.
                </p>
                
                <h3>2. The Parties</h3>
                <p>2.1. Definitions. In this Agreement, “we,” “us,” or “our” refers to HomeWize. “You,” “your,” and “Customer” refers to you personally, unless you are accessing or using our Services on behalf of an organization that does not have an existing contract with HomeWize that governs your actions. If the latter is the case, you are accepting the Terms for that organization and representing that you have the authority to bind the organization to the Terms; as such, “you,” “your,” and “Customer” will refer to the organization. If the organization already has a separate contract with HomeWize that covers your access and use of our Services, however, the contract will govern, as opposed to these Terms.</p>
                <p>2.2. Affiliates. In this Agreement, an affiliate is an entity or person that controls a party, is controlled by a party, or is under common control with a party (such as a subsidiary, parent company, or employee). Each party to this Agreement will be responsible for the acts and omissions of their affiliates in connection with this Agreement.</p>
                
                <h3>3. The Services</h3>
                <p>When we refer to our Services in the Terms, we are referring to any of our platform services, including our programs, features, websites, account portals, and technical support. In addition to HomeWize.net, SuCazza provides Services through various other websites, including HomeWize.io and MiCazza.com.  The Terms apply equally to any rebranded or white label version of HomeWize’ s platform services.</p>
                 
                <h3>4. Service Availability</h3>
                <p>
                Services described on the HomeWize Sites may or may not be available in all countries or regions of the world, may be available under different trademarks in different countries, and, where applicable, may be approved or cleared by a government regulatory body for sale or use with different indications and restrictions in different countries.
                We transmit messages initiated by you or sent to you by others through different routes, and the level of reliability and support for special features varies according to the route. You are responsible for obtaining and maintaining all computer hardware, software, and communications equipment needed to access the Services and for paying all access charges (e.g., Internet Service Provider, telecommunications) incurred while using the Services.
                HomeWize only provides that the Services are processed correctly and further transmitted by HomeWize to the applicable network. HomeWize is not responsible for the final delivery of any communication initiated through HomeWize’s Services, as this is out of our control and is the responsibility of downstream communications carriers.
                HomeWize transmits and receives text messages and voice broadcasts via other major telecommunications companies and mobile network operators, and thus HomeWize’s influence over the timing of the transmission of your messages and broadcasts is only within the technical constraints imposed upon HomeWize. While HomeWize shall use commercially reasonable efforts to transmit your messages and broadcasts to the applicable network for final delivery to your designated recipients as fast as possible, we cannot commit to, and do not guarantee, a specific maximum delivery time. Such times depend on various network and system-related factors among the various entities involved in the transmission of your messages and broadcasts across the public switched telephone network and/or Internet. You should know that communications carriers assign text messages and voice broadcasts with a default lifetime and any message or broadcast that cannot be delivered successfully within the lifetime assigned to it will be discarded by the communications carrier without any notice. HomeWize is not liable for any loss incurred by the failure of a message or broadcast to be delivered, and you acknowledge that damages for financial or other loss resulting from delivery failure cannot be claimed from HomeWize for any such non-deliveries. Furthermore, you agree that message and broadcast contents are deemed to have zero value.
                </p>

                <h3>5. Our Storage and Use of Your Data</h3>
                <p>5.1. Privacy Policy. By accessing or using our Services, you acknowledge that you have reviewed and agree to the HomeWize Services Privacy Policy and HomeWize Sites Privacy Policy. If you do not accept the terms of these privacy policies, you must stop using our Services immediately.</p>
                <p>5.2. Deletion of Your Data. While our platform hosts some Customer data, the continued hosting and storage of your data is not guaranteed by us. Except as otherwise agreed between us in writing, we may occasionally delete your data, and we will not be liable for any damages, losses, or other consequences that you may incur as a result.</p>
                
                <h3>6. Add-ons</h3>
                <p>We may make additional features and products offered by third parties (“Add-ons”) available on our Services. Your use of Add-ons available through our Services is subject to these Terms.</p>
                
                <h3>7. Your Account</h3>
                <p>
                Before you can use our Services, you must first create an account and provide us with some basic information, such as your full name, telephone number, email address, and account password. All information that you provide about yourself while maintaining a HomeWize account must be accurate, complete, and current.
                You are solely responsible for all use (whether authorized or not) of our Services under your account(s). You are also solely responsible for all access to and use of your account, and for all acts and omissions of anyone that has access to it.
                Keep your password and let us know if it is compromised. If we believe you misuse our service, we can terminate your account and forward your information to law enforcement if appropriate.
                You are responsible for maintaining the confidentiality of your password. You agree to take all reasonable precautions to prevent unauthorized access to or use of our Services, and you will notify us promptly of any unauthorized access or use. Additionally, you agree to notify HomeWize of any known or suspected unauthorized use(s) of your user account, or any known or suspected breach of security, including loss, theft, or unauthorized disclosure of your username or password. We will not be liable for any loss or damage arising from unauthorized use of your account.
                Any suspected fraudulent, abusive, or otherwise illegal activity related to our Services may be grounds for suspension or termination of your user account, in HomeWize sole discretion, and you may be reported to appropriate law-enforcement agencies.
                </p>

                <h3>8. Your Use of Our Services Must Comply with the Law</h3>
                <p>You agree to use the HomeWize Services in accordance with all applicable guidelines, as well as all state and federal laws that the Services are subject to. In order to confirm your compliance with these Terms, so as to ensure that HomeWize is complying with national and international laws, we may, from time to time, periodically check the messages and broadcasts that you send.</p>
                <p>8.1. Representation that Communications with Your Contacts Comply with the Law. You represent and warrant that the owners of the phone numbers you initiate messages or broadcasts to through the HomeWize Services have consented or otherwise opted-in to the receipt of such messages and broadcasts as required by any applicable law or regulation. You agree that you will include clear opt-out/unsubscribe information in your messages or broadcasts when required to do so by any applicable law or regulation, and that you will otherwise adhere to the Consumer Best Practices Guidelines promulgated by the Mobile Marketing Association, if it is applicable to your messages.
                You further agree that any individuals requesting Do-Not-Call (“DNC”) status shall be immediately placed on your DNC accounts list and you further agree that you will not initiate any further messages or broadcasts to any individuals after they request DNC status.</p>
                <p>8.2. Agreement to Learn About and Comply with the Relevant Laws. You agree to familiarize yourself with and abide by all applicable local, state, national, and international laws and regulations pertaining to your use of our Services. You are solely responsible for all acts or omissions that occur under your account, including, without limitation, the content of the messages and broadcasts that you create and initiate through our Services. The Telephone Consumer Protection Act (TCPA), Federal Trade Commission and Federal Communications Commission rules, the National DNC list registry rules, and various state laws, rules, and regulations place restrictions on certain types of phone calls and text messages. Without limiting the foregoing, you agree to familiarize yourself with the legal requirements triggered by any messages, calls, broadcasts, and campaigns transmitted through the HomeWize Services by visiting the following websites:
                <div>Federal Trade Commission, https://www.ftc.gov</div>
                <div>Federal Communications Commission, https://www.fcc.gov/</div>
                <div>National Do Not Call (DNC) Registry, https://www.donotcall.gov</div>
                <div>HomeWize is in no way attempting to interpret any laws, rules, or regulations. This information is provided merely as a courtesy, and it is not intended to replace your responsibility to familiarize yourself with and abide by the legal requirements pertaining to your messages, broadcasts, and campaigns prior to using the HomeWize Sites or Services. You are ultimately responsible to make your own informed decisions regarding your messages, broadcasts, and campaigns.</div>
                </p>
                <p>
                8.3. Prohibited Uses of Our Services. You accept that our Services are provided for professional use only, and you agree that your use of our Services shall not include:
                Sending unsolicited marketing messages or broadcasts (i.e., spam);
                Sending any prohibited calls to life-line services, such as hospitals, fire, police, 911, or utility- related telephone numbers;
                Using strings of numbers, as it is unlawful to engage two or more lines of a multi-line business;
                Harvesting, or otherwise collecting, information about others without their consent;
                Misleading others as to the identity of the sender of your messages or broadcasts by creating a false identity, impersonating the identity of someone/something else, or by providing contact details that do not belong to you;
                Transmitting, associating, or publishing any unlawful, racist, harassing, defamatory, abusive, threatening, demeaning, immoral, harmful, vulgar, obscene, pornographic, or otherwise objectionable material of any kind;
                Transmitting any material that may infringe upon the intellectual property rights of third parties, including trademarks, copyrights, and right of publicity;
                Transmitting any material that contains viruses, trojan horses, worms, time bombs, cancel-bots, or any other harmful or deleterious programs;
                Interfering with, or disrupting, networks connected to the Services or violating the regulations, policies, or procedures of such networks;
                Interfering with, or disrupting, networks connected to the Services or violating the regulations, policies, or procedures of such networks;
                Interfering with another's use and enjoyment of our Services;
                Engaging in any other activity that HomeWize believes could subject it to criminal or civil liability.
                You agree to provide legal contact information in any outbound campaign within any voice broadcast’s initial greeting message. You also agree to provide your own sound files for all outbound voice broadcast campaigns. HomeWize will not be liable for any misuse of the Services by you. HomeWize is not responsible for the views and opinions contained in any of your messages or broadcasts. You further agree that HomeWize is, under no circumstances, responsible for the contents and/or accuracy of your messages or broadcasts and that HomeWize will only transmit them on a basis of good faith that you use the Services in accordance with these Terms.
                </p>
                <p>8.4. No Intentional Calls to Non-RBOC Areas. You agree that you will not purposely route calls to high-cost non-RBOC (Regional Bell Operating Company) areas for origination or termination of telecommunications traffic on the HomeWize system. Calls may be monitored and reported upon each billing cycle to ensure sound traffic patterns. HomeWize will address any excessive routing of high-cost calls by a Customer, and fines may be assessed to recover excess charges from HomeWize VoIP termination companies.</p>
                
                <h3>9. Other Limitations</h3>
                <p>The following is a list of prohibitions and limitations on your use of our Services.</p>
                <p>9.1. Use for Emergency Services Prohibited. You will not attempt to use our Services to access or allow access to Emergency Services.</p>
                <p>9.2. Reverse Engineering Our Software Prohibited. Except as allowed by applicable law, you will not reverse engineer, decompile, disassemble, derive, or otherwise create, attempt to create or derive, or assist anyone else to create or derive the source code of any software provided in connection with our Services.</p>
                <p>9.3. Right to Reclaim Phone Numbers. We reserve the right to reclaim any phone number from your account and to return that number to the relevant numbering plan if you do not send sufficient traffic over that phone number, such that the phone number is unutilized or underutilized, as defined by any local or federal regulatory agency and/or governmental organization with oversight over the relevant phone number and numbering plan. When reclaiming unutilized or underutilized phone numbers, we will notify you at least fourteen (14) days in advance, unless we are prevented from doing so by the relevant carrier regulatory agency or governmental organization.</p>
                <p>We also reserve the right to reclaim phone numbers with no notice to you if your account is suspended for failure to pay or for suspected fraud, as well as if your free trial account is unutilized for more than thirty (30) days.</p>
                <p>9.4. HomeWize Is Customer of Record. You acknowledge that HomeWize is the “customer of record” for all phone numbers provided as part of our Services. As the customer of record, we have certain rights with respect to porting phone numbers. You understand and agree that you may use the phone numbers provided as part of our Services subject to these Terms. Unless otherwise required by law, we reserve the right to refuse to allow you to port away any phone number in our sole discretion.</p>
                
                <h3>10. Content Submission</h3>
                <p>10.1. Confidential or Proprietary Information. You agree that any information or materials that you or individuals acting on your behalf provide to HomeWize (other than information required for your use of the Services as contemplated herein) will not be considered confidential or proprietary. Furthermore, except as otherwise required for your use of the Services as contemplated herein, you should not send any confidential or proprietary information to HomeWize via the HomeWize Sites or the email addresses referenced on the HomeWize Sites.</p>
                <p>10.2. Your Rights in the Information. In connection with such information and materials, you warrant and represent to HomeWize that you have all rights, title, and interests necessary to provide such content to HomeWize and that your provision of the content to HomeWize does not infringe any third party’s proprietary or personal rights, including, but not limited to, any trademark, copyright, patent, or trade secret. By providing any such information or materials to HomeWize (other than information required for your use of the Services as contemplated herein), you grant to HomeWize an unrestricted, irrevocable, worldwide, royalty-free, perpetual license to use, reproduce, display, publicly perform, transmit, make derivative works of, and distribute such information and materials. You further agree that HomeWize is free to use any ideas, concepts, or know-how that you or individuals acting on your behalf provide to HomeWize.</p>
                <p>10.3. Online Forums. HomeWize may at its option provide you with one or more areas within the HomeWize Sites for online discussions (e.g., a message board, wiki, chat room, or blog). Should you choose to participate in such a forum, you agree to not utilize the forum for illegal or inappropriate purposes. HomeWize reserves the right, but will not be obligated, to edit or delete postings to its forums at any time and for any reason. HomeWize shall also have no responsibility or liability for any content created or posted by you or other third parties within any online forum.</p>
                
                <h3>11. Intellectual Property</h3>
                <p>11.1. No Customer Right to HomeWize Intellectual Property. You are permitted to access the HomeWize Services and any content provided by HomeWize (which may include text, images, hosted software, sound files, video, or other content and which may be provided via the HomeWize Sites or otherwise) solely for the purpose of receiving information about HomeWize’s business and products, purchasing and utilizing our Services, communicating with us, entering prize promotions offered by us, or as otherwise stated on the HomeWize Sites. HomeWize hereby grants a worldwide, fully paid-up, perpetual, non-exclusive, non-transferable license to you to use the HomeWize Services solely for that purpose.</p>
                <p>We may have copyrights, trademarks, patents, trade secrets, or other intellectual property rights covering subject matter in the Services, including the web pages that are part of the Services and the HomeWize Sites. Except as expressly provided in these Terms, the availability of the Services and the HomeWize Sites does not give you any license to these patents, trademarks, copyrights, or other intellectual property. All copyrights, trademarks, patents, trade secrets, and other intellectual proprietary rights contained in the HomeWize Services are the sole property of HomeWize or its licensors, each of whom reserves all rights with regard to such materials. You acknowledge and agree that you may not copy, reproduce, retransmit, modify, alter, create any derivative works, reverse engineer, decompile, or disassemble any portion of the Services, including any proprietary communications protocol used by the Services without the express written permission of HomeWize. All other trademarks or trade names are the property of their respective owners, and such material may not be copied, downloaded, redistributed, modified, or otherwise exploited, in whole or in part, without the permission of the owner.</p>
                <p>11.2. Compliance with the Digital Millennium Copyright Act. HomeWize abides by the federal Digital Millennium Copyright Act (DMCA) by responding to notices of alleged infringement that comply with the DMCA and other applicable laws. As part of our response, we may remove or disable access to material residing on a site that is controlled or operated by HomeWize and that is claimed to be infringing, in which case we will make a good-faith attempt to contact the person who submitted the affected material so that they may make a counter notification, also in accordance with the DMCA. HomeWize does not control content hosted on third party websites, and we cannot remove content from sites it does not own or control.
                If you are the copyright owner of content hosted on a third party site, and you have not authorized the use of your content, please contact the administrator of that website directly to have the content removed.
                Before serving either a Notice of Infringing Material or a Counter-Notification, you may wish to contact a lawyer to better understand your rights and obligations under the DMCA and other applicable laws. The following notice requirements are intended to comply with HomeWize’s rights and obligations under the DMCA and, in particular, section 512(c). They do not constitute legal advice.
                </p>
                <p>11.3. Filing a Notice of Infringing Material Under the DMCA. To file a notice of infringing material on a Site owned or controlled by HomeWize, please provide a notification containing the following details:</p>
                <p>1. Reasonably sufficient details to enable us to identify the work claimed to be infringed or, if multiple works are claimed to be infringed, a representative list of such works (for example, the title, author, any registration or tracking number, and URL);</p>
                <p>2. Reasonably sufficient detail to enable us to identify and locate the material that is claimed to be infringing (for example, a link to the page that contains the material);</p>
                <p>3. Your contact information so that we can contact you (for example, your address, telephone number, or email address);</p>
                <p>4. A statement that you have a good faith belief that the use of the material identified in (2) is not authorized by the copyright owner, its agent, or the law;</p>
                <p>5. A statement, under penalty of perjury, that the information in the notification is accurate and that you are authorized to act on behalf of the owner of the exclusive right that is alleged to be infringed.</p>
                <p>6. Your physical or electronic signature.
                Then, email this notice to info@HomeWize.com
                </p>

                <h3>12. Communications About HomeWize Services</h3>
                <p>12.1. Newsletters. With the exception of Services obtained through any email addresses submitted when registering for a new account to use HomeWize Services are automatically added to the HomeWize newsletter. 
                To unsubscribe from any HomeWize newsletter please email newsletter@HomeWize.com from the email address you want unsubscribed and ask to unsubscribe from the newsletter. </p>
                <p>12.2. Communications About Our Services. By creating an account with HomeWize, you agree that HomeWize may contact you via calls, text messages, or email to provide you with information about your account, transactions, and/or the HomeWize Services requested by you.
                To the extent you have separately agreed to receive periodic text or voice alerts about products, services, events, special offers, or other promotional messages related to HomeWize Services, HomeWize may contact you pursuant to the terms of any such separate agreement.</p>
                
                <h3>13. Payments</h3>
                <p>
                Your use of our Services is contingent on your paying for such use (whether paid in advance or through the purchase of credits on the HomeWize Sites), in the amounts and using the methods indicated on the HomeWize Sites. Your payment for the Services, Credits (as defined below in Section 14), keywords, DIDs, usage-sensitive fees, and/or plans (a portion of which also includes software maintenance and upgrades, as well as customer support) shall be deemed completed when HomeWize receives the full amount of payment owed for such Services, Credits, keywords, DIDs, usage-sensitive fees, and/or plans. You are responsible for paying for any message or broadcast you attempt to send to any number, regardless of whether the message is actually received by the intended recipient. As HomeWize is dependent on other entities for the delivery of your messages and broadcasts, our price per credit may require adjustment in order to account for costs that are not in our control. Accordingly, we may adjust our prices from time to time and without prior notice.
                HomeWize will charge your indicated method of payment for the Services, Credits, keywords, DIDs, usage-sensitive fees, and/or plans immediately upon your confirming the payment method and amount. Amounts to be charged may include sales and other taxes, where applicable, and any other amounts indicated on our Sites. HomeWize will assess an additional charge based on the costs HomeWize incurs for porting each DID number assigned to you that you request to be ported within six months of the original assignment by HomeWize, and HomeWize may further restrict your ability to port telephone numbers assigned to you by HomeWize consistent with FCC regulations. Your account will be credited within 24 hours of HomeWize verifying receipt of your payment. If you have elected to make a payment by credit card and we do not receive payment from the credit card issuer, you agree to pay all amounts due immediately upon demand by HomeWize.
                HomeWize cannot readily or accurately ascertain your location when you request to use the Services. You therefore agree that your use of the Services occurs in California and is subject to any applicable California taxes. To the extent that you are responsible for any additional taxes or fees beyond those collected by HomeWize, you agree that you will pay them to any applicable taxing authority when due, including any interest or penalties assessed.
                HomeWize shall not be responsible for any errors or transmission failures with regard to the charging and collection of funds from your indicated payment method, nor for any actions taken by the provider of the payment method you choose (which could include refusal to authorize the charge). In addition to these Terms, any payments made by you may be subject to the agreement between you and the provider of the payment method.
                As between you and HomeWize, you are responsible for all charges related to purchases made using your account and payment method, whether or not you authorized such purchases. Standard charges apply to any test call sent originating from your account or initiated using your API key.
                In the event of accidental under billing in favor of a Customer and a Customer’s subsequent nonpayment, HomeWize shall be entitled to immediately proceed with collection remedies and shall be entitled to recover any and all costs, fees, and expenses of such collection efforts, including but not limited to: collection agencies, court costs, filing and service of process fees, attorneys’ fees incurred from counsel of HomeWize’s choosing, or any other costs, fees, and expenses incurred in the pursuit of collection on all customer accounts and receivables due and payable under these Terms.
                </p>

                <h3>14. Credits</h3>
                <p>14.1. Using Credits. Credits are paperless vouchers that you purchase and which facilitate the transmission of the HomeWize Services selected by you. Both the chosen route and the destination of a message or broadcast impact the amount of credits required for each transmission of our Services. Additionally, the value of credits may change over time as the cost of transmission changes, and thus you acknowledge that the number of credits needed to utilize the Services may change correspondingly. The pricing information, however, will always be made known beforehand through the Pricing Pages on our HomeWize Sites. The submission of a seemingly valid message or broadcast will cause an associated quantity of credits to be debited from your account, and you can obtain the real-time status of your account at any time through the online interface.</p>
                <p>14.2. Expiration of Credits and Prepaid Minutes. Credits purchased as a part of a package or monthly plan (including any additional credits purchased during your monthly billing cycle as part of an upgrade to your plan) and not used within the month of purchase expire at the end of the month, unless the plan explicitly provides for a roll-over of credits. Individual anytime, nonmonthly plan credits purchased through your account automatically expire after 12 months from the date of purchase, unless otherwise stated at the time of purchase. Credits are purchased on a first-bought, first-to-expire basis. Any unused value shall be forfeited upon expiration.
                All prices, per-minute rates, and billing increments for credits are subject to change at any time due to fluctuations in market conditions. You may request a full refund of any unused, nonmonthly plan credits within one (1) business day of purchase. No other refunds will be given.</p>
                <p>14.3. Right to Offer Customized Plans. Notwithstanding the above, we may, in our sole discretion, offer customized plans that provide for individualized terms concerning credit expiration, billing cycles, account maintenance fees, and other agreed-upon terms. We reserve the right to do so without publicly listing the pricing for these customized plans on our Sites.</p>
                
                <h3>15. Fee Disputes</h3>
                <p>You must notify us in writing if you dispute any portion of any fees paid or payable by you under this Agreement. You must provide that written notice to us within ninety (90) days of the applicable charge, and we will work together with you to promptly resolve the applicable dispute. If you do not provide us with this written notice of your fee dispute within this 90-day period, you will not be entitled to dispute any fees paid or payable by you.</p>
                 
                <h3>16. No Warranty; Limitation of Liability</h3>
                <p>
                HomeWize reserves the right to modify, suspend, or discontinue the offering of any of the HomeWize Services at any time and for any reason without prior notice. Further, while HomeWize utilizes electronic and physical security to reduce the risk of improper access to or manipulation of data during transmission and storage, we cannot guarantee the security or integrity of the data and shall not be liable for breaches of security or integrity, third-party interception in transit, or any damage caused to your computer or other property by your use of the HomeWize Services.
                You acknowledge that text messages and voice broadcasts are transmitted unencrypted and that eavesdropping of communications by third parties is possible. HomeWize recommends that you ensure sensitive and valuable information is communicated by a protected and/or encrypted method.
                HomeWize shall use commercially reasonable efforts to make access to the Services available through the required access protocols, but makes no warranty or guarantee that (i) the Services will be available at any particular time; or (ii) you will be able to access the Services at any particular time or from any particular location. HomeWize will not be liable for any act or omission of any other company or companies furnishing a portion of the Services (including, without limitation communications carriers or ISPs); for any act or omission of a third party (including those vendors participating in HomeWize offerings made to you); for equipment that we do not furnish; or for damages that result from the operation of customer-provided systems, equipment, facilities, or services that are interconnected with the Service.
                NOTWITHSTANDING ANYTHING CONTAINED HEREIN TO THE CONTRARY, THE HOMEWIZE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE,” WITHOUT ANY WARRANTIES OR CONDITIONS WHATSOEVER, EXPRESS OR IMPLIED. NOTWITHSTANDING THE FOREGOING OR ANY STATEMENT TO THE CONTRARY CONTAINED IN THESE TERMS, HOMEWIZE DOES NOT WARRANT THAT THE USE OF THE HOMEWIZE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE, INCLUDING, BUT NOT LIMITED, TO ANY INTERRUPTIONS TO THE SERVICES CAUSED BY THE INTENTIONAL AND/OR MALICIOUS ACTS OF THIRD PARTIES (E.G., HACKING), NOR SHALL HOMEWIZE BE RESPONSIBLE FOR ANY DATA LOSS OR LOSS OF ANY INFORMATION IN YOUR ACCOUNT, REGARDLESS OF THE CAUSE. HOMEWIZE ALSO MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE RESULTS THAT MAY BE OBTAINED FROM YOUR USE OF THE SERVICES OR THAT THE HOMEWIZE SERVICES WILL MEET ANY OF YOUR SPECIFIC REQUIREMENTS OTHER THAN AS MAY BE EXPRESSLY SET FORTH IN THESE TERMS.
                HOMEWIZE MAKES NO REPRESENTATIONS OR WARRANTIES AND HEREBY DISCLAIMS ALL OTHER REPRESENTATIONS AND WARRANTIES OF ANY KIND, WHETHER EXPRESS, STATUTORY, OR IMPLIED, ORAL OR WRITTEN, WITH RESPECT TO THE SERVICES, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NONINFRINGEMENT, ACCURACY, RELIABILITY, COMPLETENESS, OR TIMELINESS OF THE MATERIAL, SERVICE, SOFTWARE, TEXT, GRAPHICS OR LINKS, AND ALL WARRANTIES IMPLIED FROM ANY COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE, AND WARRANTIES RELATED TO THIRDPARTY EQUIPMENT, MATERIAL, SERVICES, OR SOFTWARE. TO THE EXTENT THIS DISCLAIMER CONFLICTS WITH APPLICABLE LAW, THE SCOPE AND DURATION OF ANY APPLICABLE WARRANTY WILL BE THE MINIMUM PERMITTED UNDER THAT LAW.
                HOMEWIZE SHALL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY DAMAGES ARISING FROM LOSS OF USE OR LOST BUSINESS, LOSS OF OPPORTUNITY, LOSS OF REVENUE, LOSS OF ACTUAL OR ANTICIPATED PROFITS, LOSS OF DAMAGE TO OR CORRUPTION OF DATA OR LOSS OF GOODWILL), OR FOR ANY COST OF PROCUREMENT OF SUBSTITUTE SERVICES ARISING IN CONNECTION WITH THESE TERMS, WHETHER IN AN ACTION IN CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE, OR OTHER ACTIONS, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF YOUR USE OF THE HOMEWIZE SERVICES RESULTS IN THE NEED FOR SERVICING OR REPLACING OF EQUIPMENT, SOFTWARE, OR DATA, HOMEWIZE IS NOT RESPONSIBLE FOR THOSE COSTS. HOMEWIZE'S TOTAL LIABILITY ARISING OUT OF YOUR USE OF THE HOMEWIZE SERVICES FOR DIRECT DAMAGES SHALL NOT, IN THE AGGREGATE, EXCEED AN AMOUNT EQUAL TO THE MONTHLY TRANSACTION FEE PAID BY YOU TO HOMEWIZE HEREUNDER.
                </p> 

                <h3>17. User Warranties; Indemnification</h3>
                <p>
                You warrant and represent to HomeWize that you have all necessary rights, power, and authority to agree to these Terms and perform your obligations hereunder and that nothing contained in these Terms or in the performance of these obligations will place you in breach of any other contract or obligation. You further warrant and represent that you are and shall at all times remain in full compliance with all applicable laws, rules, and regulations with regard to your use of the HomeWize Services, including, without limitation, the TCPA, the Telemarketing Sales Rule, the Controlling the Assault of Non-Solicited Pornography and Marketing (CAN-SPAM) Act of 2003, and all other laws and regulations concerning privacy, telemarketing, and Internet marketing.
                You agree to indemnify and hold harmless HomeWize and our affiliates, as well as each of their respective officers, directors, shareholders, employees, agents, contractors, representatives, content providers, and service providers, from and against any and all losses, claims, obligations, liabilities, damages, settlements, costs, and expenses (including, but not limited to, consequential damages, incidental damages, special damages, disbursements, and attorneys’ fees, including attorneys’ fees incurred from counsel selected by HomeWize in its sole discretion) arising from or relating to any actual or threatened claim, suit, action, proceeding, third-party discovery demand, governmental investigation, or enforcement action based upon or arising out of your activities under these Terms or your acts or omissions in connection with any use by you, an account belonging to you, or a computer under your control, of the HomeWize Services. You acknowledge and agree to be held liable for any and all damages caused to HomeWize by you as a direct result of any violation of local, state, national, or international laws or regulations, including, but not limited to, those damages arising from your fraudulent, intentional, or unintentional harm, disability, unauthorized use of, or destruction to any and all equipment, licensing, and/or services provided by HomeWize to you.
                You agree to cooperate as fully as reasonably required in the defense of any claim, suit, action, proceeding, third-party discovery demand, governmental investigation, or enforcement action.
                We reserve the right, at your expense, to assume the exclusive defense and control of any matter in which you are a named party and in which we would otherwise subject to indemnification by you. You will also be liable to us for any costs and attorneys’ fees we incur to successfully establish or enforce our right to indemnification under this Section.
                </p>

                <h3>18. Termination, Cancellation, and/or Suspension by HomeWize</h3>
                <p>
                If you breach these Terms at any time, we may elect to suspend, terminate, and/or cancel your use of the Services and/or recover any damages from you arising from the event(s) giving rise to the suspension, termination, or cancellation. We reserve the right to suspend the Services at any time and for any reason we may deem necessary to continue to provide our Services in a way that may be hindered by your status as one of our clients, your financial status, or the content of the messages or broadcasts originating from you.
                Upon any such termination, cancellation, and/or suspension, you are still responsible for any obligations then accrued. Your obligation to pay all amounts accrued and owed by you shall continue even after any suspension or cancellation of your access to the Services (in whole or in part). Upon termination, for any reason, you agree to immediately cease using the Services and HomeWize shall have no obligation to you after any termination or cancellation of these Terms. Should such a termination take place when you still have credits in your account, you shall receive back, at our discretion, a fair monetary value of such credits, save any expenses that may be incurred by HomeWize, including, without limitation, the payment of transfer duties, legal costs, third-party costs, and penalties.
                The provisions regarding ownership, payments, warranties, and indemnifications will survive any suspension, termination, or cancellation of your use of our Services.
                </p>
                 
                <h3>19. Termination by You</h3>
                <p>You are free to terminate or cancel your use of the Services at any time, and for any reason. You can cancel your use of the Services by logging into your account, clicking on your profile dropdown menu, selecting the Billing option, and clicking the Cancel My Account text link. Notwithstanding the foregoing, unless due to a breach solely by HomeWize that it fails to cure within thirty (30) days of its receipt of your notice, in no event shall HomeWize be obligated to refund to you the reasonable value of any unused campaings previously purchased by you.</p>
                
                <h3>20. Links to Other Websites</h3>
                <p> 
                The HomeWize Sites may contain links to third-party websites. These links are provided solely as a convenience to you and not as an endorsement by HomeWize of the contents on such third-party websites. HomeWize is not responsible for the content of linked third-party websites and does not make any representations regarding the content or accuracy of materials on such third-party websites. If you decide to access linked third-party websites, you do so at your own risk.
                HomeWize cannot ensure that you will be satisfied with any products or services that you purchase from a third-party website that links to or from the HomeWize Sites, since these websites are owned and operated by independent third parties. HomeWize does not endorse any of the products/services, and HomeWize has not taken any steps to confirm the accuracy or reliability of any of the information contained on such third party websites.
                Furthermore, HomeWize does not make any representations or warranties as to the strength of the security practices for storing any information (including, without limitation, credit card and other personal information) you might be requested to give any third party, and you hereby irrevocably waive any claim against us with respect to such websites. HomeWize strongly encourages you to make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties.
                HomeWize consents to your use of links to the HomeWize Sites that conform to the following requirements. The appearance, position, and other aspects of any link to the HomeWize Sites may neither create the false appearance that an entity or its activities or products are associated with or sponsored by HomeWize nor be such as to damage or dilute the goodwill associated with the name and trademarks of HomeWize or its affiliates. Moreover, HomeWize reserves the right to revoke this consent to link at any time in its sole discretion, without notice.
                </p>

                <h3>21. Security Rules</h3>
                <p>
                You are prohibited from violating or attempting to violate the security of the HomeWize Services and from using them to violate the security of other websites by any method, including, without limitation: (a) accessing data not intended for you or logging into a server or account that you are not authorized to access; (b) attempting to probe, scan, or test the vulnerability of a system or network or to breach security or authentication measurers without proper authorization; (c) attempting to interfere with service to any user of the HomeWize Services, host, or network, including, without limitation, by means of submitting a virus to, overloading, “flooding,” “spamming,” “mailbombing,” or “crashing” the HomeWize Sites; (d) sending unsolicited e-mail, including promotions and/or advertisements of products or services; or (e) forging any HomeWize packet header or any part of the header information in any e-mail, instant message, text message, or newsgroup posting.
                Violations of system or network security may result in civil or criminal liability. We may investigate violations of these Terms, and may involve and cooperate with law enforcement authorities in prosecuting HomeWize Customers who are involved in such violations.
                </p>

                <h3>22. Force Majeure</h3>
                <p>HomeWize shall not be liable for any failure or delay in performing our obligations hereunder, if such failure or delay is caused by fire, flood, earthquake, elements of nature or acts of God, acts of war, insurrection, terrorism, strike, failure or downtime of any telecommunications line and/or unavailability of any telecommunications or Internet facilities, power failure, governmental restrictions, any court order, compliance with any law, regulation, order of any governmental authority, or any other cause beyond the reasonable control of HomeWize. In addition, HomeWize shall be so excused in the event that it is unable to acquire from its usual sources, and on terms it deems to be reasonable, any material necessary for the performance of our Services.</p>
                
                <h3>23. Choice of Law</h3>
                <p>The enforceability and interpretation of Section 24 (Agreement to Arbitrate) will be determined in accordance with the Federal Arbitration Act, 9. U.S.C. §§ 1-16, as amended (the “Federal Arbitration Act”), including its procedural provisions. Apart from Section 24 (Agreement to Arbitrate), the Terms shall be governed by and construed in accordance with the substantive law of the State of Texas (subject to any applicable preemption or supersedence by U.S. federal substantive law) without regard to conflict of law principles that would cause laws of another jurisdiction to apply.</p>
                
                <h3>24. Agreement to Arbitrate</h3>
                <p>Most disputes can be resolved by contacting our Customer Support team. We strongly encourage you to reach out to them before you decide to bring a formal legal case.</p>            
                <p>24.1. We Both Agree to Arbitrate. If we are not able to successfully work together to resolve the dispute, you (and any of your affiliates) and HomeWize (and any of our affiliates) all agree to resolve any dispute arising under these Terms (including our privacy policies) or any dispute relating to our Services by binding arbitration in Austin, Texas.
                This agreement to arbitrate applies to all claims under any legal theory, including any claim that requires the interpretation of this document, except for the exception below in subsection 24.2 (Exception to Agreement to Arbitrate). This agreement also continues to apply after you have stopped using our Services. If we have a dispute about whether this agreement to arbitrate can be enforced or whether it applies to the dispute, we all agree that the arbitrator will decide that as well.
                In addition, if any of us brings a claim in court that should be arbitrated or if any of us refuses to arbitrate a claim that should be arbitrated, the other of us can ask a court to force us to go to arbitration to resolve the claim (i.e., compel arbitration). Any of us can also ask a court to halt a court proceeding while an arbitration proceeding is ongoing.</p>
                <p>24.2. Exception to Agreement to Arbitrate. Anything in the foregoing paragraphs to the contrary notwithstanding, HomeWize may seek injunctive relief in any court having jurisdiction over the parties to enjoin or prevent any action you take or threaten to take in violation of these Terms. Moreover, you consent to the personal jurisdiction of these courts.</p>
                <p>24.3. Arbitration Procedure. The Commercial Arbitration Rules of the American Arbitration Association (“Rules”) then in effect shall apply to the arbitration, except to the extent that they conflict with any express provisions in this Section 24 (Agreement to Arbitrate).
                A single independent arbitrator shall conduct the arbitration. The parties shall endeavor to select the independent arbitrator by mutual agreement. The arbitrator shall be a member of a state bar engaged in the practice of law in the United States or a retired member of a state or the federal judiciary in the United States. If the dispute is one to be decided by arbitration and such agreement cannot be reached within thirty (30) days after the dispute first arose, the selection of the arbitrator shall be made in accordance with the Rules as then in effect.
                Each party shall be entitled to inspect and obtain a copy of non-privileged relevant documents in the possession or control of the other party. All such discovery shall be in accordance with procedures approved by the arbitrator. Unless otherwise provided in the award, each party shall bear its own costs of discovery.
                The award of the arbitrator shall be based on the evidence admitted and shall contain an award for each issue and counterclaim. The award shall be made within thirty (30) days following the close of the final hearing and the filing of any post-hearing briefs authorized by the arbitrator. It shall set forth in writing the factual findings and legal reasoning for the award. The arbitrator may, in his/her discretion, award to any party specific performance or injunctive relief (but the foregoing is not intended to limit HomeWize’s access to the courts to the extent provided in subsection 24.2). The arbitrator may not change, modify, or alter any express condition, term, or provision of these Terms.
                Except as provided in the Federal Arbitration Act, the arbitration award will be final and binding upon the parties, and no appeal of any kind may be taken. Judgment may be entered thereon in any court having jurisdiction thereof. The statute of limitations applicable under Texas law to the commencement of a lawsuit shall apply to the commencement of arbitration hereunder.</p>
                <p>24.4. Recovery of Costs and Attorney’s Fees. In any action or proceeding to enforce rights under these Terms, HomeWize and any of its affiliates will be entitled to recover costs and attorneys' fees if we substantially prevail.</p>
                <p>24.5. Class Action Waiver. Both you (and your affiliates) and HomeWize (and our affiliates) agree that any claims or controversies between us must be brought against each other only on an individual basis. Neither you (and your affiliates) nor HomeWize (and our affiliates) can bring a claim as a plaintiff or class member in a class action, consolidated action, or representative action. The arbitrator cannot combine more than one person or entity’s claims into a single case and cannot preside over any class, consolidated, or representative proceeding (unless we agree otherwise). Moreover, the arbitrator’s decision or award in one person or entity’s case can only impact the person or entity that brought the claim, not other HomeWize customers; it cannot be used to decide other disputes with other customers.
                If a court decides that this subsection 24.5 (Class Action Waiver) is unenforceable or invalid, then the entire Section 24 (Agreement to Arbitrate) will be null and void. But the rest of the Terms will still apply.</p>
                
                <h3>25. Entire Agreement</h3>
                <p>The Terms are only those stated herein, and they shall constitute the complete agreement between the parties. These Terms supersede all prior and contemporaneous proposals, statements, sales materials, presentations, and agreements, whether oral or written. No terms or conditions stated in or attached to your communications with HomeWize are applicable to these Terms in any way, and they are not to be considered exceptions to the provisions of these Terms.</p>
                
                <h3>26. Severability</h3>
                <p>If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms will otherwise remain in full force and effect and enforceable.</p>
                
                <h3>27. No Waiver</h3>
                <p>The failure of either party to exercise in any respect any right provided for herein will not be deemed a waiver of any further rights hereunder. Any waiver must be in writing and signed by both parties to be legally binding.</p>
                
                <h3>28. Relationship of the Parties</h3>
                <p>
                You and HomeWize are each independent contractors in the performance of each and every part of these Terms. No agency, partnership, joint venture, franchise, or employer-employee relationship is created as a result of these Terms. You will be solely responsible for all of your employees, agents, and your labor costs and expenses arising in connection them; HomeWize will be solely responsible for all of our employees, agents, and our labor costs and expenses arising in connection with them. Likewise, you will be solely responsible for any and all claims, liabilities, damages, or debts of any type that arise on account of your activities or those of your employees or agents in the performance of these Terms; HomeWize will be solely responsible for any and all claims, liabilities, damages, or debts of any type that may arise on account of our own activities or those of our employees or agents in the performance of these Terms.
                You do not have any authority of any kind to bind HomeWize in any respect whatsoever, and you shall not attempt to do so or imply that you have the right to do so.
                </p>

                <h3>29. Notice</h3>
                <p>
                All notices that you are required to make hereunder shall be in writing. They will be deemed to have been duly given when received, if personally delivered; when receipt is electronically confirmed, if transmitted by facsimile to (888) 287-8851 or e-mail at notice@HomeWize.com; on the day after it is sent, if sent for next-day delivery by a recognized overnight delivery service; and upon receipt, if sent by certified or registered mail, with return receipt requested.
                HomeWize may give notice to you by means of a general notice on the HomeWize Sites or Services, electronic mail to your e-mail address on record in HomeWize’s account information for you, or by written communication sent by personal delivery, fax, overnight courier, or certified or registered mail to your address on record in HomeWize's account information for you.
                </p>
                
                <h3>30. California Consumer Privacy Act of 2018</h3>
                <p>
                As part of our Services, HomeWize hosts and processes data, including personal information, about the consumers you wish to communicate with by using our platform.
                You and HomeWize acknowledge and agree that HomeWize is a “service provider” as defined by the California Consumer Privacy Act of 2018 (“CCPA”) (Cal. Civ. Code § 1798.100 et seq.). This means that HomeWize processes personal information on your behalf when you disclose to us the personal information of your consumers (“Customers’ Contact Data”) in order to use our Services.
                When we process Customers’ Contact Data on your behalf, we agree that we will not:
                </p>
                <p>1. Retain, use, or disclose Customers’ Contact Data we process in connection with the Services for any purpose other than for performing the Services in this Agreement and your instructions;</p>
                <p>2. Use or process Customers’ Contact Data for commercial purposes or direct marketing;</p>
                <p>3. Sell or promote the sale of Customers’ Contact Data; and</p>
                <p>4. Disclose or transfer Customers’ Contact Data to unauthorized personnel or parties, or outside the direct business relationship between you and HomeWize.</p>

            </div>
        </AppContainer>
    );
};

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth.auth
    };
};

export default withRouter<any, any>(
    connect(mapStateToProps, null)(Terms)
);
