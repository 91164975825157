import React, { Component } from 'react';
import numeral from "numeral";
import { Theme, withStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress, Paper, Typography } from '@material-ui/core';
import { withRouter } from "react-router-dom";

const styles: any = (theme: Theme) => ({
  paper: {
    padding: "25px",
    boxShadow: "-1px 3px 6px 2px rgba(0,0,0,0.2);"

  },
  image: {
    width: 80,
    height: 80,
    // marginRight: "20px",
    borderRadius: "40px",
    backgroundColor: "#BFEAF1",
  },
  img: {
    margin: 'auto',
    display: 'block',
    marginTop: "20px",
    fontSize: "40px"
  },
  numbervalue: {
    color: "#607897",
    fontSize: "24px",
    fontWeight: 500

  },
  root: {
    flexGrow: 1,
  },
  cardtxt: {
    margin: "5px auto 0px"
  },
  itemname: {
    color: "#627995",
    fontSize: "18px"
  },
  cardicon: {
    border: "2px solid #00B878",
    borderRadius: "25px",
    padding: "5px 10px",
    fontWeight: 600,
    backgroundColor: "#00B878",
    color: "white",
    position: 'absolute',
    right: 0,
    top: 0,
  },
  cardtxtdiv: {
    padding: "7px",
    marginLeft: "15px",
    [theme.breakpoints.between('sm', 'lg')]: {
      marginLeft: "30px",
    }
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10,
    width: '100%'
  }
})

export interface Card {
  imgsrc?: string;
  name?: string;
  value?: number;
  cardicon?: string;
  path?: string;
  avatarIcon?: any;
  avatarIconColor?: string;
}

interface CardProps {
  cards: Card[];
  history: any;
}

const Table = ({ item, classes, history }: any) => {
  const handleCampaignCardClick = () => {
    history.push(item.path)
  }
  return <Grid item xs={12} sm={6} lg={3} md={4} style={{ position: "relative" }}
    onClick={handleCampaignCardClick}>
    {item.cardicon && <div className={classes.cardicon}>
      {item.cardicon} New
    </div>}
    <Paper className={classes.paper}>
      <div style={{ display: "flex" }}>
        <div className={classes.image}>
          {<item.avatarIcon style={{ color: item.avatarIconColor }} className={classes.img} />}
        </div>
        <div className={classes.cardtxtdiv}>
          <Typography gutterBottom className={classes.itemname}>
            {item.name}
          </Typography>
          <Typography className={classes.numbervalue}>
            {numeral(item.value).format("0,0")}
          </Typography>
        </div>
      </div>
    </Paper>
  </Grid>
}

class CampaignCards extends Component<CardProps, {}> {
  constructor(props: any) {
    super(props);
  }

  handleCampaignCardClick = (path: any) => {
    this.props.history.push(path);
  }

  render() {
    const { classes }: any = this.props;
    return (
      <Grid container spacing={3} className={classes.cardcontainer}>
        { this.props.cards.map((card, index) => (
          <Table key={`stat-${index}`} {...this.props} item={card} />
        ))}
        { !this.props.cards.length &&
          <div className={classes.progressContainer}>
            <CircularProgress />
          </div>
        }
      </Grid>
    )
  }
}

export default withRouter<any, any>(withStyles(styles)(CampaignCards));