import React from 'react';
import numeral from 'numeral';

const styles: any = {
  container: {

  },
  legendContainer: {
    position: 'relative'
  }
}

interface BarChartProps {
  percent: number;
  height: number;
}

const BarChart: React.FC<BarChartProps> = (props) => {
  const percentCal = props.percent * 100;
  return (
    <div style={styles.container}>
      <div style={{
        display: 'flex',
        width: '100%',
        height: props.height,
        backgroundColor: '#F1F1F1'
      }}>
        <div style={{
            height: props.height, 
            width: `${percentCal}%`, 
            backgroundColor: '#02B6D8',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10
          }}
        />
      </div>
      <div style={styles.legendContainer}>
        <div style={{position: 'absolute', borderLeft: '1px solid #ddd', left: 0, fontSize: 10, paddingLeft: 3}}>0%</div>
        <div style={{position: 'absolute', borderLeft: '1px solid #ddd', left: `${percentCal}%`, fontSize: 10, paddingLeft: 3}}>{numeral(props.percent).format('0%')}</div>
        <div style={{position: 'absolute', borderRight: '1px solid #ddd', right: 0, fontSize: 10, paddingRight: 3}}>100%</div>
      </div>
    </div>
  )
}
export default BarChart;