import React from 'react';
import axios from 'axios';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import moment from 'moment';
import { isEmpty } from 'lodash';
// import queryString from 'query-string';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Amplify from '@aws-amplify/core';
import AuthService from './services/auth';
import ProtectedRoute from './components/auth/ProtectedRoute';

import './App.css';
import store from './store/';
import routes from './config/routes';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#00ADCB' },
    secondary: { main: '#D0D0D0' },
  },
  overrides: {
    MuiListItemText: { }
  }

});

Amplify.configure({
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_Isb1niR4R',
    userPoolWebClientId: '5rpkghpe01lpaifpl93noc6u0b'
  }
});
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

axios.interceptors.request.use(async (config) => {

  const reduxStore: any = store.getState();
  const currentTime = moment().unix();
  const auth = (!isEmpty(reduxStore.auth.auth) ? reduxStore.auth.auth : null);
  const exp = (auth && auth.payload ? auth.payload.exp : null);

  let token = null;
  if (!isEmpty(auth) && ((exp - currentTime) < 0)) {

    console.log('refreshing token...')

    const newSession = await AuthService.refreshToken()
    const accessToken = newSession.getAccessToken();
    token = accessToken.getJwtToken();
    auth.token = token;
    auth.payload = accessToken.payload;

    store.dispatch({
      type: 'ADD_AUTH',
      payload: auth
    });

  }
  if (!isEmpty(auth) && auth.token) {
    token = auth.token;
  }

  if (token) {
    config.headers = { 'Authorization': token };
  } else {
    config.headers = { 'Authorization': `API ${process.env.API_TOKEN}` };
  }

  return config;
  
}, (error: any) => {
  console.log('error', error);
  // Do something with request error
  return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  console.log('network error', error);
  // window.location.href = 'http://localhost:3000/auth/logout';
  // Do something with request error
  return Promise.reject(error);
});

class App extends React.Component {

  // componentDidMount = () => {
  //   const qs = queryString.parse(location.search);
  //   if (qs.ref) {
  //     // add to session
  //     const state = store.getState();
  //     console.log('state', state);
  //   }
  // }

  handleStoreCallback = () => {
    // set defaults in store
    // store.dispatch({
    //   type: 'SET_API_URLS',
    //   payload: AuthService.getApiUrls()
    // })
  };

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistStore(store, null, this.handleStoreCallback)}>
          <MuiThemeProvider theme={theme}>
            <Router>
              <div className="App">
                <main>
                  <Switch>
                    {routes.private.map((route: any) =>
                      <ProtectedRoute 
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        roles={route.roles}
                        component={route.component}
                      />
                    )}
                    {routes.public.map((route: any) =>
                      <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={route.component} />
                    )}
                  </Switch>
                </main>
              </div>
            </Router>
          </MuiThemeProvider>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
