import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios';
import { filter } from 'lodash';

import { Grid } from '@material-ui/core';
// app
import { Auth as AuthEntity } from '../../../entities/auth';
import { Message, MessageTypes, APIUrl } from '../../../entities/site';
import { openSnackbar, closeSnackbar } from '../../../store/action-creators';
// containers
import RowContainer from '../../../containers/Row';
import Modal from '../../../containers/Modal';
// components
import TextInput from '../../../components/inputs/TextInput';
import ActionButton from '../../../components/buttons/ActionButton';
import Loading from '../../../components/site/Loading';
import SelectInput, { SelectItem } from '../../../components/inputs/SelectInput';
// services
import AuthService from '../../../services/auth';
// entities
import { Team } from '../../../entities/site';

interface AddTeamProps {
  match?: any;
  open: any;
  teams: Team[];
  team: Team;
  auth: AuthEntity;
  onClose(msg?: string): void
  openSnackbar(message: string): void;
  closeSnackbar(): void;
}

interface AddTeamState {
  loading: boolean;
  organization: string;
  name: string;
  description: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  message: Message;
  disabled: boolean;
  teams: SelectItem[];
}

class AddTeam extends Component<AddTeamProps, AddTeamState> {

  constructor(props: AddTeamProps) {
    super(props);
    this.state = {
      loading: false,
      organization: '',
      name: '',
      description: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      message: {
        message: '',
        messageType: MessageTypes.Notification
      },
      disabled: false,
      teams: []
    }
  }

  componentDidMount = () => {
    this.fetchOrganizations();
  }

  componentDidUpdate = (prevProps: AddTeamProps, prevState: AddTeamState) => {
    if (prevProps.open !== this.props.open) {
      this.fetchOrganizations();
    }
    if (this.props.team && prevProps.team.id !== this.props.team.id) {
      this.fetchTeam();
    }
  }

  fetchOrganizations = async () => {
    const teamsRes = await axios.get(`${AuthService.getApiUrls().site}/teams/user/${this.props.auth.id}`);
    const organizations = filter(teamsRes.data, { type: "organization" }) as Team[];
    const orgList: SelectItem[] = [];
    organizations.forEach((org) => {
      orgList.push({
        name: org.name,
        value: org.id
      })
    });
    this.setState({
      teams: orgList
    });
  }

  fetchTeam = async () => {
    // 
    const teamRes = await axios.get(`${AuthService.getApiUrls().site}/team/${this.props.team.id}`);
    const team: Team = teamRes.data;
    this.setState({
      organization: team.parentId,
      name: team.name,
      description: team.description,
      address: team.address,
      city: team.city,
      state: team.state,
      zipCode: team.zipCode
    });

  }

  onOrganizationChange = (event: any) => {
    this.setState({ organization: event.target.value });
  }

  onNameChange = (event: any) => {
    this.setState({ name: event.target.value });
  }

  onDescriptionChange = (event: any) => {
    this.setState({ description: event.target.value });
  }

  onAddressChange = (event: any) => {
    this.setState({ address: event.target.value });
  }

  onCityChange = (event: any) => {
    this.setState({ city: event.target.value });
  }

  onStateChange = (event: any) => {
    this.setState({ state: event.target.value });
  }

  onZipCodeChange = (event: any) => {
    this.setState({ zipCode: event.target.value });
  }

  handleSave = async () => {
    // sign up
    try {
      if (!this.checkForm()) {
        return;
      }

      this.setState({
        loading: true,
        disabled: true
      });

      if (this.props.team && this.props.team.id) {

        const updateTeam = this.props.team;
        updateTeam.name = this.state.name;
        updateTeam.description = this.state.description;
        updateTeam.address = this.state.address;
        updateTeam.city = this.state.city;
        updateTeam.state = this.state.state;
        updateTeam.zipCode = this.state.zipCode;
        updateTeam.parentId = this.state.organization;

        await axios.put(`${AuthService.getApiUrls().site}/team/${updateTeam.id}`, updateTeam);

        this.setState({ disabled: false, loading: false });
        this.handleClose('Successfully updated team');

      } else {

        const dataTeam = {
          name: this.state.name,
          description: this.state.description,
          address: this.state.address,
          city: this.state.city,
          state: this.state.state,
          zipCode: this.state.zipCode,
          parentId: this.state.organization,
          type: 'branch'
        };

        const teamRes = await axios.post(`${AuthService.getApiUrls().site}/team`, dataTeam);

        const dataTeamUser = {
          teamId: teamRes.data.id,
          userId: this.props.auth.id,
          teamName: dataTeam.name,
          teamType: dataTeam.type,
          name: this.props.auth.user ? this.props.auth.user.name : '',
          email: this.props.auth.user ? this.props.auth.user.email : '',
          phone: this.props.auth.user ? this.props.auth.user.phone : '',
          canText: true,
          role: 'creator'
        };

        // save new team user
        await axios.post(`${AuthService.getApiUrls().site}/team/user`, dataTeamUser);

        this.setState({ disabled: false, loading: false });
        this.props.openSnackbar('Successfully saved team')
        this.handleClose('Successfully saved team');

      }

    } catch (error) {
      this.setState({ disabled: false, loading: false });
      this.setState({ message: { message: 'Error saving team', messageType: MessageTypes.Error } });
    }
  }

  resetForm = () => {
    this.setState({
      disabled: false,
      loading: false,
      name: '',
      description: '',
      address: '',
      city: '',
      state: '',
      zipCode: ''
    });
  }

  checkForm = () => {
    let error = '';
    if (!this.state.organization) {
      error = 'Organization required';
    }
    if (!this.state.name) {
      error = 'Name required';
    }
    if (!this.state.address) {
      error = 'Address required';
    }
    if (!this.state.city) {
      error = 'City required';
    }
    if (!this.state.state) {
      error = 'State required';
    }
    if (!this.state.zipCode) {
      error = 'ZipCode required';
    }
    if (!error) {
      this.setState({ message: { message: error, messageType: MessageTypes.Error } });
      return true;
    }
    return false;
  }

  handleClose = (msg?: string) => {
    this.resetForm();
    this.props.onClose(msg);
  }

  render() {

    return (
      <Modal
        open={this.props.open}
        title="Add Branch"
        onClose={this.handleClose}
        message={this.state.message}
      >
        <div>
          <Loading open={this.state.loading} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectInput
                title='Organization'
                value={this.state.organization}
                onChange={this.onOrganizationChange}
                values={this.state.teams} />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                title="Name"
                type="string"
                placeholder=""
                result={this.state.name}
                onChange={this.onNameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                title="Address"
                type="string"
                placeholder=""
                result={this.state.address}
                onChange={this.onAddressChange}
              />
            </Grid>
            <Grid item xs={5}>
              <TextInput
                title="City"
                type="string"
                placeholder=""
                result={this.state.city}
                onChange={this.onCityChange}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                title="State"
                type="string"
                placeholder=""
                result={this.state.state}
                onChange={this.onStateChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                title="ZIP Code"
                type="string"
                placeholder=""
                result={this.state.zipCode}
                onChange={this.onZipCodeChange}
              />
            </Grid>
          </Grid>
          <div style={{
            marginTop: 30
          }}>
            <RowContainer>
              <Grid container spacing={1} justify="flex-end">
                <Grid item xs={3} >
                  <ActionButton name="Cancel" onClick={this.handleClose} fullWidth size="sm" disabled={this.state.disabled} type="secondary" />
                </Grid>
                <Grid item xs={3} >
                  <ActionButton name="Save" onClick={this.handleSave} fullWidth size="sm" disabled={this.state.disabled} />
                </Grid>
              </Grid>
            </RowContainer>
          </div>
        </div>
      </Modal>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ openSnackbar, closeSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(AddTeam));
