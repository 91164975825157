import React, { Component } from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import moment from 'moment';
// type
import CampaignTableType from './type/CampTableType';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        borderTop: "1px solid #CCCCCC"
      },
      container:{
        display:"flex",
        justifyContent:"space-between",
         padding:"15px",
         boxShadow: "0 5px 10px rgba(154,160,185,0.05),0 15px 40px rgba(166,173,201,0.2)"
      },
  
      tablecelldata: {
        display: "flex",
      },
      startsent: {
        marginLeft: "12px"
      },
      itemname: {
        color: "#00ABC7",
        fontSize: "19px"
      },
      number: {
        fontSize:"14px",
        color:"#5D759D",
        margin: "7px 5px",
      },
      numbertxt:{
        fontSize:"18px",
      color:"#5C749E",
      margin:"7px 0px"
      },
      threedot:{
        color:"#00ABC7",
        width: "35px",height: "35px"
      },
      statustxtactive:{
        borderRadius: "26px",
        width: "max-content !important",
        fontSize: "12px",
        textAlign: "center",
        padding: "2px 13px;",
        fontFamily: "Roboto Bold",
        textTransform: "uppercase",
        marginTop:"4px"
      },
      sentstart:{
        fontSize: 16,
        margin: "5px 0px 4px 0px",
        color:"rgb(157, 169, 189)"
      }

    });

      interface CampTableState{
        tabledata: CampaignTableType[]
      }
      export const CampaignsTableMobile=({campaignsdata}: any)=>{
        const classes = useStyles();
      
      
      const startDate=(date: any)=>{
        const sDate = moment(date).format('MMM DD, YYYY');
        return sDate
       }
    
    
      const getStatusTheme=(status: string)=>{
        switch(status){
          case 'active':
            return {backgroundColor :"#C9F8EF", color:"#00B878"}
          case 'complete':
            return {backgroundColor :"#f9b5b5", color:"white"}
          case 'done':
            return {backgroundColor :"#eef0f3", color:"#627995"}
          default:
            return {backgroundColor :"#c7c7f7", color:"white"}
        }
       }

    const renderCampTable=(item: any)=>{
        return(
        <div>
        <List >
            <div className={classes.container}>
                <div>
                    <div style={{display:"flex"}}>
                        <div>
                            <DateRangeIcon  style={{color:"#00ABC7"}}/>
                        </div>
                    <div className={classes.startsent}>
                        <div className={classes.itemname}>
                        {item.contactName}
                        </div>
                        <div>
                        <p className={classes.sentstart}>Sent: {item.totalSent} of {item.totalContacts}</p>
                        <p className={classes.sentstart}>Start: {startDate(item.startDate)}</p>
                        </div>   
                        <div style={{display:"flex",justifyContent:"space-between"}}>
                            <p className={classes.numbertxt}>Active</p>
                            <p className={classes.number}>{item.totalActive}</p>
                        </div>
                        <div style={{display:"flex",justifyContent:"space-between"}}>
                            <p className={classes.numbertxt}>Clicks</p>
                            <p className={classes.number}>{item.totalClicks}</p>
                        </div>
                        <div  style={{display:"flex",justifyContent:"space-between"}}>
                            <p className={classes.numbertxt}>No Reply</p>
                            <p className={classes.number}>{item.totalNonResponsive}</p>
                        </div>
                        <div  style={{display:"flex",justifyContent:"space-between"}}>
                            <p className={classes.numbertxt}>Opt-Outs</p>
                            <p className={classes.number}>{item.totalOptOuts}</p>
                        </div>
                        <div style={getStatusTheme(item.status)} className={classes.statustxtactive}>
                          {item.status}
                        </div>
                    </div>                                                                   
                </div> 
            </div>
                {/* <div>
                   <MoreHorizIcon  className={classes.threedot} />
                </div> */}
            </div>
 

        </List>
        </div>
    )
  }

      return(
        <div className="campaignTable">
            {
              campaignsdata.map(renderCampTable)
            }        
        </div>  
      )
  
}

export default CampaignsTableMobile;