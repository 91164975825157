import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Auth from '@aws-amplify/auth';

// app
import { Auth as AuthEntity, Session, Signup } from '../../../entities/auth';
import { Message } from '../../../entities/site';
import { setSignup, clearSignup, addAuth, addSession, setMessage, clearMessage, openSnackbar } from '../../../store/action-creators';
import { colors } from '../../../config/styles';
// containers
import AuthContainer from '../containers/Auth';
import RowContainer from '../containers/Row';
// components
import TextInput from '../../../components/inputs/TextInput';
import ActionButton from '../../../components/buttons/ActionButton';
import { MessageTypes } from '../../../components/message/Message';
// services
import UtilsService from '../../../services/utils';


const styles: any = {
  link: {
    color: colors.blue,
    cursor: 'pointer'
  },
  footer: {
    textAlign: 'center',
    fontSize: 12
  }
}

interface ForgotPasswordProps {
  history: any;
  open: any;
  auth: AuthEntity;
  session: Session;
  signup: Signup;
  onClose(route?: string): void
  addAuth(auth: AuthEntity): void;
  addSession(session: Session): void;
  setMessage(message: Message): void;
  clearMessage(): void;
  setSignup(username: string, email: string, name: string, phone: string, isReset: boolean, password: string): void;
  clearSignup(): void;
  openSnackbar(message: string): void;
}

interface ForgotPasswordState {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
  loading: boolean;
  disabled: boolean;
  message: Message;
}

class ForgotPassword extends Component<ForgotPasswordProps, ForgotPasswordState> {

  constructor(props: ForgotPasswordProps) {
    super(props);
    this.state = {
      email: '',
      code: '',
      password: '',
      confirmPassword: '',
      loading: false,
      disabled: false,
      message: {
        message: '',
        messageType: MessageTypes.Notification
      }
    }
  }

  componentDidMount = () => {
    // mounted
  }

  onEmailChange = (event: any) => {
    this.setState({ email: event.target.value });
  }

  onCodeChange = (event: any) => {
    this.setState({ code: event.target.value });
  }

  onPasswordChange = (event: any) => {
    this.setState({ password: event.target.value });
  }

  onConfirmPasswordChange = (event: any) => {
    this.setState({ confirmPassword: event.target.value });
  }

  handleEmailSend = async () => {

    try {

      this.setState({ message: { message: '', messageType: MessageTypes.Notification } });

      if (!this.state.email) {
        this.setState({ message: { message: 'Email required', messageType: MessageTypes.Error } });
        return;
      }

      this.setState({ loading: true, disabled: true });

      await Auth.forgotPassword(this.state.email);

      this.props.setSignup(this.state.email, this.state.email, '', '', true, this.state.password);
      this.props.openSnackbar('Check email to get auth code');


    } catch (error) {

      this.setState({
        message: { message: 'Error sending auth code', messageType: MessageTypes.Error },
        loading: false,
        disabled: false
      });

    }

  }

  handleResend = async () => {
    try {
      
      const username = this.state.email ? this.state.email : this.props.signup.email;

      await Auth.forgotPassword(username);

      this.props.openSnackbar('Auth code has been re-sent');

    } catch (error) {

      console.log('error', error);

      this.setState({
        message: { message: 'Error sending auth code', messageType: MessageTypes.Error },
        loading: false,
        disabled: false
      });

    }
  }

  handleForgotPassword = async () => {

    try {

      this.setState({ message: { message: '', messageType: MessageTypes.Notification } });

      const username = this.state.email ? this.state.email : this.props.signup.email;

      if (!this.state.code) {
        this.setState({ message: { message: 'Auth code required required', messageType: MessageTypes.Error } });
        return;
      }

      if (this.state.password !== this.state.confirmPassword) {
        this.setState({ message: { message: 'Passwords do not match', messageType: MessageTypes.Error } });
        return;
      }

      await Auth.forgotPasswordSubmit(username, this.state.code, this.state.password);

      this.props.clearSignup();
      this.props.onClose();
      this.props.openSnackbar('Password has been updated');
      this.props.history.push('/auth/login');

    } catch (error) {

      this.setState({
        message: { message: 'Error resetting password', messageType: MessageTypes.Error },
        loading: false,
        disabled: false
      });

    }

  }

  resetForm = () => {
    this.setState({
      email: '',
      code: '',
      password: '',
      confirmPassword: '',
      loading: false,
      disabled: false
    });
  }

  render() {
    const isReset = this.props.signup ? this.props.signup.isReset : false;
    return (
      <AuthContainer
        open={this.props.open}
        title="Forgot Password"
        onClose={() => this.props.onClose()}
        message={this.state.message}
      >
        { !isReset &&
          <div>
            <RowContainer>
              <TextInput
                title="Email"
                type="string"
                placeholder=""
                result={this.state.email}
                onChange={this.onEmailChange}
              />
            </RowContainer>
            <RowContainer>
              <ActionButton name="Submit" onClick={this.handleEmailSend} fullWidth size="sm" loading={this.state.loading} disabled={this.state.disabled} />
            </RowContainer>
          </div>
        }
        { isReset &&
          <div>
            <RowContainer>
              <TextInput
                title="Code"
                type="string"
                placeholder=""
                result={this.state.code}
                onChange={this.onCodeChange}
              />
            </RowContainer>
            <RowContainer>
              <TextInput
                title="Password"
                type="password"
                placeholder=""
                result={this.state.password}
                onChange={this.onPasswordChange}
              />
            </RowContainer>
            <RowContainer>
              <TextInput
                title="Confirm Password"
                type="password"
                placeholder=""
                result={this.state.confirmPassword}
                onChange={this.onConfirmPasswordChange}
              />
            </RowContainer>
            <RowContainer>
              <ActionButton name="Reset" onClick={this.handleForgotPassword} fullWidth size="sm" />
            </RowContainer>
          </div>
        }
        <RowContainer>
          <div style={styles.footer}>Not seeing code? <span style={styles.link} onClick={this.handleResend}>Resend</span></div>
        </RowContainer>
      </AuthContainer>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    signup: state.auth.signup,
    session: state.session.session,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ setSignup, clearSignup, addAuth, addSession, setMessage, clearMessage, openSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(ForgotPassword));
