import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

// material
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Inbox } from '@material-ui/icons';

// entites
import { Auth } from '../../../entities/auth';

const drawerWidth = 240;
const navItems = [
    {
        name: 'Campaigns',
        role: 'admin',
        link: '/admin/campaigns'
    },
    {
        name: 'Users',
        role: 'super-admin',
        link: '/admin/users'
    },
    {
        name: 'Addresses',
        role: 'super-admin',
        link: '/admin/addresses'
    },
    {
        name: 'Logs',
        role: 'super-admin',
        link: '/admin/logs'
    }
];
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            marginTop: 60,
            width: drawerWidth,
        },
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
        },
    }),
);

interface AdminNavProps {
    auth: Auth;
    onClick(link: string): void;
}

const AdminNav = (props: AdminNavProps) => {

    const classes = useStyles({});

    return (
        <List>
          {navItems.map((item, index) => (
            <ListItem button key={`item-${index}`} onClick={() => props.onClick(item.link)}>
              <ListItemIcon><Inbox /></ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
    );
}

export default AdminNav;
