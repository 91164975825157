import React, { Component } from 'react';
// material
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
// app
import { Message, MessageTypes } from '../../../entities/site';
// components
import AuthHeader from '../components/AuthHeader';
import AuthMessage from '../components/AuthMessage';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '0 auto',
    minWidth: 400,
    maxWidth: 800,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      minWidth: 275,
    },
  },
}));

interface AuthContainerProps {
  title: string;
  open: boolean;
  message?: Message;
  onClose(data?: any): void;
}

const AuthContainer: React.FC<AuthContainerProps> = (props) => {
    
  const classes = useStyles({});
  const message = props.message || { message: '', messageType: MessageTypes.Notification };
  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
        <AuthHeader title={props.title} onClose={() => props.onClose()} />
        { message.message !== '' && (
          <AuthMessage message={message} />
        )}
        <div className={classes.container}>
          {props.children}
        </div>
      </Dialog>
  )

}
export default AuthContainer;