import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';

const classes = {
  root: {
      background: 'red'
  }
};

interface MessageProps {
  open: boolean;
  msg: string;
  onClose(event: any): void;
}

const Message = (props: MessageProps) => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
    open={props.open}
    onClose={props.onClose}
    autoHideDuration={2000}
    // ContentProps={{
    //   classes: {
    //       root: classes.root
    //   }
    // }}
    classes={{
      root: 'message'
    }}
    message={<span id="message-id" >{props.msg}</span>}
  />
);

export enum MessageTypes {
  Error = "error",
  Notification = "notification",
  Warning = "warning"
}

export default withStyles(classes)(Message);
