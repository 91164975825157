import React from 'react';
import numeral from 'numeral';
// material
import { makeStyles } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';
// components
import PieChart from '../../../components/charts/PieChart';
import RowItem from './RowItem';

interface NewPaymentProps {
  chartData: any;
  monthlyPayment: number;
  payment: number;
  principal: number;
  interest: number;
  taxes: number;
  insurance: number;
}

const NewPayment: React.FC<NewPaymentProps> = (props) => {

  const classes = useStyles({});

  return (
    <div className={classes.container}>
      <div style={{height: 250, position: 'relative'}}>
        <Hidden smDown>
          <div className={classes.monthlyPayment}>{numeral(props.monthlyPayment).format('$0,0')}</div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.monthlyPaymentMobile}>{numeral(props.monthlyPayment).format('$0,0')}</div>
        </Hidden>
        <PieChart data={props.chartData} />
      </div>
      <div className={classes.rowContainer}>
        <RowItem title="Principal" value={numeral(props.principal).format('$0,0')} border padding={10} color="#04A7E5" />
        <RowItem title="Interest" value={numeral(props.interest).format('$0,0')} border padding={10} color="#00CB80" />
        <RowItem title="Taxes" value={`${numeral(props.taxes).format('$0,0')}/mo`} border padding={10} color="#9696F0" />
        <RowItem title="Insurance" value="$85/mo" border padding={10} color="#F68C00" />
      </div>
    </div>
  );
}
export default NewPayment;


const useStyles = makeStyles(theme => ({
  container: {
    padding: 20,
    backgroundColor: '#FFF'
  },
  monthlyPayment: {
    position: 'absolute',
    top: 95,
    left: 220,
    fontSize: 39
  },
  monthlyPaymentMobile: {
    position: 'absolute',
    top: 70,
    left: 120,
    fontSize: 20
  },
  rowContainer: {
    paddingLeft: 40,
    paddingRight: 40
  }
}));