import React, { useState } from "react";
// material
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
// components
import AddressDetails from "./AddressDetails";
import PaymentDetails from "./PaymentDetails";
import NameDetails from "./NameDetails";
// entities
import { Billing, CardDetails } from "../../../../entities/payment";

// components

interface LeftPanelProps {
  billing: Billing;
  carDetails: CardDetails;
  submitted: boolean;
  onBillingChange(name: string, value: string): void;
  onCareDetailsChange(name: string, value: string): void;
}

const LeftPanel: React.FC<LeftPanelProps> = (props) => {
  const classes = useStyles({});

  if (props.submitted) {
    return (
      <div>
        <div className={classes.creditCard}>
          <CreditCardIcon />{" "}
          <div className={classes.creditCardLabel}>Credit Card</div>
        </div>
        <div style={{ marginTop: 20, marginBottom: 40 }}>
          <div className={classes.text}>{props.billing.name}</div>
          <div className={classes.text}>{props.billing.address}</div>
          <div className={classes.text}>
            {props.billing.city}, {props.billing.state} {props.billing.zipCode}
          </div>
        </div>
        <div>
          <div className={classes.detailsName}>Payment Details</div>
          <div className={classes.text} style={{ marginTop: 15 }}>
            **** **** **** {props.carDetails.cardNumber.slice(-4)}
            <div>
              {props.carDetails.month}/{props.carDetails.year}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <NameDetails name={props.billing.name} onChange={props.onBillingChange} />
      <AddressDetails
        address={props.billing.address}
        city={props.billing.city}
        state={props.billing.state}
        zipCode={props.billing.zipCode}
        onChange={props.onBillingChange}
      />
      <PaymentDetails
        cardNumber={props.carDetails.cardNumber}
        year={props.carDetails.year}
        month={props.carDetails.month}
        csv={props.carDetails.csv}
        onChange={props.onCareDetailsChange}
      />
    </React.Fragment>
  );
};
export default LeftPanel;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    creditCard: {
      padding: "20px 37px 20px 20px",
      height: 70,
      width: 200,
      borderRadius: 2,
      backgroundColor: "#F8F8F8",
      display: "flex",
      alignItems: "center",
    },
    creditCardLabel: {
      height: 19,
      width: 81,
      color: "#999999",
      fontFamily: "Asap",
      fontSize: 16,
      fontWeight: 500,
      marginLeft: 22,
    },
    detailsName: {
      width: 366,
      color: "#333333",
      fontFamily: "Asap",
      fontSize: 18,
    },
    text: {
      color: "#666666",
      fontFamily: "Asap",
      fontSize: 16,
    },
  })
);
