import React from 'react';
import { ResponsivePie } from '@nivo/pie'

interface PieChartProps {
    data: any;
}

const PieChart: React.FC<PieChartProps> = (props) => {
    return (
      <ResponsivePie
        data={props.data}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        innerRadius={0.7}
        padAngle={0}
        cornerRadius={0}
        activeOuterRadiusOffset={8}
        colors={['#04A7E5', '#00CB80', '#9696F0', '#F68C00']}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
        enableArcLinkLabels={false}
        enableArcLabels={false}
    />
    );
}

export default PieChart;