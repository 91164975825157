import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Auth from '@aws-amplify/auth';
import axios from 'axios';
import { isEmpty } from 'lodash';
import numeral from 'numeral';

// app
import { Auth as AuthEntity, Session, Signup } from '../../../entities/auth';
import { APIUrl } from '../../../entities/site';
import { addAuth, addSession, updateSession, clearSignup } from '../../../store/action-creators';
import { colors } from '../../../config/styles';
import UtilsService from '../../../services/utils';
// containers
import AuthContainer from '../containers/Auth';
import RowContainer from '../containers/Row';
// components
import TextInput from '../../../components/inputs/TextInput'; 
import ActionButton from '../../../components/buttons/ActionButton';
// entities
import { Message, MessageTypes } from '../../../entities/site';
import { Mortgage } from '../../../entities/profile';
// service 
import AuthService from '../../../services/auth';


const styles: any = {
  link: {
    color: colors.blue,
    cursor: 'pointer'
  },
  footer: {
    textAlign: 'center',
    fontSize: 12
  },
  notification: {
    padding: 5,
    textAlign: 'center',
    color: '#56D8A6',
    backgroundColor: '#BFC6D0'
  }
}

interface ConfirmCodeProps {
  open: any;
  signup: Signup;
  auth: AuthEntity;
  session: Session;
  apiUrls: APIUrl;
  onClose(route?: string): void;
  addAuth(auth: AuthEntity): void;
  addSession(session: Session): void;
  updateSession(session: Session): void;
  clearSignup(): void;
}

interface ConfirmCodeState {
  code: string;
  notification: string;
  message: Message;
  disabled: boolean;
}

class ConfirmCode extends Component<ConfirmCodeProps, ConfirmCodeState> {
  
  constructor(props: ConfirmCodeProps) {
    super(props);
    this.state = {
      code: '',
      notification: '',
      message: {
        message: '',
        messageType: MessageTypes.Notification
      },
      disabled: false
    }
  }

  componentDidMount = () => {
    // mounted
  }

  onCodeChange = (event: any) => {
    this.setState({code: event.target.value});
  }

  handleConfirmcode = async () => {

    try {

      if (!this.state.code) {
        this.setState({message:{message: 'Code required', messageType: MessageTypes.Error}});
        return;
      }

      if (!this.props.signup || !this.props.signup.username) {
        this.setState({message:{message: 'Username required', messageType: MessageTypes.Error}});
        return;
      }

      this.setState({disabled: true});

      // confirm code
      const confirm = await Auth.confirmSignUp(this.props.signup.username, this.state.code);

       // get real token
      const signIn = await Auth.signIn(this.props.signup.username, this.props.signup.password);

      // get current session
      const authSession: any = await Auth.currentSession();

      // store user
      const userData = {
        userId: signIn.username,
        email: this.props.signup.email,
        name: this.props.signup.name,
        phone: this.props.signup.phone
      };
      const newUserRes = await axios.post(`${AuthService.getApiUrls().user}/user`, userData);

      // get fresh copy of user
      const userRes = await axios.get(`${AuthService.getApiUrls().user}/user/${newUserRes.data.id}`)

      // store token
      const auth: AuthEntity = {
        id: signIn.username,
        token: authSession.accessToken.jwtToken,
        payload: authSession.accessToken.payload,
        user: userRes.data
      }
      this.props.addAuth(auth);

      this.props.clearSignup();
      this.props.onClose('/');

    } catch (error) {
      this.setState({disabled: false});
      this.setState({message:{message: 'Error confirming code', messageType: MessageTypes.Error}});
      await UtilsService.logError(AuthService.getApiUrls().core, 'ConfirmCode', 'handleConfirmcode', error);
    }

  }

  handleResendCode = () => {

    if (!this.props.signup || !this.props.signup.username) {
      this.setState({message:{message: 'Username required', messageType: MessageTypes.Error}});
      return;
    }

    Auth.resendSignUp(this.props.signup.username).then(() => {
      this.setState({notification: 'Confirm code has been resent.'});
    }).catch(e => {
      this.setState({message:{message: 'Error resending code', messageType: MessageTypes.Error}});
    });

  }

  handleLogin = () => {
    // close and open login
    this.props.onClose('login');
  }

  render() {
    return ( 
      <AuthContainer 
        open={this.props.open} title="Confirm Code" 
        onClose={this.props.onClose}
        message={this.state.message}
      >
        { this.state.notification !== '' &&
          <div style={styles.notification}>{this.state.notification}</div>
        }
        <RowContainer>Please check your email and enter confirm code.</RowContainer>
        <RowContainer>
          <TextInput 
            type="number"
            placeholder=""
            result={this.state.code}
            onChange={this.onCodeChange} 
          />
        </RowContainer>
        <RowContainer>
          <ActionButton name="Confirm" onClick={this.handleConfirmcode} size="sm" fullWidth disabled={this.state.disabled} />
        </RowContainer>
        <RowContainer>
          <div style={styles.footer}>Already confirmed code? <span style={styles.link} onClick={this.handleLogin}>Login</span></div>
          <div style={styles.footer}>Resend code? <span style={styles.link} onClick={this.handleResendCode}>Resend</span></div>
        </RowContainer>
      </AuthContainer>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    signup: state.auth.signup,
    auth: state.auth.auth,
    session: state.session.session,
    apiUrls: state.site.apiUrls
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ addAuth, addSession, updateSession, clearSignup }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(ConfirmCode));
