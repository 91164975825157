import React from 'react';
// import { useLocation } from 'react-router-dom'

import {Grid } from '@material-ui/core';

interface AuthenticatedContainerProps {
  children: any;
}

const LoanProfileContainer: React.FC<AuthenticatedContainerProps> = (props) => {

  // const location = useLocation();
  const location = { pathname: '' };
  return (
    <div className="profile-page">
      <Grid container spacing={5}>
        <Grid item md={2}>
          <div className="tabs">
            <a href="/profile" className={location.pathname === '/profile' ? 'selected' : undefined }>
              Profile
            </a>
            <a href="/campaigns" className={location.pathname === '/campaigns' ? 'selected' : undefined }>
              Campaigns
            </a>
            <a href="/organizations" className={location.pathname === '/organizations' ? 'selected' : undefined }>
              Organizations
            </a>
          </div>
        </Grid>
        <Grid item md={10}>
          <div>
            {props.children}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default LoanProfileContainer;
