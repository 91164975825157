import React, { useState } from 'react';
import { find, findIndex } from 'lodash';
// material
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// components
import TextInput from '../inputs/TextInput';
import SelectInput, { SelectItem } from '../inputs/SelectInput';
import ActionButton from '../../components/buttons/ActionButton';
// entities
import { Campaign, Message, MessageTypes, emptyMessage } from '../../entities/site';
// containers
import Modal from '../../containers/Modal';

export interface MappedHeader {
  supportedColumn: string;
  column: string;
  index: number;
}

interface AddCampaignProps {
  open: boolean;
  headers: SelectItem[];
  onClose(mappedHeaders: MappedHeader[]): void;
}

const supportedHeaders: string[] = [
  'firstName',
  'middleName',
  'lastName',
  'address',
  'city',
  'state',
  'zip',
  'lender',
  'loanAmount',
  'loanReceivedData',
  'loanType',
  'homeValue',
  'phoneNumber',
  'refId'
];

const AddCampaign: React.FC<AddCampaignProps> = (props) => {

  const classes = useStyles({});
  const [mappedHeaders, setMappedHeaders] = useState<MappedHeader[]>([]);
  const [modalMessage, setModalMessage] = useState<Message>(emptyMessage)

  const handleChange = (header: string, newHeader: any) => {
    const newMapped = [...mappedHeaders];
    const searchIndex = findIndex(newMapped, { supportedColumn: header });
    if (searchIndex !== -1) {
      newMapped[searchIndex].column = newHeader;
    } else {
      newMapped.push({
        supportedColumn: header,
        column: newHeader,
        index: -1
      });
    }
    setMappedHeaders(newMapped);
  }

  const getValue = (header: string) => {
    const search = find(mappedHeaders, { supportedColumn: header });
    if (search) {
      return search.column;
    }
    return 'empty';
  }

  return (
    <Modal
      open={props.open}
      title="Select Headers"
      width={600}
      onClose={() => props.onClose([])}
      message={modalMessage}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Current</TableCell>
            <TableCell>Supported</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {supportedHeaders.map((header: string, index: number) => (
            <TableRow key={`header-${index}`}>
              <TableCell style={{padding: 5}}>{header}</TableCell>
              <TableCell style={{padding: 5}}>
                <SelectInput
                  title=""
                  value={getValue(header)}
                  values={props.headers}
                  onChange={(event: any) => handleChange(header, event.target.value)}
                  fullWidth
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ActionButton name="save" onClick={() => props.onClose(mappedHeaders)} fullWidth />
    </Modal>
  );

}
export default AddCampaign;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 10
    }
  })
);
