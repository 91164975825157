import Auth from '@aws-amplify/auth'; 
import axios from 'axios';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { compact, isEmpty } from 'lodash';
import store from '../store/';
import { User } from '../entities/auth';

export enum Roles {
  User="user",
  Loanofficer="loanofficer",
  Branchmanager="branchmanager",
  Admin="admin",
  Superadmin="superadmin",
  Orgadmin="orgadmin",
  Textadmin="textadmin"
}

class AuthService {

  static getUser(): User | null {

    const reduxStore: any = store.getState();
    const auth: any = reduxStore.auth.auth;
    
    if (isEmpty(reduxStore.auth.auth)) {
      return null;
    }

    return {
      userId: auth.id,
      name: auth.user.name,
      email: auth.user.email,
      phone: auth.user.phone
    };

  }

  static getRoles(): string[] {
    const roles: string[] = [];
    const reduxStore: any = store.getState();
    const auth = (!isEmpty(reduxStore.auth.auth.user) ? reduxStore.auth.auth.user : null);

    if (!auth) {
      return [];
    }

    const parts = auth.role.split(',');
    parts.forEach((part: string) => {
      roles.push(part.trim());
    });

    return roles;

  }

  static isAuthorized(routeRoles: string[]): boolean {

    try {

      const reduxStore: any = store.getState();
      const auth = (!isEmpty(reduxStore.auth.auth.user) ? reduxStore.auth.auth.user : null);

      console.log('auth', auth)

      if (!auth) {
        return false;
      }

      // const cognitoUser = await Auth.currentAuthenticatedUser();

      if (AuthService.checkUserRoles(auth.role, routeRoles)) {
        return true;
      }
      return false;

    } catch (error) {
      return false;
    }
  }

  static getRedirectRoute(): string {

    const loginRoute = '/auth/login';
    try {

      const reduxStore: any = store.getState();
      const auth = (!isEmpty(reduxStore.auth.auth.user) ? reduxStore.auth.auth.user : null);

      if (auth.status && auth.status === 'reset') {
        return '/auth/reset';
      }

      if (auth.status && auth.status === 'confirm') {
        return '/auth/confirm';
      }

      return loginRoute;
    
    } catch (error) {
      return loginRoute;
    }


  }

  static checkUserRoles(userRoles: string, routeRoles: string[]): boolean {
    let hasRole = false;
    routeRoles.forEach((role: string) => {
      if (userRoles.indexOf(role) !== -1) {
        hasRole = true;
      }
    });
    return hasRole;
  }

  static refreshToken(): Promise<CognitoUserSession> {
    
    return new Promise( async (resolve, reject) => {

      try {
        
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        const refreshToken = currentSession.getRefreshToken();
        cognitoUser.refreshSession(refreshToken, (err: any, session: CognitoUserSession) => {
          
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }

        });
        
      } catch (e) {
        console.log('Unable to refresh Token', e);
      }
    
    });

  }

  static createUser(email: string, password: string, name: string, phone: string, autoConfirm: boolean, forceReset: boolean): Promise<{ id: string }> {

    return new Promise(async (resolve, reject) => {

      try {

        // clean number
        phone = phone.replace(' ', '');
        phone = phone.replace('(', '');
        phone = phone.replace(')', '');
        phone = phone.replace('-', '');

        const data: any = {
          username: email,
          password,
          attributes: {
            name
          },
          clientMetadata: {
            autoConfirmUser: autoConfirm.toString()
          }
        };

        if (phone) {
          data.attributes.phone_number = `+1${phone}`;
        }

        const signupRes: any = await Auth.signUp(data);

        const userData = {
          userId: signupRes.userSub,
          email,
          name,
          phone,
          status: (forceReset ? 'reset' : 'active')
        };
        const userRes = await axios.post(`${AuthService.getApiUrls().user}/user`, userData);

        // send email
        const emailData = {
          to: email,
          templateName: 'bvm_signup3',
          password,
          url: 'https://homewizedev.bigvalleymortgage.com/auth/login'
        };
        await axios.post(`${AuthService.getApiUrls().core}/sendemail`, emailData);


        resolve(userRes.data);

      } catch (e) {
        reject('Unable to create user');
      }

    });

  }

  static getApiUrls(): any {
    return {
      core: 'https://3pmu6xobkg.execute-api.us-west-2.amazonaws.com/dev',
      home: 'https://elsvt1aoxa.execute-api.us-west-2.amazonaws.com/dev',
      // home: 'http://localhost:4001/dev',
      integrations: 'https://daiequr87h.execute-api.us-west-2.amazonaws.com/dev',
      // integrations: 'http://localhost:4006/dev',
      user: 'https://v5m6mqvgs7.execute-api.us-west-2.amazonaws.com/dev',
      // user: 'http://localhost:4002/dev',
      notifications: 'https://f03hx98ote.execute-api.us-west-2.amazonaws.com/dev',
      site: 'https://e4yhtugwme.execute-api.us-west-2.amazonaws.com/dev',
      // site: 'http://localhost:4004/dev',
      // payment: 'http://localhost:4005',
      payment: 'https://rnf44z55z3.execute-api.us-west-2.amazonaws.com/dev',
    }
  }
}

export default AuthService;