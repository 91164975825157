import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useLocation } from 'react-router-dom'

import { List, ListItem, ListItemText, Grid } from '@material-ui/core';
import HasAccess from '../components/auth/HasAccess';

// state
import state from '../store/index';

interface AuthenticatedContainerProps {
  children: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}))

function ListItemLink(props: any) {
  return <ListItem
    button
    component="a" {...props}
  />;
}

const ProfileContainer: React.FC<AuthenticatedContainerProps> = (props) => {

  // const location = useLocation();

  const location = { pathname: '' };

  return (
    <div className="profile-page">
      <Grid container spacing={5}>
        <Grid item md={2}>
          <div className="tabs">
            <a href="/profile" className={location.pathname === '/profile' ? 'selected' : undefined }>
              Profile
            </a>
            <a href="/notifications" className={location.pathname === '/notifications' ? 'selected' : undefined }>
              Notifications
            </a>
            {/* 
            <a href="/campaigns" className={location.pathname === '/campaigns' ? 'selected' : undefined }>
              Campaigns
            </a> 
            */}
            <HasAccess roles={["admin", "superadmin"]}>
              <a href="/organizations" className={location.pathname === '/organizations' ? 'selected' : undefined }>
                Organizations
              </a>
            </HasAccess>
            <HasAccess roles={["superadmin"]}>
              <a href="/admin/users" className={location.pathname === '/admin/users' ? 'selected' : undefined }>
                Users
              </a>
            </HasAccess>
          </div>
        </Grid>
        <Grid item md={10}>
          <div>
            {props.children}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default ProfileContainer;
