import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import numeral from 'numeral';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { Grid, Hidden, Container } from '@material-ui/core';
// app
import { updateSession, setApiUrls, addAuth, openSnackbar, closeSnackbar, setSignup } from '../../store/action-creators';
// containers
import AppContainer from '../../containers/App';
// models
import { Auth, Session, Signup } from '../../entities/auth';
import { APIUrl, LeadTypes, Lead as LeadEntity } from '../../entities/site';
// components
import Loading from '../../components/site/Loading';
import HomeBox from './components/HomeBox';
import BannerImage from '../../containers/BannerImage';
import HomeSection from './components/HomeSection';
import HomeSectionEmail from './components/HomeSectionEmail';
import HomeWelcomePackage from './components/HomeWelcomePackage';
import HomeSectionContact from './components/HomeSectionContact';
import Lead from '../../components/site/Lead';
// services
import Utils from '../../services/utils';
import AuthService from '../../services/auth';

const useProd = false;

// images
const iconPmi = process.env.PUBLIC_URL + '/images/icons/icon_pmi.svg';
const iconPdc = process.env.PUBLIC_URL + '/images/icons/icon_pdc.svg';
const iconDreamRate = process.env.PUBLIC_URL + '/images/icons/icon_dream_rate.svg';
const iconReverseMortgage = process.env.PUBLIC_URL + '/images/icons/icon_reverse_mortgage.svg';

const iconSolar = process.env.PUBLIC_URL + '/images/icons/icon_solar.svg';
const iconComparables = process.env.PUBLIC_URL + '/images/icons/icon_comparables.svg';
const iconEquity = process.env.PUBLIC_URL + '/images/icons/icon_equity.svg';
const iconCashFlow = process.env.PUBLIC_URL + '/images/icons/icon_cash_flow.svg';

const iconWelcome = process.env.PUBLIC_URL + '/images/icons/icon_welcome.svg';
const iconDownPayment = process.env.PUBLIC_URL + '/images/icons/icon_down_payment.svg';
const iconReverseMortgageHome = process.env.PUBLIC_URL + '/images/icons/icon_reverse_mortgage.svg';
const iconLoan = process.env.PUBLIC_URL + '/images/icons/icon_loan.svg';

const backgroundImage = process.env.PUBLIC_URL + '/images/background_homewise_dash.jpg';
const logo = process.env.PUBLIC_URL + '/images/logo_homewize_white.svg';

const homeDreamRate = process.env.PUBLIC_URL + '/images/img_home_dreamrate.png';
const homePaymentDesignCenter = process.env.PUBLIC_URL + '/images/img_home_paymentdesign.png';
const homePMI = process.env.PUBLIC_URL + '/images/img_home_PMI.png';
const homeSearchPayment = process.env.PUBLIC_URL + '/images/img_home_searchbypayment.png';
const homeSolar = process.env.PUBLIC_URL + '/images/img_home_solar.png';
const welcomePacket = process.env.PUBLIC_URL + '/images/img_home_packet.png';

/*

PMI REMOVAL: https://hooks.zapier.com/hooks/catch/3371176/erx4qo/
Payment Design: https://hooks.zapier.com/hooks/catch/3371176/erxhxm/
Dream Rate: https://hooks.zapier.com/hooks/catch/3371176/erxdhh/
Search By Payment: https://hooks.zapier.com/hooks/catch/3371176/erxdpo/
Solar Sun Score: https://hooks.zapier.com/hooks/catch/3371176/erxm5x/

*/


const styles: any = {
  container: {
    paddingBottom: 200
  },
  title: {
    color: '#FFF',
    fontSize: 26,
    marginBottom: 10,
    textAlign: 'center'
  },
  subTitle: {
    color: '#FFF',
    fontSize: 16,
    textAlign: 'center'
  },
  logo: {
    width: 225
  }
}

interface HomePageProps {
  history: any;
  auth: Auth;
  session: Session;
  signup: Signup;
  apiUrls: APIUrl;
  setSignup(username: string, email: string, name: string, phone: string, isReset: boolean): void;
  addAuth(auth: Auth): void;
  updateSession(session: Session): void;
  setApiUrls(urls: APIUrl): void;
  openSnackbar(message: string): void;
  closeSnackbar(): void;
}

interface HomePageState {
  email: string;
  address: string;
  loading: boolean;
  openLead: boolean;
  leadType: string;
  leadEmail: string;
}

class HomePage extends Component<HomePageProps, HomePageState> {

  constructor(props: HomePageProps) {
    super(props);
    this.state = {
      email: '',
      address: '',
      loading: false,
      openLead: false,
      leadType: "",
      leadEmail: ""
    }
  }

  componentDidMount = () => {
    // get location
    this.getLocation();
  }

  getLocation = () => {
    const newSession = this.props.session;
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        newSession.coords = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }
        this.props.updateSession(newSession);
      }, (error) => {
        axios.get('http://ip-api.com/json').then((res) => {
          newSession.coords = {
            latitude: res.data.lat,
            longitude: res.data.lon
          }
          this.props.updateSession(newSession);
        });
      });
    } else {
      axios.get('http://ip-api.com/json').then((res) => {
        newSession.coords = {
          latitude: res.data.lat,
          longitude: res.data.lon
        }
        this.props.updateSession(newSession);
      });
    }
  }

  handleBoxClick = (key: string) => {
    const elem = document.getElementById(key);
    if (elem) {
      elem.scrollIntoView();
    }
  }

  sendLead = async (name: string, email: string, phone: string, message?: string, type?: string) => {
    
    try {

      this.setState({openLead: false});

      const newLead: LeadEntity = {
        id: "",
        type: (type ? type : this.state.leadType),
        name,
        email,
        phone,
        message: (message ? message : ""),
        addressId: "",
        address: "",
        estimatedValue: 0,
        loanBalance: 0,
        dreamRate: 0,
        createdDate: 0
      }

      axios.post(`${AuthService.getApiUrls().site}/lead`, newLead);
      this.setState({openLead: false});

      this.props.openSnackbar('request sent');

    } catch (error) {
      console.log('error', error);
      this.setState({openLead: false});
    }

  }

  handleLeadClose = (completed: boolean) => {
    this.setState({openLead: false});
    if (!completed && this.state.leadEmail) {
      // send incomplete lead
      // this.sendLead("", this.state.leadEmail, "", "", LeadTypes.incomplete);
    }
  } 

  handleEmailClick = (email: string, type: string) => {
    this.setState({
      openLead: true,
      leadType: type,
      leadEmail: email
    });
  }

  handleContactClick = (name: string, email: string, message: string) => {
    this.sendLead(name, email, '', message, LeadTypes.Contact);
  }

  render() {

    return (
      <AppContainer showOption={false} private={false} maxWidth={false} noPadding>
        <Loading open={this.state.loading} />
        <Lead
          open={this.state.openLead}
          email={this.state.leadEmail}
          onClick={this.sendLead}
          onClose={this.handleLeadClose}
        />
        <div style={styles.container}>
          <BannerImage image={backgroundImage} >
            <Container maxWidth="md" style={styles.container}>
              <div style={{ maxWidth: 1000, margin: '0px auto', padding: 40 }}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <div style={styles.title}>Mortgage & Real Estate Insight</div>
                    <div style={styles.subTitle}>We have the helpful tools and insights to answer your mortgage and real estate questions.</div>
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                  <HomeBox
                    title="PMI/MIP Removal"
                    description="See if you can remove your mortgage insurance."
                    keyName="pmi"
                    icon={iconPmi}
                    enabled
                    onClick={() => this.handleBoxClick('pmi-removal')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <HomeBox
                    title="Payment Design Center"
                    description="Do you wan to design your very own payment?  Now you can."
                    keyName="payment"
                    icon={iconPdc}
                    enabled
                    onClick={() => this.handleBoxClick('payment-design-center')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <HomeBox
                    title="Dream Rate"
                    description="We can alert you when your dream rate becomes available."
                    keyName="dream"
                    icon={iconDreamRate}
                    enabled
                    onClick={() => this.handleBoxClick('dream-rate')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <HomeBox
                    title="Welcome Package"
                    description="Includes down payment assistance qualifications"
                    keyName="welcome-package"
                    icon={iconCashFlow}
                    enabled
                    onClick={() => this.handleBoxClick('welcome-package')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <HomeBox
                    title="Solar Sun Score"
                    description="Check out your score and see if solar makes sense for you."
                    keyName="solar"
                    icon={iconSolar}
                    enabled
                    onClick={() => this.handleBoxClick('solar-sun-score')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <HomeBox
                    title="Search by Payment"
                    description="Enter the payment you can afford and see your available options."
                    keyName="search"
                    icon={iconReverseMortgage}
                    enabled={false}
                    onClick={this.handleBoxClick}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4}>
                  <HomeBox
                    title="Investor Cash Flow"
                    description="Search for rental properties that you can purchase with your equity."
                    keyName="investor"
                    icon={iconCashFlow}
                    enabled={false}
                    onClick={this.handleBoxClick}
                  />
                </Grid> */}
              </Grid>
            </Container>
          </BannerImage>
          <HomeSection
            id="pmi-removal"
            orientation="right"
            icon={iconPmi}
            image={homePMI}
            title="PMI/MIP Removal"
            description="See if you can remove your mortgage insurance by entering your address below."
            session={this.props.session}
          />
          <HomeSection
            id="payment-design-center"
            orientation="left"
            icon={iconPdc}
            image={homePaymentDesignCenter}
            title="Payment Design Center"
            description="Do you want to design your very own payment?  Now you can by just entering your address below."
            session={this.props.session}
          />
          <HomeSection
            id="dream-rate"
            orientation="right"
            icon={iconCashFlow}
            image={homeDreamRate}
            title="Dream Rate"
            description="See if you can remove your mortgage insurance by entering your address below."
            session={this.props.session}
          />
          <HomeSectionEmail
            id="solar-sun-score"
            orientation="left"
            icon={iconSolar}
            image={homeSolar}
            title="Solar Sun Score"
            description="Check out your score and see if solar makes sense for you."
            session={this.props.session}
            onClick={(email: string) => this.handleEmailClick(email, LeadTypes.SolarSunScore)}
          />
          <HomeWelcomePackage
            id="welcome-package"
            orientation="right"
            icon={iconCashFlow}
            image={welcomePacket}
            title="Welcome Package"
            description="Includes down payment assistance qualifications"
            session={this.props.session}
            onClick={(email: string) => this.handleEmailClick(email, LeadTypes.WelcomePackage)}
          />
          <HomeSectionContact
            id="contact"
            orientation="right"
            image={welcomePacket}
            title="Welcome Package"
            session={this.props.session}
            onClick={this.handleContactClick}
          />
        </div>
      </AppContainer>
    )
  }


}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session,
    signup: state.auth.signup,
    apiUrls: state.site.apiUrls
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ updateSession, setApiUrls, addAuth, openSnackbar, closeSnackbar, setSignup }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(HomePage));
