import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios';

import { Grid } from '@material-ui/core';
// app
import { Auth as AuthEntity } from '../../../entities/auth';
import { Message, MessageTypes, APIUrl } from '../../../entities/site';
import { openSnackbar, closeSnackbar } from '../../../store/action-creators';
// containers
import RowContainer from '../../../containers/Row';
// components
import TextInput from '../../../components/inputs/TextInput';
import ActionButton from '../../../components/buttons/ActionButton';
import Loading from '../../../components/site/Loading';
// services
import AuthService from '../../../services/auth';

import Modal from '../../../containers/Modal';

interface AddOrganizationProps {
  open: any;
  auth: AuthEntity;
  onClose(reload: boolean): void
  openSnackbar(message: string): void;
  closeSnackbar(): void;
}

interface AddOrganizationState {
  loading: boolean;
  name: string;
  description: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  message: Message;
  disabled: boolean;
}

class AddOrganization extends Component<AddOrganizationProps, AddOrganizationState> {
  
  constructor(props: AddOrganizationProps) {
    super(props);
    this.state = {
      loading: false,
      name: '',
      description: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      message: {
        message: '',
        messageType: MessageTypes.Notification
      },
      disabled: false
    }
  }

  componentDidMount = () => {
    // mounted
  }

  onNameChange = (event: any) => {
    this.setState({name: event.target.value});
  }

  onDescriptionChange = (event: any) => {
    this.setState({description: event.target.value});
  }

  onAddressChange = (event: any) => {
    this.setState({address: event.target.value});
  }

  onCityChange = (event: any) => {
    this.setState({city: event.target.value});
  }

  onStateChange = (event: any) => {
    this.setState({state: event.target.value});
  }

  onZipCodeChange = (event: any) => {
    this.setState({zipCode: event.target.value});
  }

  handleSave = async () => {
    // sign up
    try {
      if (!this.checkForm()) {
        return;
      }

      this.setState({
        disabled: true
      });

      const dataTeam = {
        name: this.state.name,
        description: this.state.description,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        zipCode: this.state.zipCode,
        type: 'organization'
      };

      // save new team
      const teamRes = await axios.post(`${AuthService.getApiUrls().site}/team`, dataTeam);

      // add current user to team
      const dataTeamUser = {
        teamId: teamRes.data.id,
        userId: this.props.auth.id,
        teamName: this.state.name,
        teamType: 'organization',
        name: (this.props.auth.user ? this.props.auth.user.name : ''),
        email: (this.props.auth.user ? this.props.auth.user.email : ''),
        role: 'creator'
      };

      await axios.post(`${AuthService.getApiUrls().site}/team/user`, dataTeamUser);

      this.props.openSnackbar('Successfully saved organization')
      this.handleClose(true);
     
    } catch (error) {
      this.setState({disabled: false});
      this.setState({message:{message: 'Error saving organization', messageType: MessageTypes.Error}});
    }
  }

  resetForm = () => {
    this.setState({
      disabled: false,
      loading: false,
      name: '',
      description: '',
      address: '',
      city: '',
      state: '',
      zipCode: ''
    });
  }

  checkForm = () => {
    let error = '';
    if (!this.state.name) {
      error = 'Name required';
    }
    if (!this.state.address) {
      error = 'Address required';
    }
    if (!this.state.city) {
      error = 'City required';
    }
    if (!this.state.state) {
      error = 'State required';
    }
    if (!this.state.zipCode) {
      error = 'ZipCode required';
    }
    if (!error) {
      this.setState({message: {message: error, messageType: MessageTypes.Error}});
      return true;
    }
    return false;
  }

  handleClose = (reload: boolean) => {
    this.resetForm();
    this.props.onClose(reload);
  }

  render() {
    return ( 
      <Modal 
        open={this.props.open} 
        title="Add Organization" 
        onClose={() => this.handleClose(false)}
        message={this.state.message}
      >
        <div>
          <Loading open={this.state.loading} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput 
                title="Name"
                type="string"
                placeholder=""
                result={this.state.name}
                onChange={this.onNameChange} 
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput 
                title="Address"
                type="string"
                placeholder=""
                result={this.state.address}
                onChange={this.onAddressChange} 
              />
            </Grid>
            <Grid item xs={5}>
              <TextInput 
                title="City"
                type="string"
                placeholder=""
                result={this.state.city}
                onChange={this.onCityChange} 
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput 
                title="State"
                type="string"
                placeholder=""
                result={this.state.state}
                onChange={this.onStateChange} 
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput 
                title="ZIP Code"
                type="string"
                placeholder=""
                result={this.state.zipCode}
                onChange={this.onZipCodeChange} 
              />
            </Grid>
          </Grid>
          <RowContainer>
            <Grid container spacing={1} justify="flex-end">
              <Grid item xs={3} >
                <ActionButton name="Cancel" onClick={() => this.handleClose(false)} fullWidth size="sm" disabled={this.state.disabled} type="secondary" />
              </Grid>
              <Grid item xs={3} >
                <ActionButton name="Save" onClick={this.handleSave} fullWidth size="sm" disabled={this.state.disabled} />
              </Grid>
            </Grid>
          </RowContainer>
        </div>
      </Modal>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ openSnackbar, closeSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(AddOrganization));
