import React, { useState } from 'react';
// material
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const CampaignContactHeader: React.FC<{}> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.columnHeader}>Name</div>
      <div className={classes.columnHeader}>Phone</div>
      <div className={classes.columnHeader}>Email</div>
      <div className={classes.columnHeader}>City</div>
      <div className={classes.columnHeaderLast}>Status</div>
    </div>
  );

}

export default CampaignContactHeader;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      paddingBottom: 10
    },
    columnHeader: {
      width: '30%',
      textAlign: 'left',
      color: '#627995',
    },
    columnHeaderLast: {
      width: '10%',
      textAlign: 'left',
      color: '#627995',
    },
  })
);